import React from "react";
import {
  Flex,
  Link,
  Menu,
  MenuButton,
  Icon,
  Text,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";

import NavHoverBox from "./NavHoverBox";

export default function NavItem({ navSize, title, icon, active, pageLink, setActive }) {
  const hoverColor = useColorModeValue("gray.100", "gray.600");
  const color = useColorModeValue("teal.300", "teal.300");

  return (
    <Flex
      mt={3}
      flexDir="column"
      w="100%"
      alignItems={navSize === "small" ? "center" : "flex-start"}
    >
      <Menu placement="right">
        <Link
          p={3}
          as={ReachLink}
          to={pageLink}
          borderRadius={8}
          _hover={{ textDecor: "none", backgroundColor: hoverColor }}
          w={navSize === "large" && "100%"}
          onClick={() => setActive(title)}
        >
          <MenuButton w="100%">
            <Flex alignItems="center">
              <Icon as={icon} fontSize="xl" color={active ? color : "gray.500"} />
              <Text
                ml={5}
                display={navSize === "small" ? "none" : "flex"}
                color={active && color}
                fontWeight={active && 600}
              >
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
        {/* {!description ? (
          <MenuList py={0} border="none" w={200} h={100} ml={5} overflow="hidden">
            <NavHoverBox title={title} icon={icon} description={description} />
          </MenuList>
        ) : null} */}
      </Menu>
    </Flex>
  );
}
