import axios from "axios";
import moment from "moment";

const end_date = moment().add(1, "d").format("YYYY-MM-DD");
const start_date = moment().format("YYYY-MM-DD");

const addNewRobospamNumber = async (payload) => {
  try {
    const response = await axios.post("api/robospam/add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editRobospamNumber = async (payload) => {
  try {
    const response = await axios.post("api/robospam/edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteRobospamNumber = async (payload) => {
  try {
    const response = await axios.post("api/robospam/delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllRoboSpamNumbers = async () => {
  try {
    const response = await axios.get("api/robospam");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const findOneRoboSpamNumber = async (payload) => {
  try {
    const response = await axios.post("api/robospam/find", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

export {
  addNewRobospamNumber,
  editRobospamNumber,
  deleteRobospamNumber,
  getAllRoboSpamNumbers,
  findOneRoboSpamNumber,
};
