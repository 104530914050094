import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useColorModeValue,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import DncModal from "../components/dnc/DncModal";
import { addToLP, addToRB } from "../components/dnc/insertDncToAll";
import TopHeading from "../components/TopHeading";

import { FiRefreshCcw } from "react-icons/fi";
import { FaFileExport } from "react-icons/fa";

import { CSVLink } from "react-csv";
import moment from "moment";

const axios = require("axios");

export default function DncList() {
  const [dncList, setDncList] = useState();
  const [headersState, setHeadersState] = useState();
  const [loading, setLoading] = useState(false);
  const fileName = `dnc_list_${moment().format("MMDDYYYY")}.csv`;
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const setHeaders = (result) => {
    const headers = [];

    Object.keys(result[0]).map((item) => {
      return headers.push({ label: item, key: item });
    });
    setHeadersState(headers);
  };

  const getDncList = () => {
    setLoading(true);
    axios
      .get("api/dnc-list")
      .then((res) => {
        const dncs = res.data;
        setDncList(dncs);
        setLoading(false);
        return dncs;
      })
      .then((result) => {
        setHeaders(result);
      })
      .catch((error) => alert(error));
  };

  const postNewDnc = (payload, resetValues, onClose) => {
    setLoading(true);
    axios
      .get(`api/dnc-list/${payload.phone}`)
      .then((res) => {
        const dnc = res.data;
        if (!dnc) {
          axios
            .post("api/dnc-list", payload)
            .then((res) => {
              const data = res.data;
              // console.log("data:", data);
              alert(`
              The following information has been added to the database successfully:
              Phone: ${data.phone}
              Advertiser: ${data.advertiser}
              Vertical: ${data.vertical}
              Is Litigator? ${String(data.litigator)}
              Notes: ${data.notes}
              `);
              return { phone: data.phone, notes: data.notes };
            })
            .then((data) => {
              addDncToAll(data.phone, data.notes);
            })
            .then(() => {
              getDncList();
              resetValues();
              setLoading(false);
              onClose();
            })
            .catch((error) => {
              console.error(error.message);
              alert(error);
              setLoading(false);
            });
        } else {
          alert("This number already exists!");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error);
        setLoading(false);
      });
  };

  const addDncToAll = (number, note) => {
    Promise.all([addToLP(number, note), addToRB(number)])
      .then(() => {
        alert("Number has been added to all platforms successfully.");
      })
      .catch((error) => {
        console.error(error);
        alert(error);
      });
  };

  // const logMe = () => {
  //   console.log("headersState:", headersState);
  //   console.log("dncList:", dncList);
  // };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      overflowY={dncList ? "scroll" : "hidden"}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="DNC List"
        description="Add a new number to the DNC list across all platforms."
        children={
          dncList
            ? [
                <IconButton
                  onClick={getDncList}
                  mr="10px"
                  aria-label="Generate DNC NList"
                  icon={<FiRefreshCcw />}
                />,
                <DncModal postNewDnc={postNewDnc} />,
                <Button ml="10px">
                  <CSVLink
                    data={dncList}
                    headers={headersState}
                    filename={fileName}
                    asyncOnClick={true}
                    onClick={() => console.log("dncList logs:", dncList)}
                  >
                    <Icon as={FaFileExport} />
                  </CSVLink>
                </Button>,
              ]
            : [
                <IconButton
                  onClick={getDncList}
                  mr="10px"
                  aria-label="Generate DNC NList"
                  icon={<FiRefreshCcw />}
                />,
                <DncModal postNewDnc={postNewDnc} />,
              ]
        }
      />

      <Flex flexDir="column">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="green.400" />
          </Flex>
        ) : null}
        <Table size="sm">
          <Thead>
            {dncList ? (
              <Tr position="sticky" top={100} backgroundColor={bgColor}>
                <Th textAlign="center">DATE</Th>
                <Th textAlign="center">PHONE</Th>
                <Th textAlign="center">VERTICAL</Th>
                <Th textAlign="center">ADVERTISER</Th>
                <Th textAlign="center">LITIGATOR</Th>
                <Th textAlign="center">NOTES</Th>
              </Tr>
            ) : null}
          </Thead>
          <Tbody>
            {dncList
              ? dncList.map((item, i) => (
                  <Tr key={`${item}-${i}`} _hover={{ backgroundColor: hoverColor }}>
                    <Td textAlign="center">{item.date}</Td>
                    <Td textAlign="center">{item.phone}</Td>
                    <Td textAlign="center">{item.vertical}</Td>
                    <Td textAlign="center">{item.advertiser}</Td>
                    <Td textAlign="center">{item.litigator}</Td>
                    <Td maxW="400px">{item.notes}</Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
}
