import axios from "axios";
import moment from "moment";

const getAllCampaigns = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  //   const payload = {
  //     InboundCallIds: [callID],
  //   };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.campaigns;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getAllTargets = async () => {
  let allTargetData = [];
  // let targetData;
  // let ringTreeTargetData;

  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/targets`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  //   const payload = {
  //     InboundCallIds: [callID],
  //   };
  try {
    const response_targets = await axios.get(url, config);
    // console.log(response_targets);
    const data_targets = response_targets.data.targets;
    // console.log("getAllTargets data::", data_targets);

    const response_rtt = await getAllRingTreeTargets();

    allTargetData = data_targets.concat(response_rtt);
    // console.log("allTargetData::", allTargetData);

    return allTargetData;
  } catch (error) {
    console.error(error);
  }
};

const getAllRingTreeTargets = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/pingtreetargets`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  //   const payload = {
  //     InboundCallIds: [callID],
  //   };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.pingTreeTargets;
    // console.log("getAllRingTreeTargets data::", data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const enableCampaign = async (enable, campaignId) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns/${campaignId}`;

  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  const payload = {
    id: campaignId,
    enabled: enable,
  };

  console.log("url", url);
  console.log("payload", payload);

  try {
    const response = await axios.patch(url, payload, config);
    console.log("enableCampaign response:", response);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const enableTarget = async (enable, targetId) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/targets/${targetId}`;

  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  const payload = {
    id: targetId,
    enabled: enable,
  };

  console.log("url", url);
  console.log("payload", payload);

  try {
    const response = await axios.patch(url, payload, config);
    console.log("enableTarget response:", response);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateCampaignPayoutBuffer = async (
  campaignId,
  affiliateId,
  payoutId,
  payout,
  buffer,
  byCallLength
) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns/${campaignId}/affiliates/${affiliateId}/payouts`;

  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  const payload_callLength = {
    allPayouts: [
      {
        id: payoutId,
        payoutAmount: Number(payout),
        payoutConversionArgs: {
          callLengthInSeconds: Number(buffer),
        },
      },
    ],
    sendNotifications: false,
  };
  const payload_callConnected = {
    allPayouts: [
      {
        id: payoutId,
        payoutAmount: Number(payout),
        payoutConversionArgs: {
          callLengthInSeconds: Number(buffer),
        },
      },
    ],
    sendNotifications: false,
  };

  console.log(
    byCallLength
      ? ("payload_callLength", payload_callLength)
      : ("payload_callConnected:", payload_callConnected)
  );

  try {
    const response = await axios.put(
      url,
      byCallLength ? payload_callLength : payload_callConnected,
      config
    );
    console.log("updateCampaignPayoutBuffer response:", response);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const getCallPlans = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallPlans response:", response);
    const data = response.data.callPlans;
    let theCallPlanId;
    data.forEach((plan) => {
      if (plan.name === campaignName) {
        theCallPlanId = plan.id;
        return;
      }
    });
    if (!theCallPlanId) return false;
    return theCallPlanId;
  } catch (error) {
    console.error(error);
  }
};

const getCallRoutesWithId = async (campaignName) => {
  const callRouteId = await getCallPlans(campaignName);
  console.log("callRouteId:", callRouteId);
  if (!callRouteId) return false;

  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallRoutesWithId response:", response);
    const data = response.data.callPlan;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getCallRoutesWithIdDirect = async (callRouteId) => {
  if (!callRouteId) return false;

  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallRoutesWithId response:", response);
    const data = response.data.callPlan;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getPublisherStats = async () => {
  const allPublishers = await getAllPublishers();
  console.log("allPublishers:", allPublishers);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/publishers/stats`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  //   const payload = {
  //     InboundCallIds: [callID],
  //   };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.stats;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getAllPublishers = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/publishers`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    //   console.log(response);
    const data = response.data.publishers;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getVolumePerCampaign = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "campaignName",
      },
    ],
    valueColumns: [
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
    ],
    orderByColumns: [],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
  };
  try {
    const response = await axios.post(url, payload, config);
    const data = response.data.report.records;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getInsights = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "campaignName",
      },
      {
        column: "targetName",
      },
    ],
    valueColumns: [
      {
        column: "liveCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [
      { column: "campaignName", direction: "asc" },
      { column: "targetName", direction: "asc" },
    ],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
    formatTimeZone: "America/Los_Angeles",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    const formattedData = formatInsightData(data);
    // console.log("formattedData::", formattedData);
    // let realData = [];
    // data.forEach((item) => {
    //   if (item.campaignId) {
    //     realData.push(item);
    //   }
    //   if (!item.campaignId && !item.campaignName) {
    //     realData.push(item);
    //   }
    // });
    // return realData;
    return formattedData;
  } catch (error) {
    console.error(error);
  }
};

const formatInsightData = (data) => {
  let campaignClusters = [];
  data.forEach((entry) => {
    let cluster = {
      campaign: null,
      targets: [],
    };
    if (!entry.targetName) {
      cluster.campaign = entry;
      campaignClusters.push(cluster);
    }
  });
  campaignClusters.forEach((cluster) => {
    data.forEach((entry) => {
      if (
        entry.campaignName === cluster.campaign.campaignName &&
        entry.targetName !== "-no value-"
      ) {
        cluster.targets.push(entry);
      }
    });
  });
  return campaignClusters;
};

const getInsightsTargets = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "targetName",
      },
      {
        column: "targetId",
      },
    ],
    valueColumns: [
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    // console.log("api data::", data);
    let realData = [];
    data.forEach((item) => {
      if (item.campaignId) {
        realData.push(item);
      }
      if (!item.campaignId && !item.campaignName) {
        realData.push(item);
      }
    });
    return realData;
  } catch (error) {
    console.error(error);
  }
};

const updateRoutingWeightPriority = async (callRouteId, priorityNum, weightNum) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callroutes/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    id: callRouteId,
    priority: {
      priority: priorityNum,
      weight: weightNum,
    },
  };
  try {
    const response = await axios.patch(url, payload, config);
    // console.log("updateRoutingWeightPriority response:", response);
    const data = response.data.callRoute.priority;
    //   console.log(data);
    // return response;
    return data;
  } catch (error) {
    console.error(error);
  }
};
const updateTargetCaps = async (rt, targetId, dailyCap, hourlyCap, concurrencyCap, hoo) => {
  const url = rt
    ? `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/pingtreetargets/${targetId}`
    : `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/targets/${targetId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  // const testUrl1 =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";
  // const testUrl2 =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";
  // const WhatRingbaUses =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";

  // console.log("rt:", rt);
  // console.log("targetId:", targetId);
  // console.log("dailyCap:", dailyCap);
  // console.log("hourlyCap:", hourlyCap);
  // console.log("concurrencyCap:", concurrencyCap);
  // console.log("hoo:", hoo);

  const payload = {
    id: targetId,
    schedule: {
      dailyCap: dailyCap > 0 ? dailyCap : -1,
      hourlyCap: hourlyCap > 0 ? hourlyCap : -1,
      concurrencyCap: concurrencyCap > 0 ? concurrencyCap : -1,
      hoursOfOperation: hoo,
      timeZoneId: "Pacific Standard Time",
    },
  };

  const payload_rt = {
    id: targetId,
    schedule: {
      dailyCapDayOfWeek:
        dailyCap > 0
          ? [dailyCap, dailyCap, dailyCap, dailyCap, dailyCap, dailyCap, dailyCap]
          : null,
      dailyCap: dailyCap > 0 ? dailyCap : -1,
      hourlyCap: hourlyCap > 0 ? hourlyCap : -1,
      concurrencyCap: concurrencyCap > 0 ? concurrencyCap : -1,
    },
  };

  console.log(rt ? "payload_rt:" : "payload:", rt ? payload_rt : payload);

  try {
    const response = await axios.patch(url, rt ? payload_rt : payload, config);
    console.log("updateTargetCaps response:", response);
    // const data = response.data.callRoute.priority;
    //   console.log(data);
    // return response;
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const getCampaignStats = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns?includeStats=true`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("response:", response);
    const campaignData = response.data.campaigns;
    const statsData = Object.entries(response.data.stats).map((e) => ({ [e[0]]: e[1] }));
    // console.log("campaignData:", campaignData);
    // console.log(statsData);

    let allCampaigns = [];
    campaignData.forEach((campaign) => {
      if (campaign.enabled) {
        const cluster = {
          name: campaign.name,
          id: campaign.id,
          campaignAffiliates: campaign.affiliates,
          stats: null,
        };
        allCampaigns.push(cluster);
      }
    });

    allCampaigns.forEach((cluster) => {
      statsData.forEach((stat) => {
        const key = Object.keys(stat)[0];
        if (key.toLowerCase() === cluster.id.toLowerCase() && stat[key].total > 0) {
          // console.log("stat:", stat);
          // console.log("stat.key:", stat[key]);
          const stats = {
            currentDay: 0,
            currentHour: 0,
            currentLive: 0,
            currentMonth: 0,
            total: 0,
          };
          stats.currentDay = stat[key].currentDay;
          stats.currentHour = stat[key].currentHour;
          stats.currentLive = stat[key].currentLive;
          stats.currentMonth = stat[key].currentMonth;
          stats.total = stat[key].total;
          cluster.stats = stats;
        }
      });
    });
    // console.log("allCampaigns:", allCampaigns);
    return allCampaigns;
  } catch (error) {
    console.error(error);
  }
};

const getCampaignCaps = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("campaignData:", response);
    const data = response.data.campaigns;
    let theCampaign;
    data.forEach((campaign) => {
      if (campaign.name === campaignName) {
        theCampaign = campaign;
        return;
      }
    });
    if (!theCampaign) return false;
    return theCampaign;
  } catch (error) {
    console.error(error);
  }
};

const updateCampaignCaps = async (
  campaignId,
  affiliateId,
  payoutId,
  caps,
  payoutAmount,
  callLength
) => {
  // console.log("campaignId:", campaignId);
  // console.log("affiliateId:", affiliateId);
  // console.log("payoutId:", payoutId);
  // console.log("caps:", caps);
  // console.log("payoutAmount:", payoutAmount);
  // console.log("callLength:", callLength);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns/${campaignId}/affiliates/${affiliateId}/payouts/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    allPayouts: [
      {
        allTimeCap: -1,
        allTimeSumCap: -1,
        concurrencyCap:
          caps.concurrencyCap === 0 || caps.concurrencyCap === "0" ? -1 : caps.concurrencyCap,
        dailyCap: caps.dailyCap === 0 || caps.dailyCap === "0" ? -1 : caps.dailyCap,
        dailySumCap: -1,
        deDupeSetting: { secondsFromLastCall: 0 },
        hourlyCap: caps.hourlyCap === 0 || caps.hourlyCap === "0" ? -1 : caps.hourlyCap,
        hourlySumCap: -1,
        id: payoutId,
        isGlobal: false,
        isRevShare: false,
        monthlyCap: -1,
        monthlySumCap: -1,
        payoutAmount: parseInt(payoutAmount),
        payoutConversionArgs: {
          callLengthInSeconds: parseInt(callLength),
        },
        payoutConversionType: "callLength",
        priority: 0,
        revShareMaxPayoutAmount: -1,
        revShareMinPayoutAmount: -1,
        showPayoutSettingsForm: true,
      },
    ],
    sendNotifications: false,
  };

  console.log("payload:", payload);

  try {
    const response = await axios.put(url, payload, config);

    const data = response.data;
    if (data.transactionId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const getStrandedMortgage = async (payload) => {
  // console.log("getStrandedMortgage payload:", payload);
  try {
    const response = await axios.post("api/calls/stranded-mortgage", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getTargetFilters = async (bulkId) => {
  console.log("bulkId:", bulkId);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/bulktags/${bulkId}/values`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(url, config);
    console.log("getTargetFilters aPI response::", response);
    const data = response.data.value;
    // console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getRingTreeSchedule = async (ringTreeId) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/pingtrees/${ringTreeId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.pingTree.schedule.hoursOfOperation;
    // console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getTargetReferences = async (targetId, rt) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/targets/${targetId}/InboundReferences`;
  const url_rt = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/pingtrees/${targetId}/InboundReferences`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(rt ? url_rt : url, config);
    console.log(response);
    const data = response.data.callplans;
    // console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getAllCallRoutes = async (targetIdsArray) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplans`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallRoutesWithId response:", response);
    const data = response.data.callPlans;
    const matchingCampaigns = getMatchingCampaignsWithTargetIds(targetIdsArray, data);
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getMatchingCampaignsWithTargetIds = (targetIdsArray, callPlansArray) => {
  const data = targetIdsArray.forEach((targetId) => {
    console.log(targetId);
  });
};

export {
  getAllCampaigns,
  getPublisherStats,
  getInsights,
  getInsightsTargets,
  getVolumePerCampaign,
  getStrandedMortgage,
  updateRoutingWeightPriority,
  updateTargetCaps,
  getCampaignCaps,
  updateCampaignCaps,
  getCampaignStats,
  enableCampaign,
  getCallRoutesWithId,
  getCallRoutesWithIdDirect,
  updateCampaignPayoutBuffer,
  getAllTargets,
  getAllRingTreeTargets,
  getTargetFilters,
  getRingTreeSchedule,
  enableTarget,
  getTargetReferences,
};
