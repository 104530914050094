import axios from "axios";
import moment from "moment";

const callDurationReport = async (payload) => {
  try {
    const response = await axios.post("api/reports/callDuration", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const failedCallsReport = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/calllogs/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    filters: [
      {
        anyConditionToMatch: [
          {
            column: "hasConnected",
            value: "no",
            isNegativeMatch: "false",
            comparisonType: "EQUALS",
          },
        ],
      },
    ],
  };
  try {
    const response = await axios.post(url, payload, config);
    const data = response.data.report;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getCallDetail = async (callID) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/calllogs/detail`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    InboundCallIds: [callID],
  };
  try {
    const response = await axios.post(url, payload, config);
    console.log("getCallDetail response::", response);
    const data = response.data.report.records[0].events;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getCallRoutes = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    console.log(response);
    const data = response.data;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getSingleCallInfo = async (InboundCallId) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/calllogs/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    filters: [
      {
        anyConditionToMatch: [
          {
            column: "inboundCallId",
            value: InboundCallId,
            isNegativeMatch: false,
            comparisonType: "EQUALS",
          },
        ],
      },
    ],
  };
  try {
    const response = await axios.post(url, payload, config);
    const data = response.data.report;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export { callDurationReport, failedCallsReport, getCallDetail, getCallRoutes, getSingleCallInfo };
