import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useColorModeValue,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import DncModal from "../../components/dnc/DncModal";
import { addToLP, addToRB } from "../../components/dnc/insertDncToAll";
import TopHeading from "../../components/TopHeading";
import {
  addNewRobospamNumber,
  deleteRobospamNumber,
  getAllRoboSpamNumbers,
  findOneRoboSpamNumber,
} from "./RoboSpamApi";

import { BsTrash } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { FaFileExport } from "react-icons/fa";

import { CSVLink } from "react-csv";
import moment from "moment";
import RoboSpamNewModal from "./RoboSpamNewModal";

const axios = require("axios");

export default function RoboSpam() {
  const [dncList, setDncList] = useState();
  const [headersState, setHeadersState] = useState();
  const [loading, setLoading] = useState(false);
  const fileName = `roboSpam_list_${moment().format("MMDDYYYY")}.csv`;
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const setHeaders = (result) => {
    const headers = [];

    Object.keys(result[0]).map((item) => {
      return headers.push({ label: item, key: item });
    });
    setHeadersState(headers);
  };

  const getRobospamList = async () => {
    setLoading(true);
    try {
      const result = await getAllRoboSpamNumbers();
      console.log("getRobospamList result:", result);
      const data = result.result;
      setDncList(data);
      setLoading(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const postNewRobospamNumber = async (payload, resetValues, onClose) => {
    setLoading(true);
    console.log("postNewRobospamNumber payload:", payload);
    try {
      const numberExists = await checkIfNumberExists(payload.phone);
      console.log("numberExists:", numberExists);
      if (numberExists.result?.phone) {
        alert("Number already exists!");
        resetValues();
        setLoading(false);
        onClose();
      } else {
        const result = await addNewRobospamNumber(payload);
        const addToPlatforms = await addDncToAll(payload.phone, payload.notes);
        if (addToPlatforms) {
          alert("Phone number has been blocked on Ringba and LeadsPedia successfully.");
        } else {
          alert("There was an error adding number to Ringba or LeadsPedia.");
        }
        console.log("result::", result);
        getRobospamList();
        resetValues();
        setLoading(false);
        onClose();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const checkIfNumberExists = async (phone) => {
    const payload = { phone };

    try {
      const result = await findOneRoboSpamNumber(payload);
      return result;
    } catch (error) {
      alert(error.message);
    }
  };

  const deleteRoboSpamNumber = async (number) => {
    setLoading(true);
    const payload = { phone: number };

    try {
      const result = await deleteRobospamNumber(payload);
      console.log("deleteRoboSpamNumber:", result);
      getRobospamList();
      setLoading(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const addDncToAll = async (number, note) => {
    console.log("number:", number);
    try {
      let promise = await Promise.all([addToLP(number, note), addToRB(number)]);
      console.log("addDncToAll promise: ", promise);
      return true;
    } catch (error) {
      alert(error.message);
    }
    // Promise.all([addToLP(number, note), addToRB(number)])
    //   .then(() => {
    //     alert("Number has been added to all platforms successfully.");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     alert(error);
    //   });
  };

  // const logMe = () => {
  //   console.log("headersState:", headersState);
  //   console.log("dncList:", dncList);
  // };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      overflowY={dncList ? "scroll" : "hidden"}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Robo Spam DNC"
        description="Add a new number to the Robo Spam DNC list across all platforms."
        children={
          dncList
            ? [
                <IconButton
                  onClick={getRobospamList}
                  mr="10px"
                  aria-label="Generate DNC NList"
                  icon={<FiRefreshCcw />}
                  isLoading={loading}
                />,
                <RoboSpamNewModal
                  postNewRobospamNumber={postNewRobospamNumber}
                  loading={loading}
                />,

                <Button ml="10px">
                  <CSVLink
                    data={dncList}
                    headers={headersState}
                    filename={fileName}
                    asyncOnClick={true}
                    onClick={() => console.log("dncList logs:", dncList)}
                  >
                    <Icon as={FaFileExport} />
                  </CSVLink>
                </Button>,
              ]
            : [
                <IconButton
                  onClick={getRobospamList}
                  mr="10px"
                  aria-label="Generate DNC NList"
                  icon={<FiRefreshCcw />}
                  isLoading={loading}
                />,
                <RoboSpamNewModal
                  postNewRobospamNumber={postNewRobospamNumber}
                  loading={loading}
                />,
              ]
        }
      />

      <Flex flexDir="column">
        {!dncList && loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="green.400" />
          </Flex>
        ) : null}
        <Table size="md">
          <Thead>
            {dncList ? (
              <Tr position="sticky" top={100} backgroundColor={bgColor}>
                <Th textAlign="center">DATE</Th>
                <Th textAlign="center">PHONE</Th>
                <Th textAlign="center">NOTES</Th>
                <Th textAlign="center">DELETE</Th>
              </Tr>
            ) : null}
          </Thead>
          <Tbody>
            {dncList
              ? dncList.map((item, i) => (
                  <Tr key={`${item}-${i}`} _hover={{ backgroundColor: hoverColor }}>
                    <Td textAlign="center">{item.date}</Td>
                    <Td textAlign="center">{item.phone}</Td>
                    <Td maxW="400px">{item.notes}</Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={<BsTrash />}
                        onClick={() => deleteRoboSpamNumber(item.phone)}
                        isLoading={loading}
                      />
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
}
