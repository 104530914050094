import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  CheckboxGroup,
  Checkbox,
  Tbody,
  Table,
  Tr,
  Td,
  Select,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";

import { ImFilter } from "react-icons/im";

export default function LeadFiltersModal({ buttonSize, updateFilteredData, filterLists }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState({
    calls: ["valid", "invalid", "missing"],
    data: ["valid", "invalid", "missing"],
    status: ["Billable", "Non-Billable", "Return"],
  });
  const [filterOperator, setFilterOperator] = useState("Or");
  const [checkedOperator, setCheckedOperator] = useState([true, false]);
  const [filterBuyer, setFilterBuyer] = useState();
  const [filterContract, setFilterContract] = useState();
  const [filterPublisher, setFilterPublisher] = useState();

  const handleFilter = (filterArray, type) => {
    console.log("filterArray:", filterArray);
    const currentFilters = filters;

    if (type === "call") {
      currentFilters.calls = filterArray;
    }
    if (type === "data") {
      currentFilters.data = filterArray;
    }

    setFilters(currentFilters);
  };

  const handleOperatorChange = (isChecked, operator) => {
    // console.log("isChecked:", isChecked);
    setFilterOperator(operator);
    if (operator === "Or") {
      setCheckedOperator([isChecked, !checkedOperator[1]]);
    } else {
      setCheckedOperator([!checkedOperator[0], isChecked]);
    }
  };

  const submit = () => {
    updateFilteredData(filters, filterOperator, filterBuyer, filterContract, filterPublisher);
  };

  const log = () => {
    // console.log("filters:", filters);
    // console.log("checkedOperator:", checkedOperator);
    // console.log("filterOperator:", filterOperator);
    console.log("filterLists:", filterLists);
  };

  return (
    <>
      <Tooltip label="Filters" placement="top">
        <IconButton onClick={onOpen} size={buttonSize} icon={<ImFilter />} />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lead Filters</ModalHeader>
          {/* <Button onClick={log}>LOG</Button> */}
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Tbody>
                {/* <Tr>
                  <Td>Status:</Td>
                  <CheckboxGroup
                    colorScheme="teal"
                    defaultValue={["Billable", "Non-Billable", "Return"]}
                    onChange={(e) => handleFilter(e, "status")}
                  >
                    <Td>
                      <Checkbox value="Billable">Billable</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="Non-Billable">Non-Billable</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="Return">Return</Checkbox>
                    </Td>
                  </CheckboxGroup>
                </Tr> */}
                <Tr>
                  <Td>Calls:</Td>
                  <CheckboxGroup
                    colorScheme="teal"
                    defaultValue={["valid", "invalid", "missing"]}
                    onChange={(e) => handleFilter(e, "call")}
                  >
                    <Td>
                      <Checkbox value="valid">Valid</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="invalid">Invalid</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="missing">Missing</Checkbox>
                    </Td>
                  </CheckboxGroup>
                </Tr>
                <Tr>
                  <Td>Operator:</Td>

                  <Td>
                    <Checkbox
                      colorScheme="teal"
                      value="Or"
                      isChecked={checkedOperator[0]}
                      onChange={(e) => handleOperatorChange(e.target.checked, "Or")}
                    >
                      Or
                    </Checkbox>
                  </Td>
                  <Td>
                    <Checkbox
                      colorScheme="teal"
                      value="And"
                      isChecked={checkedOperator[1]}
                      onChange={(e) => handleOperatorChange(e.target.checked, "And")}
                    >
                      And
                    </Checkbox>
                  </Td>
                  <Td></Td>
                </Tr>
                <Tr>
                  <Td>Data:</Td>
                  <CheckboxGroup
                    colorScheme="teal"
                    defaultValue={["valid", "invalid", "missing"]}
                    onChange={(e) => handleFilter(e, "data")}
                  >
                    <Td>
                      <Checkbox value="valid">Valid</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="invalid">Invalid</Checkbox>
                    </Td>
                    <Td>
                      <Checkbox value="missing">Missing</Checkbox>
                    </Td>
                  </CheckboxGroup>
                </Tr>
              </Tbody>
            </Table>

            {/* <Flex flexDir="column" justify="center" my="10px">
              <Text>Status</Text>
              <Select placeholder="Select Status" onChange={(e) => setFilterStatus(e.target.value)}>
                <option value="Billable">Billable</option>
                <option value="Non-Billable">Non-Billable</option>
                <option value="Return">Return</option>
              </Select>
            </Flex> */}

            <Flex flexDir="column" justify="center" my="10px">
              <Text>Buyer</Text>
              <Select placeholder="Select Buyer" onChange={(e) => setFilterBuyer(e.target.value)}>
                {filterLists && filterLists.buyerList && filterLists.buyerList.length > 0
                  ? filterLists.buyerList.map((option) => <option value={option}>{option}</option>)
                  : null}
              </Select>
            </Flex>

            <Flex flexDir="column" justify="center" my="10px">
              <Text>Contract</Text>
              <Select
                placeholder="Select Contract"
                onChange={(e) => setFilterContract(e.target.value)}
              >
                {filterLists && filterLists.contractList && filterLists.contractList.length > 0
                  ? filterLists.contractList.map((option) => (
                      <option style={{ textTransform: "capitalize" }} value={option}>
                        {option.split("_").join(" ")}
                      </option>
                    ))
                  : null}
              </Select>
            </Flex>

            <Flex flexDir="column" justify="center" my="10px">
              <Text>Publisher</Text>
              <Select
                placeholder="Select Publisher"
                onChange={(e) => setFilterPublisher(e.target.value)}
              >
                {filterLists && filterLists.pubList && filterLists.pubList.length > 0
                  ? filterLists.pubList.map((option) => <option value={option}>{option}</option>)
                  : null}
              </Select>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={submit}>
              Filter
            </Button>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
