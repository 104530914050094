import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Flex,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import { FiEdit, FiSave } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import ConfirmationModal from "../modals/ConfirmationModal";

import { addNewBuyer } from "./PartnersApi";
import moment from "moment";

const NewContactForm = ({ contacts, setContacts }) => {
  const hoverColor = useColorModeValue("gray.200", "gray.500");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [skype, setSkype] = useState("");
  const [vertical, setVertical] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setName("");
    setPhone("");
    setEmail("");
    setVertical("");
    setSkype("");
  };

  const addNewContact = () => {
    let newContacts = [...contacts];

    const newContact = {
      contactInfo: {
        email,
        phone,
        skype,
      },
      id: `contact-${uuidv4()}`,
      name,
      vertical,
    };

    newContacts.push(newContact);
    setContacts(newContacts);
    resetFields();
  };

  return (
    <Flex borderWidth={1} borderRadius={3} p={2} flexDir="column" mt={4}>
      <Text textAlign="center" letterSpacing={3} mb={1} onClick={() => console.log(contacts)}>
        NEW CONTACT
      </Text>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th textAlign="center">PoC</Th>
            <Th textAlign="center">Phone</Th>
            <Th textAlign="center">Email</Th>
            <Th textAlign="center">Skype</Th>
            <Th textAlign="center">Vertical</Th>
            <Th textAlign="center"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr _hover={{ backgroundColor: hoverColor }}>
            <Td>
              <Input size="sm" value={name} onChange={(e) => setName(e.target.value)} />
            </Td>
            <Td>
              <Input size="sm" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Td>
            <Td>
              <Input size="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Td>
            <Td>
              <Input size="sm" value={skype} onChange={(e) => setSkype(e.target.value)} />
            </Td>
            <Td>
              <Input size="sm" value={vertical} onChange={(e) => setVertical(e.target.value)} />
            </Td>
            <Td>
              <Button onClick={addNewContact} isLoading={loading}>
                Add
              </Button>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  );
};

const ContactInfoTR = ({ contact, contacts, setContacts, index }) => {
  const hoverColor = useColorModeValue("gray.200", "gray.500");
  const [name, setName] = useState(contact.name);
  const [phone, setPhone] = useState(contact.contactInfo.phone);
  const [email, setEmail] = useState(contact.contactInfo.email);
  const [skype, setSkype] = useState(contact.contactInfo.skype);
  const [vertical, setVertical] = useState(contact.vertical);

  const handleSave = () => {
    let newContacts = [...contacts];

    const data = {
      contactInfo: {
        email,
        phone,
        skype,
      },
      id: `contact-${uuidv4()}`,
      name,
      vertical,
    };

    newContacts.splice(index, 1, data);

    setContacts(newContacts);
    alert("saved!");
  };

  const deleteRow = () => {
    let newContacts = [...contacts];

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };

  return (
    <Tr _hover={{ backgroundColor: hoverColor }}>
      <Td w="10%">
        <Input value={name} size="sm" onChange={(e) => setName(e.target.value)} />
      </Td>
      <Td w="10%">
        <Input value={phone} size="sm" onChange={(e) => setPhone(e.target.value)} />
      </Td>
      <Td w="20%">
        <Input value={email} size="sm" onChange={(e) => setEmail(e.target.value)} />
      </Td>
      <Td w="20%">
        <Input value={skype} size="sm" onChange={(e) => setSkype(e.target.value)} />
      </Td>
      <Td w="20%">
        <Input value={vertical} size="sm" onChange={(e) => setVertical(e.target.value)} />
      </Td>
      <Td w="10%" textAlign="center">
        <IconButton onClick={handleSave} size="sm" colorScheme="teal" icon={<FiSave />} />

        <IconButton onClick={deleteRow} mr={2} size="sm" icon={<BsTrash />} mx={2} />
      </Td>
    </Tr>
  );
};

export default function NewBuyerModal({ getAllBuyersNow }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState();
  const [contacts, setContacts] = useState([]);
  const [businessAddress, setBusinessAddress] = useState();
  const [holidays, setHolidays] = useState();
  const [driveLink, setDriveLink] = useState();
  const [id, setId] = useState();

  const [accountingName, setAccountingName] = useState();
  const [accountingPhone, setAccountingPhone] = useState();
  const [accountingEmail, setAccountingEmail] = useState();
  const [accountingSkype, setAccountingSkype] = useState();
  const [accountingTerms, setAccountingTerms] = useState();
  const [loading, setLoading] = useState(false);

  const handleNameChange = (e) => {
    let name = e.target.value;
    setName(name);
  };

  const handleBusinessAddressChange = (e) => {
    let businessAddress = e.target.value;
    setBusinessAddress(businessAddress);
  };

  const handleHolidaysChange = (e) => {
    let holidays = e.target.value;
    setHolidays(holidays);
  };

  const handleDriveLinkChange = (e) => {
    let driveLink = e.target.value;
    setDriveLink(driveLink);
  };

  const resetValues = () => {
    setName();
    setContacts([]);
    setBusinessAddress();
    setHolidays();
    setDriveLink();
    setAccountingEmail();
    setAccountingName();
    setAccountingPhone();
    setAccountingTerms();
  };

  const submitNewBuyer = async () => {
    setLoading(true);

    const data = {
      uuid: uuidv4(),
      name,
      contacts,
      address: businessAddress,
      accounting: {
        name: accountingName,
        phone: accountingPhone,
        email: accountingEmail,
        skype: accountingSkype,
        terms: accountingTerms,
      },
      holidays,
      driveLink,
      created: moment().format("l LT"),
    };

    console.log(data);
    try {
      await addNewBuyer(data);
      setTimeout(() => {
        setLoading(false);
        getAllBuyersNow();
        resetValues();
        onClose();
      }, 2000);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const logMe = () => {
    console.log("accountingName:", accountingName);
    console.log("accountingPhone:", accountingPhone);
    console.log("accountingEmail:", accountingEmail);
  };

  return (
    <>
      <IconButton
        colorScheme="teal"
        onClick={onOpen}
        aria-label="Open Call Details"
        icon={<GoPlus />}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="7xl" scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Buyer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="phone" isRequired mb={3} onChange={handleNameChange}>
              <FormLabel>Buyer Name</FormLabel>
              <Input defaultValue={name} type="text" />
              <FormHelperText>Buyer name should match all platforms exactly.</FormHelperText>
            </FormControl>

            <FormControl id="contact" mb={3}>
              {/* <Button onClick={logMe}>LOG ME</Button> */}
              <FormLabel>Contact Info</FormLabel>
              <Flex borderWidth={1} borderRadius={7} p={2} flexDir="column">
                {contacts && contacts.length > 0 ? (
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>PoC</Th>
                        <Th>Phone</Th>
                        <Th>Email</Th>
                        <Th>Skype</Th>
                        <Th>Vertical</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {contacts.map((contact, index) => (
                        <ContactInfoTR
                          key={contact.id}
                          contact={contact}
                          getAllBuyersNow={getAllBuyersNow}
                          contacts={contacts}
                          setContacts={setContacts}
                          index={index}
                        />
                      ))}
                    </Tbody>
                  </Table>
                ) : null}
                <NewContactForm contacts={contacts} setContacts={setContacts} />
              </Flex>
            </FormControl>

            <FormControl id="contact" mb={3}>
              <FormLabel>Accounting</FormLabel>
              <Flex borderWidth={1} borderRadius={7} p={2} flexDir="column">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Phone</Th>
                      <Th>Email</Th>
                      <Th>Skype</Th>
                      <Th>Terms</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          value={accountingName}
                          onChange={(e) => setAccountingName(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={accountingPhone}
                          onChange={(e) => setAccountingPhone(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={accountingEmail}
                          onChange={(e) => setAccountingEmail(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={accountingSkype}
                          onChange={(e) => setAccountingSkype(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={accountingTerms}
                          onChange={(e) => setAccountingTerms(e.target.value)}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Flex>
            </FormControl>

            <FormControl id="address" mb={3} onChange={handleBusinessAddressChange}>
              <FormLabel>Business Address</FormLabel>
              <Input defaultValue={businessAddress} type="text" />
            </FormControl>

            <Flex flexDir="column" mb={3}>
              <FormLabel>Holidays</FormLabel>
              <Textarea
                style={{ whiteSpace: "pre-wrap" }}
                placeholder="Enter all holidays here divided with a comma."
                defaultValue={holidays}
                onChange={handleHolidaysChange}
              />
            </Flex>

            <FormControl id="drive-link" isRequired mb={3} onChange={handleDriveLinkChange}>
              <FormLabel>Google Drive Link</FormLabel>
              <Input
                placeholder="IE:
                https://drive.google.com/drive/folders/1Hfc4Pz-fg8UVQzABKe2_H1X2YZclYvqn?usp=sharing"
                type="text"
                defaultValue={driveLink}
              />
              <FormHelperText>
                If folder does not yet exist, go to Google Drive, create the folder, grab the link
                and paste here.
              </FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={submitNewBuyer} isLoading={loading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
