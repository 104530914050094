import React, { useState } from "react";
import { Box, Heading } from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";
import ReportSettings from "../components/reports/ReportSettings";
import Report from "../components/reports/Report";

export default function Reports() {
  const [reportData, setReportData] = useState();
  const [noData, setNoData] = useState();
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState();

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      p={6}
      overflowY={reportData ? "scroll" : "hidden"}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Reports"
        description="Generate various reports for internal or external purposes."
      />
      <ReportSettings
        reportData={reportData}
        setReportData={setReportData}
        setNoData={setNoData}
        setLoading={setLoading}
        reportType={reportType}
        setReportType={setReportType}
      />
      <Report
        reportData={reportData}
        setReportData={setReportData}
        noData={noData}
        loading={loading}
        reportType={reportType}
      />
    </Box>
  );
}
