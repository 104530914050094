import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Flex,
  IconButton,
  Tooltip,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { addNewBlacklistPartner } from "./PartnersApi";

import { GoPlus } from "react-icons/go";
import axios from "axios";

export default function NewBlacklistModal({ getAllPartners }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [phone, setPhone] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [landingPages, setLandingPages] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetValues = () => {
    setName("");
    setContactInfo("");
    setPhone("");
    setBusinessAddress("");
    setLandingPages("");
    setNotes("");
  };

  const submit = async () => {
    setLoading(true);
    setSuccess(false);
    const payload = {
      name,
      contactInfo,
      phone,
      address: businessAddress,
      landingPages,
      notes,
      created: moment().format("L"),
      uuid: uuidv4(),
    };

    try {
      const response = await addNewBlacklistPartner(payload);
      console.log(response);
      setSuccess(true);
      setTimeout(() => {
        resetValues();
        setLoading(false);
        setSuccess(false);
        getAllPartners();
        onClose();
      }, 2000);
    } catch (error) {
      alert(error.message);
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <>
      <Tooltip label="New Partner" placement="top" hasArrow>
        <IconButton colorScheme="teal" icon={<GoPlus />} onClick={onOpen} />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Blacklisted Partner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="phone" isRequired mb={3} onChange={(e) => setName(e.target.value)}>
              <FormLabel>Partner Name</FormLabel>
              <Input type="text" />
            </FormControl>

            <FormControl id="contact" mb={3}>
              <FormLabel>Contact Info</FormLabel>
              <Textarea
                placeholder="Enter all contact emails here divided with a comma."
                type="text"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
              />
            </FormControl>

            <FormControl id="phone" mb={3} onChange={(e) => setPhone(e.target.value)}>
              <FormLabel>Phone Number</FormLabel>
              <Input type="text" />
            </FormControl>

            <FormControl id="address" mb={3} onChange={(e) => setBusinessAddress(e.target.value)}>
              <FormLabel>Address</FormLabel>
              <Input type="text" />
            </FormControl>

            <Flex flexDir="column" mb={3}>
              <FormLabel>Landing Pages</FormLabel>
              <Textarea
                placeholder="Enter all landing page urls here divided with a comma."
                value={landingPages}
                onChange={(e) => setLandingPages(e.target.value)}
              />
            </Flex>
            <Flex flexDir="column" mb={3}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                placeholder="Enter any additional notes here."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            {success ? (
              <Alert status="success" mr="20px">
                <AlertIcon />
                <AlertDescription>Success!</AlertDescription>
              </Alert>
            ) : null}
            <Button isLoading={loading} colorScheme="teal" mr={3} onClick={submit}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
