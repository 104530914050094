export const colors = [
  "#631d76",
  "#26547C",
  "#EF476F",
  "#FFD166",
  "#06D6A0",
  "#e0ff4f",
  "#FF6663",
  "#ff2ecc",
  "#a51080",
];
