import React from "react";
import {
  Flex,
  Link,
  Menu,
  MenuButton,
  Icon,
  Text,
  MenuList,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  Box,
  AccordionPanel,
  UnorderedList,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";

import { AiFillSetting } from "react-icons/ai";
import { FaPhone } from "react-icons/fa";
import { ImHeadphones } from "react-icons/im";
import "./navItemMortgage.css";

export default function NavItemPartners({
  navSize,
  title,
  icon,
  active,
  pageLink1,
  pageLink2,
  pageLink3,
  setActive,
}) {
  const hoverColor = useColorModeValue("gray.100", "gray.600");
  const color = useColorModeValue("teal.300", "teal.300");

  return (
    <Flex mt={3} w="100%" alignItems={navSize === "small" ? "center" : "flex-start"} border={0}>
      <Accordion w="100%" allowToggle border={0}>
        <AccordionItem w="100%" className="mortgage-accordion">
          <AccordionButton
            w="100%"
            p={0}
            _hover={{ textDecor: "none", backgroundColor: hoverColor }}
            border={0}
            borderRadius="8px"
            justifyContent={navSize === "small" ? "center" : "flex-start"}
          >
            <Flex
              py={3}
              pl={navSize === "large" ? 3 : 0}
              w={navSize === "large" && "100%"}
              borderRadius={8}
              alignItems="center"
            >
              <Icon as={icon} fontSize="xl" color="gray.500" />
              <Text ml={5} display={navSize === "small" ? "none" : "flex"}>
                {title}
              </Text>
            </Flex>
            {navSize === "large" ? <AccordionIcon mr={3} /> : null}
          </AccordionButton>
          <AccordionPanel p={0} w="100%" onClick={() => setActive("affiliates")}>
            <Link as={ReachLink} to={pageLink1} style={{ textDecoration: "none" }}>
              <Flex
                alignItems="center"
                pl={7}
                w="100%"
                borderRadius={8}
                _hover={{ backgroundColor: hoverColor }}
                fontWeight={active === "affiliates" && 600}
                color={active === "affiliates" ? color : "gray.400"}
              >
                <UnorderedList>
                  {navSize === "large" ? (
                    <ListItem py={2} pl={2} borderRadius="8px" w="100%">
                      Affiliates
                    </ListItem>
                  ) : null}
                </UnorderedList>
              </Flex>
            </Link>
          </AccordionPanel>
          <AccordionPanel p={0} w="100%" onClick={() => setActive("buyers")}>
            <Link as={ReachLink} to={pageLink2} style={{ textDecoration: "none" }}>
              <Flex
                alignItems="center"
                pl={7}
                w="100%"
                _hover={{ backgroundColor: hoverColor }}
                borderRadius={8}
                fontWeight={active === "buyers" && 600}
                color={active === "buyers" ? color : "gray.400"}
              >
                <UnorderedList>
                  {navSize === "large" ? (
                    <ListItem py={2} pl={2} borderRadius="8px" w="100%">
                      Buyers
                    </ListItem>
                  ) : null}
                </UnorderedList>
              </Flex>
            </Link>
          </AccordionPanel>
          <AccordionPanel p={0} w="100%" onClick={() => setActive("blacklist")}>
            <Link as={ReachLink} to={pageLink3} style={{ textDecoration: "none" }}>
              <Flex
                alignItems="center"
                pl={7}
                w="100%"
                _hover={{ backgroundColor: hoverColor }}
                borderRadius={8}
                fontWeight={active === "blacklist" && 600}
                color={active === "blacklist" ? color : "gray.400"}
              >
                <UnorderedList>
                  {navSize === "large" ? (
                    <ListItem py={2} pl={2} borderRadius="8px" w="100%">
                      Blacklist
                    </ListItem>
                  ) : null}
                </UnorderedList>
              </Flex>
            </Link>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}
