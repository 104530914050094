import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Flex,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import moment from "moment";

import { GoPlus } from "react-icons/go";

export default function DncModal({ postNewDnc, checkDncNumber }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [vertical, setVertical] = useState("");
  const [isLitigator, setIsLitigator] = useState(false);
  const [advertiser, setAdvertiser] = useState("");

  const handlePhoneChange = (e) => {
    let phone = e.target.value;
    setPhone(phone);
  };
  const handleNoteChange = (e) => {
    let note = e.target.value;
    setNotes(note);
  };

  const handleVerticalChange = (e) => {
    let vertical = e.target.value;
    setVertical(vertical);
  };
  const handleAdvertiserChange = (e) => {
    let advertiser = e.target.value;
    setAdvertiser(advertiser);
  };

  const handleIsLitigator = () => {
    setIsLitigator(!isLitigator);
  };

  const formatPhone = () => {
    let newPhone = phone.replace(/[^\d]/g, "");
    if (newPhone.length < 11) {
      newPhone = 1 + newPhone;
    }
    console.log(newPhone);
    return newPhone;
  };

  const resetValues = () => {
    setPhone("");
    setNotes("");
    setVertical("");
    setIsLitigator(false);
    setAdvertiser("");
  };

  const submitDnc = () => {
    const formattedPhone = formatPhone();

    if (formattedPhone.length > 11 || formattedPhone < 11) {
      alert("Phone number is not in correct format");
      return;
    }

    const payload = {
      isLitigator,
      phone: formattedPhone,
      vertical,
      advertiser,
      notes,
      date: moment().format("L"),
    };

    console.log("DncModal payload:", payload);
    postNewDnc(payload, resetValues, onClose);

    // checkDncNumber(payload.phone);

    // resetValues();
    // onClose();
  };

  return (
    <>
      <IconButton colorScheme="teal" icon={<GoPlus />} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>DNC List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Add new Phone Number</Tab>
                {/* <Tab>Add new Email</Tab> */}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FormControl id="phone" isRequired mb={3} onChange={handlePhoneChange}>
                    <FormLabel onClick={formatPhone}>Phone Number</FormLabel>
                    <Input type="text" />
                  </FormControl>

                  <FormControl id="country" mb={3}>
                    <FormLabel>Vertical</FormLabel>
                    <Select placeholder="Select Vertical" onChange={handleVerticalChange}>
                      <option>Auto Insurance</option>
                      <option>Debt</option>
                      <option>Home Improvement</option>
                      <option>Home Insurance</option>
                      <option>Life Insurance</option>
                      <option>Health Insurance</option>
                      <option>Mass Tort</option>
                      <option>Mortgage</option>
                      <option>MVA</option>
                      <option>Security</option>
                      <option>Solar</option>
                      <option>Tax Relief</option>
                    </Select>
                  </FormControl>

                  <FormControl id="phone" mb={3} onChange={handleAdvertiserChange}>
                    <FormLabel>Advertiser</FormLabel>
                    <Input type="text" />
                  </FormControl>

                  <Flex flexDir="column" mb={3}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      placeholder="Enter notes here."
                      value={notes}
                      onChange={handleNoteChange}
                    />
                  </Flex>
                  <Flex spacing={10} justifyContent="flex-start">
                    <Checkbox colorScheme="teal" onChange={handleIsLitigator}>
                      Litigator
                    </Checkbox>
                  </Flex>
                </TabPanel>

                {/* <TabPanel>
                  <FormControl id="phone" isRequired mb={3} onChange={handlePhoneChange}>
                    <FormLabel onClick={formatPhone}>Phone Number</FormLabel>
                    <Input type="text" />
                  </FormControl>

                  <FormControl id="country" mb={3}>
                    <FormLabel>Vertical</FormLabel>
                    <Select placeholder="Select Vertical" onChange={handleVerticalChange}>
                      <option>Auto Insurance</option>
                      <option>Debt</option>
                      <option>Home Improvement</option>
                      <option>Home Insurance</option>
                      <option>Life Insurance</option>
                      <option>Health Insurance</option>
                      <option>Mass Tort</option>
                      <option>Mortgage</option>
                      <option>MVA</option>
                      <option>Security</option>
                      <option>Solar</option>
                      <option>Tax Relief</option>
                    </Select>
                  </FormControl>

                  <FormControl id="phone" mb={3} onChange={handleAdvertiserChange}>
                    <FormLabel>Advertiser</FormLabel>
                    <Input type="text" />
                  </FormControl>

                  <Flex flexDir="column" mb={3}>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      placeholder="Enter notes here."
                      value={notes}
                      onChange={handleNoteChange}
                    />
                  </Flex>
                  <Flex spacing={10} justifyContent="flex-start">
                    <Checkbox colorScheme="teal" onChange={handleIsLitigator}>
                      Litigator
                    </Checkbox>
                  </Flex>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={submitDnc}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
