import {
  useDisclosure,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Textarea,
  Input,
  IconButton,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import states from "./states.json";

import Select from "react-select";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { colors } from "../../config/colors";

import { HiOutlineDocumentReport } from "react-icons/hi";

const formatDuration = (duration) => {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

const TableRow = ({ call }) => {
  return (
    <Tr _hover={{ backgroundColor: "gray.500" }}>
      <Td color="white" textAlign="center">
        {moment.tz(call.call_date, "America/Los_Angeles").subtract(1, "hour").format("l")}
      </Td>
      <Td color="white" textAlign="center">
        {call.cid}
      </Td>
      <Td color="white" backgroundColor={call.color} onClick={() => console.log(call)}>
        {call.disposition}
      </Td>
      <Td color="white" textAlign="center">
        {call.duration}
      </Td>
      <Td color="white">
        {call.notes.map((note) => (
          <>{note}</>
        ))}
      </Td>
    </Tr>
  );
};

const TableRowExample = ({ call }) => {
  return (
    <Tr _hover={{ backgroundColor: "gray.500" }}>
      <Td color="white" textAlign="center">
        {call.cid}
      </Td>
      <Td color="white" backgroundColor={call.color} onClick={() => console.log(call)}>
        {call.disposition}
      </Td>
      <Td color="white">
        {call.notes.map((note) => (
          <>{note}</>
        ))}
      </Td>
    </Tr>
  );
};

export default function QaCallReportExternal({
  data,
  filteredData,
  insertCallsLoading,
  callsLoading,
  loading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [csvData, setCsvData] = useState();

  const handleOpen = () => {
    onOpen();
    generateExternalReport();
  };

  const generateExternalReport = () => {
    const newCvsData = [];

    let callDate;
    let cid;
    let category;
    let disposition;
    let sortPosition;
    let color;
    let duration;
    let notes;

    if (filteredData) {
      // console.log(filteredData);
      filteredData.forEach((call) => {
        if (call.qa.category === "Client Issues" || call.qa.category === "Miscellaneous") {
          return;
        }
        const qaNotes = [];

        if (call.qa.notes.length > 0) {
          call.qa.notes.forEach((note, i) => {
            qaNotes.push(note.note);
          });
        }

        callDate = call.call_timestamp;
        cid = call.call_callerid;
        category = call.qa.category;
        disposition = call.qa.disposition;
        color = call.qa.color;
        if (category === "Excellent") sortPosition = 1;
        if (category === "Satisfactory") sortPosition = 2;
        if (category === "Poor") sortPosition = 3;
        if (category === "Very Poor") sortPosition = 4;
        if (category === "Client Issues") sortPosition = 5;
        if (category === "Miscellaneous") sortPosition = 6;
        if (!category) sortPosition = 7;
        duration = formatDuration(call.call_length);
        notes = qaNotes;

        const newCallData = {
          call_date: callDate,
          cid,
          category,
          disposition,
          sortPosition,
          color,
          duration,
          notes,
        };

        newCvsData.push(newCallData);
      });
    } else {
      console.log(data);
      data.forEach((call) => {
        if (call.qa.category === "Client Issues" || call.qa.category === "Miscellaneous") {
          return;
        }

        const qaNotes = [];

        if (call.qa.notes.length > 0) {
          call.qa.notes.forEach((note, i) => {
            qaNotes.push(note.note);
          });
        }

        callDate = call.call_timestamp;
        cid = call.call_callerid;
        category = call.qa.category;
        disposition = call.qa.disposition;
        color = call.qa.color;
        if (category === "Excellent") sortPosition = 1;
        if (category === "Satisfactory") sortPosition = 2;
        if (category === "Poor") sortPosition = 3;
        if (category === "Very Poor") sortPosition = 4;
        if (category === "Client Issues") sortPosition = 5;
        if (category === "Miscellaneous") sortPosition = 6;
        if (!category) sortPosition = 7;
        duration = formatDuration(call.call_length);
        notes = qaNotes;

        const newCallData = {
          call_date: callDate,
          cid,
          category,
          disposition,
          sortPosition,
          color,
          duration,
          notes,
        };

        newCvsData.push(newCallData);
      });
    }
    newCvsData.sort(sortByDispo);

    setCsvData(newCvsData);
  };

  const sortByDispo = (a, b) => {
    if (a.sortPosition < b.sortPosition) {
      return -1;
    }
    if (a.sortPosition > b.sortPosition) {
      return 1;
    }
    if (a.disposition < b.disposition) {
      return -1;
    }
    if (a.disposition > b.disposition) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Flex flexDir="column" w="100%" ml="10px">
        <Text fontStyle="italic" textAlign="center">
          &nbsp;
        </Text>
        <Tooltip hasArrow placement="top" label="External Report">
          <IconButton
            colorScheme="purple"
            onClick={handleOpen}
            aria-label="Pull Mortgage Data"
            icon={<HiOutlineDocumentReport />}
            isLoading={insertCallsLoading || callsLoading || loading}
          />
        </Tooltip>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h="80vh">
          <ModalHeader textAlign="center" onClick={() => console.log(csvData)}>
            QA - External Report
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0}>
            {csvData ? (
              <Tabs>
                <TabList position="sticky" top={0} backgroundColor="#2D3748" mt={0} p={3}>
                  <Tab>Report</Tab>
                  <Tab>Examples</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Flex justifyContent="space-evenly" alignItems="center">
                      <Table w="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th textAlign="center" w="5%">
                              Call Date
                            </Th>
                            <Th textAlign="center" w="15%">
                              CID
                            </Th>
                            <Th textAlign="center" w="30%">
                              Disposition
                            </Th>
                            <Th textAlign="center" w="15%">
                              Duration
                            </Th>
                            <Th textAlign="center" w="35%">
                              Notes
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {csvData ? csvData.map((call, i) => <TableRow call={call} />) : null}
                        </Tbody>
                      </Table>
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <Flex justifyContent="space-evenly" alignItems="center">
                      <Table w="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th textAlign="center" w="15%">
                              CID
                            </Th>
                            <Th textAlign="center" w="30%">
                              Disposition
                            </Th>
                            <Th textAlign="center" w="35%">
                              Notes
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {csvData
                            ? csvData.map((call, i) => <TableRowExample call={call} />)
                            : null}
                        </Tbody>
                      </Table>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : null}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
