import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  forwardRef,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import { FaSearch } from "react-icons/fa";
import SearchCidResults from "./SearchCidResults";

const DispoTableRow = ({ dispo, getQaDispositions, category }) => {
  return (
    <Tr _hover={{ fontWeight: 700, backgroundColor: "gray.500" }} backgroundColor={dispo.color}>
      <Td color="white" w="50%">
        {dispo.name}
      </Td>
      <Td color="white" w="50%">
        {dispo.description ? dispo.description : " "}
      </Td>
    </Tr>
  );
};

const DispoTable = ({ data, getQaDispositions }) => {
  return (
    <Table size="sm" w="100%" my="10px">
      <Tbody>
        <Tr backgroundColor={data.color}>
          <Td textAlign="center" fontWeight={700} color="white">
            {data?.category}
          </Td>
          <Td fontWeight={700} color="white">
            {data.description}
          </Td>
        </Tr>
        {data &&
          data.dispositions.map((dispo) => (
            <DispoTableRow
              dispo={dispo}
              getQaDispositions={getQaDispositions}
              category={data?.category}
            />
          ))}
      </Tbody>
    </Table>
  );
};

export default function SearchCid({
  allCalls,
  qaDispos,
  updateCallCatDispoFrontEnd,
  updateCallFromDeleteFrontEnd,
  updateCallFromFlaggedFrontEnd,
  username,
  getQaDispositions,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [cidSearchValue, setCidSearchValue] = useState();
  const [searchResults, setSearchResults] = useState();

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput onClick={onClick} ref={ref} value={value} />
  ));

  const CustomInput = ({ onClick, ref, value }) => {
    return (
      <Button onClick={onClick} ref={ref} pr="40px" w="100%" disabled>
        {value === moment().format("L") ? "Today" : value}
      </Button>
    );
  };

  const handleClose = () => {
    setSearchResults(null);
    setCidSearchValue(null);

    onClose();
  };

  const filterSearchCid = () => {
    const results = allCalls.filter((call) => {
      return call.call_callerid.includes(cidSearchValue);
    });
    setSearchResults(results);
  };

  const handleCidSearch = () => {
    if (!cidSearchValue) {
      alert("Please add a seach value.");
      return;
    }
    if (searchResults) {
      setSearchResults(null);
    }

    if (
      moment(startDate).format("L") === moment().format("L") &&
      moment(endDate).format("L") === moment().format("L")
    ) {
      console.log("it's today!");
      filterSearchCid();
    } else {
      alert("Search only works for today's date currently. This feature will added soon.");
    }
  };

  const logMe = () => {
    // console.log("startDate::", startDate);
    // console.log("endDate::", endDate);
    console.log("searchResults::", searchResults);
  };

  return (
    <>
      <Tooltip label="Search for 1 CID" placement="top" hasArrow>
        <IconButton onClick={onOpen} icon={<FaSearch />} ml={2} />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={handleClose} size={searchResults ? "7xl" : "4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader onClick={logMe}>
            <Flex alignItems="center">
              <Text mr={4}>Search CID </Text>
              <Tag colorScheme="red">Search Only works for current range of data pulled.</Tag>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w="100%" justifyContent="flex-start" alignItems="flex-end" mb={5}>
              <Flex w="25%" mx="10px" flexDir="column" zIndex={3}>
                <Text>Start Date</Text>
                <Button pr="40px" w="100%" disabled>
                  Today
                </Button>
                {/* <DatePicker
                  color="red"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  value={startDate ? moment(startDate).format("L") : null}
                  isClearable
                  customInput={<DatePickerInput />}
                /> */}
              </Flex>
              <Flex w="25%" mx="10px" flexDir="column">
                <Text>End Date</Text>
                <Button pr="40px" w="100%" disabled>
                  Today
                </Button>
                {/* <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  value={endDate ? moment(endDate).format("L") : null}
                  isClearable
                  customInput={<DatePickerInput />}
                  popperClassName="datepicker-class"
                /> */}
              </Flex>
              <Flex w="25%" mx="10px" flexDir="column">
                <Text>CID</Text>
                <Input
                  value={cidSearchValue}
                  type="number"
                  onChange={(e) => setCidSearchValue(e.target.value)}
                />
              </Flex>
              <Flex w="25%" h="100%" mx="10px" flexDir="column">
                <Button colorScheme="blue" mr={3} onClick={handleCidSearch}>
                  Search
                </Button>
              </Flex>
            </Flex>
            {searchResults ? (
              <Flex w="100%" h="790px">
                {qaDispos ? (
                  <Flex flexDir="column" w="40%" h="100%">
                    <Text textAlign="center" letterSpacing={3} mb={2}>
                      QA DISPOSITION KEYS
                    </Text>
                    <Box h="750px" overflowY="scroll">
                      {qaDispos.map((table) => (
                        <DispoTable data={table} getQaDispositions={getQaDispositions} />
                      ))}
                    </Box>
                  </Flex>
                ) : null}
                <Tabs w="60%" h="850px">
                  <TabList>
                    {searchResults?.map((call, index) => (
                      <Tab key={index}>{index + 1}</Tab>
                    ))}
                  </TabList>

                  <TabPanels w="100%">
                    {searchResults?.map((call, index) => (
                      <TabPanel>
                        <SearchCidResults
                          searchResults={searchResults}
                          key={call.call_unique_jd}
                          call={call}
                          allCalls={allCalls}
                          qaDispos={qaDispos}
                          updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                          updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                          updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                          index={allCalls.map((e) => e.call_unique_id).indexOf(call.call_unique_id)}
                          username={username}
                          getQaDispositions={getQaDispositions}
                        />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
                {/* {searchResults.map((call) => (
                  <SearchCidResults
                    call={call}
                    allCalls={allCalls}
                    qaDispos={qaDispos}
                    updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                    updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                    updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                    index={allCalls.map((e) => e.call_unique_id).indexOf(call.call_unique_id)}
                    username={username}
                    getQaDispositions={getQaDispositions}
                  />
                ))} */}
              </Flex>
            ) : null}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
