import { Icon, IconButton, Link } from "@chakra-ui/react";
import React from "react";

import { TbExternalLink } from "react-icons/tb";

export default function RingbaLink({ type, id }) {
  const handleUrl = () => {
    let url;
    if (type === "campaign") {
      url = `https://app.ringba.com/#/dashboard/campaign/${id}`;
    }
    if (type === "target") {
      url = `https://app.ringba.com/#/dashboard/target/edit/${id}`;
    }
    if (type === "ringtree") {
      url = `https://app.ringba.com/#/dashboard/ringtreetarget/edit/${id}`;
    }

    return url;
  };

  return (
    <Link href={handleUrl()} isExternal>
      <Icon
        pb={1}
        _hover={{ color: "teal.400" }}
        boxSize={5}
        as={TbExternalLink}
        colorScheme="teal"
      />
    </Link>
  );
}
