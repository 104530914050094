import React, { useState } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Flex,
  Divider,
  Button,
  Spinner,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import ConfirmationModal from "../modals/ConfirmationModal";

import axios from "axios";
import moment from "moment";

const style = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10%",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function UploadDispo() {
  const [reportList, setReportList] = useState([
    "eHealth - Medicare",
    "GoHealth - Medicare",
    "Health IQ - Medicare - Inbound",
    "Health IQ - Medicare - WT",
    "Mutual of Omaha - Final Expense - WT",
    "Select Quote - Medicare",
    "Select Quote - Final Expense - Inbound",
    "Select Quote - Final Expense - WT",
    "Tranzact - Final Expense - WT",
  ]);
  const [selectedReport, setSelectedReport] = useState();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const getS3Url = async () => {
    try {
      // console.log("selectedReport:", selectedReport);
      const formattedName = `${selectedReport
        .replace(/-/g, "_")
        .replace(/ /g, "")
        .toLowerCase()}-${moment().format("MMDDYYYY_X")}.csv`;
      const fileName = { fileName: formattedName };
      // console.log("fileName:", fileName);
      const response = await axios.post("/s3Url", fileName);
      const data = response.data.url;
      return data;
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const uploadFile = async () => {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const data = file[0];
    console.log("file:", data);

    if (selectedReport && file) {
      try {
        setLoading(true);
        const url = await getS3Url();
        const response = await axios.put(url, data, config);
        // console.log("response:", response);
        if (response.status === 200) {
          setLoading(false);
          clearFields();
          alert("File has been uploaded successfully!");
        } else {
          setLoading(false);
          alert(`Error: ${response.status}. ${response.statusText}`);
        }
      } catch (error) {
        console.error(error);
        alert(error);
      }
    } else {
      console.error("There was an error.");
    }
  };

  const clearFields = () => {
    setFile();
    setSelectedReport();
  };

  return (
    <Box
      w="50%"
      h="100%"
      mt={6}
      p={6}
      //   backgroundColor="red"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.2)"
      borderRadius="7px"
      alignSelf="center"
      justifySelf="center"
    >
      <Flex mb="10px" justify="space-between" alignItems="center">
        <Text fontSize="18px" fontWeight={600} letterSpacing="3px">
          UPLOAD DISPOSITION REPORT
        </Text>
        {selectedReport && file ? (
          <ConfirmationModal
            buttonText="Upload Report"
            buttonSize="sm"
            colorScheme="purple"
            header="Confirm upload"
            messages={[`Report Type: ${selectedReport}`, `File: ${file[0].name}`]}
            alert="Please ensure that the report type and file are correct."
            event={uploadFile}
          />
        ) : null}
      </Flex>
      <Divider mb="20px" />
      <Flex>
        <FormControl
          id="report"
          value={selectedReport}
          onChange={(e) => setSelectedReport(e.target.value)}
        >
          <FormLabel>Report Type</FormLabel>
          <Flex alignItems="center">
            <Select placeholder="Select Report">
              {reportList
                ? reportList.map((item, index) => <option key={`${item}-${index}`}>{item}</option>)
                : null}
            </Select>
            <Button size="md" ml="10px" px="15px" fontSize="14px">
              New Report Type
            </Button>
          </Flex>
          <FormHelperText>Select the corresponding report type for the upload file.</FormHelperText>
        </FormControl>
      </Flex>
      {file ? (
        <Flex mt="20px">
          <Text fontStyle="italic">{`Selected File: ${file[0].name}`}</Text>
          <Button size="xs" ml="10px" onClick={() => setFile()}>
            Remove File
          </Button>
        </Flex>
      ) : null}

      <Box
        mt="20px"
        p="20px"
        w="100%"
        borderWidth="1px"
        borderRadius="7px"
        justifyContent="center"
        bgColor="gray.100"
      >
        <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles)} maxFiles={1}>
          {({ getRootProps, getInputProps }) => (
            <Box
              justifyContent="center"
              cursor="pointer"
              {...getRootProps(style)}
              transition="0.7s"
              _hover={{ backgroundColor: "gray", transition: "0.7s ease-in-out" }}
            >
              <input {...getInputProps()} />
              <Text textAlign="center">
                {file ? file[0].name : "Drag 'n' drop report file here, or click to select file."}
              </Text>
              {loading ? (
                <Flex position="absolute" justifySelf="center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="green.400"
                    size="xl"
                  />
                </Flex>
              ) : null}
            </Box>
          )}
        </Dropzone>
      </Box>
    </Box>
  );
}
