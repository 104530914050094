const ringbaAccountId = "RA31243b25cd754e2b9ebda9ca562eef11";
const RingbaAccessToken =
  "09f0c9f01f1968201c9749478adff2763085517c19af5ec5dbf4d1f91c39b6fd72a355795429044a6e82b41ccb0ea2a735ea3f922323060ac40e6686bf4d511a5fd6e7b327c5042b56b179a7af67d66b5a7de457fcfad2f75c1a945b17bad20c6eed22b6a6760a230202299129089f33f332996a";
const LeadspediaApiKey = "65fc34d5e2f764af0435b10d57302c4b";
const LeadspediaApiSecret = "0443811f3a8c12f7e7a85093fae6619d";
// const convosoToken = "cxlklz9agk5i0wpy9vmepmfap1hj0tp2";

export const addToLP = async (number, note) => {
  const url = `https://api.leadspedia.com/core/v2/blockedCallerIDs/add.do?api_secret=${LeadspediaApiSecret}&api_key=${LeadspediaApiKey}&phoneNumber=${number}&note=${note}`;

  fetch(url)
    .then((res) => res.text())
    .then((result) => console.log("addToLP result:", result))
    .catch((err) => {
      // console.error(err);
      alert(err.message);
    });
};

export const addToRB = async (number) => {
  const url = `https://api.ringba.com/v2/${ringbaAccountId}/blockedNumbers`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${RingbaAccessToken}`,
  };

  const raw = {
    phoneNumber: `+${number}`,
    campaignId: "",
  };

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(raw),
    redirect: "follow",
  };

  fetch(url, requestOptions)
    .then((res) => res.text())
    .then((result) => console.log("addToRB result:", result))
    .catch((err) => {
      // console.error(err);
      alert(err.message);
    });
};

// export const addToConvoso = (number) => {
//   console.log("addToConvoso");

//   const url = `https://api.convoso.com/v1/dnc/insert?auth_token=${convosoToken}&phone_number=${number}&phone_code=1&campaign_id=0`;

//   fetch(url)
//     .then((res) => res.text())
//     .then((result) => console.log(result))
//     .catch((err) => {
//       // console.error(err);
//       // alert(err.message);
//     });
// };
