import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { addNewQaDispositionStatus } from "../qa/QaApis";

import { GoPlus } from "react-icons/go";

export default function NewDispositionStatus({ getQaDispositions }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [qaDispos, setQaDispos] = useState();
  const [category, setCategory] = useState();
  const [disposition, setDisposition] = useState();
  const [description, setDescription] = useState();
  const [dispoColor, setDispoColor] = useState();
  const [isPoor, setIsPoor] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const handleCategoryChange = (e) => {
    let category = e.target.value;
    setCategory(category);

    if (category === "Excellent") {
      setDispoColor("green.200");
      setIsPoor(false);
    }
    if (category === "Satisfactory") {
      setDispoColor("orange.300");
      setIsPoor(false);
    }
    if (category === "Client Issues") {
      setDispoColor("blue.300");
      setIsPoor(false);
    }
    if (category === "Poor") {
      setIsPoor(true);
    }
  };

  const handleDescriptionChange = (e) => {
    let description = e.target.value;
    setDescription(description);
  };
  const handleDispositionChange = (e) => {
    let disposition = e.target.value;
    setDisposition(disposition);
  };
  const handleIsPoorColorChange = (e) => {
    let color = e.target.value;
    setDispoColor(color);
  };

  const logMe = () => {
    console.log(qaDispos);
  };

  const emptyFields = () => {
    setCategory();
    setDisposition();
    setDescription();
    setDispoColor();
    setIsPoor();
    setSuccess(false);
    setFailure(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSuccess(false);
    setFailure(false);
    const data = {
      category,
      disposition: {
        id: uuidv4(),
        name: disposition,
        description,
        color: dispoColor,
      },
    };

    try {
      const result = await addNewQaDispositionStatus(data);
      console.log(result);
      setLoading(false);
      getQaDispositions();
      setFailure(false);
      setSuccess(true);
      setTimeout(() => {
        emptyFields();
        onClose();
      }, 2000);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
      setSuccess(false);
      setFailure(true);
    }
  };

  return (
    <>
      <Tooltip label="New Disposition Status" hasArrow placement="top">
        <IconButton colorScheme="teal" icon={<GoPlus />} onClick={onOpen} />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Disposition Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="category" mb={3} isRequired>
              <FormLabel>Category</FormLabel>
              <Select placeholder="Select Category" onChange={handleCategoryChange}>
                <option style={{ color: "#68D391" }}>Excellent</option>
                <option style={{ color: "#F6AD55" }}>Satisfactory</option>
                <option style={{ color: "#FC8181" }}>Poor</option>
                <option style={{ color: "#63B3ED" }}>Client Issues</option>
              </Select>
            </FormControl>

            <FormControl id="disposition" mb={3} onChange={handleDispositionChange} isRequired>
              <FormLabel>Disposition</FormLabel>
              <Input type="text" />
            </FormControl>

            {isPoor ? (
              <FormControl id="disposition" mb={3} onChange={handleIsPoorColorChange} isRequired>
                <FormLabel>Color</FormLabel>
                <Select placeholder="Select Color" onChange={handleCategoryChange}>
                  <option style={{ backgroundColor: "#E53E3E" }}>Red</option>
                  <option style={{ backgroundColor: "#FC8181" }}>Light Red</option>
                </Select>
              </FormControl>
            ) : null}

            <Flex flexDir="column" mb={3}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter description here. (Optional)"
                value={description}
                onChange={handleDescriptionChange}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            {success ? (
              <Alert status="success" mr="20px">
                <AlertIcon />
                <AlertDescription>Success!</AlertDescription>
              </Alert>
            ) : null}
            {failure ? (
              <Alert status="error" mr="20px">
                <AlertIcon />
                <AlertDescription>Error!</AlertDescription>
              </Alert>
            ) : null}
            <Button
              w="100px"
              colorScheme="teal"
              mr={3}
              onClick={handleSubmit}
              isDisabled={loading || success}
            >
              {loading ? (
                <Spinner color="teal.300" speed="0.65s" emptyColor="gray.200" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
