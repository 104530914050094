import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  IconButton,
  Spinner,
  Divider,
  Link,
} from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";
import { allCallsToday, getAllQaDispositions } from "../components/qa/QaApis";

import { FiRefreshCcw } from "react-icons/fi";
import { AiFillSound } from "react-icons/ai";

import moment from "moment";
import DispositionKey from "../components/qa/DispositionKey";

const TableRow = ({ call }) => {
  const logMe = () => {
    console.log("call:", call);
    // console.log("ccCap:", ccCap);
    // console.log("hourlyCap:", hourlyCap);
  };

  return (
    <>
      {call ? (
        <Tr _hover={{ backgroundColor: "teal.300" }}>
          <Td>{call.call_date_time}</Td>
          <Td>{call.buyer_name}</Td>
          <Td>{call.affiliate_name}</Td>
          <Td>{call.caller_id}</Td>
          <Td>{call.campaign_name}</Td>
          <Td>{call.duration}</Td>
          <Td>
            {call.recording_url ? (
              <Link href={call.recording_url}>
                <IconButton size="sm" icon={<AiFillSound />} />
              </Link>
            ) : null}
          </Td>
          <Td>{call.qa}</Td>
        </Tr>
      ) : null}
    </>
  );
};

const FilterButton = ({ filterName, filters, handleFilter, updateFilteredData }) => {
  const [color, setColor] = useState(null);
  const handleClick = () => {
    handleFilter(filterName);
    if (filters.indexOf(filterName) > -1) {
      setColor("teal");
    } else {
      setColor(null);
    }
    updateFilteredData();
  };

  return (
    <Button colorScheme={color} size="xs" m="5px" onClick={handleClick}>
      {filterName}
    </Button>
  );
};

export default function QualityAssurance() {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment().format("LT"));
  const [sortFilter, setSortFilter] = useState("name");
  const [qaDispos, setQaDispos] = useState([]);

  const sortByCallDateTime = (a, b) => {
    if (a.call_date_time < b.call_date_time) {
      return -1;
    }
    if (a.call_date_time > b.call_date_time) {
      return 1;
    }
    return 0;
  };
  const sortByAcl = (a, b) => {
    if (a.avgHandleTime < b.avgHandleTime) {
      return -1;
    }
    if (a.avgHandleTime > b.avgHandleTime) {
      return 1;
    }
    return 0;
  };

  const getQaDispositions = async () => {
    setLoading(true);
    try {
      const data = await getAllQaDispositions();
      setQaDispos(data.result);
      console.log("qaDispos::", qaDispos);
      setLoading(false);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  // const updateFilteredData = () => {
  //   if (filters.length < 1) {
  //     setFilteredData(null);
  //     return;
  //   }
  //   let newData = [];
  //   data.forEach((campaign) => {
  //     if (campaign.campaign.campaignName) {
  //       const array = campaign.campaign.campaignName.split(" - ");
  //       if (array.some((r) => filters.indexOf(r) >= 0)) {
  //         newData.push(campaign);
  //       }
  //     }
  //   });
  //   setFilteredData(newData);
  // };

  // const handleFilter = (filterName) => {
  //   let newFilters = filters;
  //   const index = newFilters.indexOf(filterName);
  //   if (index > -1) {
  //     newFilters.splice(index, 1);
  //   } else {
  //     newFilters.push(filterName);
  //   }
  //   setFilters(newFilters);
  // };

  const buildReportQuery = () => {
    const payload = {
      startDate: moment().format("Y-MM-DD"),
      endDate: moment().add(1, "day").format("Y-MM-DD"),
    };

    return payload;
  };

  const handleClick = async () => {
    setData(null);
    setLoading(true);
    try {
      const data = await allCallsToday(buildReportQuery());
      setData(data);
      // console.log("data::", data);
      setLoading(false);
      setLastUpdated(moment().format("LT"));
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getQaDispositions();
  }, []);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="1vh"
      mx="10px"
      p={6}
      alignSelf="center"
      justifySelf="center"
      overflowY="scroll"
    >
      <TopHeading
        title="Quality Assurance"
        description="Monitor quality of all incoming calls."
        children={
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            {lastUpdated ? (
              <Text fontStyle="italic" mr="10px">
                Last Updated: {lastUpdated}
              </Text>
            ) : null}

            <IconButton
              colorScheme="teal"
              onClick={handleClick}
              aria-label="Pull Mortgage Data"
              icon={<FiRefreshCcw />}
            />
            <DispositionKey qaDispos={qaDispos} getQaDispositions={getQaDispositions} />
          </Flex>
        }
      />

      <Flex flexDir="column" h="500px" mb="15px">
        {loading ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}
        {/* {data || filteredData ? (
          <Flex alignItems="center" justify="flex-start" mt="15px">
            <Text fontSize="12px" fontWeight={500}>
              FILTERS:
            </Text>
            <Flex justify="center" alignItems="center">
              <FilterButton
                filterName="AUTO"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="FE"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MA"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MS"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MGE"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="O65"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="U65"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="UTIL"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
            </Flex>
          </Flex>
        ) : null} */}
        {data || filteredData ? (
          <Flex flexDir="column" w="100%">
            <Table size="sm" w="100%">
              <Thead>
                <Tr>
                  <Th textAlign="left">Date</Th>
                  <Th textAlign="left">Buyer</Th>
                  <Th textAlign="left">Affiliate</Th>
                  <Th textAlign="left">Caller ID</Th>
                  <Th textAlign="left">Campaign</Th>
                  <Th textAlign="left">Duration</Th>
                  <Th textAlign="left">Recording</Th>
                  <Th textAlign="left">QA</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData
                  ? filteredData
                      .sort(sortByCallDateTime)
                      .map((call, i) => <TableRow key={call.uuid} campaign={call} />)
                  : data
                      .sort(sortByCallDateTime)
                      .map((call, i) => <TableRow key={call.uuid} call={call} />)}
              </Tbody>
            </Table>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
