import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
} from "@chakra-ui/react";
import moment from "moment";

import { FaPhone, FaRegFileAlt } from "react-icons/fa";

export default function FilterConfirmationModal({
  buttonSize,
  header,
  messages,
  alert,
  event,
  icon,
  filter,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    await event();
    setLoading(false);
    onClose();
  };

  return (
    <>
      <IconButton
        icon={icon}
        onClick={onOpen}
        size={buttonSize}
        _hover={{ backgroundColor: "purple.300" }}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Flex justifyContent="center" alignItems="center" py="10%">
                <Spinner size="xl" color="teal.300" />
              </Flex>
            ) : (
              <Flex flexDir="column" justify="center" my={4}>
                {messages ? messages.map((message, index) => <Text>{message}</Text>) : null}
                {filter ? (
                  <Flex flexDir="column" mt="20px">
                    <Flex
                      borderWidth="1px"
                      borderRadius="7px"
                      borderColor="gray.200"
                      p="5px"
                      backgroundColor="white"
                      mb="20px"
                      minH="50px"
                    >
                      <Table w="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th textAlign="center">Type</Th>
                            <Th textAlign="center">Field</Th>
                            <Th textAlign="center">Operator</Th>
                            <Th textAlign="center">Compare Value</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr mb="5px">
                            <Td textAlign="center">
                              {filter.filterType === "data" ? (
                                <Icon color="black" as={FaRegFileAlt} mx="5px" w={5} h={5} />
                              ) : (
                                <Icon color="black" as={FaPhone} mx="5px" w={5} h={5} />
                              )}
                            </Td>

                            <Td color="black" fontSize="16px" px="4px" py="1px" textAlign="center">
                              {filter.field}
                            </Td>

                            <Td
                              color="black"
                              fontSize="16px"
                              px="4px"
                              py="1px"
                              mx="10px"
                              textAlign="center"
                            >
                              {filter.operator}
                            </Td>

                            <Td
                              color="black"
                              fontSize="16px"
                              px="4px"
                              py="1px"
                              textAlign="center"
                              maxW="50px"
                            >
                              {filter.value ? `${filter.value}` : null}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Flex>
                  </Flex>
                ) : null}
                {alert ? (
                  <Alert status="error" mt="20px">
                    <AlertIcon />
                    <AlertTitle mr={2}>{alert}</AlertTitle>
                  </Alert>
                ) : null}
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={submit}>
              Confirm
            </Button>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
