import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Spinner,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { getCampaignStats } from "./DashboardAPIs";
import moment from "moment";

import { FiRefreshCcw } from "react-icons/fi";
import RoutesModal from "../modals/RoutesModal";

const TableRow = ({ campaign }) => {
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const ccCap =
    campaign.campaignAffiliates &&
    campaign.campaignAffiliates[0] &&
    campaign.campaignAffiliates[0].payoutConversionSetting
      ? campaign.campaignAffiliates[0].payoutConversionSetting.concurrencyCap
      : null;
  const hourlyCap =
    campaign.campaignAffiliates &&
    campaign.campaignAffiliates[0] &&
    campaign.campaignAffiliates[0].payoutConversionSetting
      ? campaign.campaignAffiliates[0].payoutConversionSetting.hourlyCap
      : null;
  const dailyCap =
    campaign.campaignAffiliates &&
    campaign.campaignAffiliates[0] &&
    campaign.campaignAffiliates[0].payoutConversionSetting
      ? campaign.campaignAffiliates[0].payoutConversionSetting.dailyCap
      : null;

  const logMe = () => {
    console.log("campaign:", campaign);
    // console.log("ccCap:", ccCap);
    // console.log("hourlyCap:", hourlyCap);
  };

  return (
    <>
      {campaign && campaign.stats && campaign.stats.currentMonth > 0 ? (
        <Tr _hover={{ backgroundColor: hoverColor }}>
          {/* <Button onClick={logMe}>LOG</Button> */}
          <Td textAlign="left" fontWeight={500}>
            {campaign.name}
          </Td>

          <Td
            textAlign="center"
            color={ccCap && campaign.stats.currentLive / ccCap >= 1 ? "red" : null}
          >
            {campaign.stats.currentLive}
            {ccCap > 0 ? `/${ccCap}` : null}
          </Td>
          <Td
            textAlign="center"
            color={hourlyCap && campaign.stats.currentHour / hourlyCap >= 1 ? "red" : null}
          >
            {campaign.stats.currentHour}
            {hourlyCap > 0 ? `/${hourlyCap}` : null}
          </Td>
          <Td
            textAlign="center"
            color={dailyCap && campaign.stats.currentDay / dailyCap >= 1 ? "red" : null}
          >
            {campaign.stats.currentDay}
            {dailyCap > 0 ? `/${dailyCap}` : null}
          </Td>
          <Td textAlign="center">{campaign.stats.currentMonth}</Td>
          <Td textAlign="center">{campaign.stats.total}</Td>

          <Td textAlign="center">
            <RoutesModal campaignName={campaign.name} />
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

const FilterButton = ({ filterName, filters, handleFilter, updateFilteredData }) => {
  const [color, setColor] = useState(null);
  const handleClick = () => {
    handleFilter(filterName);
    if (filters.indexOf(filterName) > -1) {
      setColor("teal");
    } else {
      setColor(null);
    }
    updateFilteredData();
  };

  return (
    <Button colorScheme={color} size="xs" m="5px" onClick={handleClick}>
      {filterName}
    </Button>
  );
};

export default function CampaignCaps() {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();

  const sortByCampaignName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const updateFilteredData = () => {
    if (filters.length < 1) {
      setFilteredData(null);
      return;
    }
    let newData = [];
    data.forEach((campaign) => {
      if (campaign.name) {
        const array = campaign.name.split(" - ");
        if (array.some((r) => filters.indexOf(r) >= 0)) {
          newData.push(campaign);
        }
      }
    });
    setFilteredData(newData);
  };

  const handleFilter = (filterName) => {
    let newFilters = filters;
    const index = newFilters.indexOf(filterName);
    if (index > -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(filterName);
    }
    setFilters(newFilters);
  };

  const handleClick = async () => {
    setData(null);
    setLoading(true);
    try {
      const data = await getCampaignStats();
      setData(data);
      // console.log("data::", data);
      setLoading(false);
      setLastUpdated(moment().format("LT"));
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <Box
      w="100%"
      h="85vh"
      m="auto"
      my="10px"
      mx="10px"
      p="20px"
      overflowY={data ? "scroll" : "hidden"}
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
      borderRadius="7px"
      alignSelf="center"
      justifySelf="center"
      // className="no-scrollbar"
    >
      <Flex justify="flex-end" mb="15px">
        <Flex w="100%" justify="flex-start" alignItems="center" fontSize="18px">
          <Text fontWeight="500" letterSpacing="5px">
            CAMPAIGN CAPS - {`${moment().format("L")}`}
          </Text>
        </Flex>
        <Flex w="80%" justifyContent="flex-end" alignItems="center">
          {lastUpdated ? (
            <Text fontStyle="italic" mr="5px">
              Last Updated: {lastUpdated}
            </Text>
          ) : null}
          <IconButton onClick={handleClick} aria-label="Pull Volume Info" icon={<FiRefreshCcw />} />
        </Flex>
      </Flex>
      {/* <Button onClick={() => console.log(data)}>LOG ME</Button> */}
      <Divider />
      <Flex flexDir="column" h="500px" mb="15px">
        {loading ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}
        {data || filteredData ? (
          <Flex alignItems="center" justify="flex-start" mt="15px">
            <Flex justify="flex-start" alignItems="center" flexWrap="wrap">
              <FilterButton
                filterName="AUTO"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="DEBT"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="FE"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="HOME WARRANTY"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MASS TORT"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MA"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MS"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="MGE"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="O65"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="SECURITY"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="SOLAR"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="TAX RELIEF"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="U65"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
              <FilterButton
                filterName="UTIL"
                filters={filters}
                handleFilter={handleFilter}
                updateFilteredData={updateFilteredData}
              />
            </Flex>
          </Flex>
        ) : null}
        {data || filteredData ? (
          <Flex flexDir="column" w="100%">
            <Table size="sm" w="100%">
              <Thead>
                <Tr>
                  <Th textAlign="center">Campaign</Th>
                  <Th textAlign="center">Live</Th>
                  <Th textAlign="center">Hour</Th>
                  <Th textAlign="center">Day</Th>
                  <Th textAlign="center">Month</Th>
                  <Th textAlign="center">Total</Th>
                  <Th textAlign="center">Routing</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData
                  ? filteredData
                      .sort(sortByCampaignName)
                      .map((campaign, i) => (
                        <TableRow key={`${campaign.name}-${i}`} campaign={campaign} />
                      ))
                  : data
                      .sort(sortByCampaignName)
                      .map((campaign, i) => (
                        <TableRow key={`${campaign.name}-${i}`} campaign={campaign} />
                      ))}
              </Tbody>
            </Table>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
