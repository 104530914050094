import axios from "axios";
import moment from "moment";

const end_date = moment().add(1, "d").format("YYYY-MM-DD");
const start_date = moment().format("YYYY-MM-DD");

const allCallsToday = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllQaDispositions = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-dispositions", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewQaDispositionStatus = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-dispositions-add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteQaDispositionStatus = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-dispositions-delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewQaAssignment = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-assignments-add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};
const addManyQaAssignments = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-assignments-add-many", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editQaAssignment = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-assignments-edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteQaAssignment = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-assignments-delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllAssignments = async () => {
  try {
    const response = await axios.get("api/calls/reports/qa-assignments");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllQaKeys = async () => {
  try {
    const response = await axios.get("api/calls/reports/qa-keys");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewQaKey = async (payload) => {
  console.log("addNewQaKey payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-keys-add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteQaKey = async (payload) => {
  console.log("deleteQaKey payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-keys-delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewActiveLive = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-activelive-add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editActiveLive = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-activelive-edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteActiveLive = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-activelive-delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getActiveLive = async () => {
  try {
    const response = await axios.get("api/calls/reports/qa-activelive");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllCalls = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};
const deleteCall = async (payload) => {
  try {
    const response = await axios.post("api/calls/reports/qa-deletecall", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const setCallCategoryDisposition = async (payload) => {
  console.log("setCallCategoryDisposition payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/set-dispo", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewCallNote = async (payload) => {
  console.log("addNewCallNote payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/add-note", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteCallNote = async (payload) => {
  console.log("deleteCallNote payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/delete-note", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editCallNote = async (payload) => {
  console.log("editCallNote payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/edit-note", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const insertAllCallsIntoDB = async () => {
  try {
    const response = await axios.post(
      "https://r05pxc5ol4.execute-api.us-west-1.amazonaws.com/default/generate-all-call-clusters"
    );
    console.log("insertAllCallsIntoDB response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewFlagToCall = async (payload) => {
  console.log("addNewFlagToCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/flagged", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const updateFlaggedCallStatus = async (payload) => {
  console.log("addNewFlagToCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/flagged-status", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const unflagCall = async (payload) => {
  console.log("unflagCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/unflagged", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewMarkToCall = async (payload) => {
  console.log("addNewFlagToCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/marked", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const updateMarkedCallStatus = async (payload) => {
  console.log("addNewFlagToCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/marked-status", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const unmarkCall = async (payload) => {
  console.log("unflagCall payload:", payload);
  try {
    const response = await axios.post("api/calls/reports/qa-allcalls/unmarked", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

export {
  allCallsToday,
  getAllQaDispositions,
  addNewQaDispositionStatus,
  deleteQaDispositionStatus,
  addNewQaAssignment,
  addManyQaAssignments,
  editQaAssignment,
  deleteQaAssignment,
  getAllAssignments,
  getAllQaKeys,
  addNewQaKey,
  deleteQaKey,
  addNewActiveLive,
  deleteActiveLive,
  editActiveLive,
  getActiveLive,
  getAllCalls,
  deleteCall,
  setCallCategoryDisposition,
  addNewCallNote,
  deleteCallNote,
  editCallNote,
  insertAllCallsIntoDB,
  addNewFlagToCall,
  updateFlaggedCallStatus,
  unflagCall,
  addNewMarkToCall,
  updateMarkedCallStatus,
  unmarkCall,
};
