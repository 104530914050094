import React, { useState, useEffect } from "react";
import {
  Flex,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Button,
  Heading,
  Text,
  IconButton,
} from "@chakra-ui/react";
import moment from "moment";

import { colors } from "../../../config/colors";

import CallDetailsModal from "../modals/CallDetailsModal";
import CallRoutingDetails from "../modals/CallRoutingDetails";

export default function CallRouting({ reportData, setReportData }) {
  const [filter, setFilter] = useState("time");

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  return (
    <Flex flexDir="column" flex mt="20px">
      <Table variant="striped" size="sm">
        <Thead>
          {reportData.callPlans ? (
            <Tr>
              <Th textAlign="left">Name</Th>
              <Th textAlign="center">Live</Th>
              <Th textAlign="center"># of Routes</Th>
              <Th textAlign="center">Details</Th>
            </Tr>
          ) : null}
        </Thead>
        <Tbody>
          {reportData.callPlans
            ? reportData.callPlans.sort(sortByName).map((item, i) => (
                <Tr key={`${item}-${i}`}>
                  <Td textAlign="left">{item.name}</Td>
                  <Td textAlign="center">{`${item.enabled}`}</Td>
                  <Td textAlign="center">{item.routes.length}</Td>
                  <Td textAlign="center">
                    <CallRoutingDetails callDetails={item} />
                  </Td>
                </Tr>
              ))
            : null}
        </Tbody>
      </Table>
    </Flex>
  );
}
