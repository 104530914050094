import axios from "axios";
import moment from "moment";

const failedCallsReport = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/calllogs/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    filters: [
      {
        anyConditionToMatch: [
          {
            column: "hasConnected",
            value: "no",
            isNegativeMatch: "false",
            comparisonType: "EQUALS",
          },
        ],
      },
    ],
  };
  try {
    const response = await axios.post(url, payload, config);
    const data = response.data.report;
    // console.log("failedCallsReport data::", data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getPublisherStats = async () => {
  const allPublishers = await getAllPublishers();
  console.log("allPublishers:", allPublishers);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/publishers/stats`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  //   const payload = {
  //     InboundCallIds: [callID],
  //   };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.stats;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getAllPublishers = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/publishers`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    //   console.log(response);
    const data = response.data.publishers;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getVolumePerCampaign = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "campaignName",
      },
    ],
    valueColumns: [
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
    ],
    orderByColumns: [],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
  };
  try {
    const response = await axios.post(url, payload, config);
    const data = response.data.report.records;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getInsights = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "campaignName",
      },
      {
        column: "targetName",
      },
    ],
    valueColumns: [
      {
        column: "liveCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [
      { column: "campaignName", direction: "asc" },
      { column: "targetName", direction: "asc" },
    ],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
    formatTimeZone: "America/Los_Angeles",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    const formattedData = formatInsightData(data);
    // console.log("formattedData::", formattedData);
    // let realData = [];
    // data.forEach((item) => {
    //   if (item.campaignId) {
    //     realData.push(item);
    //   }
    //   if (!item.campaignId && !item.campaignName) {
    //     realData.push(item);
    //   }
    // });
    // return realData;
    return formattedData;
  } catch (error) {
    console.error(error);
  }
};

const formatInsightData = (data) => {
  let campaignClusters = [];
  data.forEach((entry) => {
    let cluster = {
      campaign: null,
      targets: [],
    };
    if (!entry.targetName) {
      cluster.campaign = entry;
      campaignClusters.push(cluster);
    }
  });
  campaignClusters.forEach((cluster) => {
    data.forEach((entry) => {
      if (
        entry.campaignName === cluster.campaign.campaignName &&
        entry.targetName !== "-no value-"
      ) {
        cluster.targets.push(entry);
      }
    });
  });
  return campaignClusters;
};

const getInsightsTargets = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment().add(1, "d").format("YYYY-MM-DD");
  const start_date = moment().format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "targetName",
      },
      {
        column: "targetId",
      },
    ],
    valueColumns: [
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    // console.log("api data::", data);
    let realData = [];
    data.forEach((item) => {
      if (item.campaignId) {
        realData.push(item);
      }
      if (!item.campaignId && !item.campaignName) {
        realData.push(item);
      }
    });
    return realData;
  } catch (error) {
    console.error(error);
  }
};

const getCallPlans = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallPlans response:", response);
    const data = response.data.callPlans;
    let theCallPlanId;
    data.forEach((plan) => {
      if (plan.name === campaignName) {
        theCallPlanId = plan.id;
        return;
      }
    });
    if (!theCallPlanId) return false;
    return theCallPlanId;
  } catch (error) {
    console.error(error);
  }
};

const getCallRoutesWithId = async (campaignName) => {
  const callRouteId = await getCallPlans(campaignName);
  console.log("callRouteId:", callRouteId);
  if (!callRouteId) return false;

  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("getCallRoutesWithId response:", response);
    const data = response.data.callPlan;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const updateRoutingWeightPriority = async (callRouteId, priorityNum, weightNum) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callroutes/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    id: callRouteId,
    priority: {
      priority: priorityNum,
      weight: weightNum,
    },
  };
  try {
    const response = await axios.patch(url, payload, config);
    // console.log("updateRoutingWeightPriority response:", response);
    const data = response.data.callRoute.priority;
    //   console.log(data);
    // return response;
    return data;
  } catch (error) {
    console.error(error);
  }
};
const updateTargetCaps = async (rt, targetId, dailyCap, hourlyCap, concurrencyCap, hoo) => {
  const url = rt
    ? `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/pingtreetargets/${targetId}`
    : `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/targets/${targetId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };

  // const testUrl1 =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";
  // const testUrl2 =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";
  // const WhatRingbaUses =
  //   "https://api.ringba.com/v2/RA31243b25cd754e2b9ebda9ca562eef11/pingtreetargets/PIbfeedb6114374e57bc3f8f00de133e25";

  // console.log("rt:", rt);
  // console.log("targetId:", targetId);
  // console.log("dailyCap:", dailyCap);
  // console.log("hourlyCap:", hourlyCap);
  // console.log("concurrencyCap:", concurrencyCap);
  // console.log("hoo:", hoo);

  const payload = {
    id: targetId,
    schedule: {
      dailyCap: dailyCap > 0 ? dailyCap : -1,
      hourlyCap: hourlyCap > 0 ? hourlyCap : -1,
      concurrencyCap: concurrencyCap > 0 ? concurrencyCap : -1,
      hoursOfOperation: hoo,
      timeZoneId: "Pacific Standard Time",
    },
  };

  const payload_rt = {
    id: targetId,
    schedule: {
      dailyCapDayOfWeek:
        dailyCap > 0
          ? [dailyCap, dailyCap, dailyCap, dailyCap, dailyCap, dailyCap, dailyCap]
          : null,
      dailyCap: dailyCap > 0 ? dailyCap : -1,
      hourlyCap: hourlyCap > 0 ? hourlyCap : -1,
      concurrencyCap: concurrencyCap > 0 ? concurrencyCap : -1,
    },
  };

  console.log(rt ? "payload_rt:" : "payload:", rt ? payload_rt : payload);

  try {
    const response = await axios.patch(url, rt ? payload_rt : payload, config);
    console.log("updateTargetCaps response:", response);
    // const data = response.data.callRoute.priority;
    //   console.log(data);
    // return response;
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const getCampaignStats = async () => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns?includeStats=true`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("response:", response);
    const campaignData = response.data.campaigns;
    // console.log("campaignData:", campaignData);
    const statsData = Object.entries(response.data.stats).map((e) => ({ [e[0]]: e[1] }));
    // console.log("statsData::", statsData);

    let allCampaigns = [];
    campaignData.forEach((campaign) => {
      if (campaign.enabled) {
        const cluster = {
          name: campaign.name,
          id: campaign.id,
          campaignAffiliates: campaign.affiliates,
          stats: null,
        };
        allCampaigns.push(cluster);
      }
    });

    allCampaigns.forEach((cluster) => {
      statsData.forEach((stat) => {
        const key = Object.keys(stat)[0];
        if (key.toLowerCase() === cluster.id.toLowerCase() && stat[key].total > 0) {
          // console.log("stat:", stat);
          // console.log("stat.key:", stat[key]);
          const stats = {
            currentDay: 0,
            currentHour: 0,
            currentLive: 0,
            currentMonth: 0,
            total: 0,
          };
          stats.currentDay = stat[key].currentDay;
          stats.currentHour = stat[key].currentHour;
          stats.currentLive = stat[key].currentLive;
          stats.currentMonth = stat[key].currentMonth;
          stats.total = stat[key].total;
          cluster.stats = stats;
        }
      });
    });
    console.log("allCampaigns:", allCampaigns);
    return allCampaigns;
  } catch (error) {
    console.error(error);
  }
};

const getCampaignCaps = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("campaignData:", response);
    const data = response.data.campaigns;
    let theCampaign;
    data.forEach((campaign) => {
      if (campaign.name === campaignName) {
        theCampaign = campaign;
        return;
      }
    });
    if (!theCampaign) return false;
    return theCampaign;
  } catch (error) {
    console.error(error);
  }
};

const updateCampaignCaps = async (
  campaignId,
  affiliateId,
  payoutId,
  caps,
  payoutAmount,
  callLength
) => {
  // console.log("campaignId:", campaignId);
  // console.log("affiliateId:", affiliateId);
  // console.log("payoutId:", payoutId);
  // console.log("caps:", caps);
  // console.log("payoutAmount:", payoutAmount);
  // console.log("callLength:", callLength);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns/${campaignId}/affiliates/${affiliateId}/payouts/${payoutId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    id: payoutId,
    dailyCap: caps.dailyCap === 0 || caps.dailyCap === "0" ? null : caps.dailyCap,
    concurrencyCap:
      caps.concurrencyCap === 0 || caps.concurrencyCap === "0" ? null : caps.concurrencyCap,
    hourlyCap: caps.hourlyCap === 0 || caps.hourlyCap === "0" ? null : caps.hourlyCap,
    payoutConversionType: "callLength",
    payoutConversionArgs: {
      callLengthInSeconds: callLength,
    },
    payoutAmount,
  };

  try {
    const response = await axios.patch(url, payload, config);

    const data = response.data;
    if (data.transactionId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

const getStrandedMortgage = async (payload) => {
  // console.log("getStrandedMortgage payload:", payload);
  try {
    const response = await axios.post("api/calls/stranded-mortgage", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

export {
  getPublisherStats,
  failedCallsReport,
  getInsights,
  getInsightsTargets,
  getVolumePerCampaign,
  getCallRoutesWithId,
  getStrandedMortgage,
  updateRoutingWeightPriority,
  updateTargetCaps,
  getCampaignCaps,
  updateCampaignCaps,
  getCampaignStats,
};
