import React, { useState } from "react";
import { Text, Flex, Divider, Avatar, IconButton, Button, Switch, Icon } from "@chakra-ui/react";
import NavItem from "./NavItem";
import { useColorMode } from "@chakra-ui/color-mode";

import moment from "moment";

import "./sidebar.css";

import { FiMenu, FiTool } from "react-icons/fi";
import { HiOutlineDocumentReport, HiPhoneMissedCall } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import { FaChartLine, FaLightbulb } from "react-icons/fa";
import { AiOutlineDashboard, AiOutlineHome } from "react-icons/ai";
import { BsBriefcase } from "react-icons/bs";
import { FiLink } from "react-icons/fi";
import { GiMeshBall } from "react-icons/gi";
import { BiPhoneCall } from "react-icons/bi";
import NavItemMortgage from "./NavItemMortgage";
import NavItemPartners from "./NavItemPartners";
import NavItemPerformance from "./NavItemPerformance";
import NavItemQa from "./NavItemQa";
import NavItemBlockedNumbers from "./NavItemBlockedNumbers";
import NavItemJobs from "./NavItemJobs";

export default function Sidebar({ logout, name }) {
  const [navSize, setNavSize] = useState("large");
  const [active, setActive] = useState("Dashboard");
  const { colorMode, toggleColorMode } = useColorMode();

  const changeNavSize = () => {
    navSize === "small" ? setNavSize("large") : setNavSize("small");
  };

  const setActivePage = (page) => {
    setActive(page);
    console.log("hello");
  };

  return (
    <Flex
      // position="absolute"
      left="5"
      h="95vh"
      ml="10px"
      marginTop="1vh"
      // boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.5)"
      borderRadius="7px"
      w={navSize === "small" ? "75px" : "230px"}
      flexDir="column"
      justifyContent="space-between"

      // overflowY="scroll"
    >
      <Flex
        p="5%"
        flexDir="column"
        alignItems={navSize === "small" ? "center" : "flex-start"}
        as="nav"
        className="no-scrollbar"
        overflowY="scroll"
      >
        <Flex alignItems="center">
          <IconButton
            background="none"
            mt={5}
            _hover={{ background: "none" }}
            icon={<FiMenu />}
            onClick={changeNavSize}
            color="gray.500"
            size="lg"
          />
          <Text mt={5} display={navSize === "small" ? "none" : "flex"}>
            NexLevel Direct
          </Text>
        </Flex>
        <NavItem
          navSize={navSize}
          icon={AiOutlineDashboard}
          title="Dashboard"
          description=""
          pageLink="/"
          active={active === "Dashboard" ? true : false}
          setActive={setActive}
        />
        <NavItemPerformance
          navSize={navSize}
          icon={FaChartLine}
          title="Performance"
          pageLink1="/campaigns"
          pageLink2="/targets"
          active={active}
          setActive={setActive}
        />
        <NavItemJobs
          navSize={navSize}
          icon={BsBriefcase}
          title="Jobs"
          pageLink1="/jobs/campaigns"
          pageLink2="/jobs/targets"
          active={active}
          setActive={setActive}
        />
        {/* <NavItem
          navSize={navSize}
          icon={BsBriefcase}
          title="Contracts"
          pageLink="/contracts"
          active={active === "Active Jobs" ? true : false}
          setActive={setActive}
        /> */}
        <NavItemPartners
          navSize={navSize}
          icon={IoIosPeople}
          title="Partners"
          pageLink1="/affiliates"
          pageLink2="/buyers"
          pageLink3="/blacklist"
          active={active}
          setActive={setActive}
        />
        {/* <NavItem
          navSize={navSize}
          icon={HiOutlineDocumentReport}
          title="Reports"
          pageLink="/reports"
          active={active === "Reports" ? true : false}
          setActive={setActive}
        /> */}
        <NavItemMortgage
          navSize={navSize}
          icon={AiOutlineHome}
          title="Mortgage"
          pageLink1="/mortgage"
          pageLink2="/mortgage-settings"
          pageLink3="/lo-list"
          active={active}
          setActive={setActive}
        />
        {/* <NavItem
          navSize={navSize}
          icon={BiPhoneCall}
          title="QA"
          pageLink="/qa"
          active={active === "QA" ? true : false}
          setActive={setActive}
        /> */}
        <NavItemQa
          navSize={navSize}
          icon={BiPhoneCall}
          title="QA"
          pageLink1="/qa-calls"
          pageLink2="/qa-assignments"
          pageLink3="/qa-active-live"
          active={active}
          setActive={setActive}
        />
        <NavItemBlockedNumbers
          navSize={navSize}
          icon={HiPhoneMissedCall}
          title="Blocked #"
          pageLink1="/bla"
          pageLink2="/dnclist"
          pageLink3="/robospam"
          pageLink4="/jornaya"
          active={active}
          setActive={setActive}
        />
        {/* <NavItem
          navSize={navSize}
          icon={HiPhoneMissedCall}
          title="DNC List"
          pageLink="/dnclist"
          active={active === "DNC List" ? true : false}
          setActive={setActive}
        /> */}
        {/* <NavItem
          navSize={navSize}
          icon={FiTool}
          title="Tools"
          pageLink="/tools"
          active={active === "Tools" ? true : false}
          setActive={setActive}
        /> */}
        <NavItem
          navSize={navSize}
          icon={FiLink}
          title="Resources"
          pageLink="/resources"
          active={active === "Resources" ? true : false}
          setActive={setActive}
        />
      </Flex>

      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}
        mb={4}
      >
        <Flex alignItems="center" justifyContent="center" w="100%" mt={1} py={1}>
          <Icon
            mr={2}
            as={FaLightbulb}
            w={5}
            h={5}
            display={navSize === "small" ? "none" : "flex"}
          />
          <Switch size="md" colorScheme="blackAlpha" onChange={toggleColorMode} />
        </Flex>
        <Text
          fontSize={14}
          fontWeight={600}
          w="100%"
          textAlign="center"
          justifyContent="center"
          display={navSize === "small" ? "none" : "flex"}
        >
          {moment().format("dddd, L")}
        </Text>

        <Divider display={navSize === "small" ? "none" : "flex"} />
        <Flex mt={4} p={2} alignItems="center">
          <Avatar size="md" src="https://picsum.photos/50" />
          <Flex flexDir="column" ml={4} display={navSize === "small" ? "none" : "flex"}>
            <Text fontSize={15} fontWeight={600} onClick={() => console.log(active)}>
              {name}
            </Text>
            <Text fontSize="sm" color="gray" onClick={logout} cursor="pointer">
              <i>Logout</i>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
