import { Box, Text, Flex, Divider } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function TopHeading({ title, description, children }) {
  const bgColor = useColorModeValue("white", "#1A202C");
  // const fontColor = useColorModeValue("black", "white");

  return (
    <Box position="sticky" top={0} backgroundColor={bgColor} py={2} px={4} zIndex={3}>
      <Flex flexDir="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexDir="column">
            <Text fontSize="32px" fontWeight={600}>
              {title}
            </Text>
            <Text fontSize="16px">{description}</Text>
          </Flex>
          <Flex>{children}</Flex>
        </Flex>
        <Divider my="10px" />
      </Flex>
    </Box>
  );
}
