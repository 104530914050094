import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Tr,
  Td,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";

import { IoMdOpen } from "react-icons/io";

import { getCallDetail } from "../APIs";

export default function CallDetailsModal({ inboundCallId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [callDetails, setCallDetails] = useState();
  const [loading, setLoading] = useState(false);

  const bgColor = useColorModeValue("gray.200", "gray.500");

  const handleCallDetails = async () => {
    onOpen();
    try {
      setLoading(true);
      const details = await getCallDetail(inboundCallId);
      setCallDetails(details);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <IconButton
        colorScheme="teal"
        onClick={handleCallDetails}
        aria-label="Open Call Details"
        icon={<IoMdOpen />}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          <ModalHeader>Call Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Flex justifyContent="center" alignItems="center">
                <Spinner size="xl" color="green.400" />
              </Flex>
            ) : null}
            <Flex flexDir="column" justify="flex-start">
              {callDetails
                ? callDetails.map((detailsList, i) => (
                    <Box my="10px">
                      <Text fontWeight={700} backgroundColor={bgColor} p={1}>
                        Event {i + 1}: {detailsList.name}
                      </Text>
                      <Table size="sm">
                        <Tbody>
                          {detailsList
                            ? Object.keys(detailsList).map((detail, i) => (
                                <Tr key={detail} w="100%">
                                  <Td w="30%">{detail}</Td>
                                  <Td w="70%">
                                    <Text flexWrap="wrap">
                                      {detail === "recordingUrl" ? (
                                        <Link
                                          color="teal.300"
                                          href={detailsList[detail]}
                                          isExternal
                                        >
                                          Recording Link
                                        </Link>
                                      ) : (
                                        detailsList[detail]
                                      )}
                                    </Text>
                                  </Td>
                                </Tr>
                              ))
                            : null}
                        </Tbody>
                      </Table>
                      <Divider mt="10px" />
                    </Box>
                  ))
                : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
