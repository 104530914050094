import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Spinner,
  AlertDescription,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";

import { FiPlusSquare } from "react-icons/fi";

export default function ConfirmationModal({
  buttonText,
  buttonSize,
  colorScheme,
  header,
  messages,
  alert,
  event,
  loading,
  success,
  failure,
  isDisabled,
  isIconButton,
  icon,
  tooltip,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submit = () => {
    console.log(loading);
    event();
    console.log("submit!");
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  return (
    <>
      {isIconButton ? (
        <Tooltip hasArrow placement="top" label={tooltip}>
          <IconButton
            colorScheme={colorScheme}
            onClick={onOpen}
            size={buttonSize}
            icon={icon}
            isDisabled={isDisabled}
          />
        </Tooltip>
      ) : (
        <Button
          colorScheme={colorScheme}
          onClick={onOpen}
          size={buttonSize}
          isDisabled={isDisabled}
        >
          {buttonText}
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justify="center">
              {messages ? messages.map((message, index) => <Text>{message}</Text>) : null}
            </Flex>
          </ModalBody>
          {alert ? (
            <Alert status="error" my="10px">
              <AlertIcon />
              <AlertTitle mr={2}>{alert}</AlertTitle>
            </Alert>
          ) : null}
          <ModalFooter>
            {success ? (
              <Alert status="success" mr="20px" w="50%">
                <AlertIcon />
                <AlertDescription>Success!</AlertDescription>
              </Alert>
            ) : null}
            {failure ? (
              <Alert status="error" mr="20px" w="50%">
                <AlertIcon />
                <AlertDescription>Error!</AlertDescription>
              </Alert>
            ) : null}
            <Button
              isLoading={loading}
              w="100px"
              colorScheme="teal"
              mr={3}
              onClick={submit}
              isDisabled={loading || success}
            >
              {buttonText}
            </Button>
            <Button isDisabled={loading || success} colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
