import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  IconButton,
  Spinner,
  Divider,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  AlertDescription,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Tooltip,
  useForceUpdate,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import TopHeading from "../TopHeading";
import { v4 as uuidv4 } from "uuid";

import "./QaAssignments.css";

import {
  allCallsToday,
  addNewQaAssignment,
  addManyQaAssignments,
  editQaAssignment,
  getAllAssignments,
  getAllQaKeys,
  deleteQaAssignment,
} from "../qa/QaApis";

import Select from "react-select";

import { FiRefreshCcw } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { FiSave } from "react-icons/fi";
import { BsTrash, BsFillFilterSquareFill } from "react-icons/bs";
import { AiFillFilter } from "react-icons/ai";

import moment from "moment";
import DispositionKey from "../qa/DispositionKey";
import QaKeyModal from "./QaKeyModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import TableColumnMenu from "./TableColumnMenu";

const NewEntryTr = ({ qaKeyData, newAssignments, setNewAssignments }) => {
  const [assignedTo, setAssignedTo] = useState("");
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [priorityOptions, setPriorityOptions] = useState([
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "Live", label: "🟢 - Live" },
    { value: "Paused", label: "🔴 - Paused" },
    { value: "Pending", label: "🟡 - Pending" },
    { value: "Benched", label: "⚪ - Benched" },
  ]);
  const [vertical, setVertical] = useState("");
  const [verticalColor, setVerticalColor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [buyer, setBuyer] = useState("");
  const [notes, setNotes] = useState("");
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A202C",
    }),
  };
  const handleAssignedToChange = (e) => {
    let assignedTo = e.value;
    setAssignedTo(assignedTo);
  };
  const handlePriorityChange = (e) => {
    let priority = e.value;
    setPriority(priority);
  };
  const handleStatusChange = (e) => {
    let status = e.value;
    setStatus(status);
  };
  const handleVerticalChange = (e) => {
    let vertical = e.value;
    const verticalData = qaKeyData.vertical;
    const color = verticalData.find((x) => x.name === vertical).color;

    setVertical(vertical);
    setVerticalColor(color);
  };
  const handlePublisherChange = (e) => {
    let pub = e.value;
    setPublisher(pub);
  };
  const handleBuyerChange = (e) => {
    let buyer = e.value;
    setBuyer(buyer);
  };

  const resetValues = () => {
    setStatus(null);
    setAssignedTo(null);
    setPriority(null);
    setVertical(null);
    setVerticalColor(null);
    setPublisher(null);
    setBuyer(null);
    setNotes("");
  };

  const addAssignment = () => {
    let newAssignmentArray = [...newAssignments];
    const assignment = {
      updated: moment().format("l LT"),
      assignedTo,
      status,
      priority,
      vertical,
      color: verticalColor,
      publisher,
      buyer,
      notes,
      uuid: uuidv4(),
    };

    // console.log("addAssignment assignment:", assignment);
    newAssignmentArray.push(assignment);
    setNewAssignments(newAssignmentArray);
    resetValues();
  };

  return (
    <Tr>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select name"
          onChange={handleAssignedToChange}
          options={qaKeyData.assignedTo}
          styles={selectStyles}
          value={
            qaKeyData.assignedTo[
              qaKeyData.assignedTo.findIndex((entry) => {
                return entry.name === assignedTo;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select priority"
          onChange={handlePriorityChange}
          options={priorityOptions}
          styles={selectStyles}
          value={
            priorityOptions[
              priorityOptions.findIndex((entry) => {
                return entry.value === priority;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select status"
          onChange={handleStatusChange}
          options={statusOptions}
          styles={selectStyles}
          value={
            statusOptions[
              statusOptions.findIndex((entry) => {
                return entry.value === status;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select vertical"
          onChange={handleVerticalChange}
          options={qaKeyData.vertical}
          styles={selectStyles}
          value={
            qaKeyData.vertical[
              qaKeyData.vertical.findIndex((entry) => {
                return entry.name === vertical;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select publisher"
          onChange={handlePublisherChange}
          options={qaKeyData.publisher}
          styles={selectStyles}
          value={
            qaKeyData.publisher[
              qaKeyData.publisher.findIndex((entry) => {
                return entry.name === publisher;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Select
          key={uuidv4()}
          placeholder="Select buyer"
          onChange={handleBuyerChange}
          options={qaKeyData.buyer}
          styles={selectStyles}
          value={
            qaKeyData.buyer[
              qaKeyData.buyer.findIndex((entry) => {
                return entry.name === buyer;
              })
            ]
          }
        />
      </Td>
      <Td>
        <Textarea
          rows={3}
          style={{ whiteSpace: "pre-wrap" }}
          placeholder="Enter notes here."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Td>
      <Td>
        <IconButton colorScheme="teal" icon={<GoPlus />} onClick={addAssignment} mx="10px" />
      </Td>
    </Tr>
  );
};

const NewEntry = ({ getAssignments, qaKeyData, admin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newAssignments, setNewAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    // control: (provided, { isSelected }) => ({
    //   ...provided,
    //   backgroundColor: bgColor,
    //   color: isSelected ? fontColor : "red",
    // }),
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const removeFromAssignmentQueue = (index) => {
    let newAssignmentsArray = [...newAssignments];

    newAssignmentsArray.splice(index, 1);

    setNewAssignments(newAssignmentsArray);
  };

  const handleAssignmentCreation = async () => {
    console.log("this");
    setLoading(true);
    setSuccess(false);
    setFailure(false);
    // const setVerticalColor = () => {
    //   if(qaKeyData)
    // }

    try {
      const result = await addManyQaAssignments(newAssignments);
      // console.log(result);
      setSuccess(true);
      setFailure(false);
      setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        setFailure(false);
        setNewAssignments([]);
        onClose();
      }, 2000);
      getAssignments();
    } catch (error) {
      alert("error:", error);
      setLoading(false);
      setSuccess(false);
      setFailure(true);
    }
  };

  const logMe = () => {
    console.log("qaKeyData:", qaKeyData);
  };

  return (
    <>
      <IconButton
        colorScheme="teal"
        icon={<GoPlus />}
        onClick={onOpen}
        mx="10px"
        isDisabled={!admin}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="7xl">
        <ModalOverlay />
        {qaKeyData ? (
          <ModalContent>
            <ModalHeader onClick={logMe}>New Assignment</ModalHeader>
            {/* <Button onClick={logMe}>Log Me</Button> */}

            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column">
                {newAssignments && newAssignments.length > 0 ? (
                  <Box mb={5} borderWidth={1} borderRadius={5} p={2}>
                    <Text textAlign="center" mb={3} letterSpacing={3}>
                      ASSIGNMENTS TO ADD
                    </Text>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th textAlign="center">Assigned To</Th>
                          <Th textAlign="center">Priority</Th>
                          <Th textAlign="center">Status</Th>
                          <Th textAlign="center">Vertical</Th>
                          <Th textAlign="center">Publisher</Th>
                          <Th textAlign="center">Buyer</Th>
                          <Th textAlign="center">Notes</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {newAssignments.map((tableRow, index) => (
                          <Tr>
                            <Td textAlign="center">{tableRow.assignedTo}</Td>
                            <Td textAlign="center">{tableRow.priority}</Td>
                            <Td textAlign="center">{tableRow.status}</Td>
                            <Td textAlign="center">{tableRow.vertical}</Td>
                            <Td textAlign="center">{tableRow.publisher}</Td>
                            <Td textAlign="center">{tableRow.buyer}</Td>
                            <Td textAlign="center">{tableRow.notes}</Td>
                            <Td w="20px">
                              <IconButton
                                size="sm"
                                icon={<BsTrash />}
                                onClick={() => removeFromAssignmentQueue(index)}
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                ) : null}
                <Box borderWidth={1} borderRadius={5} p={2}>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Assigned To</Th>
                        <Th textAlign="center">Priority</Th>
                        <Th textAlign="center">Status</Th>
                        <Th textAlign="center">Vertical</Th>
                        <Th textAlign="center">Publisher</Th>
                        <Th textAlign="center">Buyer</Th>
                        <Th textAlign="center">Notes</Th>
                        <Th textAlign="center"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <NewEntryTr
                        qaKeyData={qaKeyData}
                        setNewAssignments={setNewAssignments}
                        newAssignments={newAssignments}
                      />
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {success ? (
                <Alert status="success" mr="20px">
                  <AlertIcon />
                  <AlertDescription>Success!</AlertDescription>
                </Alert>
              ) : null}
              {failure ? (
                <Alert status="error" mr="20px">
                  <AlertIcon />
                  <AlertDescription>Error!</AlertDescription>
                </Alert>
              ) : null}
              <Button
                colorScheme="teal"
                onClick={handleAssignmentCreation}
                w="100px"
                isDisabled={loading}
              >
                {loading ? (
                  <Spinner color="teal.300" speed="0.65s" emptyColor="gray.200" />
                ) : (
                  "Submit"
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : null}
      </Modal>
    </>
  );
};

const TableRow = ({ assignment, qaKeyData, getAssignments, admin, updateAssignmentsFrontEnd }) => {
  const [updated, setUpdated] = useState(assignment.updated);
  const [assignedTo, setAssignedTo] = useState(assignment.assignedTo);
  const [priority, setPriority] = useState(assignment.priority);
  const [priorityOptions, setPriorityOptions] = useState([
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ]);
  const [status, setStatus] = useState(assignment.status);
  const [vertical, setVertical] = useState(assignment.vertical);
  const [verticalColor, setVerticalColor] = useState(assignment.color);
  const [publisher, setPublisher] = useState(assignment.publisher);
  const [buyer, setBuyer] = useState(assignment.buyer);
  const [notes, setNotes] = useState(assignment.notes);
  const [statusOptions, setStatusOptions] = useState([
    { value: "Live", label: "🟢" },
    { value: "Paused", label: "🔴" },
    { value: "Pending", label: "🟡" },
    { value: "Benched", label: "⚪" },
  ]);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A202C",
    }),
  };

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A202C",
    }),
  };

  const handleAssignedToChange = (e) => {
    let assignedTo = e.value;
    setAssignedTo(assignedTo);
  };

  const handlePriorityChange = (e) => {
    let priority = e.value;
    setPriority(priority);
  };

  const handleStatusChange = (e) => {
    let status = e.value;
    setStatus(status);
  };

  const handleVerticalChange = (e) => {
    let vertical = e.value;
    const verticalData = qaKeyData.vertical;
    const color = verticalData.find((x) => x.name === vertical).color;

    setVertical(vertical);
    setVerticalColor(color);
  };

  const handlePublisherChange = (e) => {
    let publisher = e.value;
    setPublisher(publisher);
  };

  const handleAdvertiserChange = (e) => {
    let buyer = e.value;
    setBuyer(buyer);
  };

  const handleNoteChange = (e) => {
    let note = e.target.value;
    setNotes(note);
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const updateQaAssignment = async (assignmentData) => {
    console.log("assignment:", assignment);
    setUpdateLoading(true);
    const data = {
      updated: moment().format("l LT"),
      assignedTo,
      priority,
      status,
      vertical,
      color: verticalColor,
      publisher,
      buyer,
      notes,
      uuid: assignment.uuid,
    };
    updateAssignmentsFrontEnd(assignmentData, data);

    console.log("updateQaAssignment:", data);

    try {
      const response = await editQaAssignment(data);
      // getAssignments();
      setUpdateLoading(false);
    } catch (error) {
      alert(error.message);
      setUpdateLoading(false);
    }
  };

  const removeQaAssignment = async () => {
    setDeleteLoading(true);
    setSuccess(false);
    setFailure(false);
    const data = {
      uuid: assignment.uuid,
    };

    try {
      const response = await deleteQaAssignment(data);
      getAssignments();
      setSuccess(true);
      setTimeout(() => {
        setDeleteLoading(false);
        setSuccess(false);
        setFailure(false);
      }, 2000);
    } catch (error) {
      alert(error.message);
      setDeleteLoading(false);
    }
  };

  const logMe = () => {
    // console.log("assignment:", assignment);
    const data = {
      updated: moment().format("l LT"),
      assignedTo,
      priority,
      status,
      vertical,
      color: verticalColor,
      publisher,
      buyer,
      notes,
      uuid: assignment.uuid,
    };

    console.log("updateQaAssignment:", data);
    // console.log("ccCap:", ccCap);
    // console.log("hourlyCap:", hourlyCap);
  };

  return (
    <>
      {assignment ? (
        <Tr _hover={{ backgroundColor: hoverColor }} borderWidth={2}>
          <Td w="160px" textAlign="center" onClick={logMe}>
            {updated}
          </Td>
          <Td textAlign="center" w="220px">
            {/* <Text onClick={() => console.log(qaKeyData.assignedTo)}>Hello</Text> */}
            {qaKeyData ? (
              <Select
                options={qaKeyData.assignedTo}
                defaultValue={
                  qaKeyData.assignedTo[
                    qaKeyData.assignedTo.findIndex((entry) => {
                      return entry.name === assignment.assignedTo;
                    })
                  ]
                }
                placeholder="Select name"
                onChange={handleAssignedToChange}
                styles={selectStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="center" w="150px">
            {qaKeyData ? (
              <Select
                defaultValue={
                  priorityOptions[
                    priorityOptions.findIndex((entry) => {
                      return entry.value === priority;
                    })
                  ]
                }
                placeholder="Select Status"
                onChange={handlePriorityChange}
                options={priorityOptions}
                styles={selectStatusStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="center" w="120px">
            {qaKeyData ? (
              <Select
                defaultValue={
                  statusOptions[
                    statusOptions.findIndex((entry) => {
                      return entry.value === status;
                    })
                  ]
                }
                placeholder="Select Status"
                onChange={handleStatusChange}
                options={statusOptions}
                styles={selectStatusStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="center" backgroundColor={verticalColor} w="200px">
            {qaKeyData ? (
              <Select
                defaultValue={
                  qaKeyData.vertical[
                    qaKeyData.vertical.findIndex((entry) => {
                      return entry.name === assignment.vertical;
                    })
                  ]
                }
                placeholder="Select Vertical"
                onChange={handleVerticalChange}
                options={qaKeyData.vertical}
                styles={selectStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="center" backgroundColor={verticalColor}>
            {qaKeyData ? (
              <Select
                defaultValue={
                  qaKeyData.publisher[
                    qaKeyData.publisher.findIndex((entry) => {
                      return entry.name === assignment.publisher;
                    })
                  ]
                }
                placeholder="Select Publisher"
                onChange={handlePublisherChange}
                options={qaKeyData.publisher}
                styles={selectStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="center" backgroundColor={verticalColor}>
            {qaKeyData ? (
              <Select
                defaultValue={
                  qaKeyData.buyer[
                    qaKeyData.buyer.findIndex((entry) => {
                      return entry.name === assignment.buyer;
                    })
                  ]
                }
                placeholder="Select Buyer"
                onChange={handleAdvertiserChange}
                options={qaKeyData.buyer}
                styles={selectStyles}
              />
            ) : null}
          </Td>
          <Td textAlign="left">
            <Textarea
              rows={3}
              style={{ whiteSpace: "pre-wrap" }}
              placeholder="Enter notes here."
              value={notes}
              onChange={handleNoteChange}
              backgroundColor="#1A202C"
              color="white"
            />
          </Td>
          <Td textAlign="center" w="110px">
            <Tooltip hasArrow placement="top" label="Update">
              <IconButton
                colorScheme="teal"
                onClick={() => updateQaAssignment(assignment)}
                icon={updateLoading ? <Spinner /> : <FiSave />}
                mr={2}
                isDisabled={!admin || updateLoading}
                size="sm"
              />
            </Tooltip>
            <Tooltip hasArrow placement="top" label="Delete">
              <ConfirmationModal
                buttonText="Delete"
                header="Delete Confirmation"
                messages={["Would you like to delete this QA Assignment?"]}
                alert="This cannot be undone"
                event={removeQaAssignment}
                isDisabled={!admin || deleteLoading}
                isIconButton
                icon={<BsTrash />}
                loading={deleteLoading}
                success={success}
                failure={failure}
                buttonSize="sm"
              />
            </Tooltip>
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

export default function QaAssignments({ username }) {
  const [admin, setAdmin] = useState(username === "Madison Fukushima" ? true : false);
  const [data, setData] = useState();
  const [existingQaKeys, setExistingQaKeys] = useState();
  const [filteredData, setFilteredData] = useState();
  const [qaKeyData, setQaKeyData] = useState();
  const [loading, setLoading] = useState(false);
  const [getQaKeysLoading, setGetQaKeysLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment().format("LT"));
  const [sortByColumnName, setSortByColumnName] = useState("created");

  const [filters, setFilters] = useState({
    assignedTo: [],
    buyer: [],
    publisher: [],
    vertical: [],
    status: [],
    priority: [],
  });
  const [asc, setAsc] = useState(true);
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
      width: "100%",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : "#1A202C",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      color: fontColor,
      backgroundColor: "#1A202C",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      color: fontColor,
    }),
  };

  const sortByCallDateTime = (a, b) => {
    if (a.call_date_time < b.call_date_time) {
      return -1;
    }
    if (a.call_date_time > b.call_date_time) {
      return 1;
    }
    return 0;
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const getAssignments = async () => {
    // if (filteredData) setFilteredData(null);
    if (!data) setLoading(true);
    // setData(null);

    try {
      const result = await getAllAssignments();
      if (result && result.result) {
        setData(result.result?.sort(sortByCallDateTime));
        console.log(result.result);
      } else {
        alert("Unable to retrieve data. Try again.");
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("error:", error);
      setLoading(false);
    }
  };

  const organizeExistingQaKeys = () => {
    let assignedTo = [];
    let buyer = [];
    let publisher = [];
    let vertical = [];
    let status = [];
    let priority = [];

    // console.log("organizeExistingQaKeys qa:", data);

    if (data) {
      data.forEach((assignment) => {
        if (!assignedTo.some((i) => (i.name ? i.name.includes(assignment.assignedTo) : false))) {
          const obj = {
            name: assignment.assignedTo,
            type: "name",
            value: assignment.assignedTo,
            label: assignment.assignedTo,
          };
          assignedTo.push(obj);
        }
        if (!buyer.some((i) => (i.name ? i.name.includes(assignment.buyer) : false))) {
          const obj = {
            name: assignment.buyer,
            type: "buyer",
            value: assignment.buyer,
            label: assignment.buyer,
          };
          buyer.push(obj);
        }
        if (!publisher.some((i) => (i.name ? i.name.includes(assignment.publisher) : false))) {
          const obj = {
            name: assignment.publisher,
            type: "publisher",
            value: assignment.publisher,
            label: assignment.publisher,
          };
          publisher.push(obj);
        }
        if (!vertical.some((i) => (i.name ? i.name.includes(assignment.vertical) : false))) {
          const obj = {
            name: assignment.vertical,
            type: "vertical",
            value: assignment.vertical,
            label: assignment.vertical,
          };
          vertical.push(obj);
        }
        if (!status.some((i) => (i.name ? i.name.includes(assignment.status) : false))) {
          const obj = {
            name: assignment.status,
            type: "status",
            value: assignment.status,
            label: assignment.status,
          };
          status.push(obj);
        }
        if (!priority.some((i) => (i.name ? i.name.includes(assignment.priority) : false))) {
          const obj = {
            name: assignment.priority,
            type: "priority",
            value: assignment.priority,
            label: assignment.priority,
          };
          priority.push(obj);
        }
      });
    }

    const currentKeys = {
      assignedTo: assignedTo.sort(sortByName),
      buyer: buyer.sort(sortByName),
      publisher: publisher.sort(sortByName),
      vertical: vertical.sort(sortByName),
      status: status.sort(sortByName),
      priority: priority.sort(sortByName),
    };

    console.log("currentKeys:", currentKeys);

    setExistingQaKeys(currentKeys);
  };

  const handleFilterChange = (e, filterType) => {
    // console.log("e", e);
    const filtersCopy = filters;
    if (filterType === "assignedTo") {
      filtersCopy.assignedTo = e;
    }
    if (filterType === "priority") {
      filtersCopy.priority = e;
    }
    if (filterType === "status") {
      filtersCopy.status = e;
    }
    if (filterType === "vertical") {
      filtersCopy.vertical = e;
    }
    if (filterType === "publisher") {
      filtersCopy.publisher = e;
    }
    if (filterType === "buyer") {
      filtersCopy.buyer = e;
    }
    // console.log("filtersCopy:", filtersCopy);
    setFilters(filtersCopy);
  };

  const getQaKeys = async () => {
    // const dataArray = [];
    if (data) {
      setGetQaKeysLoading(true);
      try {
        const result = await getAllQaKeys();
        // console.log(result);
        if (result.result[0]) {
          const dataResult = result.result[0];
          // dataResult.forEach((assignment) => {});
          setQaKeyData(result.result[0]);
          setGetQaKeysLoading(false);
        } else {
          alert("Couldn't retrieve data. Try again.");
        }
      } catch (error) {
        alert("error:", error);
        setGetQaKeysLoading(false);
      }
    }
  };

  const updateAssignmentsFrontEnd = (assignmentData, newAssignmentData) => {
    const newData = [...data];
    const assignmentIndex = newData.findIndex(
      (assignment) => assignment.uuid === assignmentData.uuid
    );

    // console.log("assignmentData:", assignmentData);
    // console.log("assignmentIndex:", assignmentIndex);

    newData.splice(assignmentIndex, 1, newAssignmentData);

    setData(newData);
  };

  const sortColumn = (columnName) => {
    let dataCopy = filteredData ? [...filteredData] : [...data];

    // console.log("newData 1 ::", dataCopy);

    if (columnName === "updated") {
      if (sortByColumnName === "updated") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByUpdatedDate(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByUpdatedDate(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByUpdatedDate(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByUpdatedDate(true));
        }
        setSortByColumnName("updated");
      }
    }

    if (columnName === "assigned") {
      if (sortByColumnName === "assigned") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByAssignedTo(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByAssignedTo(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByAssignedTo(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByAssignedTo(true));
        }
        setSortByColumnName("assigned");
      }
    }

    if (columnName === "priority") {
      if (sortByColumnName === "priority") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPriority(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPriority(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPriority(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPriority(true));
        }
        setSortByColumnName("priority");
      }
    }

    if (columnName === "status") {
      if (sortByColumnName === "status") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByStatus(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByStatus(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByStatus(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByStatus(true));
        }
        setSortByColumnName("status");
      }
    }

    if (columnName === "vertical") {
      if (sortByColumnName === "vertical") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByVertical(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByVertical(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByVertical(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByVertical(true));
        }
        setSortByColumnName("vertical");
      }
    }

    if (columnName === "publisher") {
      if (sortByColumnName === "publisher") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPublisher(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPublisher(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPublisher(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPublisher(true));
        }
        setSortByColumnName("publisher");
      }
    }

    if (columnName === "buyer") {
      if (sortByColumnName === "buyer") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByBuyer(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByBuyer(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByBuyer(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByBuyer(true));
        }
        setSortByColumnName("buyer");
      }
    }

    if (filteredData) {
      // console.log("newData 2 ::", dataCopy);
      setFilteredData(dataCopy);
    } else {
      // console.log("newData 2 ::", dataCopy);
      setData(dataCopy);
    }
  };

  const sortByUpdatedDate = (a, b) => {
    return (a, b) => {
      if (a.updated === b.updated) {
        return 0;
      }
      if (a.updated === null) {
        return 1;
      }
      if (b.updated === null) {
        return -1;
      }
      if (asc) {
        return a.updated > b.updated ? 1 : -1;
      } else {
        return a.updated > b.updated ? -1 : 1;
      }
    };
  };

  const sortByAssignedTo = (a, b) => {
    return (a, b) => {
      if (a.assignedTo === b.assignedTo) {
        return 0;
      }
      if (a.assignedTo === null) {
        return 1;
      }
      if (b.assignedTo === null) {
        return -1;
      }
      if (asc) {
        return a.assignedTo > b.assignedTo ? 1 : -1;
      } else {
        return a.assignedTo > b.assignedTo ? -1 : 1;
      }
    };
  };

  const sortByPriority = (a, b) => {
    return (a, b) => {
      if (a.priority === b.priority) {
        return 0;
      }
      if (a.priority === null) {
        return 1;
      }
      if (b.priority === null) {
        return -1;
      }
      if (asc) {
        return a.priority > b.priority ? 1 : -1;
      } else {
        return a.priority > b.priority ? -1 : 1;
      }
    };
  };

  const sortByStatus = (a, b) => {
    return (a, b) => {
      if (a.status === b.status) {
        return 0;
      }
      if (a.status === null) {
        return 1;
      }
      if (b.status === null) {
        return -1;
      }
      if (asc) {
        return a.status > b.status ? 1 : -1;
      } else {
        return a.status > b.status ? -1 : 1;
      }
    };
  };

  const sortByVertical = (a, b) => {
    return (a, b) => {
      if (a.vertical === b.vertical) {
        return 0;
      }
      if (a.vertical === null) {
        return 1;
      }
      if (b.vertical === null) {
        return -1;
      }
      if (asc) {
        return a.vertical > b.vertical ? 1 : -1;
      } else {
        return a.vertical > b.vertical ? -1 : 1;
      }
    };
  };

  const sortByPublisher = (a, b) => {
    return (a, b) => {
      if (a.publisher === b.publisher) {
        return 0;
      }
      if (a.publisher === null) {
        return 1;
      }
      if (b.publisher === null) {
        return -1;
      }
      if (asc) {
        return a.publisher > b.publisher ? 1 : -1;
      } else {
        return a.publisher > b.publisher ? -1 : 1;
      }
    };
  };

  const sortByBuyer = (a, b) => {
    return (a, b) => {
      if (a.buyer === b.buyer) {
        return 0;
      }
      if (a.buyer === null) {
        return 1;
      }
      if (b.buyer === null) {
        return -1;
      }
      if (asc) {
        return a.buyer > b.buyer ? 1 : -1;
      } else {
        return a.buyer > b.buyer ? -1 : 1;
      }
    };
  };

  const updateFilteredData = (filterBy, filterValue) => {
    if (filterBy === "all") {
      setFilteredData(null);
      return;
    }
    // console.log("filterBy", filterBy);
    // console.log("filterValue", filterValue);
    let newData = [];
    if (data) {
      setFilteredData(null);

      data.forEach((assignment) => {
        if (filterBy === "name") {
          if (assignment.assignedTo === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "priority") {
          if (assignment.priority === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "status") {
          if (assignment.status === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "vertical") {
          if (assignment.vertical === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "publisher") {
          if (assignment.publisher === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "buyer") {
          if (assignment.buyer === filterValue) {
            newData.push(assignment);
          }
        }
      });
      // console.log("newData:", newData);
      setFilteredData(newData);
    }
  };

  const handleFilteringDataClick = () => {
    let setFilters = {
      assignedTo: [],
      buyer: [],
      publisher: [],
      vertical: [],
      status: [],
      priority: [],
    };
    Object.entries(filters).forEach((filter) => {
      const [key, value] = filter;
      // console.log(value);
      value.forEach((filterType) => {
        // console.log("filterType:", filterType);
        if (filterType.type === "name") {
          // console.log("VERTICALS!");
          setFilters.assignedTo.push(filterType.value);
        }
        if (filterType.type === "buyer") {
          setFilters.buyer.push(filterType.value);
        }
        if (filterType.type === "publisher") {
          setFilters.publisher.push(filterType.value);
        }
        if (filterType.type === "vertical") {
          // console.log("VERTICALS!");
          setFilters.vertical.push(filterType.value);
        }
        if (filterType.type === "status") {
          setFilters.status.push(filterType.value);
        }
        if (filterType.type === "priority") {
          // console.log("VERTICALS!");
          setFilters.priority.push(filterType.value);
        }
      });
    });

    console.log("setFilters:", setFilters);
    // console.log("filters:", filters);

    let newlyFilteredData;

    newlyFilteredData = data.filter((assignment, i) => {
      // console.log(`$assignment-${i}`, assignment);

      const adjustFilters = (filterArray) => {
        if (filterArray.length > 1) {
          return filterArray;
        } else {
          return filterArray;
        }
      };

      return (
        assignment.assignedTo?.includes(adjustFilters(setFilters?.assignedTo)) &&
        assignment.priority?.includes(adjustFilters(setFilters?.priority)) &&
        assignment.status?.includes(adjustFilters(setFilters?.status)) &&
        assignment.vertical?.includes(adjustFilters(setFilters?.vertical)) &&
        assignment.publisher?.includes(adjustFilters(setFilters?.publisher)) &&
        assignment.buyer?.includes(adjustFilters(setFilters?.buyer))
      );
      // return (
      //   setFilters.assignedTo?.includes(assignment?.assignedTo) &&
      //   setFilters.priority?.includes(assignment?.priority) &&
      //   setFilters.status?.includes(assignment?.status) &&
      //   setFilters.vertical?.includes(assignment?.vertical) &&
      //   setFilters.publisher?.includes(assignment?.publisher) &&
      //   setFilters.buyer?.includes(assignment?.buyer)
      // );
    });

    console.log("newlyFilteredData:", newlyFilteredData);
    setFilteredData(newlyFilteredData);
    // setFilteredData(newlyFilteredData?.sort(sortByTimestamp(true)));
  };

  useEffect(() => {
    getAssignments();
  }, []);

  useEffect(() => {
    getQaKeys();
  }, [data]);

  useEffect(() => {
    organizeExistingQaKeys();
  }, [data]);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      overflowY="auto"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="QA Assignments"
        description="Quality Assurance assignments for campaigns."
        children={
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            {/* <Button onClick={() => console.log(existingQaKeys)}>LOG ME</Button> */}
            {lastUpdated ? (
              <Text fontStyle="italic" mr="10px">
                Last Updated: {lastUpdated}
              </Text>
            ) : null}

            <IconButton
              onClick={getAssignments}
              aria-label="Pull Mortgage Data"
              icon={<FiRefreshCcw />}
            />
            <NewEntry getAssignments={getAssignments} qaKeyData={qaKeyData} admin={admin} />
            {qaKeyData ? (
              <QaKeyModal qaKeyData={qaKeyData} getQaKeys={getQaKeys} admin={admin} />
            ) : (
              <IconButton icon={<Spinner />} />
            )}
          </Flex>
        }
      />

      <Flex flexDir="column" h="500px" mb="15px">
        {loading ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}

        {data || filteredData ? (
          <Flex flexDir="column" w="100%">
            <Flex
              w="100%"
              borderWidth={1}
              padding={4}
              alignItems="center"
              position="sticky"
              top={109}
              backgroundColor={bgColor}
              zIndex={2}
            >
              {/* <Text onClick={LOGME}>HELLO</Text> */}
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Assigned To"
                  options={existingQaKeys.assignedTo}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "assignedTo")}
                />
              </Box>
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Priority"
                  options={existingQaKeys.priority}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "priority")}
                />
              </Box>
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Status"
                  options={existingQaKeys.status}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "status")}
                />
              </Box>
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Vertical"
                  options={existingQaKeys.vertical}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "vertical")}
                />
              </Box>
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Publisher"
                  options={existingQaKeys.publisher}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "publisher")}
                />
              </Box>
              <Box w="16.66%" mr="10px">
                <Select
                  placeholder="Buyer"
                  options={existingQaKeys.buyer}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "buyer")}
                />
              </Box>
              <Flex w="8%" justifyContent="space-evenly">
                <Tooltip label="Filter Data" placement="top" hasArrow>
                  <IconButton
                    colorScheme="teal"
                    icon={<AiFillFilter />}
                    onClick={handleFilteringDataClick}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            <Table size="sm" w="100%">
              <Thead position="sticky" top={180} zIndex={1}>
                <Tr backgroundColor={bgColor}>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("updated")}
                  >
                    UPDATED {sortByColumnName === "updated" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "updated" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("assigned")}
                  >
                    Assigned To {sortByColumnName === "assigned" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "assigned" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("priority")}
                  >
                    Priority {sortByColumnName === "priority" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "priority" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("status")}
                  >
                    Status {sortByColumnName === "status" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "status" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("vertical")}
                  >
                    Vertical {sortByColumnName === "vertical" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "vertical" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("publisher")}
                  >
                    Publisher {sortByColumnName === "publisher" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "publisher" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("buyer")}
                  >
                    Buyer {sortByColumnName === "buyer" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "buyer" && !asc ? "▲" : null}
                  </Th>
                  <Th textAlign="center">Notes</Th>
                  <Th textAlign="center"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData
                  ? filteredData.map((assignment, i) => (
                      <TableRow
                        key={assignment.uuid}
                        assignment={assignment}
                        qaKeyData={qaKeyData}
                        getAssignments={getAssignments}
                        updateAssignmentsFrontEnd={updateAssignmentsFrontEnd}
                        admin={admin}
                      />
                    ))
                  : data.map((assignment, i) => (
                      <TableRow
                        key={assignment.uuid}
                        assignment={assignment}
                        qaKeyData={qaKeyData}
                        getAssignments={getAssignments}
                        updateAssignmentsFrontEnd={updateAssignmentsFrontEnd}
                        admin={admin}
                      />
                    ))}
              </Tbody>
            </Table>
            {/* <Text style={{ whiteSpace: "pre-line" }}>{`${data[0].notes}`}</Text> */}
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
