import React, { useState, useEffect } from "react";
import { Flex, Table, Thead, Th, Tr, Tbody, Td, Button, Text } from "@chakra-ui/react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  LabelList,
  Cell,
  ResponsiveContainer,
  Label,
} from "recharts";

import { colors } from "../../../config/colors";

import { getCallDetail } from "../APIs";

const CallDurationChart = (data) => {
  // const [chartData, setChartData] = useState();

  // const formatData = () => {
  //   let newData = [];
  //   data.forEach((e) => {
  //     const record = {
  //       name: e.campaign_name,
  //       acl: Number(e.average_call_length),
  //     };
  //     newData.push(record);
  //   });
  //   setChartData(newData);
  // };

  // useEffect(() => {
  //   formatData();
  // }, []);

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        width={600}
        height={300}
        layout="vertical"
        data={data}
        margin={{
          top: 5,
          right: 50,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="average_call_length" type="number" />
        <YAxis dataKey="campaign_name" type="category" />

        <Tooltip />
        <Legend />
        {/* {data ? data.map((item, index) => <Bar dataKey={item.average_call_length} fill={colors[index]} />) : null} */}
        <Bar
          dataKey="average_call_length"
          fill={colors[4]}
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="average_call_length" position="center" />
          <LabelList dataKey="buyer_name" position="insideLeft" />
        </Bar>
        {/* <Bar dataKey="affiliate_name" fill={colors[3]} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default function CallDuration({ reportData, setReportData }) {
  const logStuff = () => {
    console.log("reportData:", reportData);
    console.log("colors:", colors);
  };

  const [filter, setFilter] = useState("buyer");

  const sortByTargetName = (a, b) => {
    if (a.target_name < b.target_name) {
      return -1;
    }
    if (a.target_name > b.target_name) {
      return 1;
    }
    return 0;
  };

  const sortByCampaignName = (a, b) => {
    if (a.campaign_name > b.campaign_name) {
      return -1;
    }
    if (a.campaign_name < b.campaign_name) {
      return 1;
    }
    return 0;
  };

  return (
    <Flex flexDir="column" flex>
      {/* <Button onClick={logStuff}>log stuff</Button> */}
      {reportData ? (
        <Flex justify="center" mt="20px" w="100%" h="100%">
          {CallDurationChart(reportData)}
        </Flex>
      ) : null}
      {reportData ? (
        <Flex mb="15px" alignItems="center">
          <Text>Sort By:</Text>
          <Button size="sm" onClick={() => setFilter("buyer")} mx="10px">
            Target
          </Button>
          <Button size="sm" onClick={() => setFilter("affiliate")} mx="10px">
            Campaign
          </Button>
        </Flex>
      ) : null}
      <Table variant="simple">
        <Thead>
          {/* {reportData
              ? Object.keys(reportData[0]).map((keys, i) => (
                <Th textAlign="center" key={`${keys}-${i}`}>
                    {keys}
                  </Th>
                ))
              : null} */}
          {reportData ? (
            <Tr>
              <Th textAlign="center">BUYER</Th>
              <Th textAlign="center">AFFILIATE</Th>
              <Th textAlign="center">TARGET</Th>
              <Th textAlign="center">CAMPAIGN</Th>
              <Th textAlign="center">DID</Th>
              <Th textAlign="center">ACL</Th>
            </Tr>
          ) : null}
        </Thead>
        <Tbody>
          {reportData
            ? reportData
                .sort(filter === "affiliate" ? sortByCampaignName : sortByTargetName)
                .map((item, i) => (
                  <Tr key={`${item}-${i}`}>
                    <Td>{item.buyer_name}</Td>
                    <Td>{item.affiliate_name}</Td>
                    <Td>{item.target_name}</Td>
                    <Td>{item.campaign_name}</Td>
                    <Td onClick={() => getCallDetail(item.inboundCallId)} textAlign="center">
                      {item.did}
                    </Td>
                    <Td textAlign="right" maxW="50px">
                      {item.average_call_length} mins
                    </Td>
                  </Tr>
                ))
            : null}
        </Tbody>
      </Table>
    </Flex>
  );
}
