import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Alert,
  AlertIcon,
  Flex,
  Tooltip,
  useColorModeValue,
  Textarea,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { addNewFlagToCall, unflagCall, updateFlaggedCallStatus } from "./QaApis";

import { TbFlag, TbFlagOff } from "react-icons/tb";

export default function FlagCall({
  inboundCallId,
  createdBy,
  flagged,
  updateCallFromFlaggedFrontEnd,
  callIndex,
  call,
  closeCall,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [flagReason, setFlagReason] = useState();

  const handleFlaggingCall = async () => {
    if (!flagReason) {
      alert("Reason is required.");
      return;
    }

    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
      flag: {
        reason: flagReason,
        createdBy,
      },
    };

    console.log(data);

    try {
      const result = await addNewFlagToCall(data);
      console.log(result);
      setLoading(false);

      const callCopy = data.flag;
      call.tags.flags = {
        reason: data.flag.reason,
        flagged: true,
        createdBy: data.flag.createdBy,
        status: "pending",
      };

      console.log("callCopy:", callCopy);

      // updateCallFromFlaggedFrontEnd(callIndex, callCopy);

      onClose();
      if (closeCall) closeCall();
    } catch (error) {
      alert(error);
    }
  };

  const handleFlaggedCallStatus = async (status) => {
    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
      flag: {
        status,
      },
    };

    try {
      const result = await updateFlaggedCallStatus(data);
      console.log(result);
      setLoading(false);

      const callCopy = call;
      call.tags.flags.status = status;

      console.log("callCopy:", callCopy);
      updateCallFromFlaggedFrontEnd(callIndex, callCopy);
    } catch (error) {
      alert(error);
    }
  };

  const handleUnflaggingCall = async () => {
    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
    };

    console.log(data);

    try {
      const result = await unflagCall(data);
      console.log(result);
      setLoading(false);

      const callCopy = call;
      call.tags = {};

      console.log("callCopy:", callCopy);
      updateCallFromFlaggedFrontEnd(callIndex, callCopy);

      onClose();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Flex>
        <Tooltip label={flagged ? "Unflag Call" : "Flag Call"}>
          <Button colorScheme={flagged ? "yellow" : "red"} onClick={onOpen} mr={3}>
            {flagged ? <TbFlagOff /> : <TbFlag />}
          </Button>
        </Tooltip>
        {flagged && call.tags.flags?.status === "pending" ? (
          <Tooltip label="Resolve Flagged Call">
            <Button colorScheme="teal" onClick={() => handleFlaggedCallStatus("resolved")}>
              RESOLVE
            </Button>
          </Tooltip>
        ) : null}
        {flagged && call.tags.flags?.status === "resolved" ? (
          <Tooltip label="Unresolve Flagged Call">
            <Button colorScheme="teal" onClick={() => handleFlaggedCallStatus("pending")}>
              UNRESOLVE
            </Button>
          </Tooltip>
        ) : null}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          <ModalHeader>{flagged ? "UNFLAG CALL" : "FLAG CALL"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justify="flex-start">
              {flagged ? (
                <Text letterSpacing={4} mb={4}>
                  YOU WILL BE UNFLAGGING THIS CALL
                </Text>
              ) : (
                <Flex flexDir="column">
                  <Text letterSpacing={4} mb={4}>
                    REASON
                  </Text>
                  <Textarea
                    rows={4}
                    style={{ whiteSpace: "pre-wrap" }}
                    placeholder="Enter reason here."
                    value={flagReason}
                    onChange={(e) => setFlagReason(e.target.value)}
                    mb={2}
                  />
                </Flex>
              )}
              <Alert status="warning">
                <Flex flexDir="column">
                  <Flex>
                    <AlertIcon />
                    POTENTIAL REASONS TO FLAG A CALL:
                  </Flex>
                  <UnorderedList>
                    <ListItem>Potential Litigator</ListItem>
                    <ListItem>Incentivized/ Coached Calls</ListItem>
                    <ListItem>
                      Calls that blatantly go against a very specific request like Ring Partner
                      where they specifically asked for no handoffs but they keep getting it
                    </ListItem>
                    <ListItem>
                      Really bad call handling either on the call center or client side
                    </ListItem>
                    <ListItem>Very concerning verbiage that the leads mention</ListItem>
                  </UnorderedList>
                </Flex>
              </Alert>
            </Flex>
          </ModalBody>

          <ModalFooter>
            {flagged ? (
              <Button colorScheme="teal" mr={3} onClick={handleUnflaggingCall} isLoading={loading}>
                CONFIRM
              </Button>
            ) : (
              <Button colorScheme="teal" mr={3} onClick={handleFlaggingCall} isLoading={loading}>
                CONFIRM
              </Button>
            )}
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
