import {
  useDisclosure,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Textarea,
  IconButton,
  Tooltip,
  Tag,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

import "./QaAssignments.css";

import { BsTrash } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";

import { getSingleCallInfo } from "../reports/APIs";

import ConfirmationModal from "../modals/ConfirmationModal";
import {
  setCallCategoryDisposition,
  addNewCallNote,
  deleteCallNote,
  editCallNote,
  deleteCall,
} from "../qa/QaApis";
import ReactPlayer from "../ReactPlayer";
import FlagCall from "./FlagCall";
import MarkCall from "./MarkCall";

const formatDuration = (duration) => {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

const DispoTableRow = ({ dispo, getQaDispositions, category }) => {
  return (
    <Tr _hover={{ fontWeight: 700, backgroundColor: "gray.500" }} backgroundColor={dispo.color}>
      <Td color="white" w="50%">
        {dispo.name}
      </Td>
      <Td color="white" w="50%">
        {dispo.description ? dispo.description : " "}
      </Td>
    </Tr>
  );
};

const DispoTable = ({ data, getQaDispositions }) => {
  return (
    <Table size="sm" w="100%" my="10px">
      <Tbody>
        <Tr backgroundColor={data.color}>
          <Td textAlign="center" fontWeight={700} color="white">
            {data?.category}
          </Td>
          <Td fontWeight={700} color="white">
            {data.description}
          </Td>
        </Tr>
        {data &&
          data.dispositions.map((dispo) => (
            <DispoTableRow
              dispo={dispo}
              getQaDispositions={getQaDispositions}
              category={data?.category}
            />
          ))}
      </Tbody>
    </Table>
  );
};

const QaNote = ({ callId, note, callIndex, noteIndex, updateCallNoteFrontEnd }) => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noteText, setNoteText] = useState(note.note);
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const handleDelete = async () => {
    setLoading(true);
    const data = {
      call_unique_id: callId,
      note_id: note.id,
    };
    try {
      await deleteCallNote(data);
      updateCallNoteFrontEnd(callIndex, note, "delete", noteIndex);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  //! STILL NEED TO FIX THIS
  const handleEditNote = async () => {
    setSaveLoading(true);
    const noteData = {
      timestamp: moment().format("L LT"),
      id: note.id,
      note: noteText,
      created_by: note.username,
    };
    const data = {
      call_unique_id: callId,
      note_id: note.id,
      note: noteText,
    };
    try {
      await editCallNote(data);
      updateCallNoteFrontEnd(callIndex, noteData, "edit", noteIndex);
      setSaveLoading(false);
    } catch (error) {
      alert(error.message);
      setSaveLoading(false);
    }
  };

  return (
    <Tr _hover={{ backgroundColor: hoverColor }}>
      <Td textAlign="center" w="200px">
        {moment(note.timestamp).format("L LT")}
      </Td>
      <Td textAlign="center" w="250px">
        {note.created_by}
      </Td>
      <Td textAlign="center">
        {/* {note.note} */}
        <Textarea
          style={{ whiteSpace: "pre-wrap" }}
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
        />
      </Td>
      <Td textAlign="center" w="80px">
        {/* <Button onClick={handleEditNote}>Save</Button> */}
        <Flex justify="center" alignItems="center">
          <Tooltip hasArrow placement="top" label="Save">
            <IconButton
              size="sm"
              onClick={handleEditNote}
              isLoading={saveLoading}
              icon={<AiOutlineSave />}
              mr={2}
            />
          </Tooltip>

          <ConfirmationModal
            isIconButton
            buttonSize="sm"
            icon={<BsTrash />}
            loading={loading}
            success={success}
            header="Delete Confirmation"
            messages={["Are you sure you want to delete this note?"]}
            alert="This cannot be undone."
            buttonText="Delete"
            event={handleDelete}
            tooltip="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default function QaCallTableRow({
  call,
  qaDispos,
  getQaDispositions,
  index,
  updateCallCatDispoFrontEnd,
  updateCallFromDeleteFrontEnd,
  updateCallFromFlaggedFrontEnd,
  username,
  isDuplicate,
  data,
  filteredData,
}) {
  const [note, setNote] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noteLoading, setNoteLoading] = useState(false);
  const [deleteCallLoding, setDeleteCallLoding] = useState(false);
  const [sortedQaDispos, setSortedQaDispos] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [dispoChoices, setDispoChoices] = useState();
  const [selectedDispo, setSelectedDispo] = useState();
  const [dispositionDefaultValueIndex, setDispositionDefaultValueIndex] = useState();
  const [dispoColor, setDispoColor] = useState(call?.qa?.color ? call?.qa?.color : null);

  const [dispoLoading, setDispoLoading] = useState(false);
  const [dispoUpdateSuccess, setDispoUpdateSuccess] = useState(false);

  const [callRecordingUrl, setCallRecordingUrl] = useState();

  const hoverColor = useColorModeValue("gray.200", "gray.400");
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A202C",
    }),
  };

  // const handleCategoryPick = (e) => {
  //   setSelectedCategory(e.targ)
  // }

  const sortQaDispos = async () => {
    const dispoArray = [];

    await qaDispos.forEach((key) => {
      let newkeyObject = {
        value: key.category,
        label: key.category,
        color: key.color,
        description: key.description,
        dispositions: [],
      };

      // console.log(newkeyObject);

      key.dispositions.forEach((dispo) => {
        const dispoObject = {
          value: dispo.name,
          label: dispo.name,
          color: dispo.color,
          id: dispo.id,
        };
        newkeyObject.dispositions.push(dispoObject);
      });

      dispoArray.push(newkeyObject);
    });
    // console.log("dispoArray:", dispoArray);
    setSortedQaDispos(dispoArray);
  };

  const selectWhichDispo = () => {
    if (sortedQaDispos && sortedQaDispos.length > 0) {
      if (selectedCategory === "Excellent") {
        setDispoChoices(sortedQaDispos[0].dispositions);
      }
      if (selectedCategory === "Satisfactory") {
        setDispoChoices(sortedQaDispos[1].dispositions);
      }
      if (selectedCategory === "Poor") {
        setDispoChoices(sortedQaDispos[2].dispositions);
      }
      if (selectedCategory === "Very Poor") {
        setDispoChoices(sortedQaDispos[3].dispositions);
      }
      if (selectedCategory === "Client Issues") {
        setDispoChoices(sortedQaDispos[4].dispositions);
      }
      if (selectedCategory === "Miscellaneous") {
        setDispoChoices(sortedQaDispos[5].dispositions);
      }
    }
  };

  const handleDispoSelectionChange = (e) => {
    console.log("e:", e);
    setSelectedDispo(e.value);
    setDispoColor(e.color);
  };

  const saveCategoryDisposition = async () => {
    setDispoLoading(true);
    const data = {
      call_unique_id: call.call_unique_id,
      category: selectedCategory,
      disposition: selectedDispo,
      color: dispoColor,
    };

    let newCallData = call;
    newCallData.qa.category = selectedCategory;
    newCallData.qa.disposition = selectedDispo;
    newCallData.qa.color = dispoColor;

    // console.log("newCallData data:", newCallData);
    // console.log("saveCategoryDisposition data:", data);
    try {
      const result = await setCallCategoryDisposition(data);
      setDispoUpdateSuccess(true);
      setTimeout(() => {
        setDispoUpdateSuccess(false);
      }, 2000);
      // console.log(result);
      updateCallCatDispoFrontEnd(index, newCallData);

      setDispoLoading(false);
    } catch (error) {
      console.log(error.message);
      setDispoLoading(false);
    }
  };

  const handleNewNote = async () => {
    setNoteLoading(true);
    const noteData = {
      call_unique_id: call.call_unique_id,
      note: {
        timestamp: moment().format("L LT"),
        id: `note-${uuidv4()}`,
        note,
        created_by: username,
      },
    };

    try {
      const response = await addNewCallNote(noteData);
      updateCallNoteFrontEnd(index, noteData.note, "add");
      // console.log(response);
      setNote("");
      setNoteLoading(false);
    } catch (error) {
      alert(error.message);
      setNoteLoading(false);
    }
  };

  const updateCallNoteFrontEnd = (callIndex, note, action, noteIndex) => {
    // console.log("updateCallNoteFrontEnd callIndex::", callIndex);
    // console.log("updateCallNoteFrontEnd noteIndex::", noteIndex);

    const newCall = call;
    // console.log("updateCallNoteFrontEnd newCall::", newCall);
    if (action === "add") {
      newCall.qa.notes.push(note);
      // console.log("newCall from add:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
    if (action === "delete") {
      newCall.qa.notes.splice(noteIndex, 1);
      // console.log("newCall from Delete:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
    if (action === "edit") {
      newCall.qa.notes.splice(noteIndex, 1, note);
      // console.log("newCall from Edit:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
  };

  const findDispositionDefaultValueIndex = () => {
    if (dispoChoices) {
      const index = dispoChoices.findIndex((entry) => {
        return entry.value === call.qa.disposition;
      });

      // console.log("choice:", index);

      if (index !== -1) {
        // console.log("dispoChoices[choice]:", dispoChoices[index]);
        setDispositionDefaultValueIndex(index);
      } else {
        return null;
      }
    }
  };

  const setDispoValue = () => {
    if (call.qa.category) {
      setSelectedCategory(call.qa.category);
    } else {
      setSelectedCategory(null);
    }
    if (call.qa.disposition) {
      setSelectedDispo(call.qa.disposition);
    } else {
      setSelectedDispo(null);
    }
    if (call.qa.color) {
      setDispoColor(call.qa.color);
    } else {
      setDispoColor(null);
    }
  };

  const handleDeleteCall = async () => {
    setDeleteCallLoding(true);

    console.log("handleDeleteCall call:", call);

    const payload = {
      _id: call._id,
    };

    console.log("handleDeleteCall payload:", payload);

    try {
      const response = await deleteCall(payload);
      console.log("handleDeleteCall response:", response);
      updateCallFromDeleteFrontEnd(index);
      setDeleteCallLoding(false);
      onClose();
    } catch (error) {
      alert(error.message);
      setDeleteCallLoding(false);
    }
  };

  const checkForCallRecording = async () => {
    try {
      const response = await getSingleCallInfo(call.call_unique_id);
      if (response.records[0]) {
        if (response.records[0].recordingUrl) {
          setCallRecordingUrl(response.records[0].recordingUrl);
        } else {
          alert("No call recording was found in Ringba.");
        }
      }
      console.log("response", response);
    } catch (error) {
      alert(error.message);
    }
  };

  const setBorderColor = () => {
    if (call?.tags?.flags?.flagged && call?.tags?.flags?.status === "pending") {
      return "red";
    }
    if (call?.tags?.flags?.flagged && call?.tags?.flags?.status === "resolved") {
      return "blue";
    }
    if (call?.tags?.marks?.marked && call?.tags?.marks?.status === "pending") {
      return "yellow";
    }
    if (call?.tags?.marks?.marked && call?.tags?.marks?.status === "resolved") {
      return "green";
    }
  };

  const handleClose = () => {
    setSelectedCategory(null);
    setSelectedDispo(null);
    onClose();
  };

  const logMe = () => {
    // console.log("sortedQaDispos:", sortedQaDispos);
    console.log("call:", call);
    console.log("call.recording_url:", call.recording_url);
    console.log("callRecordingUrl:", callRecordingUrl);
    // console.log("selectedCategory:", selectedCategory);
    // console.log("dispoChoices:", dispoChoices);
    // console.log("dispositionDefaultValueIndex:", dispositionDefaultValueIndex);
    // console.log("selectedDispo:", selectedDispo);
    // console.log("dispoColor:", dispoColor);
  };

  // useEffect(() => {
  //   if (call) setDispoValue();
  // }, [call]);

  useEffect(() => {
    sortQaDispos();
  }, [qaDispos]);

  useEffect(() => {
    if (sortedQaDispos) {
      selectWhichDispo();
    }
  }, [sortedQaDispos, selectedCategory]);

  // useEffect(() => {
  //   if (dispoChoices && call.qa.disposition) {
  //     findDispositionDefaultValueIndex();
  //   }
  // }, [dispoChoices, selectedCategory]);

  useEffect(() => {
    if (call && call.recording_url) {
      // console.log("setCallRecording Effect Call:", call);
      // console.log("setCallRecording Effect Call.recording_url:", call.recording_url);
      setCallRecordingUrl(call.recording_url);
    } else {
      setCallRecordingUrl(null);
    }
  }, [data, filteredData, call]);

  return (
    <>
      <Tr
        _hover={{ backgroundColor: "teal.300" }}
        backgroundColor={call?.qa.category ? "gray.400" : null}
        onClick={onOpen}
        cursor="pointer"
        color={isDuplicate ? "red.400" : null}
        borderWidth={call?.tags?.flags?.flagged || call?.tags?.marks?.marked ? 4 : null}
        borderColor={setBorderColor()}
      >
        <Td textAlign="center" w="230px">
          {moment.tz(new Date(), "America/Los_Angeles").format("z") === "PST"
            ? moment
                .tz(call?.call_timestamp, "America/Los_Angeles")
                // .subtract(1, "hour")
                .format("L LT")
            : moment
                .tz(call?.call_timestamp, "America/Los_Angeles")
                // .subtract(1, "hour")
                .format("L LT")}
          {/* {moment.tz(new Date(), "America/Los_Angeles").format("z")} */}
        </Td>
        <Td textAlign="center">{call?.vertical}</Td>
        <Td textAlign="center">{call?.buyer_name}</Td>
        <Td textAlign="center">{call?.publisher_name}</Td>
        <Td textAlign="center" backgroundColor={call?.qa.category ? call.qa.color : null}>
          {call?.call_callerid}
        </Td>
        <Td textAlign="center">{call?.target_name}</Td>
        <Td textAlign="center">{call?.campaign_name}</Td>
        <Td textAlign="center">{call?.call_payout}</Td>
        <Td textAlign="center">{call?.call_revenue}</Td>
        <Td textAlign="center">{call?.call_end_source}</Td>
        {call?.call_length > 0 ? (
          <Td textAlign="center">{formatDuration(call?.call_length)}</Td>
        ) : (
          <Td textAlign="center"></Td>
        )}
      </Tr>

      <Modal isOpen={isOpen} onClose={handleClose} isCentered size="7xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          borderWidth={call?.tags?.flags?.flagged || call?.tags?.marks?.marked ? 4 : null}
          borderColor={setBorderColor()}
        >
          <ModalHeader textAlign="left">
            {isDuplicate ? (
              <Flex alignItems="center">
                <Tag mr={3} colorScheme="red" size="lg">
                  THIS IS A DUPLICATE CALL
                </Tag>
                <ConfirmationModal
                  colorScheme="red"
                  buttonText="Delete Call"
                  messages={["You will be permanently deleting this call from the database"]}
                  header="Delete Call Confirmation"
                  alert="Make sure you are deleting only one of the duplicates. This cannot be undone."
                  event={handleDeleteCall}
                  loading={deleteCallLoding}
                />
              </Flex>
            ) : null}
            <Flex alignItems="center">
              {!call?.tags.marks?.marked ? (
                <FlagCall
                  inboundCallId={call?.call_unique_id}
                  createdBy={username}
                  flagged={call?.tags?.flags?.flagged ? true : false}
                  updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                  callIndex={index}
                  call={call}
                  closeCall={onClose}
                />
              ) : null}
              {call?.tags?.flags?.flagged ? (
                <Flex>
                  {call?.tags?.flags?.status ? (
                    <Tag
                      colorScheme={call?.tags?.flags?.status === "pending" ? "red" : "teal"}
                      ml={3}
                    >
                      {call?.tags?.flags?.status}
                    </Tag>
                  ) : null}

                  <Tag ml={4} fontStyle="italic">
                    Reason: {call?.tags?.flags?.reason}
                  </Tag>
                </Flex>
              ) : null}
              {!call?.tags.flags?.flagged ? (
                <>
                  <MarkCall
                    inboundCallId={call?.call_unique_id}
                    createdBy={username}
                    marked={call?.tags?.marks?.marked ? true : false}
                    updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                    callIndex={index}
                    call={call}
                    closeCall={onClose}
                  />

                  {call?.tags?.marks?.status ? <Tag>{call?.tags?.marks?.status}</Tag> : null}
                </>
              ) : null}
            </Flex>
          </ModalHeader>
          <Box p={4} mb={5} w="100%">
            <Table w="100%">
              <Thead>
                <Tr>
                  <Th textAlign="center" onClick={() => console.log("call:", call)}>
                    Timestamp
                  </Th>
                  <Th textAlign="center">Vertical</Th>
                  <Th textAlign="center">Buyer</Th>
                  <Th textAlign="center">Affiliate</Th>
                  <Th textAlign="center">Caller ID</Th>
                  <Th textAlign="center">Target</Th>
                  <Th textAlign="center">Campaign</Th>
                  <Th textAlign="center">Payout</Th>
                  <Th textAlign="center">Revenue</Th>
                  <Th textAlign="center">End Call Source</Th>
                  <Th textAlign="center">Duration</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign="center" w="230px">
                    {moment.tz(new Date(), "America/Los_Angeles").format("z") === "PST"
                      ? moment
                          .tz(call?.call_timestamp, "America/Los_Angeles")
                          // .subtract(1, "hour")
                          .format("L LT")
                      : moment
                          .tz(call?.call_timestamp, "America/Los_Angeles")
                          // .subtract(1, "hour")
                          .format("L LT")}
                  </Td>
                  <Td textAlign="center">{call?.vertical}</Td>
                  <Td textAlign="center">{call?.buyer_name}</Td>
                  <Td textAlign="center">{call?.publisher_name}</Td>
                  <Td textAlign="center">{call?.call_callerid}</Td>
                  <Td textAlign="center">{call?.target_name}</Td>
                  <Td textAlign="center">{call?.campaign_name}</Td>
                  <Td textAlign="center">{call?.call_payout}</Td>
                  <Td textAlign="center">{call?.call_revenue}</Td>
                  <Td textAlign="center">{call?.call_end_source}</Td>
                  {call?.call_length > 0 ? (
                    <Td textAlign="center">{formatDuration(call?.call_length)}</Td>
                  ) : (
                    <Td textAlign="center"></Td>
                  )}
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="space-evenly" alignItems="center">
              {qaDispos ? (
                <Flex flexDir="column" w="35%" h="650px">
                  <Text textAlign="center" letterSpacing={3} mb={2}>
                    QA DISPOSITION KEYS
                  </Text>
                  <Box h="730px" overflowY="scroll">
                    {qaDispos.map((table) => (
                      <DispoTable data={table} getQaDispositions={getQaDispositions} />
                    ))}
                  </Box>
                </Flex>
              ) : null}
              <Flex w="65%" ml={4} h="650px" flexDir="column" justify="center" alignSelf="center">
                <Box
                  borderWidth={1}
                  borderRadius={7}
                  p={4}
                  mb={5}
                  backgroundColor={call?.qa.color ? call?.qa.color : null}
                >
                  {/* <Button onClick={() => console.log(sortedQaDispos)}>LOG</Button> */}
                  <Flex w="100%" justify="center">
                    <Text></Text>
                    <Flex w="45%" flexDir="column" mr={4}>
                      <Text letterSpacing={4} mb={2}>
                        CATEGORY
                      </Text>
                      <Box
                        mb={2}
                        p={2}
                        borderWidth={1}
                        borderColor="black"
                        borderRadius={3}
                        bg="white"
                      >
                        <Text color={call.qa.color} fontWeight={600}>
                          {call.qa.category}
                        </Text>
                      </Box>
                      {qaDispos && sortedQaDispos ? (
                        <Select
                          options={sortedQaDispos}
                          styles={selectStyles}
                          onChange={(e) => setSelectedCategory(e.value)}
                        />
                      ) : null}
                    </Flex>
                    <Flex w="45%" flexDir="column" mr={4}>
                      <Text letterSpacing={4} mb={2}>
                        DISPOSITION
                      </Text>
                      <Box
                        mb={2}
                        p={2}
                        borderWidth={1}
                        borderColor="black"
                        borderRadius={3}
                        bg="white"
                      >
                        <Text color={call.qa.color} fontWeight={600}>
                          {call.qa.disposition}
                        </Text>
                      </Box>
                      {qaDispos ? (
                        <Select
                          // key={uuidv4()}
                          isDisabled={!dispoChoices}
                          placeholder={
                            !dispoChoices ? "Select a category first." : "Select a category."
                          }
                          options={dispoChoices ? dispoChoices : [{ value: "", label: "" }]}
                          styles={selectStyles}
                          onChange={(e) => handleDispoSelectionChange(e)}
                        />
                      ) : null}
                    </Flex>

                    <Flex w="10%" flexDir="column" justify="flex-end">
                      {dispoUpdateSuccess ? (
                        <Text
                          color="green.500"
                          mb={2}
                          textAlign="center"
                          letterSpacing={3}
                          fontWeight={800}
                        >
                          SUCCESS!
                        </Text>
                      ) : (
                        <Text color="red.500" mb={2}>
                          &nbsp;
                        </Text>
                      )}
                      <Button
                        isDisabled={!selectedDispo}
                        onClick={saveCategoryDisposition}
                        isLoading={dispoLoading}
                        backgroundColor="gray"
                      >
                        Save
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  borderWidth={1}
                  borderRadius={7}
                  p={4}
                  mb={5}
                  overflow="scroll"
                  // backgroundColor="blue"
                >
                  {call?.qa && call?.qa.notes.length > 0 ? (
                    <>
                      <Text textAlign="center" letterSpacing={3} fontSize={18} mb={3}>
                        NOTES
                      </Text>
                      <Table w="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th textAlign="center">Timestamp</Th>
                            <Th textAlign="center">Created By</Th>
                            <Th textAlign="center">Note</Th>
                            <Th textAlign="center"></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {call?.qa.notes.map((note, noteIndex) => (
                            <QaNote
                              callId={call?.call_unique_id}
                              note={note}
                              callIndex={index}
                              noteIndex={noteIndex}
                              updateCallNoteFrontEnd={updateCallNoteFrontEnd}
                            />
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  ) : (
                    <Flex w="100%" justify="center" alignItems="center" py={5}>
                      <Text fontSize={20} letterSpacing={4}>
                        NO CURRENT NOTES
                      </Text>
                    </Flex>
                  )}
                  <Flex w="100%" mt={4} alignItems="center" justifyContent="center">
                    <Flex flexDir="column" w="80%" mr={4}>
                      <Text letterSpacing={4} mb={2}>
                        NEW NOTE
                      </Text>
                      <Textarea
                        rows={4}
                        style={{ whiteSpace: "pre-wrap" }}
                        placeholder="Enter notes here."
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Flex>
                    <Flex flexDir="column" w="20%" h="120px" justify="center">
                      <Text>&nbsp;</Text>
                      <Button
                        isLoading={noteLoading}
                        colorScheme="teal"
                        onClick={handleNewNote}
                        h="100%"
                      >
                        Add
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
                <Box borderWidth={1} borderRadius={8} px={4} py={5}>
                  {callRecordingUrl ? (
                    <Flex flexDir="column">
                      <Text letterSpacing={3} mb={10} textAlign="center" onClick={logMe}>
                        CALL RECORDING
                      </Text>
                      <ReactPlayer url={callRecordingUrl} />
                      {/* <Media>
                        {(mediaProps) => (
                          <div
                            className="media"
                            onKeyDown={keyboardControls.bind(null, mediaProps)}
                          >
                            <Box className="media-player" display="none">
                              <Player src={call.recording_url} />
                            </Box>
                            <Flex
                              className="media-controls"
                              justifyContent="space-evenly"
                              alignItems="center"
                            >
                              <Box _hover={{ color: "teal.300" }}>
                                <PlayPause />
                              </Box>
                              <CurrentTime />
                              <SeekBar />
                              <Duration />
                            </Flex>
                          </div>
                        )}
                      </Media> */}
                    </Flex>
                  ) : (
                    <Flex alignItems="center" justify="center" flexDir="column">
                      <Text fontSize={18} letterSpacing={4}>
                        NO CALL RECORDING
                      </Text>
                      <Button mt={3} onClick={checkForCallRecording}>
                        CHECK IF RECORDING EXISTS
                      </Button>
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
