import React, { useState, useEffect } from "react";
import {
  Flex,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Button,
  Heading,
  Text,
  IconButton,
} from "@chakra-ui/react";
import moment from "moment";

import { colors } from "../../../config/colors";

import CallDetailsModal from "../modals/CallDetailsModal";

export default function FailedCalls({ reportData, setReportData }) {
  const [filter, setFilter] = useState("time");

  const sortByCampaignName = (a, b) => {
    if (a.campaignName < b.campaignName) {
      return -1;
    }
    if (a.campaignName > b.campaignName) {
      return 1;
    }
    return 0;
  };

  const sortByDateTime = (a, b) => {
    if (a.callDt > b.callDt) {
      return -1;
    }
    if (a.callDt < b.callDt) {
      return 1;
    }
    return 0;
  };

  return (
    <Flex flexDir="column" flex mt="20px">
      {reportData.totalCount ? (
        <>
          <Heading textAlign="center" my="10px">
            Total Failed Calls: {reportData.totalCount}
          </Heading>
          <Flex mb="15px" alignItems="center">
            <Text>Sort By:</Text>
            <Button size="sm" onClick={() => setFilter("time")} mx="10px">
              Time
            </Button>
            <Button size="sm" onClick={() => setFilter("campaign")} mx="10px">
              Campaign
            </Button>
          </Flex>
        </>
      ) : null}
      <Table variant="striped" size="sm">
        <Thead>
          {reportData.records ? (
            <Tr>
              <Th textAlign="center">Call Date Time</Th>
              <Th textAlign="center">Campaign Name</Th>
              <Th textAlign="center">Publisher Name</Th>
              <Th textAlign="center">Caller ID</Th>
              <Th textAlign="center">Incomplete Reason</Th>
              <Th textAlign="center">Blocked Reason</Th>
              <Th textAlign="center">Call Details</Th>
            </Tr>
          ) : null}
        </Thead>
        <Tbody>
          {reportData.records
            ? reportData.records
                .sort(filter === "time" ? sortByDateTime : sortByCampaignName)
                .map((item, i) => (
                  <Tr key={`${item}-${i}`}>
                    <Td textAlign="center">{moment(item.callDt).format("LT")}</Td>
                    <Td textAlign="center">{item.campaignName}</Td>
                    <Td textAlign="center">{item.publisherName}</Td>
                    <Td textAlign="center">{item.inboundPhoneNumber}</Td>
                    <Td textAlign="center">{item.incompleteCallReason}</Td>
                    <Td textAlign="center">{item.blockReason}</Td>
                    <Td textAlign="center">
                      <CallDetailsModal inboundCallId={item.inboundCallId} />
                    </Td>
                  </Tr>
                ))
            : null}
        </Tbody>
      </Table>
    </Flex>
  );
}
