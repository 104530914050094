import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import TopHeading from "../TopHeading";
import { getAllCampaigns } from "./JobsApi";
import { FiRefreshCcw } from "react-icons/fi";

import Select from "react-select";

import "../sidebar/sidebar.css";

import JobsCampaignsModalModal from "./JobsCampaignsModal";

export default function JobsCampaigns() {
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const [allCampaignData, setAllCampaignData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchQueryName, setSearchQueryName] = useState();
  const [searchQueryDid, setSearchQueryDid] = useState();
  const [campaignLiveTypeState, setCampaignLiveTypeState] = useState("All Campaigns");

  const campaignLiveChoices = [
    { name: "All Campaigns", label: "All Campaigns" },
    { name: "Live Campaigns", label: "Live Campaigns" },
    { name: "Paused Campaigns", label: "Paused Campaigns" },
  ];

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
      width: "100%",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : "#1A202C",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      color: fontColor,
      backgroundColor: "#1A202C",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      color: fontColor,
    }),
  };

  const handleGetAllCampaignData = async () => {
    setLoading(true);
    try {
      const data = await getAllCampaigns();
      console.log(data);
      setAllCampaignData(data.sort(sortByCampaignName));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSearchByName = (e) => {
    const results = allCampaignData.filter((campaign) => {
      if (e.target.value === "") return campaign;
      return campaign.name?.toLowerCase().includes(e.target.value?.toLowerCase());
    });
    setSearchQueryName(e.target.value);
    setFilteredData(results);
  };

  const sortByCampaignName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleSearchByDid = (e) => {
    const results = allCampaignData.filter((campaign) => {
      if (e.target.value === "") return campaign;
      if (
        campaign.affiliateNumbers &&
        campaign.affiliateNumbers.length > 0 &&
        campaign.affiliateNumbers[0]
      )
        return campaign.affiliateNumbers[0].phoneNumber.includes(e.target.value);
    });
    setSearchQueryDid(e.target.value);
    setFilteredData(results);
  };

  const updateCampaignDetailsFrontEnd = (index, campaignData) => {
    let newData = [...allCampaignData];

    newData.splice(index, 1, campaignData);

    setAllCampaignData(newData);
  };

  const filterLiveType = (campaignLiveType) => {
    const results = allCampaignData.filter((campaign) => {
      if (campaignLiveType === "All Campaigns") {
        return { campaign };
      }
      if (campaignLiveType === "Live Campaigns") return campaign.enabled;
      if (campaignLiveType === "Paused Campaigns") return !campaign.enabled;
    });
    return results;
  };
  const handleLiveTypeFilterChange = (e) => {
    setCampaignLiveTypeState(e);

    const filterDataSet = filterLiveType(e);

    setFilteredData(filterDataSet);
  };

  useEffect(() => {
    handleGetAllCampaignData();
  }, []);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      overflowY={allCampaignData ? "scroll" : "hidden"}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Campaigns"
        description="Information on all campaigns currently in Ringba."
        children={
          <Flex justifyContent="flex-end" alignItems="center">
            {loading ? <Text mr={2}>Loading campaigns...</Text> : null}
            {!loading && allCampaignData ? (
              <Text mr={2}>{`${allCampaignData.length} Total Campaigns loaded.`}</Text>
            ) : null}

            <IconButton
              isLoading={loading}
              onClick={handleGetAllCampaignData}
              aria-label="Pull Campaign Data"
              icon={<FiRefreshCcw />}
            />
          </Flex>
        }
      />

      <Flex flexDir="column" w="100%" position="sticky" top={0} bgColor={bgColor} pt={2}>
        {allCampaignData ? (
          <Flex
            pt={2}
            pb={4}
            position="sticky"
            justifyContent="space-between"
            top={108}
            bgColor={bgColor}
            zIndex={2}
          >
            <Flex w="75%">
              <Box w="25%" zIndex={2}>
                <Select
                  placeholder="Live or Paused?"
                  defaultValue={campaignLiveChoices[0]}
                  options={campaignLiveChoices}
                  styles={selectStatusStyles}
                  onChange={(e) => handleLiveTypeFilterChange(e.name)}
                />
              </Box>
              <Box w="10px" h="1px" />
              <Input
                w="25%"
                placeholder="Search by Campaign Name"
                value={searchQueryName}
                onChange={handleSearchByName}
              />
              <Box w="10px" h="1px" />
              <Input
                w="25%"
                placeholder="Search by DID"
                value={searchQueryDid}
                onChange={handleSearchByDid}
              />
            </Flex>
            <Flex w="25%" justify="flex-end">
              <Text letterSpacing={2} borderWidth={1} p={2}>
                {`${
                  filteredData ? filteredData.length : allCampaignData.length
                } ${campaignLiveTypeState.toUpperCase()}`}
              </Text>
            </Flex>
          </Flex>
        ) : null}
        {allCampaignData ? (
          <Table size="md" w="100%">
            <Thead>
              <Tr position="sticky" top={165} bgColor={bgColor} zIndex={1}>
                <Th w="70%">Name</Th>
                <Th textAlign="center" w="15%">
                  DID
                </Th>
                <Th textAlign="center" w="15%">
                  Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData
                ? filteredData.map((campaign, i) => (
                    <JobsCampaignsModalModal
                      key={campaign.id}
                      campaignData={campaign}
                      index={i}
                      updateCampaignDetailsFrontEnd={updateCampaignDetailsFrontEnd}
                    />
                  ))
                : allCampaignData.map((campaign, i) => (
                    <JobsCampaignsModalModal
                      key={campaign.id}
                      campaignData={campaign}
                      index={i}
                      updateCampaignDetailsFrontEnd={updateCampaignDetailsFrontEnd}
                    />
                  ))}
            </Tbody>
          </Table>
        ) : null}
      </Flex>
    </Box>
  );
}
