import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Link,
  Text,
  ButtonGroup,
  Spinner,
  useColorModeValue,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { FiExternalLink, FiRefreshCcw } from "react-icons/fi";
import { GoPlus } from "react-icons/go";

import { getAllBlacklistPartners } from "./PartnersApi";

// import { createNewDriveFolder } from "../GoogleDriveApi";

import TopHeading from "../TopHeading";
import NewBlacklistModal from "./NewBlacklistModal";
import EditBlacklistModal from "./EditBlacklistModal";

export default function Blacklist() {
  const [partners, setPartners] = useState();
  const [buyers, setBuyers] = useState();
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue("white", "#1A202C");

  const getAllPartners = async () => {
    if (!partners) setLoading(true);

    try {
      const response = await getAllBlacklistPartners();
      setPartners(response.result);
      console.log(response);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      overflowY="auto"
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Blacklist Partners"
        description="Information on all of our blacklisted partners."
        children={[
          <Flex>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center">
                <IconButton
                  isLoading={loading}
                  onClick={getAllPartners}
                  mr="10px"
                  aria-label="Generate Affiliates List"
                  icon={<FiRefreshCcw />}
                />

                <NewBlacklistModal getAllPartners={getAllPartners} />
              </Flex>
            </Flex>
          </Flex>,
        ]}
      />

      <Flex flexDir="column" justifyContent="center" alignItems="center">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="green.400" />
          </Flex>
        ) : null}
        {partners && partners.length > 0 ? (
          <Table size="md">
            <Thead>
              <Tr position="sticky" top={100} backgroundColor={bgColor} zIndex={1}>
                {partners.length > 0 ? (
                  <>
                    <Th textAlign="center">CREATED</Th>
                    <Th textAlign="center">NAME</Th>
                    <Th textAlign="center">CONTACT INFO</Th>
                    <Th textAlign="center">PHONE</Th>
                    <Th textAlign="center">ADDRESS</Th>
                    <Th textAlign="center">LANDING PAGES</Th>
                    <Th textAlign="center">NOTES</Th>
                    <Th></Th>
                  </>
                ) : null}
              </Tr>
            </Thead>
            <Tbody>
              {partners
                ? partners.map((partner, i) => (
                    <EditBlacklistModal
                      key={partner.uuid}
                      partner={partner}
                      getAllPartners={getAllPartners}
                    />
                  ))
                : null}
            </Tbody>
          </Table>
        ) : null}
      </Flex>
    </Box>
  );
}
