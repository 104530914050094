import axios from "axios";
import moment from "moment";

const end_date = moment().add(1, "d").format("YYYY-MM-DD");
const start_date = moment().format("YYYY-MM-DD");

const addNewBlacklistPartner = async (payload) => {
  try {
    const response = await axios.post("api/partners/blacklist/add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteBlacklistPartner = async (payload) => {
  try {
    const response = await axios.post("api/partners/blacklist/delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editBlacklistPartner = async (payload) => {
  try {
    const response = await axios.post("api/partners/blacklist/edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllBlacklistPartners = async () => {
  try {
    const response = await axios.get("api/partners/blacklist");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewBuyer = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteBuyer = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editBuyer = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllBuyers = async () => {
  try {
    const response = await axios.get("api/partners/buyers");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewBuyerContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/add-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteBuyerContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/delete-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editBuyerContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/buyers/edit-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewAffiliate = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/add", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteAffiliate = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/delete", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editAffiliate = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/edit", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const getAllAffiliates = async () => {
  try {
    const response = await axios.get("api/partners/affiliates");
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const addNewAffiliateContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/add-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const deleteAffiliateContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/delete-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

const editAffiliateContact = async (payload) => {
  try {
    const response = await axios.post("api/partners/affiliates/edit-contact", payload);
    console.log("response:", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    alert(error);
  }
};

export {
  addNewBlacklistPartner,
  deleteBlacklistPartner,
  editBlacklistPartner,
  getAllBlacklistPartners,
  addNewBuyer,
  deleteBuyer,
  editBuyer,
  getAllBuyers,
  addNewBuyerContact,
  deleteBuyerContact,
  editBuyerContact,
  addNewAffiliate,
  deleteAffiliate,
  editAffiliate,
  getAllAffiliates,
  addNewAffiliateContact,
  deleteAffiliateContact,
  editAffiliateContact,
};
