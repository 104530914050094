const string =
  "key=ee8ddc24-8200-493b-9199-6dd74602d438&caller_number=[tag:InboundNumber:NumberE164]&first_name=[tag:user:first_name]&last_name=[tag:user:last_name]&phone=[tag:InboundNumber:NumberE164]&email=[tag:user:email]&address_1=[tag:user:address1]&city=[tag:user:city]&state=[tag:user:state]&zip_code=[tag:user:zip]&date_of_birth=[tag:user:dob]&debt_amount=[tag:user:debt_amount]";

const produceRequiredFields = (allTargets) => {
  let allRequiredFields = [];
  allTargets.forEach((target) => {
    // console.log("target::", target);
    if (target.callPingTree) {
      console.log("IS PING TREE!");
      const contentType = target.callPingTree.targets[0].contentType;
      let body = target.callPingTree.targets[0].body;
      //   console.log("body::", body);

      if (contentType === "application/x-www-form-urlencoded") {
        console.log("urlencoded");
        const requiredFields = scrapeForFields_UrlEncoded(body);
        // console.log("requiredFields::", requiredFields);
        requiredFields.forEach((field) => {
          //   console.log("field::", field);
          let fieldNew = field;
          // if (fieldNew === "lead_id") {
          //   fieldNew = `${field} (Jornaya Lead ID)`;
          // }
          if (!allRequiredFields.includes(fieldNew)) {
            allRequiredFields.push(fieldNew);
          }
        });
      }

      if (contentType === "application/json") {
        console.log("json");
        const requiredFields = scrapeForFields_Json(body);
        requiredFields.forEach((field) => {
          if (!allRequiredFields.includes(field)) {
            allRequiredFields.push(field);
          }
        });
      }
      // console.log("allRequiredFields::", allRequiredFields);
    }
  });
  return allRequiredFields;
};

const scrapeForFields_UrlEncoded = (body) => {
  if (!body) return [];

  const newBody = body.split("&");
  //   console.log("newBody::", newBody);

  let body1 = [];

  newBody.forEach((e) => {
    if (
      !e.includes("NumberE164") &&
      !e.includes("tag:InboundNumber") &&
      e.includes("[") &&
      e.includes("]")
    )
      body1.push(e);
  });

  if (body1.length < 1) {
    return [];
  }

  // console.log("scrapeForFields_UrlEncoded body1::", body1);

  let tags = [];

  body1.forEach((tag) => {
    const couplet = tag.split("=");
    tags.push(couplet[1]);
  });
  // console.log("scrapeForFields_UrlEncoded tags::", tags);

  if (tags.length < 1) {
    console.log("No required fields");
    return [];
  }

  let requiredFields = [];

  tags.forEach((tag) => {
    const newTag = tag.split(":");
    requiredFields.push(newTag[2].replace("]", ""));
  });
  //   console.log("scrapeForFields_UrlEncoded requiredFields::", requiredFields);

  return requiredFields;
};

const scrapeForFields_Json = (body) => {
  if (!body) return [];
  const newBody = JSON.parse(body);
  //   console.log("scrapeForFields_Json newBody::", newBody);

  let tags = [];

  for (const key in newBody) {
    const field = newBody[key];

    if (
      !String(field).includes("NumberE164") &&
      !String(field).includes("[tag:InboundNumber:Prefix]") &&
      String(field).includes("[") &&
      String(field).includes("]")
    ) {
      tags.push(field);
    }
  }
  //   console.log("scrapeForFields_Json tags::", tags);

  if (tags.length < 1) {
    console.log("No required fields");
    return [];
  }

  let requiredFields = [];

  tags.forEach((tag) => {
    const newTag = tag.split(":");
    requiredFields.push(newTag[2].replace("]", ""));
  });
  //   console.log("scrapeForFields_Json requiredFields::", requiredFields);

  return requiredFields;
};

export { produceRequiredFields };
