import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormLabel,
  Input,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  Select,
} from "@chakra-ui/react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getAllQaKeys, addNewQaKey, deleteQaKey } from "../qa/QaApis";

import "./QaKeyModal.css";

import { ImKey } from "react-icons/im";
import { BsTrash } from "react-icons/bs";

const KeyTableRow = ({ rowData, uuid, keyType, getQaKeys }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(keyType === "Vertical" ? rowData.color : null);

  const removeQaKey = async () => {
    setLoading(true);
    const data = {
      entryId: rowData.id,
      uuid,
      type: keyType,
    };

    try {
      const response = await deleteQaKey(data);
      setLoading(false);
      getQaKeys();
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  return (
    <Tr _hover={{ fontWeight: 700 }} backgroundColor={color}>
      <Td>{rowData.name}</Td>
      <Td textAlign="right">
        <Tooltip hasArrow placement="top" label="Delete">
          <IconButton
            _hover={{ backgroundColor: "teal.300" }}
            backgroundColor="gray.500"
            color="white"
            size="xs"
            icon={loading ? <Spinner size="sm" /> : <BsTrash />}
            onClick={removeQaKey}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

const QaKeyTable = ({ keyData, keyType, uuid, getQaKeys }) => {
  return (
    <Box h="550px" overflow="scroll">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>{keyType}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {keyData
            ? keyData.map((rowData) => (
                <KeyTableRow
                  key={`tr-${rowData.name}`}
                  rowData={rowData}
                  uuid={uuid}
                  keyType={keyType}
                  getQaKeys={getQaKeys}
                />
              ))
            : null}
        </Tbody>
      </Table>
    </Box>
  );
};

export default function QaKeyModal({ getQaKeys, qaKeyData, admin }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [assignedToName, setAssignedToName] = useState();
  const [vertical, setVertical] = useState();
  const [verticalColor, setVerticalColor] = useState();
  const [publisher, setPublisher] = useState();
  const [buyer, setBuyer] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const logMe = () => {
    console.log("qaKeyData:", qaKeyData);
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const checkIfExist = (category, key) => {
    if (category === "name") {
      if (qaKeyData.assignedTo.some((e) => e.name === key)) {
        return true;
      }
    } else if (category === "vertical") {
      if (qaKeyData.vertical.some((e) => e.name === key)) {
        return true;
      }
    } else if (category === "publisher") {
      if (qaKeyData.publisher.some((e) => e.name === key)) {
        return true;
      }
    } else if (category === "buyer") {
      if (qaKeyData.buyer.some((e) => e.name === key)) {
        return true;
      }
    } else {
      return false;
    }
  };

  const addQaKey = async () => {
    setAddLoading(true);

    const keyValue = () => {
      if (assignedToName) {
        if (checkIfExist("name", assignedToName)) {
          alert("Already exists.");
          return null;
        } else {
          return {
            id: uuidv4(),
            name: assignedToName,
            type: "name",
            value: assignedToName,
            label: assignedToName,
          };
        }
      } else if (vertical) {
        if (checkIfExist("vertical", vertical)) {
          alert("Already exists.");
          return null;
        } else {
          return {
            id: uuidv4(),
            name: vertical,
            type: "vertical",
            color: verticalColor,
            value: vertical,
            label: vertical,
          };
        }
      } else if (publisher) {
        if (checkIfExist("publisher", publisher)) {
          alert("Already exists.");
          return null;
        } else {
          return {
            id: uuidv4(),
            name: publisher,
            type: "publisher",
            value: publisher,
            label: publisher,
          };
        }
      } else if (buyer) {
        if (checkIfExist("buyer", buyer)) {
          alert("Already exists.");
          return null;
        } else {
          return {
            id: uuidv4(),
            name: buyer,
            type: "buyer",
            value: buyer,
            label: buyer,
          };
        }
      } else {
        alert("Nothing to add.");
        return null;
      }
    };

    if (keyValue()) {
      const data = {
        uuid: qaKeyData.uuid,
        keyData: keyValue(),
      };

      try {
        // console.log("addQaKey data:", data);
        const result = await addNewQaKey(data);
        setAddLoading(false);
        emptyFields();
        getQaKeys();
      } catch (error) {
        alert("error:", error);
        setAddLoading(false);
      }
    } else {
      setAddLoading(false);
    }
  };

  const emptyFields = () => {
    if (assignedToName) setAssignedToName("");
    if (vertical) setVertical("");
    if (verticalColor) setVerticalColor("");
    if (publisher) setPublisher("");
    if (buyer) setBuyer("");
  };

  useEffect(() => {
    getQaKeys();
  }, []);

  return (
    <>
      <Tooltip label="QA Keys" hasArrow placement="top">
        <IconButton
          icon={getLoading ? <Spinner /> : <ImKey />}
          onClick={onOpen}
          isDisabled={!admin}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h="900px">
          <ModalHeader>QA Keys</ModalHeader>
          {/* <Button onClick={logMe}>logMe</Button> */}
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Assigned To</Tab>
                <Tab>Verticals</Tab>
                <Tab>Publishers</Tab>
                <Tab>Buyers</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Flex flexDir="column" mb={3}>
                    <Box p={4} borderWidth={1} borderRadius={7} mb={8}>
                      <Text textAlign="center" mb={2} letterSpacing="4px">
                        CURRENT
                      </Text>
                      {qaKeyData && qaKeyData.assignedTo.length > 0 ? (
                        <QaKeyTable
                          keyData={qaKeyData.assignedTo.sort(sortByName)}
                          keyType="Name"
                          uuid={qaKeyData.uuid}
                          getQaKeys={getQaKeys}
                        />
                      ) : (
                        <Flex justify="center" alignItems="center">
                          <Text>Nothing current</Text>
                        </Flex>
                      )}
                    </Box>
                    <FormLabel>Add New Assigned To</FormLabel>
                    <Flex>
                      <Input
                        value={assignedToName}
                        onChange={(e) => setAssignedToName(e.target.value)}
                        type="text"
                      />
                      <Button onClick={addQaKey} ml={4}>
                        {addLoading ? <Spinner /> : "Add"}
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex flexDir="column" mb={3}>
                    <Box p={4} borderWidth={1} borderRadius={7} mb={8}>
                      <Text textAlign="center" mb={2} letterSpacing="4px">
                        CURRENT
                      </Text>
                      {qaKeyData && qaKeyData.vertical.length > 0 ? (
                        <QaKeyTable
                          keyData={qaKeyData.vertical.sort(sortByName)}
                          keyType="Vertical"
                          uuid={qaKeyData.uuid}
                          getQaKeys={getQaKeys}
                        />
                      ) : (
                        <Flex justify="center" alignItems="center">
                          <Text>Nothing current</Text>
                        </Flex>
                      )}
                    </Box>

                    <Flex>
                      <Flex flexDir="column" w="40%">
                        <FormLabel>New Vertical</FormLabel>
                        <Input
                          value={vertical}
                          onChange={(e) => setVertical(e.target.value)}
                          type="text"
                        />
                      </Flex>
                      <Flex flexDir="column" w="40%" mx={4}>
                        <FormLabel>Vertical Color</FormLabel>
                        <Select
                          defaultValue="gray.300"
                          onChange={(e) => setVerticalColor(e.target.value)}
                          backgroundColor={verticalColor}
                        >
                          <option style={{ backgroundColor: "#CBD5E0" }}>gray.300</option>
                          <option style={{ backgroundColor: "#718096" }}>gray.500</option>

                          <option style={{ backgroundColor: "#FC8181" }}>red.300</option>
                          <option style={{ backgroundColor: "#E53E3E" }}>red.500</option>

                          <option style={{ backgroundColor: "#F6AD55" }}>orange.300</option>
                          <option style={{ backgroundColor: "#DD6B20" }}>orange.500</option>

                          <option style={{ backgroundColor: "#F6E05E" }}>yellow.300</option>
                          <option style={{ backgroundColor: "#D69E2E" }}>yellow.500</option>

                          <option style={{ backgroundColor: "#68D391" }}>green.300</option>
                          <option style={{ backgroundColor: "#38A169" }}>green.500</option>

                          <option style={{ backgroundColor: "#4FD1C5" }}>teal.300</option>
                          <option style={{ backgroundColor: "#319795" }}>teal.500</option>

                          <option style={{ backgroundColor: "#63B3ED" }}>blue.300</option>
                          <option style={{ backgroundColor: "#3182CE" }}>blue.500</option>

                          <option style={{ backgroundColor: "#76E4F7" }}>cyan.300</option>
                          <option style={{ backgroundColor: "#00B5D8" }}>cyan.500</option>

                          <option style={{ backgroundColor: "#B794F4" }}>purple.300</option>
                          <option style={{ backgroundColor: "#805AD5" }}>purple.500</option>

                          <option style={{ backgroundColor: "#F687B3" }}>pink.300</option>
                          <option style={{ backgroundColor: "#D53F8C" }}>pink.500</option>
                        </Select>
                      </Flex>
                      <Flex flexDir="column" w="20%">
                        <FormLabel>&nbsp;</FormLabel>
                        <Button isDisabled={addLoading} onClick={addQaKey}>
                          {addLoading ? <Spinner /> : "Add"}
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex flexDir="column" mb={3}>
                    <Box p={4} borderWidth={1} borderRadius={7} mb={8}>
                      <Text textAlign="center" mb={2} letterSpacing="4px">
                        CURRENT
                      </Text>
                      {qaKeyData && qaKeyData.publisher.length > 0 ? (
                        <QaKeyTable
                          keyData={qaKeyData.publisher.sort(sortByName)}
                          keyType="Publisher"
                          uuid={qaKeyData.uuid}
                          getQaKeys={getQaKeys}
                        />
                      ) : (
                        <Flex justify="center" alignItems="center">
                          <Text>Nothing current</Text>
                        </Flex>
                      )}
                    </Box>
                    <FormLabel>New Publisher</FormLabel>
                    <Flex>
                      <Input
                        value={publisher}
                        onChange={(e) => setPublisher(e.target.value)}
                        type="text"
                      />
                      <Button onClick={addQaKey} ml={4} isDisabled={addLoading}>
                        {addLoading ? <Spinner /> : "Add"}
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex flexDir="column" mb={3}>
                    <Box p={4} borderWidth={1} borderRadius={7} mb={8}>
                      <Text textAlign="center" mb={2} letterSpacing="4px">
                        CURRENT
                      </Text>
                      {qaKeyData && qaKeyData.buyer.length > 0 ? (
                        <QaKeyTable
                          keyData={qaKeyData.buyer.sort(sortByName)}
                          keyType="Buyer"
                          uuid={qaKeyData.uuid}
                          getQaKeys={getQaKeys}
                        />
                      ) : (
                        <Flex justify="center" alignItems="center">
                          <Text>Nothing current</Text>
                        </Flex>
                      )}
                    </Box>
                    <FormLabel>New Buyer</FormLabel>
                    <Flex>
                      <Input value={buyer} onChange={(e) => setBuyer(e.target.value)} type="text" />
                      <Button onClick={addQaKey} ml={4} isDisabled={addLoading}>
                        {addLoading ? <Spinner /> : "Add"}
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
