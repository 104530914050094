import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
  Flex,
  Button,
} from "@chakra-ui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import axios from "axios";

import { callDurationReport, failedCallsReport, getCallRoutes } from "./APIs";

export default function ReportSettings({
  reportData,
  setReportData,
  setNoData,
  setLoading,
  reportType,
  setReportType,
}) {
  const [verticalList, setVerticalList] = useState();
  // const [affiliateList, setAffiliateList] = useState();
  // const [buyerList, setBuyerList] = useState();
  const reportTypes = ["Calls - Duration", "Calls - Routing", "Calls - Failed"];

  const [vertical, setVertical] = useState();
  const [buyer, setBuyer] = useState();
  const [affiliate, setAffiliate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getAllKeys = async () => {
    try {
      const response = await axios.get("/keys");
      const allKeys = response.data;
      const verticals = [];
      // const affiliates = [];
      // const buyers = [];
      allKeys.forEach((key) => {
        if (key.type === "Vertical") verticals.push(key.name);
        // if (key.type === "Affiliate") affiliates.push(key.name);
        // if (key.type === "Buyer") buyers.push(key.name);
      });
      setVerticalList(verticals.sort());
      // setAffiliateList(affiliates.sort());
      // setBuyerList(buyers.sort());
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const buildReportQuery = () => {
    const payload = {
      vertical,
      buyer,
      affiliate,
      startDate: moment(startDate).format("Y-MM-DD"),
      endDate: moment(endDate).add(1, "day").format("Y-MM-DD"),
    };

    return payload;
  };

  const pullReport = async () => {
    setReportData();
    setNoData();
    setLoading(true);
    const payload = buildReportQuery();

    try {
      if (reportType === "Calls - Duration") {
        const data = await callDurationReport(payload);

        if (data && data.length > 0) {
          setNoData(false);
          setReportData(data);
          setLoading(false);
        } else {
          setLoading(false);
          setNoData(true);
        }
      }
      if (reportType === "Calls - Failed") {
        const data = await failedCallsReport();

        if (data) {
          setNoData(false);
          setReportData(data);
          setLoading(false);
        } else {
          setLoading(false);
          setNoData(true);
        }
      }
      if (reportType === "Calls - Routing") {
        const data = await getCallRoutes();
        setNoData(false);
        setReportData(data);
        setLoading(false);
      } else {
        setLoading(false);
        setNoData(true);
      }
      if (!reportType) {
        alert("Select a report type!");
        setLoading(false);
        setNoData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllKeys();
  }, []);

  const generateReport = () => {
    pullReport();
  };

  const logStuff = () => {
    console.log("reportData:::", reportData);
  };

  return (
    <Flex justifyContent="flex-start" my="15px">
      <Flex mx="10px">
        <FormControl id="report-type" onChange={(e) => setReportType(e.target.value)}>
          <FormLabel>Report Type</FormLabel>
          <Select placeholder="Select Report Type">
            {reportTypes.map((item, index) => (
              <option key={`${item}-${index}`}>{item}</option>
            ))}
          </Select>
        </FormControl>
      </Flex>
      {reportType === "Calls - Duration" ? (
        <Flex mx="10px">
          <FormControl id="vertical" onChange={(e) => setVertical(e.target.value)}>
            <FormLabel>Vertical</FormLabel>
            <Select placeholder="Select Vertical">
              {verticalList
                ? verticalList.map((item, index) => (
                    <option key={`${item}-${index}`}>{item}</option>
                  ))
                : null}
              {/* <option>Final Expense</option> */}
            </Select>
          </FormControl>
        </Flex>
      ) : null}
      {/* {reportType === "Calls - Duration" ? (
        <Flex mx="10px">
          <FormControl id="buyer" onChange={(e) => setBuyer(e.target.value)}>
            <FormLabel>Buyer</FormLabel>
            <Select placeholder="Select Buyer">
              {buyerList
                ? buyerList.map((item, index) => <option key={`${item}-${index}`}>{item}</option>)
                : null}
            </Select>
          </FormControl>
        </Flex>
      ) : null} */}
      {/* {!reportType ? (
        <Flex mx="10px">
          <FormControl id="affiliate" onChange={(e) => setAffiliate(e.target.value)}>
            <FormLabel>Affiliate</FormLabel>
            <Select placeholder="Select Affiliate">
              {affiliateList
                ? affiliateList.map((item, index) => (
                    <option key={`${item}-${index}`}>{item}</option>
                  ))
                : null}
            </Select>
          </FormControl>
        </Flex>
      ) : null} */}
      {reportType === "Calls - Duration" ? (
        <Flex mx="10px">
          <FormControl id="start-date">
            <FormLabel>Start Date</FormLabel>
            <Box borderWidth="1px" borderColor="gray.200" borderRadius="7px" p="7px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                value={startDate ? moment(startDate).format("L") : null}
                isClearable
              />
            </Box>
          </FormControl>
        </Flex>
      ) : null}
      {reportType === "Calls - Duration" ? (
        <Flex mx="10px">
          <FormControl id="end-date">
            <FormLabel>End Date</FormLabel>
            <Box borderWidth="1px" borderColor="gray.200" borderRadius="7px" p="7px">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                value={endDate ? moment(endDate).format("L") : null}
                isClearable
              />
            </Box>
          </FormControl>
        </Flex>
      ) : null}
      <Flex alignItems="flex-end">
        <Button colorScheme="purple" onClick={generateReport}>
          Generate
        </Button>
      </Flex>
    </Flex>
  );
}
