import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Icon,
  Th,
  Select,
  Textarea,
  NumberInput,
  NumberInputField,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";

import { FaPhone, FaRegFileAlt } from "react-icons/fa";

export default function FilterEditModal({
  buttonSize,
  header,
  messages,
  alert,
  event,
  icon,
  filter,
  disabled,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(filter.value);

  const submit = async () => {
    // console.log("edit filter:", filter);
    // setLoading(true);
    // await event(filter);
    // setLoading(false);
    onClose();
  };

  const open = () => {
    setValue();
    onOpen();
    setValue(filter.value);
  };

  return (
    <>
      <Tooltip
        label="Cannot edit filters without values."
        shouldWrapChildren
        hasArrow
        isDisabled={!disabled}
      >
        <IconButton
          icon={icon}
          onClick={open}
          size={buttonSize}
          _hover={{ backgroundColor: "purple.300" }}
          isDisabled={disabled}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Flex justifyContent="center" alignItems="center" py="10%">
                <Spinner size="xl" color="teal.300" />
              </Flex>
            ) : (
              <Flex flexDir="column">
                <Flex
                  borderWidth="1px"
                  borderRadius="7px"
                  borderColor="gray.200"
                  p="5px"
                  backgroundColor="white"
                  mb="20px"
                  minH="50px"
                >
                  <Table w="100%" size="sm">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Type</Th>
                        <Th textAlign="center">Field</Th>
                        <Th textAlign="center">Operator</Th>
                        <Th textAlign="center">Compare Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr mb="5px">
                        <Td textAlign="center">
                          {filter.filterType === "data" ? (
                            <Icon color="black" as={FaRegFileAlt} mx="5px" w={5} h={5} />
                          ) : (
                            <Icon color="black" as={FaPhone} mx="5px" w={5} h={5} />
                          )}
                        </Td>

                        <Td color="black" fontSize="16px" px="4px" py="1px" textAlign="center">
                          {filter.field}
                        </Td>

                        <Td
                          color="black"
                          fontSize="16px"
                          px="4px"
                          py="1px"
                          mx="10px"
                          textAlign="center"
                        >
                          {filter.operator}
                        </Td>

                        <Td
                          color="black"
                          fontSize="16px"
                          px="4px"
                          py="1px"
                          textAlign="center"
                          maxW="50px"
                        >
                          {value ? `${value}` : null}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Flex>
                <Flex>
                  <Flex flexDir="column" w="30%">
                    <Text>Type</Text>
                    <Text borderWidth={1} borderRadius={8} p={2}>
                      {filter.filterType}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" w="70%" mx="15px">
                    <Text>Field</Text>
                    <Text placeholder="Select field" borderWidth={1} borderRadius={8} p={2}>
                      {filter.field}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" w="30%">
                    <Text>Operator</Text>
                    <Text borderWidth={1} borderRadius={8} p={2}>
                      {filter.operator}
                    </Text>
                  </Flex>
                  {filter.operator === "in" || filter.operator === "not_in" ? (
                    <Flex flexDir="column" w="100%" ml="15px">
                      <Text>Value</Text>
                      <Textarea
                        size="sm"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={`Separate values with commas.
      Accepted format: AAA,BBB,CCC,DDD,EEE, ...`}
                        defaultValue={value}
                      />
                    </Flex>
                  ) : null}
                  {filter.operator === "greater_than" || filter.operator === "less_than" ? (
                    <Flex flexDir="column" w="60%" ml="15px">
                      <Text>Value</Text>
                      <NumberInput value={value} onChange={(e) => setValue(e)}>
                        <NumberInputField />
                      </NumberInput>
                      {/* <Input onChange={(e) => setValue(e.target.value)} /> */}
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={submit}>
              {loading ? <Spinner /> : "Update"}
            </Button>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
