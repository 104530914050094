import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

import { registerWithEmailAndPassword } from "../../firebase";

export default function CreateNewUserModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleNameChange = (e) => {
    let name = e.target.value;
    setName(name);
  };

  const handlePasswordChange = (e) => {
    let password = e.target.value;
    setPassword(password);
  };

  const handleEmailChange = (e) => {
    let email = e.target.value;
    setEmail(email);
  };

  const resetValues = () => {
    setName("");
    setPassword("");
    setEmail("");
  };

  const submit = async () => {
    await registerWithEmailAndPassword(name, email, password);
    alert("User created successfully.");
    resetValues();
    onClose();
  };

  return (
    <>
      <Button colorScheme="purple" onClick={onOpen} mt="10px">
        Create New User
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="phone" isRequired mb={3} onChange={handleNameChange}>
              <FormLabel>Name</FormLabel>
              <Input type="text" />
            </FormControl>

            <FormControl id="email" isRequired mb={3} onChange={handleEmailChange}>
              <FormLabel>Email</FormLabel>
              <Input type="text" />
            </FormControl>

            <FormControl id="password" isRequired mb={3} onChange={handlePasswordChange}>
              <FormLabel>Password</FormLabel>
              <Input type="text" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={submit}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
