import React from "react";
import { Box, Flex, Text, IconButton, Link, Divider } from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";

import { IoMdOpen } from "react-icons/io";

export default function Resources() {
  const LinkBox = ({ title, link }) => {
    return (
      <Flex
        alignItems="center"
        // justify="center"
        py="10px"
        px="15px"
        h="70px"
        // borderWidth="1px"
        // borderRadius="7px"
        // w="100%"
        my="5px"
      >
        <Link href={link} isExternal mr="20px">
          <IconButton
            colorScheme="teal"
            aria-label="Open Call Details"
            icon={<IoMdOpen />}
            size="sm"
          />
        </Link>
        <Divider orientation="vertical" />
        <Text fontSize="20px" letterSpacing="5px" ml="20px">
          {title}
        </Text>
      </Flex>
    );
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      p={6}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading title="Resources" description="Links to all important resources." />
      <Flex
        justify="center"
        alignItems="flex-start"
        flexDir="column"
        // borderWidth="1px"
        // borderRadius="7px"
      >
        <Flex alignItems="center">
          <Text fontSize="20px" letterSpacing="5px" p="10px" fontWeight={500}>
            NEXLEVEL DIRECT GOOGLE DRIVE{" "}
          </Text>
          <Link
            href="https://drive.google.com/drive/folders/11J_-Mdmk_en-h8kd9kIUjxOhGrd557sG"
            isExternal
            mr="20px"
          >
            <IoMdOpen fontSize="20px" />
          </Link>
        </Flex>
        <LinkBox
          title="DOCUMENTATION"
          link="https://drive.google.com/drive/folders/13-3geCsAws8Z6oykMhhiIlZ6dJttO8rK"
        />
        <LinkBox
          title="FORMS"
          link="https://drive.google.com/drive/folders/1BMkhFXuMz8KIJUVxcP51XMHQ-LMpVzF-"
        />
        <LinkBox
          title="HUMAN RESOURCES"
          link="https://drive.google.com/drive/folders/1PwEnDFqg5DlZhgsli3355Vi_PpVMOlwp"
        />
        <LinkBox
          title="PARTNERS"
          link="https://drive.google.com/drive/folders/1Hfc4Pz-fg8UVQzABKe2_H1X2YZclYvqn"
        />
        <LinkBox
          title="SUPPRESSION LISTS"
          link="https://drive.google.com/drive/folders/12IAPh0YoNrN_xQSJj6FUhIR8qgsUccgB"
        />
      </Flex>
    </Box>
  );
}
