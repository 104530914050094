import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import moment from "moment";

import {
  getCallRoutesWithId,
  updateRoutingWeightPriority,
  getCampaignCaps,
  updateCampaignCaps,
  updateTargetCaps,
} from "../dashboard/DashboardAPIs";

import { RiRouteLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import RingbaLink from "../RingaLink";

const RoutingPlanTable = ({
  routeDetails,
  handleRouteUpdate,
  handleTargetCapsUpdate,
  loadingTargetUpdate,
  active,
}) => {
  const checkCapExists = (cap) => {
    // console.log("routeDetails:", routeDetails);
    if (cap === -1) {
      return 0;
    } else {
      return cap;
    }
  };

  const checkForHourlyCaps = () => {
    if (routeDetails.callTarget) {
      if (routeDetails.callTarget.schedule.callCapMatrix) {
        return routeDetails.callTarget.schedule.callCapMatrix[0][0];
      } else {
        return checkCapExists(routeDetails.callTarget.schedule.hourlyCap);
      }
    }
    if (routeDetails.callPingTree) {
      if (routeDetails.callPingTree.targets[0].schedule.callCapMatrix) {
        return routeDetails.callPingTree.targets[0].schedule.callCapMatrix[0][0];
      } else {
        return checkCapExists(routeDetails.callPingTree.targets[0].schedule.hourlyCap);
      }
    }
  };

  const checkForDailyCaps = () => {
    if (routeDetails.callTarget) {
      if (routeDetails.callTarget.schedule.dailyCapDayOfWeek) {
        return routeDetails.callTarget.schedule.dailyCapDayOfWeek[0];
      } else {
        return checkCapExists(routeDetails.callTarget.schedule.dailyCap);
      }
    }
    if (routeDetails.callPingTree) {
      if (routeDetails.callPingTree.targets[0].schedule.dailyCapDayOfWeek) {
        return routeDetails.callPingTree.targets[0].schedule.dailyCapDayOfWeek[0];
      } else {
        return routeDetails.callPingTree.targets[0].schedule.dailyCap;
      }
    }
  };

  const [priority, setPriority] = useState(routeDetails.priority.priority);
  const [weight, setWeight] = useState(routeDetails.priority.weight);
  const [dailyCap, setDailyCap] = useState(checkForDailyCaps());
  const [hourlyCap, setHourlyCap] = useState(checkForHourlyCaps());
  const [concurrencyCap, setConcurrencyCap] = useState(
    routeDetails.callTarget
      ? checkCapExists(routeDetails.callTarget.schedule.concurrencyCap)
      : checkCapExists(routeDetails.callPingTree.targets[0].schedule.concurrencyCap)
  );

  const firstUpdate = useRef(true);
  // const [dailyCap, setDailyCap] = useState(
  //   routeDetails.callTarget
  //     ? routeDetails.callTarget.schedule.dailyCap
  //     : routeDetails.callPingTree.targets[0].schedule.dailyCap
  // );

  const handlePriorityChange = (value) => setPriority(value);
  const handleWeightChange = (value) => setWeight(value);
  const handleHourlyCapChange = (value) => setHourlyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleDailyCapChange = (value) => setDailyCap(value);

  const handleUpdates = () => {
    handleRouteUpdate(routeDetails.id, priority, weight);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current && priority && weight) handleUpdates();
    // console.log("hello");
  }, [priority, weight]);

  const triggerHandleTargetCapsUpdate = () => {
    handleTargetCapsUpdate(
      routeDetails.callTarget ? false : true,
      routeDetails.callTarget
        ? routeDetails.callTarget.id
        : routeDetails.callPingTree.targets[0].id,
      dailyCap,
      hourlyCap,
      concurrencyCap,
      routeDetails.callTarget ? routeDetails.callTarget.schedule.hoursOfOperation : null
    );
  };

  const logMe = () => {
    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    console.log("routeDetails:", routeDetails);
    if (routeDetails.callTarget) {
      console.log("routeDetails HOO Target:", routeDetails.callTarget.schedule.hoursOfOperation);
    }
    if (routeDetails.callPingTree) {
      console.log(
        "routeDetails HOO Ring Tree:",
        routeDetails.callPingTree.schedule.hoursOfOperation
      );
    }
    // console.log(
    //   "routeDetails.callTarget.schedule.callCapMatrix[0][0]:",
    //   routeDetails.callTarget.schedule.callCapMatrix[0][0]
    // );
    // routeDetails.callTarget
    //   ? console.log("routeDetails.callTarget.schedule:", routeDetails.callTarget.schedule)
    //   : console.log(
    //       "routeDetails.callPingTree.targets[0].schedule:",
    //       routeDetails.callPingTree.targets[0].schedule
    //     );
  };

  return (
    <Box my="10px" key={routeDetails.id}>
      {/* <Button onClick={logMe}>LOG ME</Button> */}
      {routeDetails.callTarget && routeDetails.callTarget.enabled ? (
        <Flex flexDir="column">
          <Text fontWeight={700} textAlign="center" onClick={logMe}>
            {routeDetails.callTarget.name}{" "}
            <RingbaLink type="target" id={routeDetails.callTarget.id} />
          </Text>
          <Text fontWeight={700} textAlign="center">
            DID: {routeDetails.callTarget.instructions.number.substring(1)}
          </Text>
          {!active ? (
            <Text textAlign="center" fontWeight={700} color="red.600">
              (INACTIVE)
            </Text>
          ) : null}
        </Flex>
      ) : null}
      {routeDetails.callPingTree && routeDetails.callPingTree.enabled ? (
        <Flex flexDir="column">
          <Text fontWeight={700} textAlign="center" onClick={logMe}>
            {routeDetails.callPingTree.name}{" "}
            <RingbaLink type="ringtree" id={routeDetails.callPingTree.targets[0].id} />
          </Text>
          <Text fontWeight={700} textAlign="center">
            DID:{" "}
            {routeDetails.callPingTree.targets[0].instructions.number &&
              routeDetails.callPingTree.targets[0].instructions.number.substring(1)}
          </Text>
          {!active ? (
            <Text textAlign="center" fontWeight={700} color="red.600">
              (INACTIVE)
            </Text>
          ) : null}
        </Flex>
      ) : null}

      <Table variant="striped" size="sm" mb="10px">
        <Thead mt="5px">
          <Tr>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Daily
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Hourly
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              CC
            </Th>
            <Th textAlign="center" w="10%" color={!active ? "gray.600" : null}>
              Update
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Priority
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Weight
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {routeDetails.callTarget || routeDetails.callPingTree ? (
            <>
              <Tr>
                {/* <Td textAlign="center">{dailyCap === -1 ? 0 : dailyCap}</Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={dailyCap}
                    onChange={handleDailyCapChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                {/* <Td textAlign="center">{hourlyCap}</Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={hourlyCap}
                    onChange={handleHourlyCapChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                {/* <Td textAlign="center">{concurrencyCap}</Td> */}

                {/* <Td textAlign="center" fontWeight={600} letterSpacing="1px">
                  UPDATE
                </Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={concurrencyCap}
                    onChange={handleConcurrencyChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                <Td textAlign="center" maxW="20px">
                  <IconButton
                    isLoading={loadingTargetUpdate}
                    colorScheme="teal"
                    aria-label="Open Call Details"
                    icon={<FiUploadCloud />}
                    size="sm"
                    onClick={triggerHandleTargetCapsUpdate}
                    isDisabled={!active}
                  />
                </Td>

                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={priority}
                    onChange={handlePriorityChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
                <Td textAlign="center" maxW="20px">
                  <NumberInput value={weight} onChange={handleWeightChange} isDisabled={!active}>
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
              </Tr>

              {/* <Tr>
                <Td textAlign="center"></Td>
                <Td textAlign="center">
                  <NumberInput value={concurrencyCap} onChange={handleConcurrencyChange}>
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
                <Td textAlign="center">
                  <IconButton
                    colorScheme="teal"
                    aria-label="Open Call Details"
                    icon={<FiUploadCloud />}
                    size="sm"
                    onClick={() => handleTargetConcurrencyUpdate()}
                  />
                </Td>
              </Tr> */}
            </>
          ) : null}
        </Tbody>

        {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
      </Table>
    </Box>
  );
};

export default function RoutesModal({ campaignName }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState();
  const [dailyCap, setDailyCap] = useState();
  const [concurrencyCap, setConcurrencyCap] = useState();
  const [hourlyCap, setHourlyCap] = useState();
  const [loadingTargetUpdate, setLoadingTargetUpdate] = useState(false);
  const [loadingCampaignUpdate, setLoadingCampaignUpdate] = useState(false);

  const handleDailyCapChange = (value) => setDailyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleHourlyCapChange = (value) => setHourlyCap(value);

  const handleCallDetails = async () => {
    onOpen();
    setNoData(false);
    getRoutes();
    getCampaignData();
  };

  const getRoutes = async () => {
    if (data) setData(null);
    setLoading(true);
    try {
      const data = await getCallRoutesWithId(campaignName);
      // console.log("getRoutes data:", data);
      if (!data) {
        setLoading(false);
        setNoData(true);
        return;
      }
      setData(data);
      // console.log("routes data::", data);
      setLoading(false);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  const handleRouteUpdate = async (callRouteId, priority, weight) => {
    try {
      const updateStatus = await updateRoutingWeightPriority(callRouteId, priority, weight);
      if (updateStatus) {
        console.log("Weight and Priority updated successfully!");
        // alert(
        //   `Priority and Weight has been updated successfully:
        //   Priority: ${updateStatus.priority}
        //   Weight: ${updateStatus.weight}`
        // );
      } else {
        alert("There was an error.");
      }
    } catch (error) {
      alert(error.message);
    }
    // alert("ID:" + callRouteId + "Priority:" + priority + "Weight:" + weight);
  };

  const handleTargetCapsUpdate = async (rt, targetId, dailyCap, hourlyCap, concurrencyCap, hoo) => {
    setLoadingTargetUpdate(true);

    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    // console.log("hoo:", hoo);

    try {
      const response = await updateTargetCaps(
        rt,
        targetId,
        dailyCap,
        hourlyCap,
        concurrencyCap,
        hoo
      );
      if (response) {
        alert("Target Caps Updated Successfully!");
        setLoadingTargetUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingTargetUpdate(false);
    }
  };

  const handleCampaignCapUpdate = async (
    campaignId,
    affiliateId,
    payoutId,
    payoutAmount,
    callLength
  ) => {
    setLoadingCampaignUpdate(true);
    const caps = {
      dailyCap,
      concurrencyCap,
      hourlyCap,
    };
    try {
      const response = await updateCampaignCaps(
        campaignId,
        affiliateId,
        payoutId,
        caps,
        payoutAmount,
        callLength
      );
      if (response) {
        alert("Campaign caps were updated successfully.");
        getCampaignData();
        setLoadingCampaignUpdate(false);
      } else {
        alert("There seems to have been an error.");
      }
    } catch (error) {
      alert(error.message);
      setLoadingCampaignUpdate(false);
    }
  };

  const getCampaignData = async () => {
    try {
      const campaignInfo = await getCampaignCaps(campaignName);
      setCampaignData(campaignInfo);
    } catch (error) {
      alert(error.message);
    }
  };

  const setCaps = () => {
    if (campaignData && campaignData.affiliates[0].payoutConversionSettings.length > 0) {
      setDailyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].dailyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap
      );
      setConcurrencyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap
      );
      setHourlyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap
      );
    }
  };

  const logMe = () => {
    // console.log("dailyCap:", dailyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    console.log("campaignData:", campaignData);
  };

  useEffect(() => {
    setCaps();
  }, [campaignData]);

  return (
    <>
      <IconButton
        colorScheme="teal"
        onClick={handleCallDetails}
        aria-label="Open Call Details"
        icon={<RiRouteLine />}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          {/* <Button onClick={logMe}>CLICK</Button> */}
          <ModalHeader>
            <Flex flexDir="column">
              <Text onClick={logMe}>
                {campaignName}
                {campaignData ? <RingbaLink type="campaign" id={campaignData.id} /> : null}
              </Text>
              {campaignData ? (
                <Text>DID: {campaignData.affiliateNumbers[0].displayNumber}</Text>
              ) : null}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {campaignData && campaignData.affiliates[0].payoutConversionSettings.length > 0 ? (
              <Flex flexDir={"column"}>
                <Text
                  fontWeight={500}
                  letterSpacing="3px"
                  textAlign="center"
                  backgroundColor="gray.400"
                >
                  CAMPAIGN CAPS
                </Text>
                <Table variant="striped" size="sm" mb="10px">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Daily</Th>
                      <Th textAlign="center">Hourly</Th>
                      <Th textAlign="center">CC</Th>
                      <Th textAlign="center"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td textAlign="center">
                        {campaignData.affiliates[0].payoutConversionSettings[0].dailyCap === -1
                          ? 0
                          : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap}
                      </Td>
                      <Td textAlign="center">
                        {campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap === -1
                          ? 0
                          : campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap}
                      </Td>
                      <Td textAlign="center">
                        {campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap ===
                        -1
                          ? 0
                          : campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap}
                      </Td>
                      <Td textAlign="center" fontWeight={600} letterSpacing="1px">
                        UPDATE
                      </Td>
                    </Tr>
                    <Tr>
                      <Td textAlign="center">
                        <NumberInput value={dailyCap} onChange={handleDailyCapChange}>
                          <NumberInputField
                            focusBorderColor="teal"
                            size="sm"
                            borderWidth="2px"
                            textAlign="center"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Td>
                      <Td textAlign="center">
                        <NumberInput value={hourlyCap} onChange={handleHourlyCapChange}>
                          <NumberInputField
                            focusBorderColor="teal"
                            size="sm"
                            borderWidth="2px"
                            textAlign="center"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Td>
                      <Td textAlign="center">
                        <NumberInput value={concurrencyCap} onChange={handleConcurrencyChange}>
                          <NumberInputField
                            focusBorderColor="teal"
                            size="sm"
                            borderWidth="2px"
                            textAlign="center"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Td>
                      <Td textAlign="center">
                        <IconButton
                          isLoading={loadingCampaignUpdate}
                          colorScheme="teal"
                          aria-label="Open Call Details"
                          icon={<FiUploadCloud />}
                          size="sm"
                          onClick={() =>
                            handleCampaignCapUpdate(
                              campaignData.id,
                              campaignData.affiliates[0].id,
                              campaignData.affiliates[0].payoutConversionSetting.id,
                              campaignData.affiliates[0].payoutConversionSetting.payoutAmount,
                              campaignData.affiliates[0].payoutConversionSetting
                                .payoutConversionArgs.callLengthInSeconds
                            )
                          }
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Flex>
            ) : (
              <Flex my="10%" justify="center">
                <Spinner
                  color="green.400"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  size="xl"
                />
              </Flex>
            )}
            <Flex flexDir="column" justify="flex-start">
              {loading ? (
                <Flex mt="10%" justify="center">
                  <Spinner
                    color="green.400"
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    size="xl"
                  />
                </Flex>
              ) : null}
              {noData ? (
                <Flex mt="10%" justify="center">
                  <Text>No Routes Found.</Text>
                </Flex>
              ) : null}
              {data ? (
                <Text
                  fontWeight={500}
                  letterSpacing="3px"
                  textAlign="center"
                  backgroundColor="gray.400"
                  mt="10px"
                >
                  TARGET ROUTING & CAPS
                </Text>
              ) : null}
              {data
                ? data.routes.map((routeDetails, i) => (
                    <RoutingPlanTable
                      key={
                        routeDetails.callTarget
                          ? routeDetails.callTarget
                          : routeDetails.callPingTree
                      }
                      routeDetails={routeDetails}
                      handleRouteUpdate={handleRouteUpdate}
                      handleTargetCapsUpdate={handleTargetCapsUpdate}
                      loadingTargetUpdate={loadingTargetUpdate}
                      active={
                        (routeDetails.callTarget && routeDetails.callTarget.enabled) ||
                        (routeDetails.callPingTree && routeDetails.callPingTree.enabled)
                      }
                    />
                  ))
                : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
