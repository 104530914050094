import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
  Textarea,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  UnorderedList,
  ListItem,
  ModalFooter,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaPhone, FaRegFileAlt } from "react-icons/fa";
import { FaPenAlt } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ReactPlayer from "../ReactPlayer";

const ValidationErrors = ({ event, icon }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Icon
        as={icon}
        w={8}
        h={8}
        mr="10px"
        color={!event.valid_boolean ? "yellow.400" : null}
        cursor="pointer"
        onClick={onOpen}
        _hover={{ transform: "scale(1.2)" }}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          <ModalHeader>Validation Errors</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList>
              {event && event.validation_errors.length > 0
                ? event.validation_errors.map((error, i) => (
                    <ListItem key={`${error}-${i}`}>{error}</ListItem>
                  ))
                : null}
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const NoteTableRow = ({ note, cluster_id, event, updateEventNoteFrontEnd, setNotes }) => {
  const [loadingNoteDelete, setLoadingNoteDelete] = useState(false);
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const deleteNote = async (note_id) => {
    setLoadingNoteDelete(true);

    const data = {
      cluster_id,
      event_id: event.event_id,
      note_id,
    };

    // console.log("deleteNote data:", data);

    try {
      const response = await axios.post("/api/mortgage/leads/delete-event-note", data);
      // console.log("deleteNote response:", response);
      if (response.status === 200) {
        updateEventNoteFrontEnd(data, "delete");
      }

      setLoadingNoteDelete(false);
      setNotes("");
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Tr key={note.note_id} _hover={{ backgroundColor: hoverColor }}>
      <Td textAlign="center" w="20%">
        {note.date && moment(note.date).format("l LT")}
      </Td>
      <Td textAlign="center" w="50%">
        {note.notes}
      </Td>
      <Td textAlign="center" w="20%">
        {note.createdBy}
      </Td>
      <Td textAlign="center" w="10%">
        <IconButton
          onClick={() => deleteNote(note.note_id)}
          cursor="pointer"
          icon={!loadingNoteDelete ? <BsTrash /> : <Spinner size="xs" />}
          colorScheme="teal"
          size="xs"
        />
      </Td>
    </Tr>
  );
};

export default function MortgageQaModal({
  tsTime,
  tsDate,
  buyerName,
  pubName,
  icon,
  duration,
  formatDuration,
  event,
  cluster_id,
  clusterData,
  url,
  leadName,
  pullUpdatedMortgageClusters,
  username,
  updateEventNoteFrontEnd,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState();
  const [loadingNote, setLoadingNote] = useState(false);
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const submit = async () => {
    addNewNote();
  };

  const addNewNote = async () => {
    setLoadingNote(true);
    console.log(event);
    const data = {
      cluster_id,
      event_id: event.event_id,
      note: {
        date: moment().format(),
        notes: notes,
        note_id: `note-${uuidv4()}`,
        createdBy: username,
      },
    };

    console.log("addNewNote data:", data);

    try {
      const response = await axios.post("/api/mortgage/leads/add-event-note", data);
      console.log("addNewNote response:", response);
      if (response.status === 200) {
        updateEventNoteFrontEnd(data, "add");
      } else {
        alert("There was an error.");
      }
      setLoadingNote(false);
      setNotes("");
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Tr _hover={{ backgroundColor: hoverColor }} onClick={onOpen} cursor="pointer">
        <Td w="10%">
          <Flex flexDir="column">
            <Text textAlign="center">{tsDate}</Text>
            <Text textAlign="center">{tsTime}</Text>
          </Flex>
        </Td>
        <Td textAlign="center" w="10%">
          {event.validation_errors.length > 0 ? (
            <Tooltip label={event.validation_errors} hasArrow placement="top" shouldWrapChildren>
              <Icon
                as={icon}
                w={8}
                h={8}
                mr="10px"
                color="yellow.400"
                _hover={{ color: "yellow" }}
              />
            </Tooltip>
          ) : (
            <Icon as={icon} w={8} h={8} mr="10px" />
          )}
        </Td>

        <Td textAlign="center" whiteSpace="nowrap" w="25%">
          {buyerName}
        </Td>
        <Td textAlign="center" w="25%">
          {pubName}
        </Td>
        <Td textAlign="center" w="10%">
          <Text>{event.event_type === "lead" ? event.data.leadID : null}</Text>
        </Td>
        <Td textAlign="center" w="10%">
          {duration ? formatDuration(duration) : null}
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent overflow="scroll">
          <ModalHeader>Quality Assurance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" h="800px">
              <Box borderWidth={2} borderRadius={8} mb="20px">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Campaign</Th>
                      <Th textAlign="center">Lead Name</Th>
                      <Th textAlign="center">CID</Th>
                      <Th textAlign="center">Issues</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr w="100%" justifyContent="space-evenly" flexDir="column">
                      <Td textAlign="center">{clusterData.campaign_name}</Td>
                      <Td textAlign="center" style={{ textTransform: "capitalize" }}>
                        {leadName ? leadName : null}
                      </Td>
                      <Td textAlign="center">{clusterData.lead_cid}</Td>
                      <Td textAlign="center">
                        {clusterData.info.missing_call ? (
                          <Icon w={5} h={6} mx="5px" color="red.500" as={FaPhone} />
                        ) : null}
                        {clusterData.info.missing_data ? (
                          <Icon w={5} h={6} mx="5px" color="red.500" as={FaRegFileAlt} />
                        ) : null}
                        {!clusterData.info.missing_call && !clusterData.info.valid_call ? (
                          <Icon w={5} h={6} mx="5px" color="yellow.300" as={FaPhone} />
                        ) : null}
                        {!clusterData.info.missing_data && !clusterData.info.valid_data ? (
                          <Icon w={5} h={6} mx="5px" color="yellow.300" as={FaRegFileAlt} />
                        ) : null}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>

              <Flex flexDir="column" my="10px">
                <Text>Notes</Text>
                <Flex p={5} flexDir="column" borderRadius="7px" borderWidth={1}>
                  <Flex
                    borderRadius="7px"
                    borderWidth={1}
                    p="5px"
                    mb="20px"
                    minH="100px"
                    flexDir="column"
                  >
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th textAlign="center" w="20%">
                            Date
                          </Th>
                          <Th textAlign="center" w="50%">
                            Note
                          </Th>
                          <Th textAlign="center" w="20%">
                            Created By
                          </Th>
                          <Th textAlign="center" w="10%">
                            Action
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {event.qa_notes.map((note) => (
                          <NoteTableRow
                            key={note.note_id}
                            note={note}
                            cluster_id={cluster_id}
                            event={event}
                            updateEventNoteFrontEnd={updateEventNoteFrontEnd}
                            setNotes={setNotes}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </Flex>
                  <Flex flexDir="column">
                    <Text>New Note</Text>
                    <Flex alignItems="center">
                      <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
                      <Button colorScheme="teal" ml="15px" onClick={submit}>
                        {loadingNote ? <Spinner size="sm" /> : "Add"}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {event.event_type === "call" ? (
                <Flex my="10px" flexDir="column">
                  <Tabs>
                    <TabList>
                      <Tab>Recording</Tab>
                      <Tab color={event && event.validation_errors.length > 0 ? "red" : null}>
                        Issues
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Box borderWidth={1} borderRadius={8} px={4} py={10}>
                          {url ? (
                            <ReactPlayer url={url} />
                          ) : (
                            // <Media>
                            //   <div className="media">
                            //     <Box className="media-player" display="none" backgroundColor="red">
                            //       <Player src={url} />
                            //     </Box>
                            //     <Flex
                            //       className="media-controls"
                            //       justifyContent="space-evenly"
                            //       alignItems="center"
                            //     >
                            //       <Box _hover={{ color: "teal.300" }}>
                            //         <PlayPause />
                            //       </Box>
                            //       <CurrentTime />
                            //       <SeekBar />
                            //       <Duration />
                            //     </Flex>
                            //   </div>
                            // </Media>
                            <Flex alignItems="center" justify="center">
                              <Text>No Call Recording</Text>
                            </Flex>
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Flex flexDir="column" h="600px">
                          <UnorderedList>
                            {event && event.validation_errors.length > 0
                              ? event.validation_errors.map((error, i) => (
                                  <ListItem key={`${error}-${i}`}>{error}</ListItem>
                                ))
                              : null}
                          </UnorderedList>
                        </Flex>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Flex>
              ) : null}
              {event.event_type === "lead" ? (
                <Tabs>
                  <TabList>
                    <Tab>Lead Info</Tab>
                    <Tab>Lead Data</Tab>
                    <Tab color={event && event.validation_errors.length > 0 ? "red" : null}>
                      Issues
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Flex flexDir="column" h="600px">
                        <Flex flexDir="column" p={4}>
                          <Flex>
                            <Box borderWidth={1} p={3} mb="20px" mr="15px">
                              <Text backgroundColor="gray.400" p={1}>
                                Lead Information
                              </Text>
                              <Table size="sm">
                                <Thead>
                                  <Tr>
                                    <Th textAlign="center">Lead ID</Th>
                                    <Th textAlign="center">Created</Th>
                                    <Th textAlign="center">IP</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <Tr>
                                    <Td textAlign="center">{event.data.leadID}</Td>
                                    <Td textAlign="center">{event.data.createdOn}</Td>
                                    <Td textAlign="center">{event.data.ipAddress}</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Box>

                            <Box borderWidth={1} p={3} mb="20px">
                              <Text backgroundColor="gray.400" p={1}>
                                Tracking Information
                              </Text>
                              <Table size="sm">
                                <Thead>
                                  <Tr>
                                    <Th textAlign="center">Affiliate</Th>
                                    <Th textAlign="center">Campaign</Th>
                                    <Th textAlign="center">Offer</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <Tr>
                                    <Td textAlign="center">{event.data.affiliateName}</Td>
                                    <Td textAlign="center">{event.data.campaignName}</Td>
                                    <Td textAlign="center">{event.data.offerName}</Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Box>
                          </Flex>

                          <Box borderWidth={1} p={3} mb="20px">
                            <Text backgroundColor="gray.400" p={1}>
                              Contact Information
                            </Text>
                            <Table size="sm">
                              <Thead>
                                <Tr>
                                  <Th textAlign="center">First Name</Th>
                                  <Th textAlign="center">Last Name</Th>
                                  <Th textAlign="center">DOB</Th>
                                  <Th textAlign="center">Home Phone</Th>
                                  <Th textAlign="center">Email</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td textAlign="center" w="20%">
                                    {event.data.first_name}
                                  </Td>
                                  <Td textAlign="center" w="20%">
                                    {event.data.last_name}
                                  </Td>
                                  <Td textAlign="center" w="20%">
                                    {event.data.dob}
                                  </Td>
                                  <Td textAlign="center" w="20%">
                                    {event.data.day_phone}
                                  </Td>
                                  <Td textAlign="center" w="20%">
                                    {event.data.email_address}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>

                          <Box borderWidth={1} p={3} mb="20px">
                            <Text backgroundColor="gray.400" p={1}>
                              Address Information
                            </Text>
                            <Table size="sm" mb="20px">
                              <Thead>
                                <Tr>
                                  <Th textAlign="center">Address</Th>
                                  <Th textAlign="center">City</Th>
                                  <Th textAlign="center">State</Th>
                                  <Th textAlign="center">Zip</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td textAlign="center">{event.data.address}</Td>
                                  <Td textAlign="center">{event.data.city}</Td>
                                  <Td textAlign="center">{event.data.state}</Td>
                                  <Td textAlign="center">{event.data.zip_code}</Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>

                          <Box borderWidth={1} p={3} mb="20px">
                            <Text backgroundColor="gray.400" p={1}>
                              TCPA Compliance
                            </Text>
                            <Table size="sm" mb="20px">
                              <Thead>
                                <Tr>
                                  <Th textAlign="center">Jornaya Lead ID</Th>
                                  <Th textAlign="center">Trusted Form</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td textAlign="center" w="50%">
                                    {event.data.jornaya_leadid}
                                  </Td>
                                  <Td textAlign="center" w="50%">
                                    {event.data.trustedform_cert_id}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>
                          <Flex mb="20px">
                            <Flex flexDir="column" w="50%" mr="20px">
                              <Text p={1} backgroundColor="gray.400">
                                Credit Score
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.credit_dropdown}
                              </Text>
                            </Flex>
                            <Flex flexDir="column" w="50%">
                              <Text p={1} backgroundColor="gray.400">
                                Interest Rate
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.interest_rate}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex mb="20px">
                            <Flex flexDir="column" w="50%" mr="20px">
                              <Text p={1} backgroundColor="gray.400">
                                Debt Amount
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.debt_amount}
                              </Text>
                            </Flex>
                            <Flex flexDir="column" w="50%">
                              <Text p={1} backgroundColor="gray.400">
                                Loan Amount
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.loan_amount}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex mb="20px">
                            <Flex flexDir="column" w="50%" mr="20px">
                              <Text p={1} backgroundColor="gray.400">
                                Cash Out
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.cash_out}
                              </Text>
                            </Flex>
                            <Flex flexDir="column" w="50%">
                              <Text p={1} backgroundColor="gray.400">
                                Agent ID
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.agent_id}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex mb="20px">
                            <Flex flexDir="column" w="50%" mr="20px">
                              <Text p={1} backgroundColor="gray.400">
                                Property Type
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.property_type}
                              </Text>
                            </Flex>
                            <Flex flexDir="column" w="50%">
                              <Text p={1} backgroundColor="gray.400">
                                Home Value
                              </Text>
                              <Text p={2} borderWidth={1} h="45px">
                                {event.data.home_value}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>
                    <TabPanel>
                      <Flex flexDir="column" h="600px">
                        <Table size="sm">
                          <Thead>
                            <Tr>
                              <Th color="white" backgroundColor="gray.400" py="10px">
                                Field
                              </Th>
                              <Th></Th>
                              <Th color="white" backgroundColor="gray.400" py="10px">
                                Value
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Object.entries(event.data).map((field) => (
                              <Tr key={`${field}`} _hover={{ backgroundColor: "gray.400" }}>
                                <Td w="49%">{field[0]}</Td>
                                <Td w="2%"></Td>
                                <Td w="49%">{field[1]}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    </TabPanel>
                    <TabPanel color="red">
                      <Flex flexDir="column" h="600px">
                        <UnorderedList>
                          {event && event.validation_errors.length > 0
                            ? event.validation_errors.map((error, i) => (
                                <ListItem key={`${error}-${i}`}>{error}</ListItem>
                              ))
                            : null}
                        </UnorderedList>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              ) : null}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
