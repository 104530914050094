import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import moment from "moment";

import { IoMdOpen } from "react-icons/io";

export default function CallRoutingDetails({ callDetails }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCallDetails = async () => {
    onOpen();
    console.log(callDetails);
  };

  return (
    <>
      <IconButton
        colorScheme="purple"
        onClick={handleCallDetails}
        aria-label="Open Call Details"
        icon={<IoMdOpen />}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          <ModalHeader>Routing Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justify="flex-start">
              {callDetails
                ? callDetails.routes.map((routeDetails, i) => (
                    <Box my="20px" key={routeDetails.id}>
                      {routeDetails.callTarget ? (
                        <Text fontWeight={700}>{routeDetails.callTarget.name}</Text>
                      ) : null}
                      {routeDetails.callPingTree ? (
                        <Text fontWeight={700}>{routeDetails.callPingTree.name}</Text>
                      ) : null}
                      <Table variant="striped" size="sm" mb="20px">
                        <Thead>
                          <Tr>
                            <Th textAlign="left">Priority</Th>
                            <Th textAlign="left">Weight</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {routeDetails.priority ? (
                            <Tr>
                              <Td textAlign="left">{routeDetails.priority.priority}</Td>
                              <Td textAlign="left">{routeDetails.priority.weight}</Td>
                            </Tr>
                          ) : null}
                        </Tbody>
                      </Table>
                      <Divider />
                    </Box>
                  ))
                : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
