import {
  useDisclosure,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useColorModeValue,
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Textarea,
  Input,
  IconButton,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Select from "react-select";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { colors } from "../../config/colors";

import states from "./states.json";

import { HiDocumentReport } from "react-icons/hi";

import {
  QaCallReportInternalCategoryChart,
  QaCallReportInternalDispoChart,
} from "./QaCallReportInternalChart";

const formatDuration = (duration) => {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

const TableRow = ({ call }) => {
  return (
    <Tr
      _hover={{ backgroundColor: "gray.500" }}
      borderRightWidth={1}
      onClick={() => console.log(call)}
    >
      <Td color="white" textAlign="center">
        {moment
          .tz(call.call_date, "America/Los_Angeles")
          // .subtract(1, "hour")
          .format("l LTS")}
      </Td>
      <Td color="white" textAlign="center">
        {call.buyer}
      </Td>
      <Td color="white" textAlign="center">
        {call.target}
      </Td>
      <Td color="white" textAlign="center">
        {call.campaign}
      </Td>
      <Td color="white" textAlign="center">
        {call.publisher}
      </Td>
      <Td color="white" textAlign="center">
        {call.revenue}
      </Td>
      <Td color="white" textAlign="center">
        {call.payout}
      </Td>
      <Td color="white" textAlign="center">
        {call.state}
      </Td>
      <Td color="white" textAlign="center">
        {call.cid}
      </Td>
      <Td
        color="white"
        backgroundColor={call.color}
        textAlign="center"
        onClick={() => console.log(call)}
      >
        {call.category}
      </Td>
      <Td color="white" backgroundColor={call.color}>
        {call.disposition}
      </Td>
      <Td color="white" textAlign="center">
        {call.duration}
      </Td>
      <Td color="white">
        {call.notes.map((note) => (
          <Text>{note}</Text>
        ))}
      </Td>
    </Tr>
  );
};

export default function QaCallReportInternal({
  data,
  filteredData,
  insertCallsLoading,
  callsLoading,
  loading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [csvData, setCsvData] = useState();
  const [dataSummary, setDataSummary] = useState();
  const [totalQaCalls, setTotalQaCalls] = useState(0);
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#2D3748");
  // const [billableType, setBillableType] = useState();
  const [filteredReportData, setFilteredReportData] = useState();

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
      width: "100%",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : "#1A202C",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      color: fontColor,
      backgroundColor: "#1A202C",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      color: fontColor,
    }),
  };

  const allCallsOrBillableChoices = [
    { name: "All Calls", label: "All Calls" },
    { name: "Billable Calls", label: "Billable Calls" },
    { name: "Non-Billable Calls", label: "Non-Billable Calls" },
  ];

  const handleBillableTypeChange = (billableType) => {
    // console.log("billable type:", e);
    // setBillableType(e);
    // let summaryData;
    const filteredDataSet = filterReport(billableType.name);
    setFilteredReportData(filteredDataSet);
    // if (filteredReportData) {
    //   console.log("filteredData!");
    //   summaryData = generateSummaryReport(filteredDataSet.sort(sortByDispo));
    // } else {
    //   console.log("csvData!");
    //   summaryData = generateSummaryReport(csvData.sort(sortByDispo));
    // }

    // console.log("summaryData:", summaryData);
    // setDataSummary(summaryData);
  };

  const handleOpen = () => {
    onOpen();
    generateInternalReport();
  };

  const handleClose = () => {
    setFilteredReportData();
    onClose();
  };

  const generateInternalReport = () => {
    const newCvsData = [];

    let callDate;
    let vertical;
    let buyer;
    let target;
    let campaign;
    let transferType;
    let publisher;
    let revenue;
    let payout;
    let cid;
    let state;
    let category;
    let disposition;
    let sortPosition;
    let color;
    let duration;
    let notes;

    if (filteredData) {
      // console.log("filteredData::", filteredData);
      filteredData.forEach((call) => {
        const qaNotes = [];

        if (call.qa.notes.length > 0) {
          call.qa.notes.forEach((note, i) => {
            qaNotes.push(note.note);
          });
        }

        callDate = call.call_timestamp;
        vertical = call.vertical;
        buyer = call.buyer_name;
        target = call.target_name;
        campaign = call.campaign_name;
        transferType = call.campaign_name.split("-")[1];
        publisher = call.publisher_name;
        revenue = call.call_revenue;
        payout = call.call_payout;
        cid = call.call_callerid;
        const areaCode = `${cid[1]}${cid[2]}${cid[3]}`;
        // console.log("areaCode:", areaCode);
        // console.log("state[areaCode]:", state[areaCode] ? state[areaCode] : "N/A");
        state = states[areaCode] ? states[areaCode] : null;
        // console.log("state:", state);
        category = call.qa.category;
        disposition = call.qa.disposition;
        color = call.qa.color;
        if (category === "Excellent") sortPosition = 1;
        if (category === "Satisfactory") sortPosition = 2;
        if (category === "Poor") sortPosition = 3;
        if (category === "Very Poor") sortPosition = 4;
        if (category === "Client Issues") sortPosition = 5;
        if (category === "Miscellaneous") sortPosition = 6;
        if (!category) sortPosition = 7;
        duration = formatDuration(call.call_length);
        notes = qaNotes;

        const newCallData = {
          call_date: callDate,
          vertical,
          buyer,
          target,
          campaign,
          transferType,
          publisher,
          revenue,
          payout,
          cid,
          state,
          category,
          disposition,
          sortPosition,
          color,
          duration,
          notes,
        };

        newCvsData.push(newCallData);
      });
    } else {
      // console.log("data::", data);
      data.forEach((call) => {
        const qaNotes = [];

        if (call.qa.notes.length > 0) {
          call.qa.notes.forEach((note, i) => {
            qaNotes.push(note.note);
          });
        }

        callDate = call.call_timestamp;
        vertical = call.vertical;
        buyer = call.buyer_name;
        target = call.target_name;
        campaign = call.campaign_name;
        transferType = call.campaign_name.split("-")[1];
        publisher = call.publisher_name;
        revenue = call.call_revenue;
        payout = call.call_payout;
        cid = call.call_callerid;
        const areaCode = `${cid[1]}${cid[2]}${cid[3]}`;
        // console.log("areaCode:", areaCode);
        // console.log("state[areaCode]:", state[areaCode] ? state[areaCode] : "N/A");
        state = states[areaCode] ? states[areaCode] : null;
        // console.log("state:", state);
        category = call.qa.category;
        disposition = call.qa.disposition;
        color = call.qa.color;
        if (category === "Excellent") sortPosition = 1;
        if (category === "Satisfactory") sortPosition = 2;
        if (category === "Poor") sortPosition = 3;
        if (category === "Very Poor") sortPosition = 4;
        if (category === "Client Issues") sortPosition = 5;
        if (category === "Miscellaneous") sortPosition = 6;
        if (!category) sortPosition = 7;
        duration = formatDuration(call.call_length);
        notes = qaNotes;

        const newCallData = {
          call_date: callDate,
          vertical,
          buyer,
          target,
          campaign,
          transferType,
          publisher,
          revenue,
          payout,
          cid,
          state,
          category,
          disposition,
          sortPosition,
          color,
          duration,
          notes,
        };

        newCvsData.push(newCallData);
      });
    }
    const summaryData = generateSummaryReport(newCvsData.sort(sortByDispo));

    // console.log("summaryData:", summaryData);
    setDataSummary(summaryData);

    setCsvData(newCvsData.sort(sortByDispo));
  };

  const generateSummaryReport = (summaryData) => {
    // console.log("generateSummaryReport summaryData::", summaryData);
    if (!summaryData) return;

    let categoryData = [
      { excellent: [] },
      { satisfactory: [] },
      { poor: [] },
      { veryPoor: [] },
      { clientIssues: [] },
      { miscellaneous: [] },
    ];

    summaryData.forEach((call) => {
      if (call.category === "Excellent") categoryData[0].excellent.push(call.disposition);
      if (call.category === "Satisfactory") categoryData[1].satisfactory.push(call.disposition);
      if (call.category === "Poor") categoryData[2].poor.push(call.disposition);
      if (call.category === "Very Poor") categoryData[3].veryPoor.push(call.disposition);
      if (call.category === "Client Issues") categoryData[4].clientIssues.push(call.disposition);
      if (call.category === "Miscellaneous") categoryData[5].miscellaneous.push(call.disposition);
    });

    const dispositionData = [
      {
        name: "Dispositions",
        excellent: countDispoType(categoryData[0].excellent, "excellent"),
        satisfactory: countDispoType(categoryData[1].satisfactory, "satisfactory"),
        poor: countDispoType(categoryData[2].poor, "poor"),
        veryPoor: countDispoType(categoryData[3].veryPoor, "very_poor"),
        clientIssues: countDispoType(categoryData[4].clientIssues, "client_issues"),
        miscellaneous: countDispoType(categoryData[5].miscellaneous, "miscellaneous"),
      },
    ];

    const dispositionCounts = [
      sortByDispoCounts(dispositionData[0].excellent),
      sortByDispoCounts(dispositionData[0].satisfactory),
      sortByDispoCounts(dispositionData[0].poor),
      sortByDispoCounts(dispositionData[0].veryPoor),
      sortByDispoCounts(dispositionData[0].clientIssues),
      sortByDispoCounts(dispositionData[0].miscellaneous),
    ];

    // const sortedDispoCounts = sortByDispoCounts(dispositionCounts);

    const categoryCounts = [
      {
        name: "Disposition Categories",
        excellent: categoryData[0].excellent.length,
        satisfactory: categoryData[1].satisfactory.length,
        poor: categoryData[2].poor.length,
        very_poor: categoryData[3].veryPoor.length,
        client_issues: categoryData[4].clientIssues.length,
        miscellaneous: categoryData[5].miscellaneous.length,
      },
    ];

    // console.log("categoryCounts::", categoryCounts);
    // console.log("categoryData::", categoryData);
    // console.log("dispositionData::", dispositionData);
    // console.log("dispositionCounts::", dispositionCounts);
    // console.log("sortedDispoCounts::", sortedDispoCounts);

    return { categoryCounts, dispositionCounts };
  };

  const filterReport = (billableType) => {
    let filterData;

    if (billableType === "All Calls") {
      filterData = [...csvData];
    }
    if (billableType === "Billable Calls") {
      filterData = csvData.filter((call) => {
        // console.log("billable call:", call);
        return call.revenue;
      });
    }
    if (billableType === "Non-Billable Calls") {
      filterData = csvData.filter((call) => {
        return !call.revenue;
      });
    }
    // console.log("filterData:", filterData);
    return filterData;
  };

  const countDispoType = (dispoData, category) => {
    // console.log("dispoData:", dispoData);
    const counts = {
      name: category,
    };
    dispoData.forEach((category) => {
      counts[category] = (counts[category] || 0) + 1;
    });
    return counts;
  };

  const sortByDispoCounts = (sortableData) => {
    const sortable = Object.fromEntries(Object.entries(sortableData).sort(([, a], [, b]) => b - a));

    // console.log("sortable::", sortable);
    return sortable;
  };

  const [asc, setAsc] = useState(true);

  const sortTheData = () => {
    const dataCopy = [...csvData];

    dataCopy.sort(sortByDispo);

    setCsvData(dataCopy);
  };

  const sortByDispo = (a, b) => {
    // return a.category?.localeCompare(b.category) || a.disposition < b.disposition;
    if (a.sortPosition < b.sortPosition) {
      return -1;
    }
    if (a.sortPosition > b.sortPosition) {
      return 1;
    }
    if (a.disposition < b.disposition) {
      return -1;
    }
    if (a.disposition > b.disposition) {
      return 1;
    }
    return 0;
  };

  const LOGME = () => {
    // console.log("callType:", callType);
    // console.log("billableType:", billableType);
    // console.log("csvData:", csvData);
    // console.log("data:", data);
    // console.log("states:", states);
    // console.log("states:", states["860"]);
    // console.log("filteredData:", filteredData);
  };

  useEffect(() => {
    if (dataSummary) {
      setTotalQaCalls(
        parseInt(
          dataSummary.categoryCounts[0].excellent +
            dataSummary.categoryCounts[0].satisfactory +
            dataSummary.categoryCounts[0].poor +
            dataSummary.categoryCounts[0].very_poor +
            dataSummary.categoryCounts[0].client_issues +
            dataSummary.categoryCounts[0].miscellaneous
        )
      );
    }
  }, [dataSummary]);

  useEffect(() => {
    let summaryData;
    if (csvData && filteredReportData) {
      // console.log("filteredData!");
      summaryData = generateSummaryReport(filteredReportData?.sort(sortByDispo));
    } else {
      // console.log("csvData!");
      summaryData = generateSummaryReport(csvData?.sort(sortByDispo));
    }

    setDataSummary(summaryData);
  }, [filteredReportData]);

  return (
    <>
      <Flex flexDir="column" w="100%" ml="5px">
        <Text fontStyle="italic" textAlign="center">
          &nbsp;
        </Text>
        <Tooltip hasArrow placement="top" label="Internal Report">
          <IconButton
            colorScheme="blue"
            onClick={handleOpen}
            aria-label="Pull Mortgage Data"
            icon={<HiDocumentReport />}
            isLoading={insertCallsLoading || callsLoading || loading}
          />
        </Tooltip>
      </Flex>

      <Modal isOpen={isOpen} onClose={handleClose} isCentered size="7xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h="80vh">
          <ModalHeader textAlign="center" onClick={LOGME}>
            QA - Internal Report
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0}>
            {csvData ? (
              <Tabs>
                <TabList position="sticky" top={0} backgroundColor="#2D3748" mt={0} p={3}>
                  <Tab>Report</Tab>
                  <Tab
                    onClick={() =>
                      generateSummaryReport(filteredReportData ? filteredReportData : csvData)
                    }
                  >
                    Categories
                  </Tab>
                  <Tab
                    onClick={() =>
                      generateSummaryReport(filteredReportData ? filteredReportData : csvData)
                    }
                  >
                    Dispositions
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Flex alignItems="center">
                      <Box w="20%" mr="10px">
                        <Select
                          placeholder="Billable Type"
                          options={allCallsOrBillableChoices}
                          styles={selectStatusStyles}
                          onChange={(e) => handleBillableTypeChange(e)}
                        />
                      </Box>

                      <Box w="20%" ml="10px">
                        <Text letterSpacing={3} fontSize={20}>
                          {filteredReportData ? filteredReportData?.length : csvData?.length} TOTAL
                          CALLS
                        </Text>
                      </Box>
                    </Flex>
                    <Flex justifyContent="space-evenly" alignItems="center">
                      <Table w="100%" size="sm">
                        <Thead position="sticky" top={65}>
                          <Tr backgroundColor={bgColor} m={0}>
                            <Th
                              py={4}
                              textAlign="center"
                              cursor="pointer"
                              _hover={{ color: "#4fd1c5" }}
                            >
                              Call Date
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Buyer
                            </Th>

                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Target
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Campaign
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Affiliate
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Revenue
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Payout
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              State
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              CID
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Category
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Disposition
                            </Th>
                            <Th textAlign="center" cursor="pointer" _hover={{ color: "#4fd1c5" }}>
                              Duration
                            </Th>
                            <Th
                              textAlign="center"
                              cursor="pointer"
                              _hover={{ color: "#4fd1c5" }}
                              w="20%"
                            >
                              Notes
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredReportData
                            ? []
                                .concat(filteredReportData)
                                .map((call, i) => <TableRow call={call} />)
                            : null}
                          {csvData && !filteredReportData
                            ? [].concat(csvData).map((call, i) => <TableRow call={call} />)
                            : null}
                        </Tbody>
                      </Table>
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <Flex justifyContent="space-evenly" alignItems="center" flexDir="column">
                      <Table w="100%" size="xl" mb={4}>
                        <Thead>
                          <Tr>
                            <Th textAlign="center">Excellent</Th>
                            <Th textAlign="center">Satisfactory</Th>
                            <Th textAlign="center">Poor</Th>
                            <Th textAlign="center">Very Poor</Th>
                            <Th textAlign="center">Client Issues</Th>
                            <Th textAlign="center">Miscellaneous</Th>
                          </Tr>
                        </Thead>
                        {dataSummary ? (
                          <Tbody>
                            <Tr>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="green.200"
                              >
                                {dataSummary.categoryCounts[0].excellent}/{totalQaCalls}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="orange.300"
                              >
                                {dataSummary.categoryCounts[0].satisfactory}/{totalQaCalls}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="red.300"
                              >
                                {dataSummary.categoryCounts[0].poor}/{totalQaCalls}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="red.500"
                              >
                                {dataSummary.categoryCounts[0].very_poor}/{totalQaCalls}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="blue.300"
                              >
                                {dataSummary.categoryCounts[0].client_issues}/{totalQaCalls}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="gray.400"
                              >
                                {dataSummary.categoryCounts[0].miscellaneous}/{totalQaCalls}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="green.200"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].excellent) /
                                    totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="orange.300"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].satisfactory) /
                                    totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="red.300"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].poor) / totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="red.500"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].very_poor) /
                                    totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="blue.300"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].client_issues) /
                                    totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                              <Td
                                textAlign="center"
                                w="16.5%"
                                fontSize={26}
                                fontWeight={700}
                                color="gray.400"
                              >
                                {`${(
                                  (parseInt(dataSummary.categoryCounts[0].miscellaneous) /
                                    totalQaCalls) *
                                  100
                                ).toFixed(2)}%`}
                              </Td>
                            </Tr>
                          </Tbody>
                        ) : null}
                      </Table>
                    </Flex>
                    <Divider borderWidth={3} />
                    <Flex justifyContent="space-evenly" alignItems="center" flexDir="column" my={4}>
                      <Flex w="100%" justifyContent="space-evenly" alignItems="flex-start" my={4}>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="green.300">
                                Excellent
                              </Th>
                              <Th textAlign="center" color="green.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="green.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[0]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="green.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[0][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[0][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="orange.300">
                                Satisfactory
                              </Th>
                              <Th textAlign="center" color="orange.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="orange.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[1]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="orange.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[1][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[1][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="red.300">
                                Poor
                              </Th>
                              <Th textAlign="center" color="red.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="red.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[2]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="red.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[2][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[2][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="red.500">
                                Very Poor
                              </Th>
                              <Th textAlign="center" color="red.500">
                                Count
                              </Th>
                              <Th textAlign="center" color="red.500">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[3]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="red.500"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[3][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[3][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="blue.300">
                                Client Issues
                              </Th>
                              <Th textAlign="center" color="blue.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="blue.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[4]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="blue.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[4][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[4][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="gray.400">
                                Miscellaneous
                              </Th>
                              <Th textAlign="center" color="gray.400">
                                Count
                              </Th>
                              <Th textAlign="center" color="gray.400">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[5]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="gray.400"
                                          _hover={{ backgroundColor: "gray.600" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[5][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[5][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                      </Flex>
                      {/* {dataSummary
                        ? QaCallReportInternalDispoChart(dataSummary.dispositionCounts)
                      : null} */}
                      <Divider borderWidth={3} />
                    </Flex>
                    {dataSummary
                      ? QaCallReportInternalCategoryChart(dataSummary.categoryCounts)
                      : null}
                  </TabPanel>
                  <TabPanel>
                    <Flex justifyContent="space-evenly" alignItems="center" flexDir="column">
                      <Flex w="100%" justifyContent="space-evenly" alignItems="flex-start">
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="green.300">
                                Excellent
                              </Th>
                              <Th textAlign="center" color="green.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="green.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[0]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="green.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[0][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[0][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="orange.300">
                                Satisfactory
                              </Th>
                              <Th textAlign="center" color="orange.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="orange.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[1]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="orange.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[1][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[1][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="red.300">
                                Poor
                              </Th>
                              <Th textAlign="center" color="red.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="red.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[2]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="red.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[2][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[2][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="red.500">
                                Very Poor
                              </Th>
                              <Th textAlign="center" color="red.500">
                                Count
                              </Th>
                              <Th textAlign="center" color="red.500">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[3]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="red.500"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[3][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[3][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="blue.300">
                                Client Issues
                              </Th>
                              <Th textAlign="center" color="blue.300">
                                Count
                              </Th>
                              <Th textAlign="center" color="blue.300">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[4]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="blue.300"
                                          _hover={{ backgroundColor: "gray.400" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[4][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[4][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                        <Table w="25%" size="xl" mb={4} mx={1}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" color="gray.400">
                                Miscellaneous
                              </Th>
                              <Th textAlign="center" color="gray.400">
                                Count
                              </Th>
                              <Th textAlign="center" color="gray.400">
                                %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dataSummary ? (
                              <>
                                {Object.keys(dataSummary.dispositionCounts[5]).map(
                                  (dispo, index) => (
                                    <>
                                      {dispo !== "name" ? (
                                        <Tr
                                          backgroundColor="gray.400"
                                          _hover={{ backgroundColor: "gray.600" }}
                                        >
                                          <Td w="50%">{dispo}</Td>
                                          <Td textAlign="center" w="25%">
                                            {dataSummary.dispositionCounts[5][dispo]}
                                          </Td>
                                          <Td textAlign="center" w="25%">
                                            {(
                                              (parseInt(dataSummary.dispositionCounts[5][dispo]) /
                                                totalQaCalls) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </Td>
                                        </Tr>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                      </Flex>
                      {dataSummary
                        ? QaCallReportInternalDispoChart(dataSummary.dispositionCounts)
                        : null}
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : null}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
