import React from "react";
import { Flex, Table, Thead, Th, Tr, Tbody, Td, Heading, Spinner, Box } from "@chakra-ui/react";

import CallDuration from "./reportTypes/CallDuration";
import FailedCalls from "./reportTypes/FailedCalls";
import CallRouting from "./reportTypes/CallRouting";

export default function Report({ reportData, noData, loading, reportType, setReportData }) {
  if (reportType === "Calls - Duration" && reportData && reportData.length > 0) {
    return <CallDuration reportData={reportData} setReportData={setReportData} />;
  }
  if (reportType === "Calls - Failed" && reportData) {
    return <FailedCalls reportData={reportData} setReportData={setReportData} />;
  }
  if (reportType === "Calls - Routing" && reportData) {
    return (
      <CallRouting reportData={reportData} setReportData={setReportData} reportType={reportType} />
    );
  }
  return (
    <Box>
      {loading ? (
        <Flex mt="10%" justifyContent="center" alignItems="center" w="100%">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="green.400"
            size="xl"
          />
        </Flex>
      ) : null}
      {noData ? (
        <Heading mt="10%" textAlign="center">
          No matching records.
        </Heading>
      ) : null}
    </Box>
  );
}
