import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useColorModeValue,
  IconButton,
  FormControl,
  Input,
} from "@chakra-ui/react";
import TopHeading from "../../components/TopHeading";

import { lookUpNumber } from "../blocked/BlaApi";

import { FiSearch } from "react-icons/fi";

export default function BLA() {
  const [cid, setCid] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const formatPhone = () => {
    let newPhone = cid.replace(/[^\d]/g, "");
    if (newPhone.length < 11) {
      newPhone = 1 + newPhone;
    }
    if (newPhone.length < 10) {
      alert("Invalid phone number.");
      return;
    }
    console.log(newPhone);
    return newPhone;
  };

  const BlaLookUpNumber = async () => {
    if (data) setData(null);
    if (!cid) {
      alert("Enter valid phone number.");
      return;
    }

    setLoading(true);

    const phoneData = {
      cid: formatPhone(),
    };

    try {
      const result = await lookUpNumber(phoneData);
      setData(result);
      console.log(result);
      setLoading(false);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Blacklist Alliance"
        description="Check to see if a number exists on Blacklist Alliance"
        children={
          <Flex alignItems="center">
            <FormControl mr={4}>
              <Input
                id="number"
                type="number"
                onChange={(e) => setCid(e.target.value)}
                placeholder="Enter caller ID here."
              />
            </FormControl>
            <IconButton
              onClick={BlaLookUpNumber}
              mr="10px"
              aria-label="Generate DNC NList"
              icon={loading ? <Spinner color="teal.300" /> : <FiSearch />}
            />
          </Flex>
        }
      />

      <Flex flexDir="column">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="teal.300" />
          </Flex>
        ) : null}
        <Flex flexDir="column">
          {data ? (
            <Flex>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th textAlign="center">Blacklisted?</Th>
                    <Th textAlign="center">CID</Th>
                    <Th textAlign="center">Type</Th>
                    <Th textAlign="center">Carrier</Th>
                    <Th textAlign="center">Country</Th>
                    <Th textAlign="center">State</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr h="150px">
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.results > 0 ? "TRUE" : "FALSE"}
                    </Td>
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.phone}
                    </Td>
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.carrier.type}
                    </Td>
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.carrier.name}
                    </Td>
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.carrier.country}
                    </Td>
                    <Td textAlign="center" fontSize={18} _hover={{ backgroundColor: hoverColor }}>
                      {data.carrier.state}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
}
