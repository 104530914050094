import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Textarea,
  IconButton,
  Tooltip,
  Button,
  Box,
  TabPanel,
  Tag,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  setCallCategoryDisposition,
  addNewCallNote,
  deleteCallNote,
  editCallNote,
} from "../QaApis";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ReactPlayer from "../../ReactPlayer";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

import { BsTrash } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import FlagCall from "../FlagCall";
import MarkCall from "../MarkCall";

const formatDuration = (duration) => {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

const QaNote = ({ callId, note, callIndex, noteIndex, updateCallNoteFrontEnd }) => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noteText, setNoteText] = useState(note.note);
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const handleDelete = async () => {
    setLoading(true);
    const data = {
      call_unique_id: callId,
      note_id: note.id,
    };
    try {
      await deleteCallNote(data);
      updateCallNoteFrontEnd(callIndex, note, "delete", noteIndex);
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  //! STILL NEED TO FIX THIS
  const handleEditNote = async () => {
    setSaveLoading(true);
    const noteData = {
      timestamp: moment().format("L LT"),
      id: note.id,
      note: noteText,
      created_by: note.username,
    };
    const data = {
      call_unique_id: callId,
      note_id: note.id,
      note: noteText,
    };
    try {
      await editCallNote(data);
      updateCallNoteFrontEnd(callIndex, noteData, "edit", noteIndex);
      setSaveLoading(false);
    } catch (error) {
      alert(error.message);
      setSaveLoading(false);
    }
  };

  return (
    <Tr _hover={{ backgroundColor: hoverColor }}>
      <Td textAlign="center" w="200px">
        {moment(note.timestamp).format("L LT")}
      </Td>
      <Td textAlign="center" w="250px">
        {note.created_by}
      </Td>
      <Td textAlign="center">
        {/* {note.note} */}
        <Textarea
          style={{ whiteSpace: "pre-wrap" }}
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
        />
      </Td>
      <Td textAlign="center" w="80px">
        {/* <Button onClick={handleEditNote}>Save</Button> */}
        <Flex justify="center" alignItems="center">
          <Tooltip hasArrow placement="top" label="Save">
            <IconButton
              size="sm"
              onClick={handleEditNote}
              isLoading={saveLoading}
              icon={<AiOutlineSave />}
              mr={2}
            />
          </Tooltip>

          <ConfirmationModal
            isIconButton
            buttonSize="sm"
            icon={<BsTrash />}
            loading={loading}
            success={success}
            header="Delete Confirmation"
            messages={["Are you sure you want to delete this note?"]}
            alert="This cannot be undone."
            buttonText="Delete"
            event={handleDelete}
            tooltip="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default function SearchCidResults({
  call,
  qaDispos,
  updateCallCatDispoFrontEnd,
  updateCallFromDeleteFrontEnd,
  updateCallFromFlaggedFrontEnd,
  index,
  username,
  getQaDispositions,
  allCalls,
  searchResults,
}) {
  const [noteLoading, setNoteLoading] = useState(false);
  const [note, setNote] = useState("");

  const [sortedQaDispos, setSortedQaDispos] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [dispoChoices, setDispoChoices] = useState();
  const [selectedDispo, setSelectedDispo] = useState();
  const [dispositionDefaultValueIndex, setDispositionDefaultValueIndex] = useState();
  const [dispoColor, setDispoColor] = useState(call?.qa?.color ? call?.qa?.color : null);

  const [dispoLoading, setDispoLoading] = useState(false);
  const [dispoUpdateSuccess, setDispoUpdateSuccess] = useState(false);

  const [callRecordingUrl, setCallRecordingUrl] = useState();

  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A202C",
    }),
  };

  const sortQaDispos = async () => {
    const dispoArray = [];

    await qaDispos.forEach((key) => {
      let newkeyObject = {
        value: key.category,
        label: key.category,
        color: key.color,
        description: key.description,
        dispositions: [],
      };

      // console.log(newkeyObject);

      key.dispositions.forEach((dispo) => {
        const dispoObject = {
          value: dispo.name,
          label: dispo.name,
          color: dispo.color,
          id: dispo.id,
        };
        newkeyObject.dispositions.push(dispoObject);
      });

      dispoArray.push(newkeyObject);
    });
    // console.log("dispoArray:", dispoArray);
    setSortedQaDispos(dispoArray);
  };

  const handleDispoSelectionChange = (e) => {
    console.log("e:", e);
    setSelectedDispo(e.value);
    setDispoColor(e.color);
  };

  const saveCategoryDisposition = async () => {
    setDispoLoading(true);
    const data = {
      call_unique_id: call.call_unique_id,
      category: selectedCategory,
      disposition: selectedDispo,
      color: dispoColor,
    };

    let newCallData = call;
    newCallData.qa.category = selectedCategory;
    newCallData.qa.disposition = selectedDispo;
    newCallData.qa.color = dispoColor;

    // console.log("newCallData data:", newCallData);
    // console.log("saveCategoryDisposition data:", data);
    try {
      const result = await setCallCategoryDisposition(data);
      setDispoUpdateSuccess(true);
      setTimeout(() => {
        setDispoUpdateSuccess(false);
      }, 2000);
      // console.log(result);
      updateCallCatDispoFrontEnd(index, newCallData);

      setDispoLoading(false);
    } catch (error) {
      console.log(error.message);
      setDispoLoading(false);
    }
  };

  const handleNewNote = async () => {
    setNoteLoading(true);
    const noteData = {
      call_unique_id: call.call_unique_id,
      note: {
        timestamp: moment().format("L LT"),
        id: `note-${uuidv4()}`,
        note,
        created_by: username,
      },
    };

    try {
      const response = await addNewCallNote(noteData);
      updateCallNoteFrontEnd(index, noteData.note, "add");
      // console.log(response);
      setNote("");
      setNoteLoading(false);
    } catch (error) {
      alert(error.message);
      setNoteLoading(false);
    }
  };

  const updateCallNoteFrontEnd = (callIndex, note, action, noteIndex) => {
    // console.log("updateCallNoteFrontEnd callIndex::", callIndex);
    // console.log("updateCallNoteFrontEnd noteIndex::", noteIndex);

    const newCall = call;
    // console.log("updateCallNoteFrontEnd newCall::", newCall);
    if (action === "add") {
      newCall.qa.notes.push(note);
      // console.log("newCall from add:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
    if (action === "delete") {
      newCall.qa.notes.splice(noteIndex, 1);
      // console.log("newCall from Delete:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
    if (action === "edit") {
      newCall.qa.notes.splice(noteIndex, 1, note);
      // console.log("newCall from Edit:", newCall);
      updateCallCatDispoFrontEnd(callIndex, newCall);
    }
  };

  const setBorderColor = () => {
    if (call?.tags?.flags?.flagged && call?.tags?.flags?.status === "pending") {
      return "red";
    }
    if (call?.tags?.flags?.flagged && call?.tags?.flags?.status === "resolved") {
      return "blue";
    }
    if (call?.tags?.marks?.marked && call?.tags?.marks?.status === "pending") {
      return "yellow";
    }
    if (call?.tags?.marks?.marked && call?.tags?.marks?.status === "resolved") {
      return "green";
    }
  };

  const selectWhichDispo = () => {
    if (sortedQaDispos && sortedQaDispos.length > 0) {
      if (selectedCategory === "Excellent") {
        setDispoChoices(sortedQaDispos[0].dispositions);
      }
      if (selectedCategory === "Satisfactory") {
        setDispoChoices(sortedQaDispos[1].dispositions);
      }
      if (selectedCategory === "Poor") {
        setDispoChoices(sortedQaDispos[2].dispositions);
      }
      if (selectedCategory === "Very Poor") {
        setDispoChoices(sortedQaDispos[3].dispositions);
      }
      if (selectedCategory === "Client Issues") {
        setDispoChoices(sortedQaDispos[4].dispositions);
      }
      if (selectedCategory === "Miscellaneous") {
        setDispoChoices(sortedQaDispos[5].dispositions);
      }
    }
  };

  const logMe = () => {
    console.log("sortedQaDispos::", sortedQaDispos);
  };

  useEffect(() => {
    sortQaDispos();
  }, [qaDispos]);

  useEffect(() => {
    if (sortedQaDispos) {
      selectWhichDispo();
    }
  }, [sortedQaDispos, selectedCategory]);

  useEffect(() => {
    if (call && call.recording_url) {
      // console.log("setCallRecording Effect Call:", call);
      // console.log("setCallRecording Effect Call.recording_url:", call.recording_url);
      setCallRecordingUrl(call.recording_url);
    } else {
      setCallRecordingUrl(null);
    }
  }, [allCalls, call, searchResults]);

  return (
    <Flex
      flexDir="column"
      w="100%"
      borderWidth={3}
      borderColor={setBorderColor()}
      pr={4}
      overflow="scroll"
      h="700px"
    >
      {/* <Flex alignItems="center">
        {!call?.tags.marks?.marked ? (
          <FlagCall
            inboundCallId={call?.call_unique_id}
            createdBy={username}
            flagged={call?.tags?.flags?.flagged ? true : false}
            updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
            callIndex={index}
            call={call}
          />
        ) : null}
        {call?.tags?.flags?.flagged ? (
          <Flex>
            {call?.tags?.flags?.status ? (
              <Tag colorScheme={call?.tags?.flags?.status === "pending" ? "red" : "teal"} ml={3}>
                {call?.tags?.flags?.status}
              </Tag>
            ) : null}

            <Tag ml={4} fontStyle="italic">
              Reason: {call?.tags?.flags?.reason}
            </Tag>
          </Flex>
        ) : null}
        {!call?.tags.flags?.flagged ? (
          <>
            <MarkCall
              inboundCallId={call?.call_unique_id}
              createdBy={username}
              marked={call?.tags?.marks?.marked ? true : false}
              updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
              callIndex={index}
              call={call}
            />

            {call?.tags?.marks?.status ? <Tag>{call?.tags?.marks?.status}</Tag> : null}
          </>
        ) : null}
      </Flex> */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">Timestamp</Th>
            <Th textAlign="center" onClick={logMe}>
              Target
            </Th>
            <Th textAlign="center">Campaign</Th>
            <Th textAlign="center">CID</Th>
            <Th textAlign="center">Duration</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td textAlign="center">{call.call_timestamp}</Td>
            <Td textAlign="center">{call.target_name}</Td>
            <Td textAlign="center">{call.campaign_name}</Td>
            <Td textAlign="center">{call.call_callerid}</Td>
            <Td textAlign="center">{formatDuration(call.call_length)}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Flex justifyContent="space-evenly" alignItems="center">
        <Flex w="100%" ml={4} h="680px" flexDir="column" justify="center" alignSelf="center">
          <Box
            borderWidth={1}
            borderRadius={7}
            p={4}
            mb={5}
            backgroundColor={call?.qa.color ? call?.qa.color : null}
          >
            <Flex w="100%" justify="center">
              <Text></Text>
              <Flex w="45%" flexDir="column" mr={4}>
                <Text letterSpacing={4} mb={2}>
                  CATEGORY
                </Text>
                <Box mb={2} p={2} borderWidth={1} borderColor="black" borderRadius={3} bg="white">
                  <Text color={call.qa.color} fontWeight={600}>
                    {call.qa.category}
                  </Text>
                </Box>
                {qaDispos && sortedQaDispos ? (
                  <Select
                    options={sortedQaDispos}
                    styles={selectStyles}
                    onChange={(e) => setSelectedCategory(e.value)}
                    // defaultValue={
                    //   call.qa.category
                    //     ? sortedQaDispos[
                    //         sortedQaDispos.findIndex((entry) => {
                    //           return entry.value === call?.qa?.category;
                    //         })
                    //       ]
                    //     : null
                    // }
                  />
                ) : null}
              </Flex>
              <Flex w="45%" flexDir="column" mr={4}>
                <Text letterSpacing={4} mb={2}>
                  DISPOSITION
                </Text>
                <Box mb={2} p={2} borderWidth={1} borderColor="black" borderRadius={3} bg="white">
                  <Text color={call.qa.color} fontWeight={600}>
                    {call.qa.disposition}
                  </Text>
                </Box>
                {qaDispos ? (
                  <Select
                    isDisabled={!dispoChoices}
                    placeholder={!dispoChoices ? "Select a category first." : "Select a category."}
                    options={dispoChoices ? dispoChoices : [{ value: "", label: "" }]}
                    styles={selectStyles}
                    onChange={(e) => handleDispoSelectionChange(e)}
                    // defaultValue={
                    //   call.qa.disposition && dispositionDefaultValueIndex > -1
                    //     ? dispoChoices[dispositionDefaultValueIndex]
                    //     : null
                    // }
                  />
                ) : null}
              </Flex>

              <Flex w="10%" flexDir="column" justify="flex-end">
                {dispoUpdateSuccess ? (
                  <Text
                    color="green.500"
                    mb={2}
                    textAlign="center"
                    letterSpacing={3}
                    fontWeight={800}
                  >
                    SUCCESS!
                  </Text>
                ) : (
                  <Text color="red.500" mb={2}>
                    &nbsp;
                  </Text>
                )}
                <Button
                  isDisabled={!selectedDispo}
                  onClick={saveCategoryDisposition}
                  isLoading={dispoLoading}
                  backgroundColor="gray"
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box borderWidth={1} borderRadius={7} p={4} mb={5} overflow="auto">
            {call?.qa && call?.qa.notes.length > 0 ? (
              <>
                <Text textAlign="center" letterSpacing={3} fontSize={18} mb={3}>
                  NOTES
                </Text>
                <Table w="100%" size="sm">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Timestamp</Th>
                      <Th textAlign="center">Created By</Th>
                      <Th textAlign="center">Note</Th>
                      <Th textAlign="center"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {call?.qa.notes.map((note, noteIndex) => (
                      <QaNote
                        callId={call?.call_unique_id}
                        note={note}
                        callIndex={index}
                        noteIndex={noteIndex}
                        updateCallNoteFrontEnd={updateCallNoteFrontEnd}
                      />
                    ))}
                  </Tbody>
                </Table>
              </>
            ) : (
              <Flex w="100%" justify="center" alignItems="center" py={5}>
                <Text fontSize={20} letterSpacing={4}>
                  NO CURRENT NOTES
                </Text>
              </Flex>
            )}
            <Flex w="100%" mt={4} alignItems="center" justifyContent="center">
              <Flex flexDir="column" w="80%" mr={4}>
                <Text letterSpacing={4} mb={2}>
                  NEW NOTE
                </Text>
                <Textarea
                  rows={4}
                  style={{ whiteSpace: "pre-wrap" }}
                  placeholder="Enter notes here."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Flex>
              <Flex flexDir="column" w="20%" h="120px" justify="center">
                <Text>&nbsp;</Text>
                <Button isLoading={noteLoading} colorScheme="teal" onClick={handleNewNote} h="100%">
                  Add
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box borderWidth={1} borderRadius={8} px={4} py={5}>
            {callRecordingUrl ? (
              <Flex flexDir="column">
                <Text letterSpacing={3} mb={10} textAlign="center">
                  CALL RECORDING
                </Text>
                <ReactPlayer url={callRecordingUrl} />
              </Flex>
            ) : (
              <Flex alignItems="center" justify="center" flexDir="column">
                <Text fontSize={18} letterSpacing={4}>
                  NO CALL RECORDING
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
