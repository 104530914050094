import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Input,
  Textarea,
  Tooltip,
  Icon,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { FaPhone, FaRegFileAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import TopHeading from "../TopHeading";
import axios from "axios";
import FilterConfirmationModal from "./FilterConfirmationModal";
import FilterEditModal from "./FilterEditModal";

const TargetFilters = ({ target, getAllFilters }) => {
  const [filterType, setFilterType] = useState("data");
  const [field, setField] = useState();
  const [operator, setOperator] = useState();
  const [value, setValue] = useState();
  const [campaignId, setCampaignId] = useState([]);
  const [campaigns, setCampaigns] = useState(target.assigned_campaigns);
  const [filters, setFilters] = useState([]);
  const [filtersData, setFiltersData] = useState(target.filters.filters_data);
  const [filtersCall, setFiltersCall] = useState(target.filters.filters_calls);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const generateFilter = () => {
    if (!field) {
      alert("Missing field.");
      return;
    }
    if (!operator) {
      alert("Missing operator");
      return;
    }

    let formattedValue = value;
    if (value) {
      formattedValue = value.replace(/ /g, "");
    } else {
      formattedValue = null;
    }

    if (formattedValue && formattedValue.split(",").length === 1) {
      formattedValue = [formattedValue];
    } else if (formattedValue && formattedValue.split(",").length > 1) {
      formattedValue = formattedValue.split(",");
    }

    const filter = {
      filterType,
      field,
      operator,
      value: formattedValue,
      id: filterType === "data" ? `data_${uuidv4()}` : `call_${uuidv4()}`,
    };

    if (filter.filterType === "data") {
      setFiltersData((prevFilter) => [...prevFilter, filter]);
    } else {
      setFiltersCall((prevFilter) => [...prevFilter, filter]);
    }

    setFilterType("data");
    setValue("");
    return filter;
  };

  const createSettingObject = () => {
    const setting = {
      uuid: uuidv4(),
      contract: target,
      assigned_campaigns: campaigns,
      filters: {
        filters_data: filtersData,
        filters_calls: filtersCall,
      },
    };
    return setting;
  };

  const createNewFilter = async () => {
    const newFilter = createSettingObject();
    try {
      const response = await axios.post("/api/mortgage/settings", newFilter);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateFilterCampaigns = async () => {
    setCampaignLoading(true);
    const data = {
      uuid: target.uuid,
      assigned_campaigns: campaigns,
    };

    console.log("updateFilterCampaigns data:", data);

    try {
      const response = await axios.post("/api/mortgage/settings/update-campaigns", data);

      await getAllFilters();
      setCampaignLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const addNewFilter = async () => {
    setFilterLoading(true);
    const data = {
      uuid: target.uuid,
      filter: generateFilter(),
    };
    try {
      const response = await axios.post("/api/mortgage/settings/add-filter", data);

      await getAllFilters();
      setFilterLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteFilter = async (filterType, filterId) => {
    const data = {
      uuid: target.uuid,
      filterType,
      filterId,
    };

    try {
      const response = await axios.post("/api/mortgage/settings/delete-filter", data);

      await getAllFilters();
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateFilter = async (filter) => {
    const data = {
      uuid: target.uuid,
      filterType: filter.filterType,
      filter,
    };

    try {
      const response = await axios.post("/api/mortgage/settings/update-filter", data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setFilters([...filtersData, ...filtersCall]);
  }, [filtersData, filtersCall]);

  return (
    <AccordionItem w="100%" py={3}>
      <AccordionButton _hover={{ backgroundColor: "teal.300" }}>
        <AccordionIcon mr="10px" />
        <Flex>
          {target.contract === "ld_conventional" && <Text>Loan Depot - Conventional</Text>}
          {target.contract === "mrp_va" && <Text>My Resources Pro - VA</Text>}
          {target.contract === "mrp_conventional_co" && (
            <Text>My Resources Pro - Conventional Cash Out</Text>
          )}
          {target.contract === "freedom_conventional_co" && (
            <Text>Freedom Mortgage - Conventional Cash Out</Text>
          )}
          {target.contract === "freedom_va_co" && <Text>Freedom Mortgage - VA Cash Out</Text>}
          {/* <Button onClick={() => console.log(target)}>LOG</Button> */}
        </Flex>
      </AccordionButton>
      <AccordionPanel>
        {target ? (
          <Flex w="100%" flexDir="column" mb="20px">
            <Flex flexDir="column" w="100%">
              <Text>Current Campaigns</Text>
              <Flex
                borderRadius="7px"
                borderWidth={1}
                borderColor="gray.200"
                p="5px"
                backgroundColor="white"
                mb="20px"
                minH="100px"
                flexDir="column"
              >
                {target.assigned_campaigns.length > 0 &&
                  target.assigned_campaigns.map((campaign, index) => (
                    <Text key={index} color="black">
                      {campaign}
                    </Text>
                  ))}
              </Flex>
            </Flex>
            <Flex w="100%" alignItems="center">
              <Flex flexDir="column" w="100%">
                <Text>Add Campaign IDs</Text>
                <Textarea
                  borderRadius={8}
                  size="sm"
                  value={campaigns}
                  onChange={(e) => setCampaigns(e.target.value.split(","))}
                  placeholder={`Separate values with commas.
Accepted format: ID1,ID2,ID3,ID4,ID5, ...`}
                />
              </Flex>
              <Button colorScheme="teal" w={100} ml="15px" onClick={() => updateFilterCampaigns()}>
                {campaignLoading ? <Spinner /> : "Update"}
              </Button>
            </Flex>
          </Flex>
        ) : null}
        <Text>Current Filters:</Text>
        <Flex
          borderRadius={8}
          borderWidth={1}
          borderColor="gray.200"
          p="5px"
          backgroundColor="white"
          mb="20px"
          minH="100px"
          flexDir="column"
        >
          <Table w="100%" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center">Type</Th>
                <Th textAlign="center">Field</Th>
                <Th textAlign="center">Operator</Th>
                <Th textAlign="center">Compare Value</Th>
                <Th textAlign="center">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {target && target.filters.filters_calls.length > 0
                ? target.filters.filters_calls.map((filter, index) => (
                    <Tr mb="5px" _hover={{ backgroundColor: "teal.300" }} key={index}>
                      <Td textAlign="center">
                        <Icon color="black" as={FaPhone} mx="5px" w={5} h={5} />
                      </Td>

                      <Td color="black" fontSize="16px" px="4px" py="1px" textAlign="center">
                        {filter.field}
                      </Td>

                      <Td
                        color="black"
                        fontSize="16px"
                        px="4px"
                        py="1px"
                        mx="10px"
                        textAlign="center"
                      >
                        {filter.operator}
                      </Td>

                      <Td
                        color="black"
                        fontSize="16px"
                        px="4px"
                        py="1px"
                        textAlign="center"
                        maxW="50px"
                      >
                        {filter.value ? `${filter.value}` : null}
                      </Td>

                      <Td color="black" textAlign="center">
                        <Flex justifyContent="center">
                          <FilterEditModal
                            filter={filter}
                            icon={<FiEdit />}
                            header="Edit Filter"
                            disabled={filter.value ? false : true}
                            event={() => updateFilter(filter)}
                          />
                          <Box mx="5px"></Box>
                          <FilterConfirmationModal
                            icon={<BsTrash />}
                            event={() => deleteFilter(filter.filterType, filter.id)}
                            header="Delete Filter"
                            messages={["Would you like to delete this filter?"]}
                            alert="This cannot be undone."
                            filter={filter}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))
                : null}
              {target && target.filters.filters_data.length > 0
                ? target.filters.filters_data.map((filter, index) => (
                    <Tr mb="5px" _hover={{ backgroundColor: "teal.300" }} key={index}>
                      <Td textAlign="center">
                        <Icon color="black" as={FaRegFileAlt} mx="5px" w={5} h={5} />
                      </Td>

                      <Td color="black" fontSize="16px" px="4px" py="1px" textAlign="center">
                        {filter.field}
                      </Td>

                      <Td
                        color="black"
                        fontSize="16px"
                        px="4px"
                        py="1px"
                        mx="10px"
                        textAlign="center"
                      >
                        {filter.operator}
                      </Td>

                      <Td
                        color="black"
                        fontSize="16px"
                        px="4px"
                        py="1px"
                        textAlign="center"
                        maxW="50px"
                      >
                        {filter.value ? `${filter.value}` : null}
                      </Td>

                      <Td color="black" textAlign="center">
                        <Flex justifyContent="center">
                          <FilterEditModal
                            filter={filter}
                            icon={<FiEdit />}
                            header="Edit Filter"
                            disabled={filter.value ? false : true}
                            event={() => updateFilter(filter)}
                          />

                          <Box mx="5px"></Box>
                          <FilterConfirmationModal
                            icon={<BsTrash />}
                            event={() => deleteFilter(filter.filterType, filter.id)}
                            header="Delete Filter"
                            messages={["Would you like to delete this filter?"]}
                            alert="This cannot be undone."
                            filter={filter}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Flex>
        <Flex flexDir="column" borderWidth={1} borderRadius={8} p={3}>
          <Text>Create Filter</Text>

          <Flex
            borderWidth="1px"
            borderRadius="7px"
            borderColor="gray.200"
            p="5px"
            backgroundColor="white"
            mb="20px"
            minH="50px"
          >
            <Table w="100%" size="sm">
              <Thead>
                <Tr>
                  <Th textAlign="center">Type</Th>
                  <Th textAlign="center">Field</Th>
                  <Th textAlign="center">Operator</Th>
                  <Th textAlign="center">Compare Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr mb="5px">
                  <Td textAlign="center">
                    {filterType === "data" ? (
                      <Icon color="black" as={FaRegFileAlt} mx="5px" w={5} h={5} />
                    ) : (
                      <Icon color="black" as={FaPhone} mx="5px" w={5} h={5} />
                    )}
                  </Td>

                  <Td color="black" fontSize="16px" px="4px" py="1px" textAlign="center">
                    {field}
                  </Td>

                  <Td color="black" fontSize="16px" px="4px" py="1px" mx="10px" textAlign="center">
                    {operator}
                  </Td>

                  <Td
                    color="black"
                    fontSize="16px"
                    px="4px"
                    py="1px"
                    textAlign="center"
                    maxW="50px"
                  >
                    {value ? `${value}` : null}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
          <Flex>
            <Flex flexDir="column" w="30%">
              <Text>Type</Text>
              <Select onChange={(e) => setFilterType(e.target.value)}>
                <option value="data">Data</option>
                <option value="call">Call</option>
              </Select>
            </Flex>
            {filterType === "data" ? (
              <Flex flexDir="column" w="70%" mx="15px">
                <Text>Field</Text>
                <Select onChange={(e) => setField(e.target.value)} placeholder="Select field">
                  <option value="agent_id">Agent ID</option>
                  <option value="first_name">First Name</option>
                  <option value="last_name">Last Name</option>
                  <option value="phone_home">Phone Home</option>
                  <option value="email">Email</option>
                  <option value="address">Address</option>
                  <option value="city">City</option>
                  <option value="state">State</option>
                  <option value="zip_code">Zip</option>
                  <option value="interest_rate">Interest Rate</option>
                  <option value="loan_amount">Loan Amount</option>
                  <option value="jornayaLeadID">Jornaya Lead ID</option>
                  <option value="trusted">Trusted Form URL/ Certificate ID</option>
                </Select>
              </Flex>
            ) : (
              <Flex flexDir="column" w="100%" mx="15px">
                <Text>Field</Text>
                <Select onChange={(e) => setField(e.target.value)} placeholder="Select field">
                  <option value="call_length">Call Duration</option>
                  <option value="connected_duration">Call Connected Duration</option>
                  <option value="state">Caller ID State</option>
                </Select>
              </Flex>
            )}
            <Flex flexDir="column" w="30%">
              <Text>Operator</Text>
              <Select onChange={(e) => setOperator(e.target.value)} placeholder="Select Operator">
                <option value="required">Required</option>
                <option value="in">In</option>
                <option value="not_in">Not In</option>
                <option value="greater_than">Greater Than</option>
                <option value="less_than">Less Than</option>
              </Select>
            </Flex>
            {operator === "in" || operator === "not_in" ? (
              <Flex flexDir="column" w="100%" ml="15px">
                <Text>Value</Text>
                <Textarea
                  size="sm"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={`Separate values with commas.
Accepted format: AAA,BBB,CCC,DDD,EEE, ...`}
                />
              </Flex>
            ) : null}
            {operator === "greater_than" || operator === "less_than" ? (
              <Flex flexDir="column" w="60%" ml="15px">
                <Text>Value</Text>
                <NumberInput value={value} onChange={(e) => setValue(e)}>
                  <NumberInputField />
                </NumberInput>
                {/* <Input onChange={(e) => setValue(e.target.value)} /> */}
              </Flex>
            ) : null}
            <Flex flexDir="column" ml="15px">
              <Text>&nbsp;</Text>
              <Button onClick={addNewFilter} colorScheme="teal" w={100}>
                {filterLoading ? <Spinner /> : "Add"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default function MortgageSettings() {
  const [allFilters, setAllFilters] = useState();
  const [loading, setLoading] = useState(false);

  const logMe = () => {
    console.log("allFilters:", allFilters);
  };

  const getAllFilters = async () => {
    try {
      const response = await axios.get("/api/mortgage/settings");

      const data = response.data;
      setAllFilters(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllFiltersFirst = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/mortgage/settings");
      const data = response.data;
      setAllFilters(data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllFiltersFirst();
  }, []);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="1vh"
      mx="10px"
      p={6}
      overflowY="scroll"
      alignSelf="center"
      justifySelf="center"
    >
      <TopHeading
        title="Mortgage Settings"
        description="Create and apply settings to mortgage campaigns."
      />

      <Box overflowY="hidden">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="teal.300" />
          </Flex>
        ) : null}
        <Flex w="100%">
          <Accordion allowToggle w="100%">
            {allFilters
              ? allFilters.allTargetFilters.map((target) => (
                  <TargetFilters key={target.uuid} target={target} getAllFilters={getAllFilters} />
                ))
              : null}
          </Accordion>
        </Flex>
      </Box>
    </Box>
  );
}
