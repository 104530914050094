import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  IconButton,
  Spinner,
  Divider,
  useColorModeValue,
  forwardRef,
} from "@chakra-ui/react";
import moment from "moment";

import TopHeading from "../TopHeading";
import { getInsights, getInsightsTargets } from "./performanceApi";

import DatePicker from "react-datepicker";

import { FiRefreshCcw } from "react-icons/fi";

import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import RoutesModal from "../modals/RoutesModal";

const TableRow = ({ target }) => {
  const [expand, setExpand] = useState(false);
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <>
      {target.target.targetName ? (
        <Tr _hover={{ backgroundColor: hoverColor }} onClick={handleExpand} cursor="pointer">
          <Td>
            {!expand ? (
              <Icon as={RiArrowDropDownLine} h={8} w={8} onClick={handleExpand} cursor="pointer" />
            ) : (
              <Icon as={RiArrowDropUpLine} h={8} w={8} onClick={handleExpand} cursor="pointer" />
            )}
          </Td>
          <Td textAlign="left" fontWeight={500}>
            {target.target.targetName}
          </Td>

          <Td textAlign="center">{expand ? "" : target.target.liveCallCount}</Td>
          <Td textAlign="center">{expand ? "" : target.target.connectedCallCount}</Td>
          <Td textAlign="center">{expand ? "" : target.target.convertedCalls}</Td>
          <Td textAlign="center">{expand ? "" : target.target.convertedPercent}</Td>
          <Td textAlign="center">{expand ? "" : `$${target.target.payoutAmount}`}</Td>
          <Td textAlign="center">{expand ? "" : target.target.avgHandleTime}</Td>
          {target.target.payoutAmount ? (
            <Td textAlign="center">
              {expand
                ? ""
                : `$${(target.target.payoutAmount / target.target.connectedCallCount).toFixed(2)}`}
            </Td>
          ) : (
            <Td textAlign="center">{expand ? "" : "N/A"}</Td>
          )}
          <Td textAlign="center"></Td>
        </Tr>
      ) : null}
      {expand && target.campaigns.length > 0
        ? target.campaigns.map((campaign, i) => (
            <Tr
              _hover={{ backgroundColor: hoverColor }}
              key={
                campaign.campaignName
                  ? `${campaign.campaignName}-${i}`
                  : `${campaign.targetName}-total-${i}`
              }
            >
              <Td></Td>
              {campaign.campaignName ? (
                <Td textAlign="left" pl={10}>
                  {campaign.campaignName}
                </Td>
              ) : (
                <Td textAlign="left" pl={20} textDecor="underline" fontWeight={600}>
                  Total
                </Td>
              )}

              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                {campaign.liveCallCount}
              </Td>
              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                {campaign.connectedCallCount}
              </Td>
              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                {campaign.convertedCalls}
              </Td>
              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                {campaign.convertedPercent}
              </Td>
              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                ${campaign.payoutAmount}.00
              </Td>
              <Td
                textAlign="center"
                fontWeight={!campaign.campaignName ? 500 : null}
                textDecoration={!campaign.campaignName ? "underline" : null}
              >
                {campaign.avgHandleTime}
              </Td>
              {campaign.payoutAmount ? (
                <Td
                  textAlign="center"
                  fontWeight={!campaign.campaignName ? 500 : null}
                  textDecoration={!campaign.campaignName ? "underline" : null}
                >
                  ${(campaign.payoutAmount / campaign.connectedCallCount).toFixed(2)}
                </Td>
              ) : (
                <Td
                  textAlign="center"
                  fontWeight={!campaign.campaignName ? 500 : null}
                  textDecoration={!campaign.campaignName ? "underline" : null}
                >
                  N/A
                </Td>
              )}
              {!campaign.campaignName ? (
                <Td textAlign="center"></Td>
              ) : (
                <Td textAlign="center">
                  <RoutesModal campaignName={campaign.campaignName} />
                </Td>
              )}
            </Tr>
          ))
        : null}
      {!target.target.targetName ? (
        <Tr _hover={{ backgroundColor: hoverColor }}>
          <Td></Td>
          <Td textAlign="right" fontWeight={600} textDecoration="underline">
            Total
          </Td>

          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            {target.target.liveCallCount}
          </Td>
          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            {target.target.connectedCallCount}
          </Td>
          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            {target.target.convertedCalls}
          </Td>
          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            {target.target.convertedPercent}
          </Td>
          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            ${target.target.payoutAmount}.00
          </Td>
          <Td textAlign="center" fontWeight={600} textDecoration="underline">
            {target.target.avgHandleTime}
          </Td>
          {target.target.payoutAmount ? (
            <Td textAlign="center" fontWeight={600} textDecoration="underline">
              ${(target.target.payoutAmount / target.target.connectedCallCount).toFixed(2)}
            </Td>
          ) : (
            <Td textAlign="center" fontWeight={600} textDecoration="underline">
              N/A
            </Td>
          )}
        </Tr>
      ) : null}
    </>
  );
};

const FilterButton = ({ filterName, filters, handleFilter, updateFilteredData }) => {
  const [color, setColor] = useState(null);
  const handleClick = () => {
    handleFilter(filterName);
    if (filters.indexOf(filterName) > -1) {
      setColor("teal");
    } else {
      setColor(null);
    }
    updateFilteredData();
  };

  return (
    <Button colorScheme={color} m="5px" onClick={handleClick}>
      {filterName}
    </Button>
  );
};

export default function Targets() {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [sortFilter, setSortFilter] = useState("name");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const sortByTargetName = (a, b) => {
    if (a.targetName < b.targetName) {
      return -1;
    }
    if (a.targetName > b.targetName) {
      return 1;
    }
    return 0;
  };
  const sortByAcl = (a, b) => {
    if (a.avgHandleTime < b.avgHandleTime) {
      return -1;
    }
    if (a.avgHandleTime > b.avgHandleTime) {
      return 1;
    }
    return 0;
  };

  const updateFilteredData = () => {
    if (filters.length < 1) {
      setFilteredData(null);
      return;
    }
    let newData = [];
    data.forEach((target) => {
      if (target.target.targetName) {
        const array = target.target.targetName.split(" - ");
        if (array.some((r) => filters.indexOf(r) >= 0)) {
          newData.push(target);
        }
      }
    });
    setFilteredData(newData);
  };

  const handleFilter = (filterName) => {
    let newFilters = filters;
    const index = newFilters.indexOf(filterName);
    if (index > -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(filterName);
    }
    setFilters(newFilters);
  };

  const handleClick = async () => {
    setData(null);
    setLoading(true);

    const data = {
      startDate,
      endDate,
    };

    try {
      const response = await getInsightsTargets(data);
      setData(response);
      // console.log("data::", data);
      setLoading(false);
      setLastUpdated(moment().format("l LT"));
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleClick();
  }, []);

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput onClick={onClick} ref={ref} value={value} />
  ));

  const CustomInput = ({ onClick, ref, value }) => {
    return (
      <Button onClick={onClick} ref={ref} pr="40px">
        {value}
      </Button>
    );
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Target Performance"
        description="A look at performance across all active targets."
        children={
          <Flex justifyContent="flex-end" alignItems="center">
            <Flex mx="10px" flexDir="column" zIndex={3}>
              <Text>Start Date</Text>

              <DatePicker
                color="red"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                value={startDate ? moment(startDate).format("L") : null}
                isClearable
                customInput={<DatePickerInput />}
              />
            </Flex>
            <Flex mx="10px" flexDir="column">
              <Text>End Date</Text>

              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                value={endDate ? moment(endDate).format("L") : null}
                isClearable
                customInput={<DatePickerInput />}
                popperClassName="datepicker-class"
              />
            </Flex>
            <Flex mx="10px" flexDir="column">
              <Text>&nbsp;</Text>

              <IconButton
                onClick={handleClick}
                aria-label="Pull Volume Info"
                icon={<FiRefreshCcw />}
              />
            </Flex>
          </Flex>
        }
      />
      <Box
        w="100%"
        h="85vh"
        m="auto"
        mx="10px"
        // p="20px"
        overflowY={data ? "scroll" : "hidden"}
        //   boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
        //   borderRadius="7px"
        alignSelf="center"
        justifySelf="center"
      >
        <Flex justify="flex-end" mb="15px">
          <Flex w="100%" alignItems="center" fontSize="18px">
            {data || filteredData ? (
              <Flex alignItems="center">
                <Flex justify="center" alignItems="center">
                  <FilterButton
                    filterName="AUTO"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="DEBT"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="FE"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />

                  <FilterButton
                    filterName="HOME WARRANTY"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="MASS TORT"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="MA"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="MS"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="MGE"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="O65"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="SECURITY"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="SOLAR"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="TAX RELIEF"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="U65"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                  <FilterButton
                    filterName="UTIL"
                    filters={filters}
                    handleFilter={handleFilter}
                    updateFilteredData={updateFilteredData}
                  />
                </Flex>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        {/* <Button onClick={() => console.log(data)}>LOG ME</Button> */}
        <Divider />
        <Flex flexDir="column" h="500px" mb="15px">
          {loading ? (
            <Flex mt="10%" justify="center">
              <Spinner
                color="green.400"
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                size="xl"
              />
            </Flex>
          ) : null}

          {data || filteredData ? (
            <Flex flexDir="column" w="100%">
              <Table size="sm" w="100%">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th
                      color={sortFilter === "name" ? "teal.300" : null}
                      textAlign="center"
                      onClick={() => setSortFilter("name")}
                      cursor="pointer"
                    >
                      Target
                    </Th>
                    <Th textAlign="center">Live</Th>
                    <Th textAlign="center">Connected</Th>
                    <Th textAlign="center">Billable</Th>
                    <Th textAlign="center">Converted %</Th>
                    <Th textAlign="center">Total Payout</Th>
                    <Th textAlign="center" onClick={() => setSortFilter("acl")} cursor="pointer">
                      ACL
                    </Th>
                    <Th textAlign="center">RPC</Th>
                    <Th textAlign="center">Routing</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData
                    ? filteredData
                        .sort(sortByTargetName)
                        .map((target, i) => (
                          <TableRow key={`${target.target.targetName}-${i}`} target={target} />
                        ))
                    : data
                        .sort(sortByTargetName)
                        .map((target, i) => (
                          <TableRow key={`${target.target.targetName}-${i}`} target={target} />
                        ))}
                </Tbody>
              </Table>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
}
