import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Flex,
  IconButton,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import { getAllQaDispositions, deleteQaDispositionStatus } from "../qa/QaApis";

import { ImKey } from "react-icons/im";
import { BsTrash } from "react-icons/bs";

import NewDispositionStatus from "./NewDispositionStatus";
import ConfirmationModal from "../modals/ConfirmationModal";

const DispoTableRow = ({ dispo, getQaDispositions, category }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const deleteQaDisposition = async () => {
    const dispoData = {
      category,
      dispo_id: dispo.id,
    };

    setLoading(true);
    setSuccess(false);
    setFailure(false);
    try {
      const data = await deleteQaDispositionStatus(dispoData);
      console.log(data);
      setLoading(false);
      setSuccess(true);
      setFailure(false);
      getQaDispositions();
    } catch (error) {
      alert("error:", error);
      setLoading(false);
      setSuccess(false);
      setFailure(true);
    }
  };

  return (
    <Tr _hover={{ fontWeight: 700, backgroundColor: "gray.500" }} backgroundColor={dispo.color}>
      <Td color="white" w="50%">
        {dispo.name}
      </Td>
      <Td color="white" w="50%">
        {dispo.description ? dispo.description : " "}
      </Td>
      <Td color="white" w="50%">
        <Tooltip label="Delete" hasArrow placement="top">
          <ConfirmationModal
            isIconButton
            icon={<BsTrash />}
            buttonText="Delete"
            buttonSize="xs"
            colorScheme="white"
            header="Delete Confirmation"
            messages={["Would you like to delete this disposition status?", dispo.name]}
            alert="This cannot be undone."
            event={() => deleteQaDisposition(dispo.id)}
            loading={loading}
            success={success}
            failure={failure}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

const DispoTable = ({ data, getQaDispositions }) => {
  return (
    <Table size="sm" w="100%" my="10px">
      <Tbody>
        <Tr backgroundColor={data.color}>
          <Td textAlign="center" fontWeight={700} color="white" onClick={() => console.log(data)}>
            {data.category}
          </Td>
          <Td fontWeight={700} color="white">
            {data.description}
          </Td>
          <Td></Td>
        </Tr>
        {data &&
          data.dispositions.map((dispo) => (
            <DispoTableRow
              key={dispo}
              dispo={dispo}
              getQaDispositions={getQaDispositions}
              category={data.category}
            />
          ))}
      </Tbody>
    </Table>
  );
};

export default function DispositionKey({ qaDispos, getQaDispositions, loading, username }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [qaDispos, setQaDispos] = useState();

  // const getQaDispositions = async () => {
  //   setLoading(true);
  //   try {
  //     const data = await getAllQaDispositions();
  //     setQaDispos(data.result);
  //     // console.log("data::", data);
  //     setLoading(false);
  //   } catch (error) {
  //     alert("error:", error);
  //     setLoading(false);
  //   }
  // };

  const logMe = () => {
    console.log(qaDispos);
  };

  return (
    <>
      <Tooltip hasArrow placement="top" label="Edit QA Keys">
        <IconButton
          icon={<ImKey />}
          onClick={onOpen}
          mx="10px"
          isLoading={loading}
          isDisabled={username === "Madison Fukushima" ? false : true}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between">
              <Text>QA Disposition Key</Text>
              <NewDispositionStatus getQaDispositions={getQaDispositions} />
            </Flex>
          </ModalHeader>

          <ModalBody>
            {/* <Button onClick={logMe}>LOG</Button> */}

            {qaDispos ? (
              <Flex flexDir="column" w="100%">
                {qaDispos.map((table) => (
                  <DispoTable data={table} getQaDispositions={getQaDispositions} />
                ))}
              </Flex>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
