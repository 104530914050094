import React, { useState, useEffect } from "react";
import { Flex, Table, Thead, Th, Tr, Tbody, Td, Button, Text } from "@chakra-ui/react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  LabelList,
  Cell,
  ResponsiveContainer,
  Label,
} from "recharts";

import { colors } from "../../config/colors";

const QaCallReportInternalCategoryChart = (data) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 50,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />

        <Tooltip />
        <Legend />
        {/* {data ? data.map((item, index) => <Bar dataKey={item.average_call_length} fill={colors[index]} />) : null} */}
        <Bar
          dataKey="excellent"
          fill="#9AE6B4"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="excellent" position="center" />
        </Bar>
        <Bar
          dataKey="satisfactory"
          fill="#F6AD55"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="satisfactory" position="center" />
        </Bar>
        <Bar
          dataKey="poor"
          fill="#FC8181"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="poor" position="center" />
        </Bar>
        <Bar
          dataKey="very_poor"
          fill="#E53E3E"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="very_poor" position="center" />
        </Bar>
        <Bar
          dataKey="client_issues"
          fill="#63B3ED"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="client_issues" position="center" />
        </Bar>
        <Bar
          dataKey="miscellaneous"
          fill="#A0AEC0"
          // label={{ fontSize: 10 }}
          minPointSize={10}
        >
          <LabelList dataKey="miscellaneous" position="center" />
        </Bar>
        {/* <Bar dataKey="affiliate_name" fill={colors[3]} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const QaCallReportInternalDispoChart = (data) => {
  const logMe = () => {
    console.log(data);
  };

  return (
    <ResponsiveContainer width="100%" height={800}>
      <BarChart
        width={600}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 50,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />

        <Tooltip />
        {/* <Legend /> */}
        {/* {data ? data.map((item, index) => <Bar dataKey={item.average_call_length} fill={colors[index]} />) : null} */}
        {Object.keys(data[0]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#9AE6B4"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
        {Object.keys(data[1]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#F6AD55"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
        {Object.keys(data[2]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#FC8181"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
        {Object.keys(data[3]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#E53E3E"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
        {Object.keys(data[4]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#63B3ED"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
        {Object.keys(data[5]).map((key, index) => (
          <Bar
            dataKey={key !== "name" ? key : null}
            fill="#A0AEC0"
            // label={{ fontSize: 10 }}
            minPointSize={10}
            barSize={20}
          >
            <LabelList dataKey={key !== "name" ? key : null} position="center" />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export { QaCallReportInternalCategoryChart, QaCallReportInternalDispoChart };
