import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Textarea,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { editBlacklistPartner, deleteBlacklistPartner } from "./PartnersApi";

import { BsTrash } from "react-icons/bs";
import ConfirmationModal from "../modals/ConfirmationModal";

export default function EditBlacklistModal({ partner, getAllPartners }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const [name, setName] = useState(partner.name);
  const [contactInfo, setContactInfo] = useState(partner.contactInfo);
  const [phone, setPhone] = useState(partner.phone);
  const [businessAddress, setBusinessAddress] = useState(partner.address);
  const [notes, setNotes] = useState(partner.notes);
  const [landingPages, setLandingPages] = useState(partner.landingPages);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      uuid: partner.uuid,
      name,
      contactInfo,
      phone,
      address: businessAddress,
      notes,
      landingPages,
      created: partner.created,
    };
    try {
      const response = await editBlacklistPartner(data);
      setSuccess(true);
      setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        getAllPartners();
        onClose();
      }, 2000);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const data = {
      uuid: partner.uuid,
    };
    try {
      const response = await deleteBlacklistPartner(data);
      setTimeout(() => {
        setDeleteLoading(false);
        getAllPartners();
        onClose();
      }, 2000);
    } catch (error) {
      alert(error.message);
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <Tr _hover={{ backgroundColor: hoverColor }} cursor="pointer">
        <Td textAlign="center" onClick={onOpen}>
          {partner.created}
        </Td>
        <Td textAlign="center" onClick={onOpen}>
          {partner.name}
        </Td>
        <Td textAlign="center" onClick={onOpen} maxW="500px">
          {partner.contactInfo}
        </Td>
        <Td textAlign="center" onClick={onOpen}>
          {partner.phone}
        </Td>
        <Td textAlign="center" onClick={onOpen}>
          {partner.businessAddress}
        </Td>
        <Td textAlign="center" onClick={onOpen}>
          {partner.landingPages}
        </Td>
        <Td textAlign="center" onClick={onOpen}>
          {partner.notes}
        </Td>
        <Td>
          {/* <IconButton
            onClick={handleDelete}
            size="sm"
            icon={<BsTrash />}
            isLoading={deleteLoading}
            colorScheme="teal"
          /> */}
          <ConfirmationModal
            colorScheme="teal"
            isIconButton
            buttonSize="sm"
            icon={<BsTrash />}
            loading={deleteLoading}
            success={success}
            header="Delete Confirmation"
            messages={["Are you sure you want to delete this?"]}
            alert="This cannot be undone."
            buttonText="Delete"
            event={handleDelete}
          />
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Partner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="phone" isRequired mb={3}>
              <FormLabel>Partner Name</FormLabel>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>

            <FormControl id="contact" mb={3}>
              <FormLabel>Contact Info</FormLabel>
              <Textarea
                placeholder="Enter all contact emails here divided with a comma."
                type="text"
                value={contactInfo}
                onChange={(e) => setContactInfo(e.target.value)}
              />
            </FormControl>

            <FormControl id="phone" mb={3}>
              <FormLabel>Phone Number</FormLabel>
              <Input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </FormControl>

            <FormControl id="address" mb={3}>
              <FormLabel>Address</FormLabel>
              <Input
                type="text"
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </FormControl>

            <Flex flexDir="column" mb={3}>
              <FormLabel>Landing Pages</FormLabel>
              <Textarea
                placeholder="Enter all landing page urls here divided with a comma."
                value={landingPages}
                onChange={(e) => setLandingPages(e.target.value)}
              />
            </Flex>
            <Flex flexDir="column" mb={3}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                placeholder="Enter any additional notes here."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            {success ? (
              <Alert status="success" mr="20px">
                <AlertIcon />
                <AlertDescription>Success!</AlertDescription>
              </Alert>
            ) : null}
            <Button colorScheme="teal" mr={3} onClick={handleSubmit} isLoading={loading}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
