import React, { useState } from "react";
import {
  Button,
  Input,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  IconButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
// import { v4 as uuidv4 } from "uuid";

import { GoPlus } from "react-icons/go";
// import { FiEdit, FiSave } from "react-icons/fi";
// import { BsTrash } from "react-icons/bs";

export default function NewContract({ contacts, setContacts }) {
  // const hoverColor = useColorModeValue("gray.200", "gray.500");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [loading, setLoading] = useState(false);

  const resetFields = () => {
    console.log("reset");
  };

  const addNewContract = async () => {
    console.log("add");
    resetFields();
  };

  return (
    <>
      <Tooltip hasArrow placement="top" label="New Contract">
        <IconButton
          colorScheme="teal"
          onClick={onOpen}
          aria-label="Open Call Details"
          icon={<GoPlus />}
          size="sm"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ADD NEW CONTRACT</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w="100%" flexDir="column">
              <Flex flexDir="column">
                <Text>Contract Name</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Status</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Terms</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Filters</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Schedule</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Footprints</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Rate</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Notes</Text>
                <Input />
              </Flex>
              <Flex flexDir="column">
                <Text>Forms</Text>
                <Input />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addNewContract}>
              Add
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
