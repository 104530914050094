import axios from "axios";
import moment from "moment";

const getInsights = async (data) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment(data.endDate).add(1, "d").format("YYYY-MM-DD");
  const start_date = moment(data.startDate).format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "campaignName",
      },
      {
        column: "targetName",
      },
    ],
    valueColumns: [
      {
        column: "liveCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [
      { column: "campaignName", direction: "asc" },
      { column: "targetName", direction: "asc" },
    ],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
    formatTimeZone: "America/Los_Angeles",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    const formattedData = formatInsightData(data);
    // console.log("formattedData::", formattedData);
    // let realData = [];
    // data.forEach((item) => {
    //   if (item.campaignId) {
    //     realData.push(item);
    //   }
    //   if (!item.campaignId && !item.campaignName) {
    //     realData.push(item);
    //   }
    // });
    // return realData;
    return formattedData;
  } catch (error) {
    console.error(error);
  }
};

const formatInsightData = (data) => {
  let campaignClusters = [];
  data.forEach((entry) => {
    let cluster = {
      campaign: null,
      targets: [],
    };
    if (!entry.targetName) {
      cluster.campaign = entry;
      campaignClusters.push(cluster);
    }
  });
  campaignClusters.forEach((cluster) => {
    data.forEach((entry) => {
      if (
        entry.campaignName === cluster.campaign.campaignName &&
        entry.targetName !== "-no value-"
      ) {
        cluster.targets.push(entry);
      }
    });
  });
  return campaignClusters;
};

const getInsightsTargets = async (data) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/insights/`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const end_date = moment(data.endDate).add(1, "d").format("YYYY-MM-DD");
  const start_date = moment(data.startDate).format("YYYY-MM-DD");
  const payload = {
    reportEnd: `${end_date}T11:59:00Z`,
    reportStart: `${start_date}T07:00:00Z`,
    size: 500,
    groupKeys: [],
    groupByColumns: [
      {
        column: "targetName",
      },
      {
        column: "campaignName",
      },
    ],
    valueColumns: [
      {
        column: "liveCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "connectedCallCount",
        aggregrateFunction: "count",
      },
      {
        column: "convertedCalls",
        aggregrateFunction: "count",
      },
      {
        column: "payoutAmount",
        aggregrateFunction: "sum",
      },
      {
        column: "totalCost",
        aggregrateFunction: "sum",
      },
      {
        column: "convertedPercent",
        aggregrateFunction: "percent",
      },
      {
        column: "earningsPerCallNet",
        aggregrateFunction: "avg",
      },
      {
        column: "avgHandleTime",
        aggregrateFunction: "avg",
      },
    ],
    orderByColumns: [
      { column: "targetName", direction: "asc" },
      { column: "campaignName", direction: "asc" },
    ],
    filters: [],
    formatTimespans: "true",
    formatPercentages: "true",
    generateRollups: "true",
    formatTimeZone: "America/Los_Angeles",
  };
  try {
    const response = await axios.post(url, payload, config);
    // console.log("api response::", response);
    const data = response.data.report.records;
    // console.log("data:", data);
    const formattedData = formatTargetInsightData(data);
    // console.log("formattedData::", formattedData);
    // let realData = [];
    // data.forEach((item) => {
    //   if (item.campaignId) {
    //     realData.push(item);
    //   }
    //   if (!item.campaignId && !item.campaignName) {
    //     realData.push(item);
    //   }
    // });
    // return realData;
    return formattedData;
  } catch (error) {
    console.error(error);
  }
};

const formatTargetInsightData = (data) => {
  let targetClusters = [];
  data.forEach((entry) => {
    let cluster = {
      target: null,
      campaigns: [],
    };
    if (!entry.campaignName && entry.targetName !== "-no value-") {
      cluster.target = entry;
      targetClusters.push(cluster);
    }
  });
  console.log("targetClusters:", targetClusters);
  targetClusters.forEach((cluster) => {
    data.forEach((entry) => {
      if (entry.targetName === cluster.target.targetName && entry.targetName !== "-no value-") {
        cluster.campaigns.push(entry);
      }
    });
  });
  return targetClusters;
};

const getCallPlans = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.callPlans;
    let theCallPlanId;
    data.forEach((plan) => {
      if (plan.name === campaignName) {
        theCallPlanId = plan.id;
        return;
      }
    });
    if (!theCallPlanId) return false;
    return theCallPlanId;
  } catch (error) {
    console.error(error);
  }
};

const getCallRoutesWithId = async (campaignName) => {
  const callRouteId = await getCallPlans(campaignName);
  if (!callRouteId) return false;

  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callplan/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log(response);
    const data = response.data.callPlan;
    //   console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const updateRoutingWeightPriority = async (callRouteId, priorityNum, weightNum) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/callroutes/${callRouteId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    id: callRouteId,
    priority: {
      priority: priorityNum,
      weight: weightNum,
    },
  };
  try {
    const response = await axios.patch(url, payload, config);
    // console.log("updateRoutingWeightPriority response:", response);
    const data = response.data.callRoute.priority;
    //   console.log(data);
    // return response;
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getCampaignCaps = async (campaignName) => {
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.get(url, config);
    // console.log("campaignData:", response);
    const data = response.data.campaigns;
    let theCampaign;
    data.forEach((campaign) => {
      if (campaign.name === campaignName) {
        theCampaign = campaign;
        return;
      }
    });
    if (!theCampaign) return false;
    return theCampaign;
  } catch (error) {
    console.error(error);
  }
};

const updateCampaignCaps = async (
  campaignId,
  affiliateId,
  payoutId,
  caps,
  payoutAmount,
  callLength
) => {
  // console.log("campaignId:", campaignId);
  // console.log("affiliateId:", affiliateId);
  // console.log("payoutId:", payoutId);
  // console.log("caps:", caps);
  // console.log("payoutAmount:", payoutAmount);
  // console.log("callLength:", callLength);
  const url = `https://api.ringba.com/v2/${process.env.REACT_APP_RINGBA_ACCOUNT_ID}/campaigns/${campaignId}/affiliates/${affiliateId}/payouts/${payoutId}`;
  const config = {
    headers: {
      Authorization: `Token ${process.env.REACT_APP_RINGBA_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  };
  const payload = {
    id: payoutId,
    dailyCap: caps.dailyCap === 0 || caps.dailyCap === "0" ? null : caps.dailyCap,
    concurrencyCap:
      caps.concurrencyCap === 0 || caps.concurrencyCap === "0" ? null : caps.concurrencyCap,
    hourlyCap: caps.hourlyCap === 0 || caps.hourlyCap === "0" ? null : caps.hourlyCap,
    payoutConversionType: "callLength",
    payoutConversionArgs: {
      callLengthInSeconds: callLength,
    },
    payoutAmount,
  };

  try {
    const response = await axios.patch(url, payload, config);

    const data = response.data;
    if (data.transactionId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

export {
  getInsights,
  getInsightsTargets,
  getCallRoutesWithId,
  updateRoutingWeightPriority,
  getCampaignCaps,
  updateCampaignCaps,
};
