import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";
import NewContract from "../active-live/NewContract";

export default function ActiveJobs() {
  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="1vh"
      mx="10px"
      p={6}
      alignSelf="center"
      justifySelf="center"
    >
      <TopHeading
        title="Active Jobs"
        description="Statuses of all currently running campaigns."
        children={[<NewContract />]}
      />
    </Box>
  );
}
