import axios from "axios";
import moment from "moment";

const lp_api_key = "65fc34d5e2f764af0435b10d57302c4b";
const lp_api_secret = "0443811f3a8c12f7e7a85093fae6619d";

const getMortgageClusters = async (start_date, end_date) => {
  // const end_date = moment().format("YYYY-MM-DD");
  // const start_date = moment().subtract(1, "d").format("YYYY-MM-DD");
  // const end_date = "2022-02-22";
  // const start_date = "2022-02-21";
  const url = `https://51v2f701pi.execute-api.us-west-1.amazonaws.com/mortgage-suite`;
  const config = {
    headers: {
      "Content-Type": "text/plain",
    },
  };
  const payload = {
    beginDate: start_date,
    endDate: end_date,
  };

  //   console.log("payload:", payload);
  try {
    const response = await axios.post(url, payload, config);
    // console.log(response);
    const data = response.data;
    // console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const getMortgageClusters2 = () => {
  var data = JSON.stringify({ beginDate: "2022-01-13", endDate: "2022-01-14" });

  var config = {
    method: "post",
    url: "https://51v2f701pi.execute-api.us-west-1.amazonaws.com/mortgage-suite",
    withCredentials: false,
    headers: {
      "Content-Type": "text/plain",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

const deleteLeadContractFilter = async (filterID) => {
  console.log("deleteLeadContractFilter filterID:", filterID);
  const url = `https://api.leadspedia.com/core/v2/leadDistributionContracts/deleteFilter.do?api_secret=${lp_api_secret}&api_key=${lp_api_key}&filterID=${filterID}`;

  try {
    const response = await axios.get(url);
    // console.log("deleteLeadContractFilter response:", response);
    const result = response.data.message;
    // console.log("deleteLeadContractFilter result:", result);
  } catch (error) {
    console.error(error.message);
  }
};

const createNewLeadContractFilter = async (contractID, value, operator) => {
  // console.log("createNewLeadContractFilter:", value);
  const formatAgentIDs = () => {
    let agentIdArray = [];
    value.forEach((agent) => {
      agentIdArray.push(agent.agentID);
    });
    return agentIdArray;
  };
  const agentIdValues = formatAgentIDs().join(", ");
  // console.log("agentIdValues:", agentIdValues);
  const fieldID = 88;
  const url = `https://api.leadspedia.com/core/v2/leadDistributionContracts/addFilter.do?api_secret=${lp_api_secret}&api_key=${lp_api_key}&contractID=${contractID}&fieldID=${fieldID}&value=${agentIdValues}&operator=${operator}`;

  try {
    const response = await axios.get(url);
    // console.log("createNewLeadContractFilter response:", response);
    const result = response.data.message;
    // console.log("createNewLeadContractFilter result:", result);
  } catch (error) {
    console.error(error.message);
  }
};

const getLeadContractFilters = async (filterData) => {
  let filterID;
  const getAgentFilterId = (data) => {
    data.forEach((filter) => {
      if (filter.uniqueName === "agent_id" && filter.operator === "Equals") {
        // console.log("filter:", filter);
        // console.log("filterID1:", filter.filterID);
        filterID = filter.filterID;
      }
    });
  };

  // console.log("filterData", filterData);
  const contractID = filterData.contractID;
  // const contractID = 207;
  const operator = "Equals";
  const value = filterData.value;

  const url = `https://api.leadspedia.com/core/v2/leadDistributionContracts/getFilters.do?api_secret=${lp_api_secret}&api_key=${lp_api_key}&contractID=${contractID}`;

  try {
    // console.log(contractID);
    const response = await axios.get(url);
    const result = response.data.response.data;
    // console.log("getLeadContractFilters result:", result);
    getAgentFilterId(result);

    if (!filterID) {
      await createNewLeadContractFilter(contractID, value, operator);
      // console.log("No Agent ID filter to delete.");
      return;
    } else {
      await deleteLeadContractFilter(filterID);
      // console.log("value:", value);
      if (value.length > 0) {
        await createNewLeadContractFilter(contractID, value, operator);
      }
    }

    // Delete Filter

    // return res.status(200).json({
    //   message: `Successfully retrieved filters for Contract ID ${contractId}`,
    //   result,
    // });
  } catch (error) {
    // return res.status(400).json({
    //   message: error.message,
    // });
  }
};

export { getMortgageClusters, getMortgageClusters2, getLeadContractFilters };
