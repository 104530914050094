import { Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
// import { AiFillSound } from "react-icons/ai";
import ReactPlayer from "react-player";

// import "./ReactPlayer.css";

export default function MediaPlayer({ url }) {
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  return (
    <Flex flexDir="column" h="80px" w="100%">
      <ReactPlayer
        url={url}
        className="react-player"
        controls
        playbackRate={playbackSpeed}
        width="100%"
        height="100%"
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
      <Flex justifyContent="flex-end" alignItems="center" mt={1} pb={3}>
        <Text fontSize={14}>Speed:</Text>
        <Button
          size="xs"
          mx={2}
          onClick={() => setPlaybackSpeed(1)}
          backgroundColor={playbackSpeed === 1 ? "teal.300" : null}
        >
          x1
        </Button>
        <Button
          size="xs"
          mx={2}
          onClick={() => setPlaybackSpeed(1.2)}
          backgroundColor={playbackSpeed === 1.2 ? "teal.300" : null}
        >
          x1.2
        </Button>
        <Button
          size="xs"
          mx={2}
          onClick={() => setPlaybackSpeed(1.5)}
          backgroundColor={playbackSpeed === 1.5 ? "teal.300" : null}
        >
          x1.5
        </Button>
        <Button
          size="xs"
          onClick={() => setPlaybackSpeed(1.75)}
          backgroundColor={playbackSpeed === 1.75 ? "teal.300" : null}
        >
          x1.75
        </Button>
      </Flex>
    </Flex>
  );
}
