import React, { useState } from "react";
import { Box, Heading, Button, Flex } from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";
import UploadDispo from "../components/tools/UploadDispo";

export default function Tools() {
  const [view, setView] = useState();

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      p={6}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Tools"
        description="Useful tools for internal tasks."
        children={[
          <Button colorScheme="teal" onClick={() => setView("dispo")}>
            Upload Dispo
          </Button>,
        ]}
      />
      <Flex w="100%" justifyContent="center">
        {view === "dispo" ? <UploadDispo /> : null}
      </Flex>
    </Box>
  );
}
