import React, { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ChakraProvider, Flex, Box, Heading } from "@chakra-ui/react";
import { auth, logout, signInWithEmailAndPassword } from "../src/firebase";

import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Dashboard from "./pages/Dashboard";
import ActiveJobs from "./pages/ActiveJobs";
import Reports from "./pages/Reports";
import DncList from "./pages/DncList";
import Resources from "./pages/Resources";
import Login from "./pages/Login";
import Tools from "./pages/Tools";
import Mortgage from "./pages/Mortgage";
import QualityAssurance from "./pages/QualityAssurance";
import MortgageSettings from "./components/mortgage/MortgageSettings";
import AgentList from "./components/mortgage/AgentList";
import Affiliates from "./components/partners/Affilates";
import Buyers from "./components/partners/Buyers";
import Blacklist from "./components/partners/Blacklist";
import Campaigns from "./components/performance/Campaigns";
import Targets from "./components/performance/Targets";
import QaCalls from "./components/qa/QaCalls";
import QaAssignments from "./components/qa/QaAssignments";
import BLA from "./components/blocked/BLA";
import ActiveLive from "./components/qa/ActiveLive";
import RoboSpam from "./components/blocked/RoboSpam";
import JobsCampaigns from "./components/jobs/JobsCampaigns";
import JobsTargets from "./components/jobs/JobsTargets";
import Jornaya from "./components/blocked/Jornaya";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const resetInput = () => {
    setEmail("");
    setPassword("");
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) setName(user.displayName);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        w="100vw"
        h="100vh"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
      >
        <Heading>Loading User...</Heading>
      </Box>
    );
  }

  return (
    <ChakraProvider>
      <BrowserRouter>
        {!currentUser ? (
          <Login
            login={signInWithEmailAndPassword}
            resetInput={resetInput}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        ) : (
          <Flex>
            <Sidebar logout={logout} name={name} />
            <Switch>
              <Route path="/" exact render={() => <Dashboard />} />
              <Route path="/campaigns" exact render={() => <Campaigns />} />
              <Route path="/targets" exact render={() => <Targets />} />
              <Route path="/jobs/campaigns" exact render={() => <JobsCampaigns />} />
              <Route path="/jobs/targets" exact render={() => <JobsTargets />} />
              <Route path="/contracts" exact render={() => <ActiveJobs />} />
              <Route path="/affiliates" exact render={() => <Affiliates />} />
              <Route path="/buyers" exact render={() => <Buyers />} />
              <Route path="/blacklist" exact render={() => <Blacklist />} />
              <Route path="/reports" exact render={() => <Reports />} />
              <Route path="/mortgage" exact render={() => <Mortgage username={name} />} />
              <Route path="/mortgage-settings" exact render={() => <MortgageSettings />} />

              <Route path="/lo-list" exact render={() => <AgentList />} />
              <Route path="/qa-calls" exact render={() => <QaCalls username={name} />} />
              <Route
                path="/qa-assignments"
                exact
                render={() => <QaAssignments username={name} />}
              />
              <Route path="/qa-active-live" exact render={() => <ActiveLive />} />
              <Route path="/qa" exact render={() => <QualityAssurance />} />
              <Route path="/jornaya" exact render={() => <Jornaya />} />
              <Route path="/bla" exact render={() => <BLA />} />
              <Route path="/dnclist" exact render={() => <DncList />} />
              <Route path="/robospam" exact render={() => <RoboSpam />} />
              <Route path="/tools" exact render={() => <Tools />} />
              <Route path="/resources" exact render={() => <Resources />} />
            </Switch>
          </Flex>
        )}
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
