import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  IconButton,
  FormControl,
  Input,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import TopHeading from "../../components/TopHeading";

import { lookUpJornayaId } from "../blocked/JornayaApi";

import { FiSearch } from "react-icons/fi";

export default function Jornaya() {
  const [leadId, setLeadId] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const ValidateJornayaId = async () => {
    if (data) setData(null);
    if (!leadId) {
      alert("Enter valid Lead ID.");
      return;
    }

    setLoading(true);

    try {
      const result = await lookUpJornayaId(leadId);
      setData(result);
      console.log(result);
      setLoading(false);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const openJornayaValidationLink = () => {
    const url = `https://api.leadid.com/Authenticate?lac=6C150E74-BF7B-B8B1-1100-9FD4488A1F84&id=${leadId}`;
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Jornaya"
        description="Authenticate Jornaya Lead ID"
        children={
          <Flex alignItems="center">
            <FormControl mr={4}>
              <Input
                w="400px"
                id="number"
                type="text"
                onChange={(e) => setLeadId(e.target.value)}
                placeholder="Enter Lead ID here."
              />
            </FormControl>
            <Link
              href={`https://api.leadid.com/Authenticate?lac=6C150E74-BF7B-B8B1-1100-9FD4488A1F84&id=${leadId}`}
              isExternal
            >
              <IconButton mr="10px" aria-label="Generate DNC NList" icon={<FiSearch />} />
            </Link>
          </Flex>
        }
      />

      <Flex flexDir="column">
        {loading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="teal.300" />
          </Flex>
        ) : null}

        <Flex>
          <Box p={4} borderWidth={1} w="50%">
            <Text fontWeight={700} fontSize={24}>
              Notes:
            </Text>
            <UnorderedList>
              <ListItem fontSize={18}>
                Due to security measures from Jornaya, searching a Jornaya Lead ID will open a new
                browser tab which will result in a JSON response.
              </ListItem>
              <ListItem fontSize={18}>
                If you see "authentic":1 in the reponse, this means that the Lead ID is VALID.
              </ListItem>
              <ListItem fontSize={18}>
                If you see "authentic":0 in the reponse, this means that the Lead ID is INVALID.
              </ListItem>
            </UnorderedList>
          </Box>

          <Box w="50%" borderWidth={1} p={4}>
            <Flex mt={2} flexDir="column">
              <Text fontWeight={700} fontSize={24}>
                RESPONSE EXAMPLES:
              </Text>
              <Box mb={5}>
                <Text fontWeight={700}>VALID ID RESPONSE</Text>
                <Text>
                  &#123;"authenticate":&#123;
                  <span style={{ color: "green", fontWeight: "700" }}>"authentic":1</span>
                  ,"token":"B6344E7A-E7E2-A573-7D13-BBD6AB274FBE"&#125;&#125;
                </Text>
              </Box>
              <Box>
                <Text fontWeight={700}>INVALID ID RESPONSE</Text>
                <Text>
                  &#123;"authenticate":&#123;
                  <span style={{ color: "red", fontWeight: "700" }}>"authentic":0</span>
                  ,"token":"B6344E7A-E7E2-A573-7D13-BBD6AB274FBE"&#125;&#125;
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Box w="50%" borderWidth={1} p={4}>
          <Text fontWeight={700} fontSize={24}>
            REASONS FOR INVALID JORNAYA VALIDATION
          </Text>
          <UnorderedList>
            <ListItem fontSize={18}>This was not a token that Jornaya created.</ListItem>
            <ListItem fontSize={18}>
              This token has been queried too many times (i.e. hundreds or thousands of times) when
              Jornaya’s partners performed an API call to our system.
            </ListItem>
            <ListItem fontSize={18}>This token creation is over a year old.</ListItem>
            <ListItem fontSize={18}>
              This token is associated with a consumer who has exercised their CCPA Right to
              Opt-out.
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>
    </Box>
  );
}
