import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Link,
  Text,
  ButtonGroup,
  Spinner,
  useColorModeValue,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { FiExternalLink, FiRefreshCcw } from "react-icons/fi";
import { BiCopy } from "react-icons/bi";
import { AiFillPhone, AiFillMessage } from "react-icons/ai";

// import { createNewDriveFolder } from "../GoogleDriveApi";

import { addNewBuyer, deleteBuyer, editBuyer, getAllBuyers } from "../partners/PartnersApi";

import TopHeading from "../TopHeading";
import NewBuyerModal from "./NewBuyerModal";
import EditBuyerModal from "../partners/EditBuyerModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import { BsTrash } from "react-icons/bs";
import UploadToFirebase from "../modals/UploadToFirebase";

const axios = require("axios");

export default function Buyers() {
  const [buyers, setBuyers] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.500");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const getAllBuyersNow = async () => {
    if (!buyers) setGetLoading(true);
    let newBuyers;
    try {
      const response = await getAllBuyers();
      console.log(response);
      newBuyers = response.result;
      setBuyers(newBuyers);
      setGetLoading(false);
    } catch (error) {
      alert(error.message);
      setGetLoading(false);
    }
  };

  const handleDelete = async (uuid) => {
    setDeleteLoading(true);
    const data = {
      uuid,
    };
    try {
      const response = await deleteBuyer(data);
      setDeleteLoading(false);
      getAllBuyersNow();
    } catch (error) {
      alert(error.message);
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    getAllBuyersNow();
  }, []);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      overflowY="auto"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Buyers"
        description="Information on all of our buyer partners."
        children={[
          <Flex>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Flex justifyContent="center" alignItems="center" mt="5px" w="100%">
                {/* <UploadToFirebase /> */}
                <IconButton
                  onClick={getAllBuyersNow}
                  mr="10px"
                  aria-label="Generate Buyer List"
                  icon={<FiRefreshCcw />}
                  isLoading={getLoading}
                />

                <NewBuyerModal getAllBuyersNow={getAllBuyersNow} />
              </Flex>
            </Flex>
          </Flex>,
        ]}
      />

      <Flex flexDir="column">
        {getLoading ? (
          <Flex justifyContent="center" alignItems="center" mt="10%">
            <Spinner size="xl" color="green.400" />
          </Flex>
        ) : null}
        <Table size="sm">
          <Thead>
            <Tr position="sticky" top={100} backgroundColor={bgColor} zIndex={1}>
              {buyers ? (
                <>
                  <Th w="8%" textAlign="center">
                    CREATED
                  </Th>
                  <Th w="8%" textAlign="center">
                    NAME
                  </Th>
                  <Th w="10%" textAlign="center">
                    ADDRESS
                  </Th>
                  <Th w="35%" textAlign="center">
                    CONTACT INFO
                  </Th>
                  <Th w="15%" textAlign="center">
                    ACCOUNTING
                  </Th>
                  <Th w="15%" textAlign="center">
                    HOLIDAYS
                  </Th>
                  <Th w="5%" textAlign="center">
                    DRIVE LINK
                  </Th>
                  <Th w="5%" textAlign="center">
                    EDIT
                  </Th>
                </>
              ) : null}
            </Tr>
          </Thead>
          <Tbody>
            {buyers
              ? buyers.map((item, i) => (
                  <Tr key={item.uuid} _hover={{ backgroundColor: hoverColor }}>
                    <Td textAlign="center">{item.created}</Td>
                    <Td textAlign="center">{item.name}</Td>
                    <Td textAlign="center">{item.address}</Td>
                    <Td textAlign="center" borderWidth={1}>
                      <Table size="sm">
                        {item.contacts && item.contacts.length > 0 ? (
                          <Thead>
                            <Tr>
                              <Th w="20%">PoC</Th>
                              <Th w="20%" textAlign="center">
                                Phone
                              </Th>
                              <Th w="30%">Email</Th>
                              <Th w="15%" textAlign="center">
                                Skype
                              </Th>
                              <Th w="15%" textAlign="center">
                                Vertical
                              </Th>
                            </Tr>
                          </Thead>
                        ) : (
                          <Text letterSpacing={3} fontSize={20}>
                            NO CONTACTS
                          </Text>
                        )}
                        <Tbody>
                          {item.contacts && item.contacts.length > 0
                            ? item.contacts.map((contact) => (
                                <Tr key={contact.id}>
                                  <Td>{contact.name}</Td>
                                  <Td textAlign="center">{contact.contactInfo.phone}</Td>
                                  <Td>
                                    <Flex>
                                      <Text mr={1}>{contact.contactInfo.email}</Text>
                                      <Icon
                                        as={BiCopy}
                                        _hover={{ color: "teal.300", fontWeight: 700 }}
                                        cursor="pointer"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `${contact.contactInfo.email}`
                                          );
                                        }}
                                      />
                                    </Flex>
                                  </Td>
                                  <Td textAlign="center">
                                    {contact.contactInfo.skype ? (
                                      <>
                                        <Link href={`skype:${contact.contactInfo.skype}?call`}>
                                          <IconButton
                                            icon={<AiFillPhone />}
                                            size="xs"
                                            colorScheme="teal"
                                          />
                                        </Link>
                                        <Link
                                          ml={2}
                                          href={`skype:${contact.contactInfo.skype}?chat`}
                                        >
                                          <IconButton
                                            icon={<AiFillMessage />}
                                            size="xs"
                                            colorScheme="teal"
                                          />
                                        </Link>
                                      </>
                                    ) : null}
                                  </Td>
                                  <Td>{contact.vertical}</Td>
                                </Tr>
                              ))
                            : null}
                        </Tbody>
                      </Table>
                    </Td>

                    <Td textAlign="center" borderWidth={1}>
                      {item.accounting.name ||
                      item.accounting.phone ||
                      item.accounting.email ||
                      item.accounting.skype ||
                      item.accounting.terms ? (
                        <Table size="sm">
                          <Tbody>
                            <Tr>
                              <Td fontWeight={700}>Name:</Td>
                              <Td>{item.accounting.name}</Td>
                            </Tr>
                            <Tr>
                              <Td fontWeight={700}>Phone:</Td>
                              <Td>{item.accounting.phone}</Td>
                            </Tr>
                            <Tr>
                              <Td fontWeight={700}>Email:</Td>
                              <Td>
                                {item.accounting.email ? (
                                  <Flex>
                                    <Text mr={1}>{item.accounting.email}</Text>
                                    <Icon
                                      as={BiCopy}
                                      _hover={{ color: "teal.300", fontWeight: 700 }}
                                      cursor="pointer"
                                      onClick={() => {
                                        navigator.clipboard.writeText(`${item.accounting.email}`);
                                      }}
                                    />
                                  </Flex>
                                ) : null}
                              </Td>
                            </Tr>
                            <Tr>
                              {item.accounting.skype ? (
                                <>
                                  <Td fontWeight={700}>Skype:</Td>
                                  <Td>
                                    <Link href={`skype:${item.accounting.skype}?call`}>
                                      <IconButton
                                        icon={<AiFillPhone />}
                                        size="xs"
                                        colorScheme="teal"
                                      />
                                    </Link>
                                    <Link ml={2} href={`skype:${item.accounting.skype}?chat`}>
                                      <IconButton
                                        icon={<AiFillMessage />}
                                        size="xs"
                                        colorScheme="teal"
                                      />
                                    </Link>
                                  </Td>
                                </>
                              ) : null}
                            </Tr>
                            <Tr>
                              <Td fontWeight={700}>Terms:</Td>
                              <Td>{item.accounting.terms}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      ) : (
                        <Text letterSpacing={3}>NO ACCOUNTING CONTACT</Text>
                      )}
                    </Td>
                    <Td>{item.holidays}</Td>
                    <Td>
                      <Flex justify="center">
                        {item.driveLink ? (
                          <Link href={item.driveLink} isExternal>
                            <FiExternalLink fontSize="20px" />
                          </Link>
                        ) : null}
                      </Flex>
                    </Td>
                    <Td textAlign="center">
                      <Flex>
                        <EditBuyerModal
                          buyerDetails={item}
                          getAllBuyersNow={getAllBuyersNow}
                          buyers={buyers}
                        />
                        <Box boxSize={2} />
                        <ConfirmationModal
                          buttonText="Delete"
                          header="Delete Confirmation"
                          messages={["Would you like to delete this buyer?"]}
                          alert="This cannot be undone"
                          event={() => handleDelete(item.uuid)}
                          isDisabled={deleteLoading}
                          isIconButton
                          icon={<BsTrash />}
                          loading={deleteLoading}
                          success={success}
                          failure={failure}
                          buttonSize="sm"
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
}
