import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Spinner,
  Select,
  ListItem,
  OrderedList,
  Tag,
} from "@chakra-ui/react";
import moment from "moment";
import { getLeadContractFilters } from "./MortgageApi";

import "./agentList.css";

import { v4 as uuidv4 } from "uuid";
import Spreadsheet from "react-spreadsheet";

import TopHeading from "../TopHeading";
import axios from "axios";

export default function AgentList() {
  const [contractName, setContractName] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
  ]);
  const columnLabels = ["Agent ID *", "First Name", "Last Name", "Full Name", "Email"];

  const resetTable = () => {
    setData([
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    ]);
  };

  const resetForm = () => {
    setData([
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
    ]);
    setContractName("Select Contract");
  };

  const createJsonData = () => {
    let allAgents = [];
    data.forEach((dataRow) => {
      //   console.log("dataRow:", dataRow);
      if (
        dataRow[0] &&
        dataRow[0].value &&
        ((dataRow[1] && dataRow[1].value && dataRow[2] && dataRow[2].value) ||
          (dataRow[3] && dataRow[3].value) ||
          (dataRow[4] && dataRow[4].value))
      ) {
        let name = "";
        if (dataRow[1] && dataRow[1].value && dataRow[2] && dataRow[2].value) {
          name = `${dataRow[1].value} ${dataRow[2].value}`;
        }
        if (dataRow[3] && dataRow[3].value) {
          name = dataRow[3].value;
        }
        const agentData = {
          agentID: dataRow[0].value ? dataRow[0].value : null,
          agentName: name ? name : null,
          agentEmail: dataRow[4] && dataRow[4].value ? dataRow[4].value : null,
        };
        allAgents.push(agentData);
      }
    });
    console.log(allAgents);
    return allAgents;
  };

  const formatToMatrix = (jsonData) => {
    let agentArray = [];
    jsonData.forEach((array) => {
      const item = [
        { value: array.agentID },
        { value: "" },
        { value: "" },
        { value: array.agentName },
        { value: array.agentEmail },
      ];
      agentArray.push(item);
    });
    // console.log("agentArray", agentArray);
    return agentArray;
  };

  const getAgentList = async (theContractName) => {
    setLoading(true);
    const agentData = {
      contract: theContractName,
    };
    try {
      const response = await axios.post("/api/mortgage/get-agents", agentData);

      // console.log("getAgentList response:", response);

      const result =
        response.data.result && response.data.result.agents ? response.data.result.agents : [];

      // console.log(result);
      if (result.length < 1) {
        resetTable();
        setLoading(false);
        return;
      }

      // console.log("getAgentList result:", result);

      const formattedResult = formatToMatrix(result);

      setData(formattedResult);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const updateAgentList = async () => {
    // console.log("contractName:", contractName);
    if (!contractName) {
      alert("Select a contract!");
      return;
    }
    setLoading(true);
    const getBuyerName = () => {
      const array = contractName.split("-");
      const buyerName = array[0];
      return buyerName;
    };

    const data = {
      buyer: getBuyerName(),
      contract: contractName,
      agents: createJsonData(),
    };
    try {
      // console.log("updateAgentList data:", data);
      const response = await axios.post("/api/mortgage/update-agents", data);
      const updateInLeadsPedia = await updateFilterInLeadspedia(data);

      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateFilterInLeadspedia = async (data) => {
    let contractID = null;
    if (data.contract === "Freedom Mortgage - Conventional Cash Out") {
      contractID = 307;
    }
    if (data.contract === "Freedom Mortgage - VA Cash Out") {
      contractID = 271;
    }
    if (data.contract === "My Resource Pro - VA") {
      contractID = 304;
    }
    if (data.contract === "My Resource Pro - Conventional Cash Out") {
      contractID = 311;
    }
    if (data.contract === "Loan Depot - Conventional") {
      contractID = 259;
    }
    if (contractID) {
      const filterData = {
        contractID,
        value: data.agents,
      };
      // console.log("filterData:", filterData);
      await getLeadContractFilters(filterData);
    } else {
      alert("Select a contract.");
    }
  };
  const logMe2 = async () => {
    console.log("data:", data);
  };

  const handleSelection = (contract) => {
    // console.log("handleSelection contract:", contract);
    setContractName(contract);
    getAgentList(contract);
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="1vh"
      mx="10px"
      p={6}
      overflowY="scroll"
      alignSelf="center"
      justifySelf="center"
    >
      <TopHeading
        title="Loan Officer List"
        description="Update LO list for all mortgage campaigns."
      />

      <Box overflowY="hidden">
        <Flex w="100%" flexDir="column" justifyContent="center">
          {/* <Button onClick={updateFilterInLeadspedia}>LOG ME</Button> */}
          {/* <Button onClick={logMe2}>LOG ME 2</Button> */}
          <Flex flexDir="column" mr="10px">
            <Flex flexDir="column" mb="15px">
              <OrderedList mb="5px">
                <ListItem>
                  Select the contract name.{" "}
                  <em>(If a LO list already exists, it will load it into the spreadsheet below)</em>
                </ListItem>
                <ListItem>Copy and paste the corresponding column into the table below.</ListItem>
                <ListItem>
                  Click the <Tag colorScheme="teal">Update</Tag> button
                </ListItem>
              </OrderedList>
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle fontSize="14px">
                  Agent ID and one form of (First Name + Last Name), Full Name, or Email is Required
                </AlertTitle>
              </Alert>
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle fontSize="14px">Backspace is the delete for this table</AlertTitle>
              </Alert>
              <Alert status="info" colorScheme="teal">
                <AlertIcon />
                <AlertTitle fontSize="14px">
                  This will update the publisher mortgage forms agent dropdown list and update the
                  filters in Leadspedia.
                </AlertTitle>
              </Alert>
              {/* <Text color="purple.300">
                Agent ID and one form of Agent Name or Email is Required
              </Text>
              <Text color="purple.300">Note: Backspace is the delete for this table</Text> */}
            </Flex>
            <Text>Contract Name</Text>
            <Flex>
              <Select
                placeholder="Select Contract"
                value={contractName}
                onChange={(e) => handleSelection(e.target.value)}
                mb="20px"
              >
                <option value="Freedom Mortgage - Conventional Cash Out">
                  Freedom Mortgage - Conventional Cash Out
                </option>
                <option value="Freedom Mortgage - VA Cash Out">
                  Freedom Mortgage - VA Cash Out
                </option>
                <option value="Loan Depot - Conventional">Loan Depot - Conventional</option>
                <option value="My Resource Pro - Conventional Cash Out">
                  My Resource Pro - Conventional Cash Out
                </option>
                <option value="My Resource Pro - VA">My Resource Pro - VA</option>
              </Select>
              <Button w="200px" ml="20px" colorScheme="teal" onClick={updateAgentList}>
                {loading ? <Spinner size="md" color="teal.300" /> : "Update"}
              </Button>
              <Button w="200px" ml="10px" onClick={resetForm}>
                Reset
              </Button>
            </Flex>
            {data ? (
              <Spreadsheet
                data={data}
                onChange={setData}
                columnLabels={columnLabels}
                className="spreadsheet"
              />
            ) : null}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
