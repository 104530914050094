import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Link,
  Divider,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  FormControl,
  FormLabel,
  Icon,
  filter,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import TopHeading from "../components/TopHeading";
import { getMortgageClusters } from "../components/mortgage/MortgageApi";
import MortgageModal from "../components/mortgage/MortgageModal";
import DatePicker from "react-datepicker";

import { FiRefreshCcw } from "react-icons/fi";
import { FaFileExport } from "react-icons/fa";

import moment from "moment";
import LeadFiltersModal from "../components/mortgage/LeadFiltersModal";

export default function Mortgage({ username }) {
  const [data, setData] = useState();
  const [csvData, setCsvData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterLists, setFilterLists] = useState([]);
  const [sortByColumnName, setSortByColumnName] = useState("created");
  const fileName = `mortgage_leads_${moment().format("MMDDYYYY")}.csv`;
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput onClick={onClick} ref={ref} value={value} />
  ));
  const bgColor = useColorModeValue("white", "#1A202C");
  const refreshButtonColor = useColorModeValue("teal.300", "teal.500");
  const refreshButtonTextColor = useColorModeValue("white", "gray.800");

  const updateFilteredData = (
    filters,
    filterOperator,
    filterBuyer,
    filterContract,
    filterPublisher
  ) => {
    // console.log("filters:", filters);
    if (filters.calls.length < 1 && filters.data.length < 1) {
      setFilteredData([]);
      return;
    }

    let newData = [];
    if (data) {
      setFilteredData(null);

      data.forEach((cluster) => {
        let callStatus;
        let dataStatus;

        // console.log("billableStatus:", billableStatus);

        if (cluster.info.missing_call) {
          callStatus = "missing";
        } else if (!cluster.info.valid_call) {
          callStatus = "invalid";
        } else {
          callStatus = "valid";
        }

        if (cluster.info.missing_data) {
          dataStatus = "missing";
        } else if (!cluster.info.valid_data) {
          dataStatus = "invalid";
        } else {
          dataStatus = "valid";
        }

        let buyerAllowed = true;
        let contractAllowed = true;
        let publisherAllowed = true;
        let clusterAllowed = false;
        if (filterBuyer && cluster.buyer !== filterBuyer) {
          buyerAllowed = false;
        }
        if (filterContract && cluster.contract !== filterContract) {
          contractAllowed = false;
        }
        if (filterPublisher && cluster.publisher_name !== filterPublisher) {
          publisherAllowed = false;
        }
        if (buyerAllowed && contractAllowed && publisherAllowed) {
          clusterAllowed = true;
        }

        if (filterOperator === "And") {
          if (
            filters.calls.includes(callStatus) &&
            filters.data.includes(dataStatus) &&
            clusterAllowed
          ) {
            newData.push(cluster);
          }
        } else {
          if (
            (filters.calls.includes(callStatus) || filters.data.includes(dataStatus)) &&
            clusterAllowed
          ) {
            // console.log("added cluster:", cluster);
            newData.push(cluster);
          }
        }
      });

      setFilteredData(newData);
      // console.log("newData:", newData);
      // console.log("newerData:", newerData);
    }
  };

  const pullMortgageClusters = async () => {
    setData(null);
    setFilteredData(null);

    setLoading(true);

    try {
      const mortgageData = await getMortgageClusters(
        moment(startDate).format("Y-MM-DD"),
        moment(endDate).add(1, "day").format("Y-MM-DD")
      );
      // console.log("mortgageData:", mortgageData);
      setData(mortgageData);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const pullUpdatedMortgageClusters = async () => {
    setFilteredData(null);

    try {
      const mortgageData = await getMortgageClusters(
        moment(startDate).format("Y-MM-DD"),
        moment(endDate).add(1, "day").format("Y-MM-DD")
      );
      setData(mortgageData);
    } catch (error) {
      console.error(error.message);
    }
  };

  const createCsvData = () => {
    const newCsvData = [];

    if (filteredData) {
      filteredData.forEach((cluster) => {
        let billingStatus;
        if (cluster.info.client_billable) billingStatus = "Billable";
        if (cluster.info.is_return) billingStatus = "Return";
        if (cluster.info.is_non_billable) billingStatus = "Non-Billable";

        let leadID = "";
        let leadNotes = "";
        let callNotes = "";
        let dataNotes = "";
        let callCreated;
        let dataCreated;

        if (cluster.info.user_notes.length > 0) {
          const allNotes = [];
          cluster.info.user_notes.forEach((note, index) => {
            const noteString = `#${index + 1}: ${note.notes}. | Created by ${
              note.createdBy
            } on ${moment(note.date).format("l LT")}. \n`;
            allNotes.push(noteString);
          });
          leadNotes = allNotes;
        }

        cluster.events.forEach((event) => {
          if (event.event_type === "lead" && event.valid_boolean) {
            leadID = event.data.leadID;
            dataCreated = moment(event.timestamp).format("LT");
            const allNotes = [];
            if (event.qa_notes.length > 0) {
              event.qa_notes.forEach((note, index) => {
                const noteString = `#${index + 1}: ${note.notes}. | Created by ${
                  note.createdBy
                } on ${moment(note.date).format("l LT")}. \n`;
                allNotes.push(noteString);
              });
            }
            dataNotes = allNotes;
          }
          if (event.event_type === "call" && event.valid_boolean) {
            callCreated = moment(event.timestamp).format("LT");
            const allNotes = [];
            if (event.qa_notes.length > 0) {
              event.qa_notes.forEach((note, index) => {
                const noteString = `#${index + 1}: ${note.notes}. | Created by ${
                  note.createdBy
                } on ${moment(note.date).format("l LT")}. \n`;
                allNotes.push(noteString);
              });
            }
            callNotes = allNotes;
          }
        });

        const newCluster = {
          created_date: moment(cluster.created_at).format("l"),
          call_time: callCreated,
          data_time: dataCreated,
          buyer: cluster.buyer,
          publisher: cluster.publisher_name,
          campaign: cluster.campaign_name,
          contract: cluster.contract,
          cid: cluster.lead_cid,
          leadID,
          status: billingStatus,
          lead_notes: leadNotes.toString().replace(",", ""),
          call_notes: callNotes.toString().replace(",", ""),
          data_notes: dataNotes.toString().replace(",", ""),
        };

        newCsvData.push(newCluster);
      });
    } else {
      data.forEach((cluster) => {
        let billingStatus;
        if (cluster.info.client_billable) billingStatus = "Billable";
        if (cluster.info.is_return) billingStatus = "Return";
        if (cluster.info.is_non_billable) billingStatus = "Non-Billable";

        let leadID = "";
        let leadNotes = "";
        let callNotes = "";
        let dataNotes = "";
        let callCreated;
        let dataCreated;

        if (cluster.info.user_notes.length > 0) {
          const allNotes = [];
          cluster.info.user_notes.forEach((note, index) => {
            const noteString = `#${index + 1}: ${note.notes}. | Created by ${
              note.createdBy
            } on ${moment(note.date).format("l LT")}. \n`;
            allNotes.push(noteString);
          });
          leadNotes = allNotes;
        }

        cluster.events.forEach((event) => {
          if (event.event_type === "lead" && event.valid_boolean) {
            leadID = event.data.leadID;
            dataCreated = moment(event.timestamp).format("LT");
            const allNotes = [];
            if (event.qa_notes.length > 0) {
              event.qa_notes.forEach((note, index) => {
                const noteString = `#${index + 1}: ${note.notes}. | Created by ${
                  note.createdBy
                } on ${moment(note.date).format("l LT")}. \n`;
                allNotes.push(noteString);
              });
            }
            dataNotes = allNotes;
          }
          if (event.event_type === "call" && event.valid_boolean) {
            callCreated = moment(event.timestamp).format("LT");
            const allNotes = [];
            if (event.qa_notes.length > 0) {
              event.qa_notes.forEach((note, index) => {
                const noteString = `#${index + 1}: ${note.notes}. | Created by ${
                  note.createdBy
                } on ${moment(note.date).format("l LT")}. \n`;
                allNotes.push(noteString);
              });
            }
            callNotes = allNotes;
          }
        });

        const newCluster = {
          created_date: moment(cluster.created_at).format("l"),
          call_time: callCreated,
          data_time: dataCreated,
          buyer: cluster.buyer,
          publisher: cluster.publisher_name,
          campaign: cluster.campaign_name,
          contract: cluster.contract,
          cid: cluster.lead_cid,
          leadID,
          status: billingStatus,
          lead_notes: leadNotes.toString().replace(",", ""),
          call_notes: callNotes.toString().replace(",", ""),
          data_notes: dataNotes.toString().replace(",", ""),
        };

        newCsvData.push(newCluster);
      });
    }

    setCsvData(newCsvData);
  };

  const getAllFilterLists = () => {
    let buyerList = [];
    let contractList = [];
    let pubList = [];

    if (data) {
      data.forEach((cluster) => {
        if (!buyerList.includes(cluster.buyer)) {
          buyerList.push(cluster.buyer);
        }
        if (!contractList.includes(cluster.contract)) {
          contractList.push(cluster.contract);
        }
        if (!pubList.includes(cluster.publisher_name)) {
          pubList.push(cluster.publisher_name);
        }
      });
    }

    const lists = {
      buyerList,
      contractList,
      pubList,
    };
    // console.log("getAllFilterLists:", lists);
    setFilterLists(lists);
  };

  const CustomInput = ({ onClick, ref, value }) => {
    return (
      <Button onClick={onClick} ref={ref} pr="40px">
        {value}
      </Button>
    );
  };

  const updateLeadClusterFrontEnd = (cluster_data) => {
    const newData = [...data];
    const index = newData.findIndex((cluster) => cluster.cluster_id === cluster_data.cluster_id);
    if (cluster_data.status === "Billable") {
      newData[index].info.client_billable = true;
      newData[index].info.is_return = false;
      newData[index].info.is_non_billable = false;
      newData[index].info.is_finalized = true;
    }
    if (cluster_data.status === "Return") {
      newData[index].info.client_billable = false;
      newData[index].info.is_return = true;
      newData[index].info.is_non_billable = false;
      newData[index].info.is_finalized = true;
    }
    if (cluster_data.status === "Non-Billable") {
      newData[index].info.client_billable = false;
      newData[index].info.is_return = false;
      newData[index].info.is_non_billable = true;
      newData[index].info.is_finalized = true;
    }
    if (cluster_data.status === "reset") {
      newData[index].info.client_billable = false;
      newData[index].info.is_return = false;
      newData[index].info.is_non_billable = false;
      newData[index].info.is_finalized = false;
    }
    setData(newData);
  };

  const updateEventNoteFrontEnd = (cluster_data, action) => {
    const newData = [...data];
    const clusterIndex = newData.findIndex(
      (cluster) => cluster.cluster_id === cluster_data.cluster_id
    );
    const eventIndex = newData[clusterIndex].events.findIndex(
      (event) => event.event_id === cluster_data.event_id
    );

    if (action === "add") {
      newData[clusterIndex].events[eventIndex].qa_notes.push(cluster_data.note);
    } else {
      const noteIndex = newData[clusterIndex].events[eventIndex].qa_notes.findIndex(
        (note) => note.note_id === cluster_data.note_id
      );
      newData[clusterIndex].events[eventIndex].qa_notes.splice(noteIndex, 1);
    }
    setData(newData);
  };

  const updateLeadNoteFrontEnd = (cluster_data, action) => {
    const newData = [...data];
    const clusterIndex = newData.findIndex(
      (cluster) => cluster.cluster_id === cluster_data.cluster_id
    );

    if (action === "add") {
      newData[clusterIndex].info.user_notes.push(cluster_data.note);
    } else {
      const noteIndex = newData[clusterIndex].info.user_notes.findIndex(
        (note) => note.note_id === cluster_data.note_id
      );
      newData[clusterIndex].info.user_notes.splice(noteIndex, 1);
    }
    setData(newData);
  };

  const [asc, setAsc] = useState(true);

  const sortColumn = (columnName) => {
    let newData = filteredData ? [...filteredData] : [...data];
    if (columnName === "created") {
      if (sortByColumnName === "created" && asc) {
        setAsc(!asc);
        newData.sort(sortByCreatedDateDesc);
      } else {
        setSortByColumnName("created");
        setAsc(!asc);
        newData.sort(sortByCreatedDateAsc);
      }
    }
    if (columnName === "buyer") {
      if (sortByColumnName === "buyer" && asc) {
        setAsc(!asc);
        newData.sort(sortByBuyerDesc);
      } else {
        setSortByColumnName("buyer");
        setAsc(!asc);
        newData.sort(sortByBuyerAsc);
      }
    }
    if (columnName === "contract") {
      if (sortByColumnName === "contract" && asc) {
        setAsc(!asc);
        newData.sort(sortByContractDesc);
      } else {
        setSortByColumnName("contract");
        setAsc(!asc);
        newData.sort(sortByContractAsc);
      }
    }
    if (columnName === "publisher") {
      if (sortByColumnName === "publisher" && asc) {
        setAsc(!asc);
        newData.sort(sortByPublisherDesc);
      } else {
        setSortByColumnName("publisher");
        setAsc(!asc);
        newData.sort(sortByPublisherAsc);
      }
    }
    if (columnName === "campaign") {
      if (sortByColumnName === "campaign" && asc) {
        setAsc(!asc);
        newData.sort(sortByCampaignDesc);
      } else {
        setSortByColumnName("campaign");
        setAsc(!asc);
        newData.sort(sortByCampaignAsc);
      }
    }
    if (columnName === "duration") {
      if (sortByColumnName === "duration" && asc) {
        setAsc(!asc);
        newData.sort(sortByDurationDesc);
      } else {
        setSortByColumnName("duration");
        setAsc(!asc);
        newData.sort(sortByDurationAsc);
      }
    }
    if (columnName === "status") {
      if (sortByColumnName === "status" && asc) {
        setAsc(!asc);
        newData.sort(sortByStatusDesc);
      } else {
        setSortByColumnName("status");
        setAsc(!asc);
        newData.sort(sortByStatusAsc);
      }
    }
    if (filteredData) {
      setFilteredData(newData);
    } else {
      setData(newData);
    }
  };

  const sortByCreatedDateAsc = (a, b) => {
    if (a.created_at > b.created_at) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByCreatedDateDesc = (a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByBuyerAsc = (a, b) => {
    if (a.buyer > b.buyer) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByBuyerDesc = (a, b) => {
    if (a.buyer > b.buyer) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByContractAsc = (a, b) => {
    if (a.contract > b.contract) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByContractDesc = (a, b) => {
    if (a.contract > b.contract) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByPublisherAsc = (a, b) => {
    if (a.publisher_name > b.publisher_name) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByPublisherDesc = (a, b) => {
    if (a.publisher_name > b.publisher_name) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByCampaignAsc = (a, b) => {
    if (a.campaign_name > b.campaign_name) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByCampaignDesc = (a, b) => {
    if (a.campaign_name > b.campaign_name) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByDurationAsc = (a, b) => {
    let peakDurationA;
    let peakDurationB;
    let total_durationA = 0;
    let total_durationB = 0;

    a.events.forEach((event) => {
      if (event.event_type === "call" && event.data.call_length > 0) {
        if (total_durationA < event.data.call_length) {
          total_durationA = event.data.call_length;
        }
      }
      peakDurationA = total_durationA;
    });

    b.events.forEach((event) => {
      if (event.event_type === "call" && event.data.call_length > 0) {
        if (total_durationB < event.data.call_length) {
          total_durationB = event.data.call_length;
        }
      }
      peakDurationB = total_durationB;
    });

    if (peakDurationA > peakDurationB) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByDurationDesc = (a, b) => {
    let peakDurationA;
    let peakDurationB;
    let total_durationA = 0;
    let total_durationB = 0;

    a.events.forEach((event) => {
      if (event.event_type === "call" && event.data.call_length > 0) {
        if (total_durationA < event.data.call_length) {
          total_durationA = event.data.call_length;
        }
      }
      peakDurationA = total_durationA;
    });

    b.events.forEach((event) => {
      if (event.event_type === "call" && event.data.call_length > 0) {
        if (total_durationB < event.data.call_length) {
          total_durationB = event.data.call_length;
        }
      }
      peakDurationB = total_durationB;
    });

    if (peakDurationA > peakDurationB) {
      return -1;
    } else {
      return 1;
    }
  };

  const sortByStatusAsc = (a, b) => {
    let statusA;
    let statusB;

    if (a.info.client_billable) {
      statusA = "billable";
    } else if (a.info.is_return) {
      statusA = "return";
    } else if (a.info.is_non_billable) {
      statusA = "non-billable";
    } else {
      statusA = "z";
    }
    if (b.info.client_billable) {
      statusB = "billable";
    } else if (b.info.is_return) {
      statusB = "return";
    } else if (b.info.is_non_billable) {
      statusB = "non-billable";
    } else {
      statusB = "z";
    }

    if (statusA > statusB) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByStatusDesc = (a, b) => {
    let statusA;
    let statusB;

    if (a.info.client_billable) {
      statusA = "billable";
    } else if (a.info.is_return) {
      statusA = "return";
    } else if (a.info.is_non_billable) {
      statusA = "non-billable";
    } else {
      statusA = "z";
    }
    if (b.info.client_billable) {
      statusB = "billable";
    } else if (b.info.is_return) {
      statusB = "return";
    } else if (b.info.is_non_billable) {
      statusB = "non-billable";
    } else {
      statusB = "z";
    }

    if (statusA > statusB) {
      return -1;
    } else {
      return 1;
    }
  };

  const logMe = () => {
    console.log("data:", data);
    // console.log("filteredBy:", filteredBy);
    // console.log("filteredData:", filteredData);
    // updateFilteredData();
  };

  useEffect(() => {
    pullMortgageClusters();
  }, []);

  useEffect(() => {
    getAllFilterLists();
  }, [data]);

  useEffect(() => {
    if (data) createCsvData();
  }, [data, filteredData]);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      overflowY="auto"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
      className="no-scrollbar"
    >
      <TopHeading
        title="Mortgage"
        description="All Mortgage Leads"
        children={[
          <Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Flex mx="10px" flexDir="column">
                <Text>Start Date</Text>

                <DatePicker
                  color="red"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  value={startDate ? moment(startDate).format("L") : null}
                  isClearable
                  customInput={<DatePickerInput />}
                />
              </Flex>
              <Flex mx="10px" flexDir="column">
                <Text>End Date</Text>

                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  value={endDate ? moment(endDate).format("L") : null}
                  isClearable
                  customInput={<DatePickerInput />}
                />
              </Flex>
              <Flex flexDir="column" w="100%" ml="10px">
                <Text fontStyle="italic" textAlign="center">
                  &nbsp;
                </Text>
                <IconButton
                  backgroundColor="teal.300"
                  _hover={{ backgroundColor: "#38B2AC" }}
                  color={refreshButtonTextColor}
                  // colorScheme="teal"
                  onClick={pullMortgageClusters}
                  aria-label="Generate Mortgage Clusters"
                  icon={<FiRefreshCcw />}
                />
              </Flex>
              <Flex flexDir="column" w="100%" ml="10px">
                <Text fontStyle="italic" textAlign="center">
                  &nbsp;
                </Text>

                <LeadFiltersModal
                  updateFilteredData={updateFilteredData}
                  filterLists={filterLists}
                />
              </Flex>
              {csvData ? (
                <Flex flexDir="column" w="100%" ml="10px">
                  <Text fontStyle="italic" textAlign="center">
                    &nbsp;
                  </Text>
                  <Tooltip label="Export" placement="top">
                    <Button icon={<FaFileExport />}>
                      <CSVLink
                        data={csvData}
                        // headers={headersState}
                        filename={fileName}
                        asyncOnClick={true}
                        onClick={() => console.log("data:", data)}
                      >
                        <Icon as={FaFileExport} />
                      </CSVLink>
                    </Button>
                  </Tooltip>
                </Flex>
              ) : null}
            </Flex>
          </Flex>,
        ]}
      />

      {loading ? (
        <Flex mt="10%" justify="center">
          <Spinner color="teal.300" thickness="4px" speed="0.65s" emptyColor="gray.200" size="xl" />
        </Flex>
      ) : null}
      {data || filteredData ? (
        <Flex justify="center" alignItems="flex-start" flexDir="column">
          <Table w="100%" size="sm">
            <Thead position="sticky" top={105}>
              <Tr backgroundColor={bgColor}>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("created")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Created {sortByColumnName === "created" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "created" && !asc ? "▲" : null}
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("buyer")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Buyer {sortByColumnName === "buyer" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "buyer" && !asc ? "▲" : null}
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("contract")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Contract {sortByColumnName === "contract" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "contract" && !asc ? "▲" : null}
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("publisher")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Publisher {sortByColumnName === "publisher" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "publisher" && !asc ? "▲" : null}
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("campaign")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Campaign Name {sortByColumnName === "campaign" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "campaign" && !asc ? "▲" : null}
                </Th>
                <Th textAlign="center" pb="15px">
                  Lead Name
                </Th>
                <Th textAlign="center" pb="15px">
                  Lead CID
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("duration")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Peak Duration {sortByColumnName === "duration" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "duration" && !asc ? "▲" : null}
                </Th>
                <Th
                  textAlign="center"
                  pb="15px"
                  onClick={() => sortColumn("status")}
                  cursor="pointer"
                  _hover={{ color: "#4fd1c5" }}
                >
                  Status {sortByColumnName === "status" && asc ? "▼" : null}{" "}
                  {sortByColumnName === "status" && !asc ? "▲" : null}
                </Th>
                <Th textAlign="center" pb="15px">
                  Notifications
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData && filteredData.length > 0
                ? filteredData.map((cluster) => (
                    <MortgageModal
                      key={`filtered-${cluster.cluster_id}`}
                      clusterData={cluster}
                      pullUpdatedMortgageClusters={pullUpdatedMortgageClusters}
                      username={username}
                      updateLeadClusterFrontEnd={updateLeadClusterFrontEnd}
                      updateEventNoteFrontEnd={updateEventNoteFrontEnd}
                      updateLeadNoteFrontEnd={updateLeadNoteFrontEnd}
                    />
                  ))
                : data.map((cluster) => (
                    <MortgageModal
                      key={`data-${cluster.cluster_id}`}
                      clusterData={cluster}
                      pullUpdatedMortgageClusters={pullUpdatedMortgageClusters}
                      username={username}
                      updateLeadClusterFrontEnd={updateLeadClusterFrontEnd}
                      updateEventNoteFrontEnd={updateEventNoteFrontEnd}
                      updateLeadNoteFrontEnd={updateLeadNoteFrontEnd}
                    />
                  ))}
            </Tbody>
          </Table>
        </Flex>
      ) : null}
    </Box>
  );
}
