import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  IconButton,
  Flex,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Table,
  Thead,
  Tbody,
  Box,
  UnorderedList,
  ListItem,
  Tag,
  Tooltip,
  useColorModeValue,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { FaPhone, FaRegFileAlt } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";
import MortgageQaModal from "./MortgageQaModal";

const StatusTag = ({ statusTags }) => {
  const [color, setColor] = useState();

  const setTagColor = () => {
    if (statusTags[0] === "Billable") setColor("teal.500");
    if (statusTags[0] === "Non-Billable") setColor("gray");
    if (statusTags[0] === "Return") setColor("red.500");
  };

  useEffect(() => {
    setTagColor();
  }, [statusTags]);

  return (
    <>
      {statusTags.map((tag) => (
        <Tag backgroundColor={color} color="white">
          {tag}
        </Tag>
      ))}
    </>
  );
};

const StatusButtons = ({ updateLeadStatus, statusTags }) => {
  const [isSelected, setIsSelected] = useState();

  const handleClick = (status) => {
    if (isSelected === status) {
      setIsSelected(null);
      updateLeadStatus("reset");
    } else {
      setIsSelected(status);
      updateLeadStatus(status);
    }
  };

  const setLeadStatus = () => {
    if (statusTags.includes("Billable")) {
      setIsSelected("Billable");
    }
    if (statusTags.includes("Non-Billable")) {
      setIsSelected("Non-Billable");
    }
    if (statusTags.includes("Return")) {
      setIsSelected("Return");
    }
  };

  useEffect(() => {
    setLeadStatus();
  }, [statusTags]);

  return (
    <Flex>
      <Button
        onClick={() => handleClick("Billable")}
        backgroundColor={isSelected === "Billable" ? "teal.300" : null}
        mx="10px"
      >
        Billable
      </Button>
      <Button
        onClick={() => handleClick("Non-Billable")}
        backgroundColor={isSelected === "Non-Billable" ? "teal.300" : null}
        mx="10px"
      >
        Non-Billable
      </Button>
      <Button
        onClick={() => handleClick("Return")}
        backgroundColor={isSelected === "Return" ? "teal.300" : null}
        mx="10px"
      >
        Return
      </Button>
    </Flex>
  );
};

const formatDuration = (duration) => {
  var sec_num = parseInt(duration, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
};

const TableRow = ({
  event,
  leadName,
  clusterData,
  cluster_id,
  tsDate,
  tsTime,
  buyerName,
  pubName,
  icon,
  duration,
  pullUpdatedMortgageClusters,
  username,
  updateEventNoteFrontEnd,
}) => {
  return (
    <MortgageQaModal
      tsTime={tsTime}
      tsDate={tsDate}
      buyerName={buyerName}
      pubName={pubName}
      icon={icon}
      duration={duration}
      formatDuration={formatDuration}
      event={event}
      clusterData={clusterData}
      cluster_id={cluster_id}
      leadName={leadName}
      url={event.data.recording_url}
      pullUpdatedMortgageClusters={pullUpdatedMortgageClusters}
      username={username}
      updateEventNoteFrontEnd={updateEventNoteFrontEnd}
    />
  );
};

const NoteTableRow = ({ note, cluster_id, setNotes, updateLeadNoteFrontEnd }) => {
  const [loadingNoteDelete, setLoadingNoteDelete] = useState(false);
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const deleteNote = async (note_id) => {
    setLoadingNoteDelete(true);

    const data = {
      cluster_id,
      note_id,
    };

    try {
      const response = await axios.post("/api/mortgage/leads/delete-note", data);
      console.log("deleteNote response:", response);
      if (response.status === 200) {
        updateLeadNoteFrontEnd(data, "delete");
      }

      setLoadingNoteDelete(false);
      setNotes("");
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Tr key={note.note_id} _hover={{ backgroundColor: hoverColor }}>
      <Td textAlign="center" w="20%">
        {note.date && moment(note.date).format("l LT")}
      </Td>
      <Td textAlign="center" w="50%">
        {note.notes}
      </Td>
      <Td textAlign="center" w="20%">
        {note.createdBy}
      </Td>
      <Td textAlign="center" w="10%">
        <IconButton
          onClick={() => deleteNote(note.note_id)}
          cursor="pointer"
          icon={!loadingNoteDelete ? <BsTrash /> : <Spinner size="xs" />}
          colorScheme="teal"
          size="xs"
        />
      </Td>
    </Tr>
  );
};

export default function MortgageModal({
  clusterData,
  pullUpdatedMortgageClusters,
  username,
  updateLeadClusterFrontEnd,
  updateEventNoteFrontEnd,
  updateLeadNoteFrontEnd,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [leadName, setLeadName] = useState();
  const [statusTags, setStatusTags] = useState([]);
  const [notes, setNotes] = useState("");
  const [loadingNote, setLoadingNote] = useState(false);

  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const handleMortgageModal = async () => {
    onOpen();
    console.log("clusterData:", clusterData);
  };

  const findLeadName = () => {
    let name;
    clusterData.events.forEach((event) => {
      if (event.event_type === "lead") {
        name = `${event.data.first_name.toLowerCase()} ${event.data.last_name.toLowerCase()}`;
        return;
      }
    });
    setLeadName(name);
  };

  const sortByTime = (a, b) => {
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0;
  };

  const calculateTotalDuration = () => {
    let total_duration = 0;
    if (clusterData.events.length > 0) {
      clusterData.events.forEach((event) => {
        if (event.event_type === "call" && event.data.call_length > 0) {
          if (total_duration < event.data.call_length) {
            total_duration = event.data.call_length;
          }
        }
      });
    }
    return total_duration;
  };

  const getAllLeadStatuses = () => {
    const tags = [];
    if (clusterData.info.client_billable) {
      tags.push("Billable");
    }
    if (clusterData.info.is_non_billable) {
      tags.push("Non-Billable");
    }
    if (clusterData.info.is_return) {
      tags.push("Return");
    }
    setStatusTags(tags);
  };

  const submitNewLeadNote = async () => {
    setLoadingNote(true);
    const data = {
      cluster_id: clusterData.cluster_id,
      note: {
        date: moment().format(),
        notes: notes,
        note_id: `note-${uuidv4()}`,
        createdBy: username,
      },
    };

    console.log("addNewNote data:", data);

    try {
      const response = await axios.post("/api/mortgage/leads/add-note", data);
      console.log("submitNewLeadNote response:", response);
      if (response.status === 200) {
        updateLeadNoteFrontEnd(data, "add");
        console.log("success!");
      } else {
        alert("There was an error.");
      }
      setLoadingNote(false);
      setNotes("");
    } catch (error) {
      console.error(error.message);
    }
  };

  const logMe = () => {
    console.log("clusterData:", clusterData);
  };

  const updateLeadStatus = async (status) => {
    const data = {
      cluster_id: clusterData.cluster_id,
      status,
    };

    // console.log("updateLeadStatus data:", data);

    try {
      const response = await axios.post("/api/mortgage/leads/set-status", data);
      console.log("updateLeadStatus response:", response);
      if (response.status === 200) {
        await updateLeadClusterFrontEnd(data);
        getAllLeadStatuses();
      } else {
        alert("There was an error.");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    findLeadName();
    getAllLeadStatuses();
  }, [clusterData]);

  return (
    <>
      <Tr _hover={{ backgroundColor: hoverColor }} cursor="pointer" onClick={handleMortgageModal}>
        <Td textAlign="center">
          {moment(clusterData.created_at).tz("America/Los_Angeles").format("L")}
        </Td>
        <Td textAlign="center">
          <Text
            textAlign="center"
            w="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {clusterData.buyer}
          </Text>
        </Td>

        <Td textAlign="center" whiteSpace="nowrap" style={{ textTransform: "capitalize" }}>
          {clusterData.contract.split("_").join(" ")}
        </Td>
        <Td textAlign="center">
          <Text
            textAlign="center"
            w="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {clusterData.publisher_name}
          </Text>
        </Td>
        <Td textAlign="center">
          <Text
            textAlign="center"
            w="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {clusterData.campaign_name}
          </Text>
        </Td>
        <Td textAlign="center" style={{ textTransform: "capitalize" }}>
          <Text
            textAlign="center"
            w="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {leadName ? leadName : ""}
          </Text>
        </Td>
        <Td textAlign="center">{clusterData.lead_cid}</Td>
        <Td textAlign="center">{formatDuration(calculateTotalDuration())}</Td>
        <Td textAlign="center">
          <StatusTag statusTags={statusTags} />
        </Td>
        <Td textAlign="center">
          {/* {`${clusterData.info.missing_call}`} */}
          {clusterData.info.missing_call ? (
            <Tooltip label="Missing" hasArrow placement="top" shouldWrapChildren>
              <Icon color="red.500" as={FaPhone} mx="5px" />
            </Tooltip>
          ) : (
            <Tooltip
              label={clusterData.info.valid_call ? "Valid" : "Invalid"}
              hasArrow
              placement="top"
              shouldWrapChildren
            >
              <Icon
                color={clusterData.info.valid_call ? "gray.300" : "yellow.400"}
                as={FaPhone}
                mx="5px"
              />
            </Tooltip>
          )}
          {/* {`${clusterData.info.missing_data}`} */}
          {clusterData.info.missing_data ? (
            <Tooltip label="Missing" hasArrow placement="top" shouldWrapChildren>
              <Icon color="red.500" as={FaRegFileAlt} mx="5px" />
            </Tooltip>
          ) : (
            <Tooltip
              label={clusterData.info.valid_data ? "Valid" : "Invalid"}
              hasArrow
              placement="top"
              shouldWrapChildren
            >
              <Icon
                color={clusterData.info.valid_data ? "gray.300" : "yellow.400"}
                as={FaRegFileAlt}
                mx="5px"
              />
            </Tooltip>
          )}
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          {/* <Button onClick={logMe}>CLICK</Button> */}
          <ModalHeader>
            <Text>Lead Details</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" mb="20px">
              <Text>Status:</Text>
              <StatusButtons updateLeadStatus={updateLeadStatus} statusTags={statusTags} />
            </Flex>
            <Box borderWidth={2} borderRadius={8}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="center">Campaign</Th>
                    <Th textAlign="center">Lead Name</Th>
                    <Th textAlign="center">CID</Th>
                    <Th textAlign="center">Issues</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr w="100%" justifyContent="space-evenly" flexDir="column">
                    <Td textAlign="center">{clusterData.campaign_name}</Td>
                    <Td textAlign="center" style={{ textTransform: "capitalize" }}>
                      {leadName ? leadName : null}
                    </Td>
                    <Td textAlign="center">{clusterData.lead_cid}</Td>
                    <Td textAlign="center">
                      {clusterData.info.missing_call ? (
                        <Icon w={5} h={6} mx="5px" color="red.500" as={FaPhone} />
                      ) : null}
                      {clusterData.info.missing_data ? (
                        <Icon w={5} h={6} mx="5px" color="red.500" as={FaRegFileAlt} />
                      ) : null}
                      {!clusterData.info.missing_call && !clusterData.info.valid_call ? (
                        <Icon w={5} h={6} mx="5px" color="yellow.300" as={FaPhone} />
                      ) : null}
                      {!clusterData.info.missing_data && !clusterData.info.valid_data ? (
                        <Icon w={5} h={6} mx="5px" color="yellow.300" as={FaRegFileAlt} />
                      ) : null}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            <Tabs h="600px" overflowY="scroll" mt="15px">
              <TabList>
                <Tab>Events</Tab>
                <Tab>Notes</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Date</Th>
                        <Th textAlign="center">Type</Th>
                        <Th textAlign="center">Buyer</Th>
                        <Th textAlign="center">Publisher</Th>
                        <Th textAlign="center">Lead ID</Th>
                        <Th textAlign="center">Duration</Th>
                        {/* <Th textAlign="center">QA</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {clusterData.events.length > 0
                        ? clusterData.events
                            .sort(sortByTime)
                            .map((event) => (
                              <>
                                {event.event_type === "call" ? (
                                  <TableRow
                                    key={moment(event.timestamp).unix()}
                                    event={event}
                                    leadName={leadName}
                                    clusterData={clusterData}
                                    cluster_id={clusterData.cluster_id}
                                    tsDate={moment(event.timestamp).format("L")}
                                    tsTime={moment(event.timestamp)
                                      .tz("America/Los_Angeles")
                                      .format("LT")}
                                    buyerName={clusterData.buyer}
                                    pubName={event.data.publisher_name}
                                    icon={FaPhone}
                                    duration={event.data.call_length}
                                    pullUpdatedMortgageClusters={pullUpdatedMortgageClusters}
                                    username={username}
                                    updateEventNoteFrontEnd={updateEventNoteFrontEnd}
                                  />
                                ) : (
                                  <TableRow
                                    key={moment(event.timestamp).unix()}
                                    event={event}
                                    leadName={leadName}
                                    clusterData={clusterData}
                                    cluster_id={clusterData.cluster_id}
                                    tsDate={moment(event.timestamp).format("L")}
                                    tsTime={moment(event.timestamp).format("LT")}
                                    buyerName={clusterData.buyer}
                                    pubName={event.data.affiliateName}
                                    icon={FaRegFileAlt}
                                    pullUpdatedMortgageClusters={pullUpdatedMortgageClusters}
                                    username={username}
                                    updateEventNoteFrontEnd={updateEventNoteFrontEnd}
                                  />
                                )}
                              </>
                            ))
                        : null}
                    </Tbody>
                  </Table>
                </TabPanel>
                <TabPanel>
                  <Flex p={5} flexDir="column" borderRadius="7px" borderWidth={1}>
                    <Flex
                      borderRadius="7px"
                      borderWidth={1}
                      p="5px"
                      mb="20px"
                      minH="100px"
                      flexDir="column"
                    >
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th textAlign="center" w="20%">
                              Date
                            </Th>
                            <Th textAlign="center" w="50%">
                              Note
                            </Th>
                            <Th textAlign="center" w="20%">
                              Created By
                            </Th>
                            <Th textAlign="center" w="10%">
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {clusterData.info.user_notes.map((note) => (
                            <NoteTableRow
                              key={note.note_id}
                              note={note}
                              cluster_id={clusterData.cluster_id}
                              updateLeadNoteFrontEnd={updateLeadNoteFrontEnd}
                              setNotes={setNotes}
                            />
                          ))}
                        </Tbody>
                      </Table>
                    </Flex>
                    <Flex flexDir="column">
                      <Text>New Note</Text>
                      <Flex alignItems="center">
                        <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
                        <Button backgroundColor="teal.300" ml="15px" onClick={submitNewLeadNote}>
                          {loadingNote ? <Spinner size="sm" /> : "Add"}
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
