import React, { useState } from "react";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import TopHeading from "../components/TopHeading";
import VolumeByCampaignDashboard from "../components/dashboard/VolumeByCampaign";
import FailedCalls from "../components/dashboard/FailedCalls";
import Rpc from "../components/dashboard/CampaignMetrics";
import StrandedMortgage from "../components/dashboard/StrandedMortgage";
import CampaignCaps from "../components/dashboard/CampaignCaps";

export default function Dashboard() {
  const [data, setData] = useState();

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      overflowY="scroll"
      alignSelf="center"
      justifySelf="center"
      className="no-scrollbar"
    >
      <TopHeading title="Dashboard" description="An overview of all the most important metrics" />
      <Flex w="100%" className="no-scrollbar">
        <Flex w="50%" className="no-scrollbar">
          <FailedCalls />
        </Flex>
        <Flex w="50%" className="no-scrollbar">
          <CampaignCaps />
        </Flex>
      </Flex>
      {/* <Flex>
        <VolumeByCampaignDashboard />
        <StrandedMortgage />
      </Flex> */}
    </Box>
  );
}
