import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import { getVolumePerCampaign } from "./DashboardAPIs";
import moment from "moment";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

import { FiRefreshCcw } from "react-icons/fi";
const randomColor = require("randomcolor");

const ThePieChart = (data) => {
  const colors = randomColor({ count: data.length, hue: "random" });
  return (
    <PieChart width={400} height={500}>
      <Pie
        dataKey="convertedCalls"
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        outerRadius="80%"
        fill="#8884d8"
        label
        nameKey="campaignName"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
      </Pie>

      <Tooltip />
    </PieChart>
  );
};

const FilterButton = ({ filterName, filters, handleFilter, updateFilteredData }) => {
  const [color, setColor] = useState(null);
  const handleClick = () => {
    handleFilter(filterName);
    if (filters.indexOf(filterName) > -1) {
      setColor("purple");
    } else {
      setColor(null);
    }
    updateFilteredData();
  };

  return (
    <Button colorScheme={color} size="xs" m="5px" onClick={handleClick}>
      {filterName}
    </Button>
  );
};

export default function VolumeByCampaignDashboard() {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [sortFilter, setSortFilter] = useState("name");

  const sortByCampaign = (a, b) => {
    if (a.campaignName < b.campaignName) {
      return -1;
    }
    if (a.campaignName > b.campaignName) {
      return 1;
    }
  };
  const sortByVolume = (a, b) => {
    if (a.convertedCalls < b.convertedCalls) {
      return -1;
    }
    if (a.convertedCalls > b.convertedCalls) {
      return 1;
    }
    return 0;
  };

  const updateFilteredData = () => {
    if (filters.length < 1) {
      setFilteredData(null);
      return;
    }
    let newData = [];
    data.forEach((campaign) => {
      if (campaign.campaignName) {
        const array = campaign.campaignName.split(" - ");
        if (array.some((r) => filters.indexOf(r) >= 0)) {
          newData.push(campaign);
        }
      }
    });
    setFilteredData(newData);
  };

  const handleFilter = (filterName) => {
    let newFilters = filters;
    const index = newFilters.indexOf(filterName);
    if (index > -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(filterName);
    }
    setFilters(newFilters);
  };

  const handleClick = async () => {
    setData(null);
    setLoading(true);
    try {
      let neededData = [];
      const stats = await getVolumePerCampaign();
      stats.forEach((campaign) => {
        if (campaign.campaignName) neededData.push(campaign);
      });
      setData(neededData);
      setLoading(false);
      setLastUpdated(moment().format("LT"));
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <Box
      w="50%"
      h="600px"
      m="auto"
      my="10px"
      mx="10px"
      p="20px"
      overflowY={data ? "scroll" : "hidden"}
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
      borderRadius="7px"
      alignSelf="center"
      justifySelf="center"
    >
      <Flex justify="flex-end" mb="15px">
        <Flex w="100%" justify="flex-start" alignItems="center" fontSize="18px">
          <Text fontWeight="500" letterSpacing="5px">
            CALL VOLUME BY CAMPAIGN - {`${moment().format("L")}`}
          </Text>
        </Flex>
        <Flex w="80%" justifyContent="flex-end" alignItems="center">
          {lastUpdated ? (
            <Text fontStyle="italic" mr="5px">
              Last Updated: {lastUpdated}
            </Text>
          ) : null}
          <IconButton onClick={handleClick} aria-label="Pull Volume Info" icon={<FiRefreshCcw />} />
        </Flex>
      </Flex>
      <Divider />
      <Flex justify="space-around" h="500px" my="15px">
        {loading ? (
          <Flex mt="10%">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}
        {data ? ThePieChart(filteredData ? filteredData : data) : null}
        {data || filteredData ? (
          <Flex flexDir="column" w="100%">
            <Flex alignItems="center" justify="flex-start">
              <Text fontSize="12px" fontWeight={500}>
                FILTERS:
              </Text>
              <Flex justify="center" alignItems="center">
                <FilterButton
                  filterName="AUTO"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="FE"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="MA"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="MS"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="MGE"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="O65"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="U65"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
                <FilterButton
                  filterName="UTIL"
                  filters={filters}
                  handleFilter={handleFilter}
                  updateFilteredData={updateFilteredData}
                />
              </Flex>
            </Flex>
            <Table size="sm" variant="striped" w="100%">
              <Thead>
                <Tr>
                  <Th
                    textAlign="left"
                    color={sortFilter === "name" ? "red" : "purple"}
                    textDecor={sortFilter === "name" ? "underline" : "null"}
                    onClick={() => setSortFilter("name")}
                    cursor="pointer"
                  >
                    Campaign
                  </Th>
                  <Th
                    color={sortFilter === "volume" ? "red" : "purple"}
                    textDecor={sortFilter === "volume" ? "underline" : "null"}
                    onClick={() => setSortFilter("volume")}
                    cursor="pointer"
                    textAlign="right"
                  >
                    Volume
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData
                  ? filteredData
                      .sort(sortFilter === "name" ? sortByCampaign : sortByVolume)
                      .map((campaign, i) => (
                        <Tr key={`${campaign}=${i}`}>
                          <Td>{campaign.campaignName}</Td>
                          <Td textAlign="right">{campaign.convertedCalls}</Td>
                        </Tr>
                      ))
                  : data
                      .sort(sortFilter === "name" ? sortByCampaign : sortByVolume)
                      .map((campaign, i) => (
                        <Tr key={`${campaign}=${i}`}>
                          <Td maxW="250px">{campaign.campaignName}</Td>
                          <Td textAlign="right">{campaign.convertedCalls}</Td>
                        </Tr>
                      ))}
              </Tbody>
            </Table>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
