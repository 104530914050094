import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Switch,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tag,
  Tooltip,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import moment from "moment";

import {
  enableCampaign,
  updateCampaignCaps,
  updateCampaignPayoutBuffer,
  getCallRoutesWithId,
  updateTargetCaps,
  updateRoutingWeightPriority,
} from "./JobsApi";

import { RiRouteLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import RingbaLink from "../RingaLink";
import { produceRequiredFields } from "./produceRequiredFields";

const RoutingPlanTable = ({
  routeDetails,
  handleRouteUpdate,
  handleTargetCapsUpdate,
  loadingTargetUpdate,
  active,
}) => {
  const checkCapExists = (cap) => {
    // console.log("routeDetails:", routeDetails);
    if (cap === -1) {
      return 0;
    } else {
      return cap;
    }
  };

  const checkForHourlyCaps = () => {
    if (routeDetails.callTarget) {
      if (routeDetails.callTarget.schedule.callCapMatrix) {
        return routeDetails.callTarget.schedule.callCapMatrix[0][0];
      } else {
        return checkCapExists(routeDetails.callTarget.schedule.hourlyCap);
      }
    }
    if (routeDetails.callPingTree) {
      if (routeDetails.callPingTree.targets[0].schedule.callCapMatrix) {
        return routeDetails.callPingTree.targets[0].schedule.callCapMatrix[0][0];
      } else {
        return checkCapExists(routeDetails.callPingTree.targets[0].schedule.hourlyCap);
      }
    }
  };

  const checkForDailyCaps = () => {
    if (routeDetails.callTarget) {
      if (routeDetails.callTarget.schedule.dailyCapDayOfWeek) {
        return routeDetails.callTarget.schedule.dailyCapDayOfWeek[0];
      } else {
        return checkCapExists(routeDetails.callTarget.schedule.dailyCap);
      }
    }
    if (routeDetails.callPingTree) {
      if (routeDetails.callPingTree.targets[0].schedule.dailyCapDayOfWeek) {
        return routeDetails.callPingTree.targets[0].schedule.dailyCapDayOfWeek[0];
      } else {
        return checkCapExists(routeDetails.callPingTree.targets[0].schedule.dailyCap);
      }
    }
  };

  const [priority, setPriority] = useState(routeDetails.priority.priority);
  const [weight, setWeight] = useState(routeDetails.priority.weight);
  const [dailyCap, setDailyCap] = useState(checkForDailyCaps());
  const [hourlyCap, setHourlyCap] = useState(checkForHourlyCaps());
  const [concurrencyCap, setConcurrencyCap] = useState(
    routeDetails.callTarget
      ? checkCapExists(routeDetails.callTarget.schedule.concurrencyCap)
      : checkCapExists(routeDetails.callPingTree.targets[0].schedule.concurrencyCap)
  );

  const firstUpdate = useRef(true);
  // const [dailyCap, setDailyCap] = useState(
  //   routeDetails.callTarget
  //     ? routeDetails.callTarget.schedule.dailyCap
  //     : routeDetails.callPingTree.targets[0].schedule.dailyCap
  // );

  const handlePriorityChange = (value) => setPriority(value);
  const handleWeightChange = (value) => setWeight(value);
  const handleHourlyCapChange = (value) => setHourlyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleDailyCapChange = (value) => setDailyCap(value);

  const handleUpdates = () => {
    handleRouteUpdate(routeDetails.id, priority, weight);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current && priority && weight) handleUpdates();
    // console.log("hello");
  }, [priority, weight]);

  const triggerHandleTargetCapsUpdate = () => {
    handleTargetCapsUpdate(
      routeDetails.callTarget ? false : true,
      routeDetails.callTarget
        ? routeDetails.callTarget.id
        : routeDetails.callPingTree.targets[0].id,
      dailyCap,
      hourlyCap,
      concurrencyCap,
      routeDetails.callTarget ? routeDetails.callTarget.schedule.hoursOfOperation : null
    );
  };

  const logMe = () => {
    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    console.log("active:", active);
    console.log("routeDetails:", routeDetails);
    // if (routeDetails.callTarget) {
    //   console.log("routeDetails HOO Target:", routeDetails.callTarget.schedule.hoursOfOperation);
    // }
    // if (routeDetails.callPingTree) {
    //   console.log(
    //     "routeDetails HOO Ring Tree:",
    //     routeDetails.callPingTree.schedule.hoursOfOperation
    //   );
    // }
    // console.log(
    //   "routeDetails.callTarget.schedule.callCapMatrix[0][0]:",
    //   routeDetails.callTarget.schedule.callCapMatrix[0][0]
    // );
    // routeDetails.callTarget
    //   ? console.log("routeDetails.callTarget.schedule:", routeDetails.callTarget.schedule)
    //   : console.log(
    //       "routeDetails.callPingTree.targets[0].schedule:",
    //       routeDetails.callPingTree.targets[0].schedule
    //     );
  };

  return (
    <Box my="10px" key={routeDetails.id}>
      {/* <Button onClick={logMe}>LOG ME</Button> */}
      {routeDetails.callTarget ? (
        <Flex flexDir="column">
          <Text
            fontWeight={700}
            textAlign="center"
            onClick={logMe}
            color={!active ? "gray.600" : null}
          >
            {routeDetails.callTarget.name}{" "}
            <RingbaLink type="target" id={routeDetails.callTarget.id} />
          </Text>
          <Text fontWeight={700} textAlign="center" color={!active ? "gray.600" : null}>
            DID: {routeDetails.callTarget.instructions.number.substring(1)}
          </Text>
          {!active ? (
            <Text textAlign="center" fontWeight={700} color="red.600">
              (INACTIVE)
            </Text>
          ) : null}
        </Flex>
      ) : null}
      {routeDetails.callPingTree ? (
        <Flex flexDir="column">
          <Text fontWeight={700} textAlign="center" onClick={logMe}>
            {routeDetails.callPingTree.name}{" "}
            <RingbaLink type="ringtree" id={routeDetails.callPingTree.targets[0].id} />
          </Text>
          <Text fontWeight={700} textAlign="center">
            DID:{" "}
            {routeDetails.callPingTree.targets[0].instructions.number &&
              routeDetails.callPingTree.targets[0].instructions.number.substring(1)}
          </Text>
          {!active ? (
            <Text textAlign="center" fontWeight={700} color="red.600">
              (INACTIVE)
            </Text>
          ) : null}
        </Flex>
      ) : null}

      <Table variant="striped" size="sm" mb="10px">
        <Thead mt="5px">
          <Tr>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Daily
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Hourly
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              CC
            </Th>
            <Th textAlign="center" w="10%" color={!active ? "gray.600" : null}>
              Update
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Priority
            </Th>
            <Th textAlign="center" w="18%" color={!active ? "gray.600" : null}>
              Weight
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {routeDetails.callTarget || routeDetails.callPingTree ? (
            <>
              <Tr>
                {/* <Td textAlign="center">{dailyCap === -1 ? 0 : dailyCap}</Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={dailyCap}
                    onChange={handleDailyCapChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                {/* <Td textAlign="center">{hourlyCap}</Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={hourlyCap}
                    onChange={handleHourlyCapChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                {/* <Td textAlign="center">{concurrencyCap}</Td> */}

                {/* <Td textAlign="center" fontWeight={600} letterSpacing="1px">
                  UPDATE
                </Td> */}
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={concurrencyCap}
                    onChange={handleConcurrencyChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>

                <Td textAlign="center" maxW="20px">
                  <IconButton
                    isLoading={loadingTargetUpdate}
                    colorScheme="teal"
                    aria-label="Open Call Details"
                    icon={<FiUploadCloud />}
                    size="sm"
                    onClick={triggerHandleTargetCapsUpdate}
                    isDisabled={!active}
                  />
                </Td>
                <Td textAlign="center" maxW="20px">
                  <NumberInput
                    value={priority}
                    onChange={handlePriorityChange}
                    isDisabled={!active}
                  >
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
                <Td textAlign="center" maxW="20px">
                  <NumberInput value={weight} onChange={handleWeightChange} isDisabled={!active}>
                    <NumberInputField
                      focusBorderColor="teal"
                      size="sm"
                      borderWidth="2px"
                      textAlign="center"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
              </Tr>
            </>
          ) : null}
        </Tbody>
        {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
      </Table>

      <Divider borderWidth={2} />
    </Box>
  );
};

export default function JobsCampaignsModalModal({
  campaignData,
  index,
  updateCampaignDetailsFrontEnd,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const [noData, setNoData] = useState(false);
  const [enabled, setEnabled] = useState(campaignData && campaignData.enabled);
  const [loading, setLoading] = useState(false);
  const [enableCampaignLoading, setEnableCampaignLoading] = useState(false);
  const [loadingCampaignUpdate, setLoadingCampaignUpdate] = useState(false);
  const [loadingTargetUpdate, setLoadingTargetUpdate] = useState(false);
  const [loadingPayoutBuffer, setLoadingPayoutBuffer] = useState(false);

  const [payout, setPayout] = useState();
  const [buffer, setBuffer] = useState();
  const [dailyCap, setDailyCap] = useState();
  const [oldBuffer, setOldBuffer] = useState();
  const [oldPayout, setOldPayout] = useState();
  const [concurrencyCap, setConcurrencyCap] = useState();
  const [hourlyCap, setHourlyCap] = useState();

  const [requiredDataFields, setRequiredDataFields] = useState([]);

  const [copiedText, setCopiedText] = useState();
  const hoverColor = useColorModeValue("gray.200", "gray.500");
  const bgColor = useColorModeValue("teal.200", "gray.500");

  const handlePayoutChange = (value) => setPayout(value);
  const handleBufferChange = (value) => setBuffer(value);
  const handleDailyCapChange = (value) => setDailyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleHourlyCapChange = (value) => setHourlyCap(value);

  const handleCallDetails = async () => {
    onOpen();
    setNoData(false);
    getRoutes();
  };

  const handleEnableCampaign = async (campaignId) => {
    setEnableCampaignLoading(true);

    console.log("campaignData.enabled:", campaignData.enabled);

    try {
      const response = await enableCampaign(!enabled, campaignId);
      if (response) {
        enabled ? alert("Campaign has been paused.") : alert("Campaign has been enabled.");
      }
      setEnabled(!enabled);
      setEnableCampaignLoading(false);
    } catch (error) {
      alert(error);
      setEnableCampaignLoading(false);
    }
  };

  const handleCampaignCapUpdate = async (
    campaignId,
    affiliateId,
    payoutId,
    payoutAmount,
    callLength
  ) => {
    setLoadingCampaignUpdate(true);
    const caps = {
      dailyCap,
      concurrencyCap,
      hourlyCap,
    };

    try {
      const response = await updateCampaignCaps(
        campaignId,
        affiliateId,
        payoutId,
        caps,
        payoutAmount,
        callLength
      );
      if (response) {
        handleCampaignUpdateFrontEnd();
        alert("Campaign has been updated successfully.");
        // getCampaignData();
        setLoadingCampaignUpdate(false);
      } else {
        alert("There seems to have been an error.");
        setLoadingCampaignUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingCampaignUpdate(false);
    }
  };

  const getRoutes = async () => {
    if (data) setData(null);
    setLoading(true);
    try {
      const data = await getCallRoutesWithId(campaignData.name);
      // console.log("getRoutes data:", data);
      if (!data) {
        setLoading(false);
        setNoData(true);
        return;
      }
      setData(data);
      // console.log("routes data::", data);
      setLoading(false);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  const handleRouteUpdate = async (callRouteId, priority, weight) => {
    try {
      const updateStatus = await updateRoutingWeightPriority(callRouteId, priority, weight);
      if (updateStatus) {
        console.log("Weight and Priority updated successfully!");
        // alert(
        //   `Priority and Weight has been updated successfully:
        //   Priority: ${updateStatus.priority}
        //   Weight: ${updateStatus.weight}`
        // );
      } else {
        alert("There was an error.");
      }
    } catch (error) {
      alert(error.message);
    }
    // alert("ID:" + callRouteId + "Priority:" + priority + "Weight:" + weight);
  };

  const handleTargetCapsUpdate = async (rt, targetId, dailyCap, hourlyCap, concurrencyCap, hoo) => {
    setLoadingTargetUpdate(true);

    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    // console.log("hoo:", hoo);

    try {
      const response = await updateTargetCaps(
        rt,
        targetId,
        dailyCap,
        hourlyCap,
        concurrencyCap,
        hoo
      );
      if (response) {
        alert("Target Caps Updated Successfully!");
        setLoadingTargetUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingTargetUpdate(false);
    }
  };

  const setCaps = () => {
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings.length > 0
    ) {
      setDailyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].dailyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap
      );
      setConcurrencyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap
      );
      setHourlyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap
      );
    }
  };

  const setPayoutBuffer = () => {
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings &&
      campaignData.affiliates[0].payoutConversionSettings[0] &&
      campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
    ) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
      setOldBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
    }
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings &&
      campaignData.affiliates[0].payoutConversionSettings[0] &&
      campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionType ===
        "connectedCall"
    ) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(0);
      setOldBuffer(0);
    }
  };

  const handleCampaignUpdateFrontEnd = () => {
    let newCampaignData = campaignData;

    newCampaignData.affiliates[0].payoutConversionSettings[0].dailyCap = parseInt(dailyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].hourlyCap = parseInt(hourlyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap =
      parseInt(concurrencyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].payoutAmount = parseInt(payout);
    newCampaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs.callLengthInSeconds =
      parseInt(buffer);

    console.log("newCampaignData:", newCampaignData);
    setPayoutBuffer();
    updateCampaignDetailsFrontEnd(index, newCampaignData);
  };

  const handleCopyEndpoint = () => {
    setCopiedText(
      `https://display.ringba.com/enrich/${campaignData.affiliateNumbers[0].providerId}?callerid=<<E.164-CALLER-NUMBER>>&key1=value1&key2=value2`
    );
    navigator.clipboard.writeText(
      `https://display.ringba.com/enrich/${campaignData.affiliateNumbers[0].providerId}?callerid=<<E.164-CALLER-NUMBER>>&key1=value1&key2=value2`
    );
  };

  const handleGettingRequiredFields = () => {
    if (data) {
      console.log("data.routes::", data.routes);
      const requiredFields = produceRequiredFields(data.routes);
      console.log("handleGettingRequiredFields requiredFields:", requiredFields);
      if (requiredFields && requiredFields.length > 0) {
        setRequiredDataFields(requiredFields);
      }
    }
  };

  const createdExampleEndpoint = () => {
    const possibleFieldNames = {
      first_name: "John",
      last_name: "Doe",
      email: "johndoe_123@gmail.com",
      address1: "123+First+St",
      city: "Beverly+Hills",
      state: "CA",
      zip: "90210",
      dob: "1975-12-04",
      lead_id: "49E00D8F-BB90-C0AB-0E0E-A7900CC00308",
      trusted_form: "https://cert.trustedform.com/ba000e0be6e00007d570e9c6a000da00348b00da",
      debt_amount: "15000",
      currently_represented: "No",
      needs_attorney: "Yes",
      person_at_fault: "No",
      hospitalized_or_treated: "No",
    };
    const fields = [];
    requiredDataFields.forEach((field) => {
      let exampleField;
      for (const fieldName in possibleFieldNames) {
        // console.log("field::", field, " | ", "fieldName::", fieldName);
        if (fieldName === field) {
          exampleField = possibleFieldNames[fieldName];
        }
        // if(fieldName === "lead_id (Jornaya Lead ID)")
      }
      // console.log("exampleField::", exampleField);
      // if(exampleField === "lead_id (Jornaya Lead ID)") exampleField = "lead_id"
      fields.push(`${field}=${exampleField}`);
    });
    const urlSuffix = fields.join("&");
    let exampleEndpoint = `https://display.ringba.com/enrich/${campaignData.affiliateNumbers[0].providerId}?callerid=+12223334444&${urlSuffix}`;

    console.log("exampleEndpoint:", exampleEndpoint);
    return exampleEndpoint;
  };

  const logMe = () => {
    // console.log("dailyCap:", dailyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    // console.log("campaignData:", campaignData);
    // console.log("data:", data);
    console.log("requiredDataFields:", requiredDataFields);
    // console.log("enabled:", enabled);
    createdExampleEndpoint();
  };

  useEffect(() => {
    setCaps();
    setPayoutBuffer();
  }, [campaignData]);

  useEffect(() => {
    handleGettingRequiredFields();
  }, [data]);

  return (
    <>
      <Tr _hover={{ backgroundColor: hoverColor }} cursor="pointer" onClick={handleCallDetails}>
        <Td onClick={() => console.log(campaignData)}>{campaignData.name}</Td>
        <Td textAlign="center">
          {campaignData.affiliateNumbers && campaignData.affiliateNumbers.length > 0
            ? campaignData.affiliateNumbers[0].phoneNumber
            : "N/A"}
        </Td>
        <Td textAlign="center">
          <Switch colorScheme="teal" isChecked={enabled} isReadOnly />
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose} size="7xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent overflow="scroll">
          {/* <Button onClick={logMe}>CLICK</Button> */}
          <ModalHeader>
            <Flex flexDir="column">
              <Text onClick={logMe}>
                {campaignData.name} <RingbaLink type="campaign" id={campaignData.id} />
              </Text>
              {/* {campaignData ? (
                <Text>DID: {campaignData.affiliateNumbers[0].displayNumber}</Text>
              ) : null} */}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {campaignData ? (
              <Flex>
                <Flex w="50%">
                  <Box w="100%" borderWidth={1} mr={2} pl={2} maxH="70vh" overflowY="scroll">
                    <Table variant="striped" size="lg" mb="10px">
                      <Thead mt="5px">
                        <Tr>
                          <Th textAlign="center" w="50%">
                            Name
                          </Th>

                          <Th textAlign="center" w="25%">
                            DID
                          </Th>
                          <Th textAlign="center" w="20%">
                            Status
                          </Th>
                          <Th textAlign="center" w="5%"></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr _hover={{ backgroundColor: hoverColor }}>
                          <Td textAlign="center">
                            {campaignData.name} <RingbaLink type="campaign" id={campaignData.id} />
                          </Td>
                          <Td textAlign="center">
                            {campaignData.affiliateNumbers &&
                            campaignData.affiliateNumbers.length > 0 &&
                            campaignData.affiliateNumbers[0]
                              ? campaignData.affiliateNumbers[0].phoneNumber
                              : "N/A"}
                          </Td>

                          <Td textAlign="center">
                            <Switch
                              colorScheme="teal"
                              isChecked={enabled}
                              onChange={() => handleEnableCampaign(campaignData.id)}
                            />
                          </Td>
                          <Td textAlign="center">
                            {/* <Spinner color="teal.300" /> */}
                            {enableCampaignLoading ? <Spinner color="teal.300" size="sm" /> : null}
                          </Td>
                        </Tr>
                      </Tbody>

                      {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
                    </Table>
                    {campaignData.affiliateNumbers &&
                    campaignData.affiliateNumbers.length > 0 &&
                    campaignData.affiliates[0].payoutConversionSettings.length > 0 ? (
                      <Flex flexDir="column">
                        <Text
                          fontWeight={500}
                          letterSpacing="3px"
                          textAlign="center"
                          backgroundColor="gray.400"
                        >
                          CAMPAIGN CAPS | PAYOUT/BUFFER
                        </Text>
                        <Table variant="striped" size="md" mb="10px">
                          <Thead>
                            <Tr>
                              <Th textAlign="center">Daily</Th>
                              <Th textAlign="center">Hourly</Th>
                              <Th textAlign="center">CC</Th>
                              <Th textAlign="center">Payout</Th>
                              <Th textAlign="center">Buffer</Th>

                              <Th textAlign="center"></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td textAlign="center">
                                {campaignData.affiliates[0].payoutConversionSettings[0].dailyCap ===
                                -1
                                  ? 0
                                  : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap}
                              </Td>
                              <Td textAlign="center">
                                {campaignData.affiliates[0].payoutConversionSettings[0]
                                  .hourlyCap === -1
                                  ? 0
                                  : campaignData.affiliates[0].payoutConversionSettings[0]
                                      .hourlyCap}
                              </Td>
                              <Td textAlign="center">
                                {campaignData.affiliates[0].payoutConversionSettings[0]
                                  .concurrencyCap === -1
                                  ? 0
                                  : campaignData.affiliates[0].payoutConversionSettings[0]
                                      .concurrencyCap}
                              </Td>
                              <Td textAlign="center">${oldPayout}</Td>
                              <Td textAlign="center">{oldBuffer} s</Td>
                            </Tr>
                            <Tr>
                              <Td textAlign="center">
                                <NumberInput value={dailyCap} onChange={handleDailyCapChange}>
                                  <NumberInputField
                                    focusBorderColor="teal"
                                    size="sm"
                                    borderWidth="2px"
                                    textAlign="center"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td textAlign="center">
                                <NumberInput value={hourlyCap} onChange={handleHourlyCapChange}>
                                  <NumberInputField
                                    focusBorderColor="teal"
                                    size="sm"
                                    borderWidth="2px"
                                    textAlign="center"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td textAlign="center">
                                <NumberInput
                                  value={concurrencyCap}
                                  onChange={handleConcurrencyChange}
                                >
                                  <NumberInputField
                                    focusBorderColor="teal"
                                    size="sm"
                                    borderWidth="2px"
                                    textAlign="center"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td textAlign="center">
                                <NumberInput value={payout} onChange={handlePayoutChange}>
                                  <NumberInputField
                                    focusBorderColor="teal"
                                    size="sm"
                                    borderWidth="2px"
                                    textAlign="center"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td textAlign="center">
                                {buffer === 0 ? (
                                  "Per Connected Call"
                                ) : (
                                  <NumberInput value={buffer} onChange={handleBufferChange}>
                                    <NumberInputField
                                      focusBorderColor="teal"
                                      size="sm"
                                      borderWidth="2px"
                                      textAlign="center"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                )}
                              </Td>
                              <Td textAlign="center">
                                <IconButton
                                  isLoading={loadingCampaignUpdate}
                                  colorScheme="teal"
                                  aria-label="Open Call Details"
                                  icon={<FiUploadCloud />}
                                  size="sm"
                                  onClick={() =>
                                    handleCampaignCapUpdate(
                                      campaignData.id,
                                      campaignData.affiliates[0].id,
                                      campaignData.affiliates[0].payoutConversionSetting.id,
                                      payout,
                                      buffer
                                    )
                                  }
                                />
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <Text
                          fontWeight={500}
                          letterSpacing="3px"
                          textAlign="center"
                          backgroundColor="gray.400"
                        >
                          POSTING INSTRUCTIONS
                        </Text>

                        {requiredDataFields.length > 0 ? (
                          <Flex flexDir="column" p={4}>
                            <Text>Campaign Name: {campaignData.name}</Text>
                            <Text>
                              DID:
                              {campaignData.affiliateNumbers &&
                              campaignData.affiliateNumbers.length > 0
                                ? campaignData.affiliateNumbers[0].phoneNumber.replace("+", "")
                                : null}
                            </Text>
                            <Text>
                              |----------------------Posting Instructions----------------------|
                            </Text>
                            <Text>
                              GET Endpoint:{" "}
                              {`https://display.ringba.com/enrich/${campaignData.affiliateNumbers[0].providerId}?callerid=<<E.164-CALLER-NUMBER>>&key1=value1&key2=value2`}
                            </Text>
                            <Text mt={2}>REQUIRED FIELDS:</Text>
                            <UnorderedList>
                              {requiredDataFields.map((field, i) => (
                                <ListItem key={field}>
                                  {field === "lead_id" ? "lead_id (Jornaya Lead ID)" : field}
                                </ListItem>
                              ))}
                            </UnorderedList>
                            {requiredDataFields.includes("lead_id") ||
                            requiredDataFields.includes("trusted_form") ? (
                              <Text>
                                NOTE: Either one form of Jornaya or Trusted Form is required
                              </Text>
                            ) : null}

                            <Text mt={2}>EXAMPLE ENDPOINT:</Text>
                            {createdExampleEndpoint()}
                          </Flex>
                        ) : (
                          <Flex justify="center" alignItems="center">
                            <Text my={5} py={5} letterSpacing={2}>
                              NO RING TREE TARGETS ON ROUTING PLAN THAT REQUIRE DATA
                            </Text>
                          </Flex>
                        )}

                        {/* {requiredDataFields.length === 0 &&
                        campaignData &&
                        campaignData.affiliateNumbers &&
                        campaignData.affiliateNumbers[0] ? (
                          <Flex w="100%" alignItems="center" p={3} mt={4}>
                            <Text mr={3}>GET Endpoint:</Text>
                            <Flex
                              w="60%"
                              p={2}
                              // h="50px"

                              alignItems="center"
                              borderWidth={1}
                            >
                              <Tooltip
                                label="Copied!"
                                placement="top-end"
                                isOpen={copiedText}
                                hasArrow
                              >
                                <Text
                                  noOfLines={1}
                                >{`https://display.ringba.com/enrich/${campaignData.affiliateNumbers[0].providerId}?callerid=<<E.164-CALLER-NUMBER>>&key1=value1&key2=value2`}</Text>
                              </Tooltip>
                            </Flex>
                            <Button
                              ml={2}
                              size="sm"
                              cursor="pointer"
                              colorScheme="teal"
                              onClick={handleCopyEndpoint}
                            >
                              COPY
                            </Button>
                          </Flex>
                        ) : null} */}
                      </Flex>
                    ) : (
                      <Flex my="10%" justify="center">
                        <Spinner
                          color="green.400"
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          size="xl"
                        />
                      </Flex>
                    )}
                  </Box>
                </Flex>
                <Flex w="50%">
                  <Box
                    w="100%"
                    borderWidth={1}
                    ml={2}
                    pl={2}
                    maxH="70vh"
                    overflowX="hidden"
                    overflowY="scroll"
                  >
                    <Flex flexDir="column">
                      {loading ? (
                        <Flex mt="10%" justify="center">
                          <Spinner
                            color="green.400"
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            size="xl"
                          />
                        </Flex>
                      ) : null}
                      {noData ? (
                        <Flex mt="10%" justify="center">
                          <Text>No Routes Found.</Text>
                        </Flex>
                      ) : null}
                      {data ? (
                        <Text
                          fontWeight={500}
                          letterSpacing="3px"
                          textAlign="center"
                          backgroundColor="gray.400"
                          mt="10px"
                        >
                          TARGET ROUTING & CAPS
                        </Text>
                      ) : null}
                      {data
                        ? data.routes.map((routeDetails, i) => (
                            <RoutingPlanTable
                              key={
                                routeDetails.callTarget
                                  ? routeDetails.callTarget
                                  : routeDetails.callPingTree
                              }
                              routeDetails={routeDetails}
                              handleRouteUpdate={handleRouteUpdate}
                              handleTargetCapsUpdate={handleTargetCapsUpdate}
                              loadingTargetUpdate={loadingTargetUpdate}
                              active={
                                (routeDetails.callTarget && routeDetails.callTarget.enabled) ||
                                (routeDetails.callPingTree && routeDetails.callPingTree.enabled)
                              }
                            />
                          ))
                        : null}
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
