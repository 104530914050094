import { Box, Button, FormControl, FormLabel, Input, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import Logo from "../assets/NLD_logo.jpg";
import CreateNewUserModal from "../components/login/CreateNewUserModal";

export default function Login({ login, resetInput, email, setEmail, password, setPassword }) {
  return (
    <Box
      className="login"
      justifyContent="center"
      alignItems="center"
      //   w="100vw"
      //   h="100vh"
      //   backgroundColor="red"
    >
      <Flex
        className="login__container"
        w="500px"
        alignSelf="center"
        justifySelf="center"
        borderRadius="10px"
        mb="10%"
        backgroundColor="white"
        boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.5)"
      >
        <Image src={Logo} boxSize="150px" m="auto" />
        <Text mb="20px" fontSize="18px" color="black">
          NexLevel Direct
        </Text>

        <FormControl id="email">
          <FormLabel color="black">Email address</FormLabel>
          <Input color="black" type="email" onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
        <FormControl id="password">
          <FormLabel color="black">Password</FormLabel>
          <Input color="black" type="password" onChange={(e) => setPassword(e.target.value)} />
        </FormControl>

        <Button
          mt={8}
          colorScheme="green"
          onClick={() => signInWithEmailAndPassword(email, password)}
        >
          Login
        </Button>
        {email === "admin" && password === "NLDadmin" ? <CreateNewUserModal /> : null}
      </Flex>
    </Box>
  );
}
