import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  IconButton,
  Spinner,
  Divider,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  AlertDescription,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Tooltip,
  useForceUpdate,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import TopHeading from "../TopHeading";
import { v4 as uuidv4 } from "uuid";

import { addNewActiveLive, deleteActiveLive, editActiveLive, getActiveLive } from "../qa/QaApis";
import { getInsights } from "../performance/performanceApi";

import Select from "react-select";

import { FiRefreshCcw } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { FiSave } from "react-icons/fi";
import { BsTrash, BsFillFilterSquareFill } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";

import moment from "moment";
import DispositionKey from "../qa/DispositionKey";
import QaKeyModal from "./QaKeyModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import TableColumnMenu from "./TableColumnMenu";

const NewEntry = ({ getAllActiveLiveData, insightsData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newInsightData, setNewInsightData] = useState(insightsData);
  const [selectedTargetChoices, setSelectedTargetChoices] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [status, setStatus] = useState();
  const [statusOptions, setStatusOptions] = useState([
    { value: "Live", label: "Live" },
    { value: "Paused", label: "Paused" },
    { value: "Pending", label: "Pending" },
    { value: "Benched", label: "Benched" },
  ]);
  const [vertical, setVertical] = useState("");
  const [verticalColor, setVerticalColor] = useState();
  const [publisher, setPublisher] = useState("");
  const [buyer, setBuyer] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    // control: (provided, { isSelected }) => ({
    //   ...provided,
    //   backgroundColor: bgColor,
    //   color: isSelected ? fontColor : "red",
    // }),
  };

  const handleCampaignNameChange = (e) => {
    let campaign = e.value;
    setCampaignName(campaign);
    let campaignIndex;
    insightsData.forEach((campaign) => {
      if (campaign.campaignName === campaign) campaignIndex = insightsData.indexOf();
      console.log();
    });
    setSelectedTargetChoices(insightsData[campaignIndex]);
  };

  const handleStatusChange = (e) => {
    let status = e.value;
    setStatus(status);
  };

  const handleVerticalChange = (e) => {
    let vertical = e.value;
    // const verticalData = qaKeyData.vertical;
    // const color = verticalData.find((x) => x.name === vertical).color;

    setVertical(vertical);
    // setVerticalColor(color);
  };

  const handlePublisherChange = (e) => {
    let publisher = e.value;
    setPublisher(publisher);
  };

  const handleAdvertiserChange = (e) => {
    let buyer = e.value;
    setBuyer(buyer);
  };

  const handleNoteChange = (e) => {
    let note = e.target.value;
    setNotes(note);
  };

  const resetValues = () => {
    setStatus();
    setCampaignName();
    setVertical();
    setVerticalColor();
    setPublisher();
    setBuyer();
    setNotes("");
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const createData = async () => {
    console.log("selectedTargetChoices:", selectedTargetChoices);
    // setLoading(true);
    // setSuccess(false);
    // setFailure(false);
    // const setVerticalColor = () => {
    //   if(qaKeyData)
    // }

    // const data = {
    //   updated: moment().format("l LT"),
    //   assignedTo,
    //   status,
    //   vertical,
    //   publisher,
    //   buyer,
    //   notes,
    //   color: verticalColor,
    //   uuid: uuidv4(),
    // };
    // // console.log(data);

    // try {
    //   const result = await addNewQaAssignment(data);
    //   // console.log(result);
    //   setSuccess(true);
    //   setFailure(false);
    //   setTimeout(() => {
    //     setLoading(false);
    //     setSuccess(false);
    //     setFailure(false);
    //     onClose();
    //   }, 2000);
    //   getAllActiveLiveData();
    //   resetValues();
    // } catch (error) {
    //   alert("error:", error);
    //   setLoading(false);
    //   setSuccess(false);
    //   setFailure(true);
    // }
  };

  const logMe = () => {
    // console.log("qaKeyData:", qaKeyData);
  };

  return (
    <>
      <IconButton colorScheme="teal" icon={<GoPlus />} onClick={onOpen} mx="10px" />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>New Active Live Campaign</ModalHeader>
          {/* <Button onClick={logMe}>Log Me</Button> */}

          <ModalCloseButton />
          <ModalBody>
            <FormControl id="assigned_to" isRequired mb={4}>
              <FormLabel>Campaign Name</FormLabel>
              <Select
                placeholder="Select name"
                onChange={handleCampaignNameChange}
                options={insightsData}
                styles={selectStyles}
              />
            </FormControl>

            <FormControl id="status" mb={4} isRequired>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                onChange={handleStatusChange}
                options={statusOptions}
                styles={selectStyles}
              />
            </FormControl>
            <Flex>
              <FormControl isRequired id="vertical" mb={4}>
                <FormLabel>Vertical</FormLabel>
                <Select
                  placeholder="Select Vertical"
                  onChange={handleVerticalChange}
                  // options={qaKeyData.vertical}
                  styles={selectStyles}
                />
              </FormControl>

              <FormControl isRequired id="publisher" mx={3} mb={4}>
                <FormLabel>Publisher</FormLabel>
                <Select
                  placeholder="Select Publisher"
                  onChange={handlePublisherChange}
                  // options={qaKeyData.publisher}
                  styles={selectStyles}
                />
              </FormControl>

              <FormControl isRequired id="buyer" mb={4}>
                <FormLabel>Buyer</FormLabel>
                <Select
                  placeholder="Select Buyer"
                  onChange={handleAdvertiserChange}
                  // options={insightsData.targets}
                  styles={selectStyles}
                />
              </FormControl>
            </Flex>

            <Flex flexDir="column" mb={4}>
              <FormLabel>Notes</FormLabel>
              <Textarea
                rows={8}
                style={{ whiteSpace: "pre-wrap" }}
                placeholder="Enter notes here."
                value={notes}
                onChange={handleNoteChange}
                backgroundColor="white"
                color="black"
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            {success ? (
              <Alert status="success" mr="20px">
                <AlertIcon />
                <AlertDescription>Success!</AlertDescription>
              </Alert>
            ) : null}
            {failure ? (
              <Alert status="error" mr="20px">
                <AlertIcon />
                <AlertDescription>Error!</AlertDescription>
              </Alert>
            ) : null}
            <Button colorScheme="teal" onClick={createData} w="100px" isDisabled={loading}>
              {loading ? (
                <Spinner color="teal.300" speed="0.65s" emptyColor="gray.200" />
              ) : (
                "Submit"
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const QaNote = ({ note }) => {
  const [notes, setNotes] = useState(note.note);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const handleDelete = async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        alert("DONE!");
        setLoading(false);
      }, 2000);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  return (
    <Tr _hover={{ backgroundColor: hoverColor }}>
      <Td textAlign="center" w="200px">
        {moment(note.timestamp).format("L LT")}
      </Td>
      <Td textAlign="center" w="250px">
        {note.createdBy}
      </Td>
      <Td textAlign="center">
        <Textarea
          backgroundColor="white"
          color="black"
          rows={4}
          style={{ whiteSpace: "pre-wrap" }}
          placeholder="Enter notes here."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Td>
      <Td textAlign="center" w="80px">
        <Flex>
          <Tooltip hasArrow placement="top" label="Update">
            <IconButton
              isLoading={updateLoading}
              colorScheme="teal"
              onClick={() => console.log(note)}
              icon={<FiSave />}
              mr={2}
              isDisabled={updateLoading}
            />
          </Tooltip>
          <ConfirmationModal
            isIconButton
            buttonSize="md"
            icon={<BsTrash />}
            loading={loading}
            success={success}
            header="Delete Confirmation"
            messages={["Are you sure you want to delete this?"]}
            alert="This cannot be undone."
            buttonText="Delete"
            event={handleDelete}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

const TableRow = ({ campaign, getAssignments, updateAssignmentsFrontEnd }) => {
  const [note, setNote] = useState("");

  const [noteLoading, setNoteLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const selectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : null,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5e9e4",
      color: "green",
    }),
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const handleNewNote = async () => {
    setNoteLoading(true);
    try {
      setTimeout(() => {
        alert("DONE!");
        setNoteLoading(false);
      }, 2000);
    } catch (error) {
      alert(error.message);
      setNoteLoading(false);
    }
  };

  //   const updateQaAssignment = async (assignmentData) => {
  //     setUpdateLoading(true);
  //     updateAssignmentsFrontEnd(assignmentData);
  //     const data = {
  //       updated: moment().format("l LT"),
  //       assignedTo,
  //       status,
  //       vertical,
  //       publisher,
  //       buyer,
  //       notes,
  //       uuid: campaign.uuid,
  //     };

  //     try {
  //       const response = await editQaAssignment(data);
  //       getAssignments();
  //       setUpdateLoading(false);
  //     } catch (error) {
  //       alert(error.message);
  //       setUpdateLoading(false);
  //     }
  //   };

  //   const removeQaAssignment = async () => {
  //     setDeleteLoading(true);
  //     setSuccess(false);
  //     setFailure(false);
  //     const data = {
  //       uuid: campaign.uuid,
  //     };

  //     try {
  //       const response = await deleteQaAssignment(data);
  //       getAssignments();
  //       setSuccess(true);
  //       setTimeout(() => {
  //         setDeleteLoading(false);
  //         setSuccess(false);
  //         setFailure(false);
  //       }, 2000);
  //     } catch (error) {
  //       alert(error.message);
  //       setDeleteLoading(false);
  //     }
  //   };

  const logMe = () => {
    console.log("assignment:", campaign);
    // console.log("ccCap:", ccCap);
    // console.log("hourlyCap:", hourlyCap);
  };

  return (
    <>
      {campaign ? (
        <Tr _hover={{ backgroundColor: hoverColor }}>
          <Td textAlign="center" w="220px" onClick={onOpen} cursor="pointer">
            {campaign.status}
          </Td>
          <Td textAlign="center" w="150px" onClick={onOpen} cursor="pointer">
            {campaign.vertical}
          </Td>
          <Td textAlign="center" onClick={onOpen} cursor="pointer">
            {campaign.buyer}
          </Td>
          <Td textAlign="center" onClick={onOpen} cursor="pointer">
            {campaign.publisher}
          </Td>
          <Td textAlign="center" onClick={onOpen} cursor="pointer">
            {campaign.campaign}
          </Td>
          <Td textAlign="center">
            <Tooltip hasArrow placement="top" label="Delete">
              <ConfirmationModal
                buttonText="Delete"
                header="Delete Confirmation"
                messages={["Would you like to delete this Active Live Campaign?"]}
                alert="This cannot be undone"
                event={() => alert("Delete me!~")}
                isDisabled={deleteLoading}
                isIconButton
                icon={<BsTrash />}
                loading={deleteLoading}
                success={success}
                failure={failure}
              />
            </Tooltip>
          </Td>
        </Tr>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader onClick={() => console.log(campaign)}>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderWidth={1} borderRadius={7} p={4} mb={5}>
              <Table w="100%">
                <Thead>
                  <Tr>
                    <Th textAlign="center">Status</Th>
                    <Th textAlign="center">Vertical</Th>
                    <Th textAlign="center">Buyer</Th>
                    <Th textAlign="center">Publisher</Th>
                    <Th textAlign="center">Campaign</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td textAlign="center" w="220px">
                      {campaign.status}
                    </Td>
                    <Td textAlign="center" w="150px">
                      {campaign.vertical}
                    </Td>
                    <Td textAlign="center">{campaign.buyer}</Td>
                    <Td textAlign="center">{campaign.publisher}</Td>
                    <Td textAlign="center">{campaign.campaign}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>

            <Box borderWidth={1} borderRadius={7} p={4} mb={5}>
              {campaign && campaign.notes.length > 0 ? (
                <>
                  <Text textAlign="center" letterSpacing={3} fontSize={18} mb={3}>
                    NOTES
                  </Text>
                  <Table w="100%">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">CREATED</Th>
                        <Th textAlign="center">Created By</Th>
                        <Th textAlign="center">Note</Th>
                        <Th textAlign="center"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {campaign.notes.map((note) => (
                        <QaNote note={note} />
                      ))}
                    </Tbody>
                  </Table>
                </>
              ) : (
                <Flex w="100%" justify="center" mt="5%">
                  <Text fontSize={20} letterSpacing={4}>
                    NO CURRENT NOTES
                  </Text>
                </Flex>
              )}
              <Flex w="100%" mt={4} alignItems="center" justifyContent="center">
                <Flex flexDir="column" w="75%" mr={4}>
                  <Text letterSpacing={4}>NEW NOTE</Text>
                  <Textarea
                    rows={4}
                    style={{ whiteSpace: "pre-wrap" }}
                    placeholder="Enter notes here."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Flex>
                <Flex flexDir="column" w="25%">
                  <Text>&nbsp;</Text>
                  <Button isLoading={noteLoading} colorScheme="teal" onClick={handleNewNote}>
                    Add
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default function ActiveLive() {
  const [data, setData] = useState();
  const [insightsData, setInsightsData] = useState();
  const [insightsLoading, setInsightsLoading] = useState(false);
  const [existingQaKeys, setExistingQaKeys] = useState();
  const [filteredData, setFilteredData] = useState();
  const [qaKeyData, setQaKeyData] = useState();
  const [loading, setLoading] = useState(false);
  const [getQaKeysLoading, setGetQaKeysLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment().format("LT"));
  const [sortByColumnName, setSortByColumnName] = useState("created");
  const bgColor = useColorModeValue("white", "#1A202C");
  const hoverColor = useColorModeValue("gray.200", "gray.400");

  const sortByCallDateTime = (a, b) => {
    if (a.call_date_time < b.call_date_time) {
      return -1;
    }
    if (a.call_date_time > b.call_date_time) {
      return 1;
    }
    return 0;
  };
  const sortByAcl = (a, b) => {
    if (a.avgHandleTime < b.avgHandleTime) {
      return -1;
    }
    if (a.avgHandleTime > b.avgHandleTime) {
      return 1;
    }
    return 0;
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const getAllActiveLiveData = async () => {
    if (filteredData) setFilteredData(null);
    if (!data) setLoading(true);
    // setData(null);

    try {
      const result = await getActiveLive();
      if (result && result.result) {
        // console.log(result.result);
        setData(result.result);
      } else {
        alert("Unable to retrieve data. Try again.");
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("error:", error);
      setLoading(false);
    }
  };

  //   const organizeExistingQaKeys = () => {
  //     let assignedTo = [];
  //     let buyer = [];
  //     let publisher = [];
  //     let vertical = [];
  //     let status = [];

  //     // console.log("organizeExistingQaKeys qa:", data);

  //     if (data) {
  //       data.forEach((assignment) => {
  //         if (!assignedTo.some((i) => (i.name ? i.name.includes(assignment.assignedTo) : false))) {
  //           const obj = {
  //             name: assignment.assignedTo,

  //             type: "name",
  //           };
  //           assignedTo.push(obj);
  //         }
  //         if (!buyer.some((i) => i.name.includes(assignment.buyer))) {
  //           const obj = {
  //             name: assignment.buyer,

  //             type: "buyer",
  //           };
  //           buyer.push(obj);
  //         }
  //         if (!publisher.some((i) => i.name.includes(assignment.publisher))) {
  //           const obj = {
  //             name: assignment.publisher,

  //             type: "publisher",
  //           };
  //           publisher.push(obj);
  //         }
  //         if (!vertical.some((i) => i.name.includes(assignment.vertical))) {
  //           const obj = {
  //             name: assignment.vertical,

  //             type: "vertical",
  //           };
  //           vertical.push(obj);
  //         }
  //         if (!status.some((i) => i.name.includes(assignment.status))) {
  //           const obj = {
  //             name: assignment.status,

  //             type: "status",
  //           };
  //           status.push(obj);
  //         }
  //       });
  //     }

  //     const currentKeys = {
  //       assignedTo: assignedTo.sort(sortByName),
  //       buyer: buyer.sort(sortByName),
  //       publisher: publisher.sort(sortByName),
  //       vertical: vertical.sort(sortByName),
  //       status: status.sort(sortByName),
  //     };

  //     // console.log("currentKeys:", currentKeys);

  //     setExistingQaKeys(currentKeys);
  //   };

  //   const getQaKeys = async () => {
  //     // const dataArray = [];
  //     if (data) {
  //       setGetQaKeysLoading(true);
  //       try {
  //         const result = await getAllQaKeys();
  //         // console.log(result);
  //         if (result.result[0]) {
  //           const dataResult = result.result[0];
  //           // dataResult.forEach((assignment) => {});
  //           setQaKeyData(result.result[0]);
  //           setGetQaKeysLoading(false);
  //         } else {
  //           alert("Couldn't retrieve data. Try again.");
  //         }
  //       } catch (error) {
  //         alert("error:", error);
  //         setGetQaKeysLoading(false);
  //       }
  //     }
  //   };

  const updateAssignmentsFrontEnd = (assignmentData) => {
    const newData = [...data];
    const assignmentIndex = newData.findIndex(
      (assignment) => assignment.uuid === assignmentData.uuid
    );

    // console.log("assignmentData:", assignmentData);
    // console.log("assignmentIndex:", assignmentIndex);

    newData.splice(assignmentIndex, 1, assignmentData);

    setData(newData);
  };

  const sortColumn = (columnName, asc) => {
    let newData = filteredData ? [...filteredData] : [...data];
    if (columnName === "created") {
      if (sortByColumnName === "created" && !asc) {
        newData.sort(sortByCreatedDateDesc);
      } else {
        setSortByColumnName("created");
        newData.sort(sortByCreatedDateAsc);
      }
    }
    if (columnName === "assigned") {
      if (sortByColumnName === "assigned" && !asc) {
        newData.sort(sortByAssignedDesc);
      } else {
        setSortByColumnName("assigned");
        newData.sort(sortByAssignedAsc);
      }
    }
    if (columnName === "status") {
      if (sortByColumnName === "status" && !asc) {
        newData.sort(sortByStatusDesc);
      } else {
        setSortByColumnName("status");
        newData.sort(sortByStatusAsc);
      }
    }
    if (columnName === "vertical") {
      if (sortByColumnName === "vertical" && !asc) {
        newData.sort(sortByVerticalDesc);
      } else {
        setSortByColumnName("vertical");
        newData.sort(sortByVerticalAsc);
      }
    }
    if (columnName === "publisher") {
      if (sortByColumnName === "publisher" && !asc) {
        newData.sort(sortByPublisherDesc);
      } else {
        setSortByColumnName("publisher");
        newData.sort(sortByPublisherAsc);
      }
    }
    if (columnName === "buyer") {
      if (sortByColumnName === "buyer" && !asc) {
        newData.sort(sortByBuyerDesc);
      } else {
        setSortByColumnName("buyer");
        newData.sort(sortByBuyerAsc);
      }
    }
    if (filteredData) {
      setFilteredData(newData);
    } else {
      setData(newData);
    }
  };

  const sortByCreatedDateAsc = (a, b) => {
    if (a.updated > b.updated) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByCreatedDateDesc = (a, b) => {
    if (a.updated > b.updated) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByAssignedAsc = (a, b) => {
    if (a.assignedTo > b.assignedTo) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByAssignedDesc = (a, b) => {
    if (a.assignedTo > b.assignedTo) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByStatusAsc = (a, b) => {
    if (a.status > b.status) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByStatusDesc = (a, b) => {
    if (a.status > b.status) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByVerticalAsc = (a, b) => {
    if (a.vertical > b.vertical) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByVerticalDesc = (a, b) => {
    if (a.vertical > b.vertical) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByPublisherAsc = (a, b) => {
    if (a.publisher > b.publisher) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByPublisherDesc = (a, b) => {
    if (a.publisher > b.publisher) {
      return -1;
    } else {
      return 1;
    }
  };
  const sortByBuyerAsc = (a, b) => {
    if (a.buyer > b.buyer) {
      return 1;
    } else {
      return -1;
    }
  };
  const sortByBuyerDesc = (a, b) => {
    if (a.buyer > b.buyer) {
      return -1;
    } else {
      return 1;
    }
  };

  const updateFilteredData = (filterBy, filterValue) => {
    if (filterBy === "all") {
      setFilteredData(null);
      return;
    }
    // console.log("filterBy", filterBy);
    // console.log("filterValue", filterValue);
    let newData = [];
    if (data) {
      setFilteredData(null);

      data.forEach((assignment) => {
        if (filterBy === "name") {
          if (assignment.assignedTo === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "status") {
          if (assignment.status === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "vertical") {
          if (assignment.vertical === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "publisher") {
          if (assignment.publisher === filterValue) {
            newData.push(assignment);
          }
        }
        if (filterBy === "buyer") {
          if (assignment.buyer === filterValue) {
            newData.push(assignment);
          }
        }
      });
      // console.log("newData:", newData);
      setFilteredData(newData);
    }
  };

  const getInsightsData = async () => {
    setInsightsLoading(true);
    try {
      const response = await getInsights();
      console.log(response);
      formatInsightsData(response);
      setInsightsLoading(false);
    } catch (error) {
      alert(error.message);
      setInsightsLoading(false);
    }
  };

  const formatInsightsData = (insightData) => {
    let formattedData = [];
    insightData.forEach((campaign) => {
      const data = {
        campaignName: campaign.campaign.campaignName,
        value: campaign.campaign.campaignName,
        label: campaign.campaign.campaignName,
        targets: [],
      };
      campaign.targets.forEach((target) => {
        if (target.targetName) {
          const targetData = {
            value: target.targetName,
            label: target.targetName,
          };
          data.targets.push(targetData);
        }
      });
      formattedData.push(data);
    });
    setInsightsData(formattedData);
  };

  useEffect(() => {
    getAllActiveLiveData();
  }, []);

  //   useEffect(() => {
  //     getQaKeys();
  //   }, [data]);

  //   useEffect(() => {
  //     organizeExistingQaKeys();
  //   }, [data]);

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      overflowY="auto"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="Active Live Summary"
        description="QA Summary of all campaigns."
        children={
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Button onClick={getInsightsData}>getInsightsData</Button>
            <Button onClick={() => console.log(insightsData)}>LOG insightsData</Button>
            {lastUpdated ? (
              <Text fontStyle="italic" mr="10px">
                Last Updated: {lastUpdated}
              </Text>
            ) : null}

            <IconButton
              onClick={getActiveLive}
              aria-label="Pull Mortgage Data"
              icon={<FiRefreshCcw />}
            />
            <NewEntry getAllActiveLiveData={getAllActiveLiveData} insightsData={insightsData} />
          </Flex>
        }
      />

      <Flex flexDir="column" h="500px" mb="15px">
        {loading ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}

        {data || filteredData ? (
          <Flex flexDir="column" w="100%">
            <Table size="sm" w="100%">
              <Thead>
                <Tr>
                  <Th textAlign="center">Status</Th>
                  <Th textAlign="center">Vertical</Th>
                  <Th textAlign="center">Buyer</Th>
                  <Th textAlign="center">Publisher</Th>
                  <Th textAlign="center">Campaign</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData
                  ? filteredData.sort(sortByCallDateTime).map((campaign, i) => (
                      <TableRow
                        key={campaign.uuid}
                        campaign={campaign}
                        //   qaKeyData={qaKeyData}
                        getAllActiveLiveData={getAllActiveLiveData}
                        //   updateAssignmentsFrontEnd={updateAssignmentsFrontEnd}
                      />
                    ))
                  : data.sort(sortByCallDateTime).map((campaign, i) => (
                      <TableRow
                        key={campaign.uuid}
                        campaign={campaign}
                        //   qaKeyData={qaKeyData}
                        getAllActiveLiveData={getAllActiveLiveData}
                        //   updateAssignmentsFrontEnd={updateAssignmentsFrontEnd}
                      />
                    ))}
              </Tbody>
            </Table>
            {/* <Text style={{ whiteSpace: "pre-line" }}>{`${data[0].notes}`}</Text> */}
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
