import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Alert,
  AlertIcon,
  Flex,
  Tooltip,
  useColorModeValue,
  Textarea,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { addNewMarkToCall, unmarkCall, updateMarkedCallStatus } from "./QaApis";

import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";

export default function MarkCall({
  inboundCallId,
  createdBy,
  marked,
  updateCallFromFlaggedFrontEnd,
  callIndex,
  call,
  closeCall,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleMarkingCall = async () => {
    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
      mark: {
        createdBy,
      },
    };

    console.log(data);

    try {
      const result = await addNewMarkToCall(data);
      console.log(result);
      setLoading(false);

      const callCopy = data.flag;
      call.tags.marks = {
        marked: true,
        createdBy: data.mark.createdBy,
        status: "pending",
      };

      console.log("callCopy:", callCopy);

      //   updateCallFromFlaggedFrontEnd(callIndex, callCopy);

      onClose();
      if (closeCall) closeCall();
    } catch (error) {
      alert(error);
    }
  };

  const handleMarkedCallStatus = async (status) => {
    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
      mark: {
        status,
      },
    };

    try {
      const result = await updateMarkedCallStatus(data);
      console.log(result);
      setLoading(false);

      const callCopy = call;
      call.tags.marks.status = status;

      console.log("callCopy:", callCopy);
      updateCallFromFlaggedFrontEnd(callIndex, callCopy);
    } catch (error) {
      alert(error);
    }
  };

  const handleUnmarkingCall = async () => {
    setLoading(true);
    const data = {
      call_unique_id: inboundCallId,
    };

    console.log(data);

    try {
      const result = await unmarkCall(data);
      console.log(result);
      setLoading(false);

      const callCopy = call;
      call.tags = {};

      console.log("callCopy:", callCopy);
      updateCallFromFlaggedFrontEnd(callIndex, callCopy);

      onClose();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      {marked ? (
        <Flex>
          <Tooltip label="Unmark Call">
            <Button onClick={handleUnmarkingCall} mr={3} isLoading={loading}>
              <TiStarOutline />
            </Button>
          </Tooltip>
          {call.tags.marks.status === "pending" ? (
            <Button
              colorScheme="teal"
              onClick={() => handleMarkedCallStatus("resolved")}
              mr={3}
              isLoading={loading}
            >
              RESOLVE
            </Button>
          ) : (
            <Button
              colorScheme="teal"
              onClick={() => handleMarkedCallStatus("pending")}
              mr={3}
              isLoading={loading}
            >
              UNRESOLVE
            </Button>
          )}
        </Flex>
      ) : (
        <Tooltip label="Mark Call">
          <Button onClick={handleMarkingCall} mr={3} isLoading={loading}>
            <TiStarFullOutline color="yellow" />
          </Button>
        </Tooltip>
      )}
    </>
  );
}
