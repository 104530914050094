import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  IconButton,
  Spinner,
  useColorModeValue,
  forwardRef,
  Tooltip,
} from "@chakra-ui/react";
import TopHeading from "../TopHeading";
import { getAllQaDispositions, getAllCalls, insertAllCallsIntoDB } from "../qa/QaApis";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
// import { CSVLink } from "react-csv";

import { FiRefreshCcw } from "react-icons/fi";
import { AiFillFilter, AiFillAlert } from "react-icons/ai";
import { TiStarFullOutline } from "react-icons/ti";

import moment from "moment";
import DispositionKey from "../qa/DispositionKey";

import "./QaCalls.css";

import QaCallTableRow from "./QaCallTableRow";
import QaCallReportInternal from "./QaCallReportInternal";
import QaCallReportExternal from "./QaCallReportExternal";
import Select from "react-select";
import SearchCid from "./search-cid/SearchCid";

// import { v4 as uuid } from "uuid";

export default function QaCalls({ username }) {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  // const [csvData, setCsvData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filters, setFilters] = useState({
    verticals: [],
    buyers: [],
    publishers: [],
    targets: [],
    campaigns: [],
  });
  const [existingQaKeys, setExistingQaKeys] = useState();
  const [duplicateUniqueIds, setDuplicateUniqueIds] = useState();
  const [callsLoading, setCallsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insertCallsLoading, setInsertCallsLoading] = useState(false);
  const [sortByColumnName, setSortByColumnName] = useState("timestamp");
  const [asc, setAsc] = useState(true);
  // const [lastUpdated, setLastUpdated] = useState(moment().format("LT"));
  // const [sortFilter, setSortFilter] = useState("name");
  const [qaDispos, setQaDispos] = useState([]);
  // const fileNameInternal = `qa_report_internal_${moment().format("MMDDYYYY")}.csv`;
  // const fileNameExternal = `qa_report_external_${moment().format("MMDDYYYY")}.csv`;
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#1A202C");
  const [lastGenerated, setLastGenerated] = useState();

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
      width: "100%",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : "#1A202C",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      color: fontColor,
      backgroundColor: "#1A202C",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      color: fontColor,
    }),
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput onClick={onClick} ref={ref} value={value} />
  ));

  const CustomInput = ({ onClick, ref, value }) => {
    return (
      <Button onClick={onClick} ref={ref} pr="40px" w="150px">
        {value === moment().format("L") ? "Today" : value}
      </Button>
    );
  };

  const getAllCallsFromDb = async () => {
    setCallsLoading(true);
    if (currentItems) setCurrentItems(null);
    if (filteredData) setFilteredData(null);
    if (data) setData(null);

    const ringbaInboundIds = [];

    try {
      const result = await getAllCalls(buildReportQuery());
      if (result) {
        // console.log("result:", result);
        const retrievedData = result.result?.sort(sortByTimestamp(true));

        retrievedData.forEach((call, i) => {
          ringbaInboundIds.push(call.call_unique_id);
        });

        // console.log("ringbaInboundIds:", ringbaInboundIds);

        const duplicateIds = findDupeIds(ringbaInboundIds);

        // console.log("duplicateIds:", duplicateIds);

        setDuplicateUniqueIds(duplicateIds);
        setData(retrievedData);

        setCallsLoading(false);
      } else {
        setCallsLoading(false);
        return;
      }
    } catch (error) {
      alert(error.message);
      setCallsLoading(false);
    }
  };

  const findDupeIds = (ids) => {
    const dupes = ids.filter((item, index) => ids.indexOf(item) !== index);
    return dupes;
  };

  const buildReportQuery = () => {
    const payload = {
      startDate: moment(startDate).format("Y-MM-DD"),
      endDate: moment(endDate).add(1, "day").format("Y-MM-DD"),
    };

    return payload;
  };

  const getQaDispositions = async () => {
    setLoading(true);
    try {
      const data = await getAllQaDispositions();
      setQaDispos(data.result);
      // console.log("qaDispos::", qaDispos);
      setLastGenerated(moment().format("LT"));
      setLoading(false);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  const insertAllCalls = async () => {
    setInsertCallsLoading(true);
    try {
      const data = await insertAllCallsIntoDB();
      console.log("successfully inserted all calls into database!");
      setInsertCallsLoading(false);
    } catch (error) {
      alert("error:", error);
      setInsertCallsLoading(false);
    }
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const organizeExistingColumnNames = () => {
    let vertical = [];
    let buyer = [];
    let publisher = [];
    let target = [];
    let campaign = [];

    // console.log("organizeExistingColumnNames qa:", data);
    const filterByCampaignName = (a, b) => {
      if (a.campaign_name > b.campaign_name) return 1;
      else if (a.campaign_name < b.campaign_name) return -1;
      else return 0;
    };

    if (data) {
      data.sort(filterByCampaignName).forEach((call) => {
        if (!vertical.some((i) => (i.name ? i.name.includes(call.vertical) : false))) {
          const obj = {
            name: call.vertical,
            type: "vertical",
            value: call.vertical,
            label: call.vertical,
          };

          vertical.push(obj);
        }
        if (
          call.buyer_name &&
          !buyer.some((i) => (i.name ? i.name.includes(call.buyer_name) : false))
        ) {
          const obj = {
            name: call.buyer_name,
            type: "buyer",
            value: call.buyer_name,
            label: call.buyer_name,
          };
          buyer.push(obj);
        }
        if (!publisher.some((i) => (i.name ? i.name.includes(call.publisher_name) : false))) {
          const obj = {
            name: call.publisher_name,
            type: "publisher",
            value: call.publisher_name,
            label: call.publisher_name,
          };
          publisher.push(obj);
        }
        if (
          call.target_name &&
          !target.some((i) => (i.name ? i.name.includes(call.target_name) : false))
        ) {
          const obj = {
            name: call.target_name,
            type: "target",
            value: call.target_name,
            label: call.target_name,
          };
          target.push(obj);
        }
        if (!campaign.some((i) => (i.name ? i.name.includes(call.campaign_name) : false))) {
          const obj = {
            name: call.campaign_name,
            type: "campaign",
            value: call.campaign_name,
            label: call.campaign_name,
          };
          campaign.push(obj);
        }
      });
    }

    const currentKeys = {
      vertical: vertical.sort(sortByName),
      buyer: buyer.sort(sortByName),
      publisher: publisher.sort(sortByName),
      target: target.sort(sortByName),
      campaign: campaign.sort(sortByName),
    };

    // console.log("currentKeys:", currentKeys);

    setExistingQaKeys(currentKeys);
  };

  const sortColumn = (columnName) => {
    let dataCopy = filteredData ? [...filteredData] : [...data];

    if (columnName === "timestamp") {
      if (sortByColumnName === "timestamp") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByTimestamp(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByTimestamp(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByTimestamp(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByTimestamp(true));
        }
        setSortByColumnName("timestamp");
      }
    }
    if (columnName === "vertical") {
      if (sortByColumnName === "vertical") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByVertical(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByVertical(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByVertical(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByVertical(true));
        }
        setSortByColumnName("vertical");
      }
    }
    if (columnName === "buyer") {
      if (sortByColumnName === "buyer") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByBuyer(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByBuyer(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByBuyer(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByBuyer(true));
        }
        setSortByColumnName("buyer");
      }
    }
    if (columnName === "publisher") {
      if (sortByColumnName === "publisher") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPub(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPub(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPub(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPub(true));
        }
        setSortByColumnName("publisher");
      }
    }
    if (columnName === "callerid") {
      if (sortByColumnName === "callerid") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByCid(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByCid(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByCid(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByCid(true));
        }
        setSortByColumnName("callerid");
      }
    }
    if (columnName === "campaign") {
      if (sortByColumnName === "campaign") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByCampaign(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByCampaign(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByCampaign(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByCampaign(true));
        }
        setSortByColumnName("campaign");
      }
    }
    if (columnName === "revenue") {
      if (sortByColumnName === "revenue") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByRevenue(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByRevenue(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByRevenue(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByRevenue(true));
        }
        setSortByColumnName("revenue");
      }
    }
    if (columnName === "payout") {
      if (sortByColumnName === "payout") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPayout(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPayout(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByPayout(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByPayout(true));
        }
        setSortByColumnName("payout");
      }
    }
    if (columnName === "endsource") {
      if (sortByColumnName === "endsource") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByEndsource(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByEndsource(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByEndsource(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByEndsource(true));
        }
        setSortByColumnName("endsource");
      }
    }
    if (columnName === "duration") {
      if (sortByColumnName === "duration") {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByDuration(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByDuration(true));
        }
        // setAsc(!asc);
      } else {
        if (asc) {
          setAsc(false);
          dataCopy.sort(sortByDuration(false));
        }
        if (!asc) {
          setAsc(true);
          dataCopy.sort(sortByDuration(true));
        }
        setSortByColumnName("duration");
      }
    }

    if (filteredData) {
      setFilteredData(dataCopy);
    } else {
      setData(dataCopy);
    }
  };
  const sortByTimestamp = (asc) => {
    return (a, b) => {
      if (a.call_timestamp === b.call_timestamp) {
        return 0;
      }
      if (a.call_timestamp === null) {
        return 1;
      }
      if (b.call_timestamp === null) {
        return -1;
      }
      if (asc) {
        return a.call_timestamp > b.call_timestamp ? 1 : -1;
      } else {
        return a.call_timestamp > b.call_timestamp ? -1 : 1;
      }
    };
  };
  const sortByVertical = (asc) => {
    return (a, b) => {
      if (a.vertical === b.vertical) {
        return 0;
      }
      if (a.vertical === null) {
        return 1;
      }
      if (b.vertical === null) {
        return -1;
      }
      if (asc) {
        return a.vertical > b.vertical ? 1 : -1;
      } else {
        return a.vertical > b.vertical ? -1 : 1;
      }
    };
  };
  const sortByBuyer = (asc) => {
    return (a, b) => {
      if (a.buyer_name === b.buyer_name) {
        return 0;
      }
      if (a.buyer_name === null) {
        return 1;
      }
      if (b.buyer_name === null) {
        return -1;
      }
      if (asc) {
        return a.buyer_name > b.buyer_name ? 1 : -1;
      } else {
        return a.buyer_name > b.buyer_name ? -1 : 1;
      }
    };
  };
  const sortByPub = (asc) => {
    return (a, b) => {
      if (a.publisher_name === b.publisher_name) {
        return 0;
      }
      if (a.publisher_name === null) {
        return 1;
      }
      if (b.publisher_name === null) {
        return -1;
      }
      if (asc) {
        return a.publisher_name > b.publisher_name ? 1 : -1;
      } else {
        return a.publisher_name > b.publisher_name ? -1 : 1;
      }
    };
  };
  const sortByCid = (asc) => {
    return (a, b) => {
      if (a.call_callerid === b.call_callerid) {
        return 0;
      }
      if (a.call_callerid === null) {
        return 1;
      }
      if (b.call_callerid === null) {
        return -1;
      }
      if (asc) {
        return a.call_callerid > b.call_callerid ? 1 : -1;
      } else {
        return a.call_callerid > b.call_callerid ? -1 : 1;
      }
    };
  };
  const sortByCampaign = (asc) => {
    return (a, b) => {
      if (a.campaign_name === b.campaign_name) {
        return 0;
      }
      if (a.campaign_name === null) {
        return 1;
      }
      if (b.campaign_name === null) {
        return -1;
      }
      if (asc) {
        return a.campaign_name > b.campaign_name ? 1 : -1;
      } else {
        return a.campaign_name > b.campaign_name ? -1 : 1;
      }
    };
  };
  const sortByRevenue = (asc) => {
    return (a, b) => {
      if (a.call_revenue === b.call_revenue) {
        return 0;
      }
      if (a.call_revenue === null) {
        return 1;
      }
      if (b.call_revenue === null) {
        return -1;
      }
      if (asc) {
        return a.call_revenue > b.call_revenue ? 1 : -1;
      } else {
        return a.call_revenue > b.call_revenue ? -1 : 1;
      }
    };
  };
  const sortByPayout = (asc) => {
    return (a, b) => {
      if (a.call_payout === b.call_payout) {
        return 0;
      }
      if (a.call_payout === null) {
        return 1;
      }
      if (b.call_payout === null) {
        return -1;
      }
      if (asc) {
        return a.call_payout > b.call_payout ? 1 : -1;
      } else {
        return a.call_payout > b.call_payout ? -1 : 1;
      }
    };
  };
  const sortByEndsource = (asc) => {
    return (a, b) => {
      if (a.call_end_source === b.call_end_source) {
        return 0;
      }
      if (a.call_end_source === null) {
        return 1;
      }
      if (b.call_end_source === null) {
        return -1;
      }
      if (asc) {
        return a.call_end_source > b.call_end_source ? 1 : -1;
      } else {
        return a.call_end_source > b.call_end_source ? -1 : 1;
      }
    };
  };
  const sortByDuration = (asc) => {
    return (a, b) => {
      if (a.call_length === b.call_length) {
        return 0;
      }
      if (a.call_length === 0) {
        return 1;
      }
      if (b.call_length === 0) {
        return -1;
      }
      if (asc) {
        return a.call_length > b.call_length ? 1 : -1;
      } else {
        return a.call_length > b.call_length ? -1 : 1;
      }
    };
  };

  const updateFilteredData = (filterBy, filterValue) => {
    if (filterBy === "all") {
      setFilteredData(null);
      return;
    }
    // console.log("filterBy", filterBy);
    // console.log("filterValue", filterValue);
    let newData = [];
    if (data) {
      setFilteredData(null);

      data.forEach((call) => {
        if (filterBy === "vertical") {
          if (call.vertical === filterValue) {
            newData.push(call);
          }
        }
        if (filterBy === "buyer") {
          if (call.buyer_name === filterValue) {
            newData.push(call);
          }
        }
        if (filterBy === "publisher") {
          if (call.publisher_name === filterValue) {
            newData.push(call);
          }
        }
        if (filterBy === "campaign") {
          if (call.campaign_name === filterValue) {
            newData.push(call);
          }
        }
      });
      // console.log("newData:", newData);
      setFilteredData(newData);
    }
  };

  const handleFilterChange = (e, filterType) => {
    // console.log("e", e);
    const filtersCopy = filters;
    if (filterType === "vertical") {
      filtersCopy.verticals = e;
    }
    if (filterType === "buyer") {
      filtersCopy.buyers = e;
    }
    if (filterType === "publisher") {
      filtersCopy.publishers = e;
    }
    if (filterType === "target") {
      filtersCopy.targets = e;
    }
    if (filterType === "campaign") {
      filtersCopy.campaigns = e;
    }
    // console.log("filtersCopy:", filtersCopy);
    setFilters(filtersCopy);
  };

  const handleFilteringDataClick = () => {
    let setFilters = {
      verticals: [],
      buyers: [],
      publishers: [],
      targets: [],
      campaigns: [],
    };
    Object.entries(filters).forEach((filter) => {
      const [key, value] = filter;
      // console.log(value);
      value.forEach((filterType) => {
        // console.log("filterType:", filterType);
        if (filterType.type === "vertical") {
          // console.log("VERTICALS!");
          setFilters.verticals.push(filterType.value);
        }
        if (filterType.type === "buyer") {
          setFilters.buyers.push(filterType.value);
        }
        if (filterType.type === "publisher") {
          setFilters.publishers.push(filterType.value);
        }
        if (filterType.type === "target") {
          setFilters.targets.push(filterType.value);
        }
        if (filterType.type === "campaign") {
          setFilters.campaigns.push(filterType.value);
        }
      });
    });

    // console.log("setFilters:", setFilters);
    // console.log("filters:", filters);

    let newlyFilteredData;

    newlyFilteredData = data.filter((call) => {
      // console.log("call:", call);

      //? Check for campaign names that differ with dashes
      //? IE: DEBT - INBD - Era Media vs DEBT - INBD - Era Media - Spanish
      let campName = call.campaign_name;
      if (campName.endsWith("2") || campName.endsWith("3") || campName.endsWith("4")) {
        const campNum = campName.slice(-1);
        // console.log("campName:", campName, "Ends with the number:", campNum);
        campName = campName.replace(campNum, `- ${campNum}`);
        // console.log("campName:", campName);
        campName = campName.split("-");
        // console.log("campName2:", campName);
      }

      let filterCampName = setFilters.campaigns.length > 0 ? setFilters.campaigns[0] : [];
      // console.log("filterCampName 1:", filterCampName);
      if (
        filterCampName.length > 0 &&
        (filterCampName.endsWith("2") ||
          filterCampName.endsWith("3") ||
          filterCampName.endsWith("4"))
      ) {
        const filterCampNum = filterCampName.slice(-1);
        // console.log("filterCampName:", filterCampName, "Ends with the number:", filterCampNum);
        filterCampName = filterCampName.replace(filterCampNum, `- ${filterCampNum}`);
        // console.log("filterCampName:", filterCampName);
        filterCampName = filterCampName.split("-");
      }
      if (filterCampName?.length < 1) {
        campName = [];
      }

      let buyerName = call.buyer_name ? call.buyer_name : [];
      let buyerFilter;
      if (setFilters.buyers.length < 1) {
        buyerFilter = true;
      } else {
        buyerFilter = buyerName.includes(setFilters?.buyers);
      }

      let targetName = call.target_name ? call.target_name : [];
      let targetFilter;
      if (setFilters.targets.length < 1) {
        targetFilter = true;
      } else {
        targetFilter = targetName.includes(setFilters?.targets);
      }

      return (
        call.campaign_name?.includes(setFilters?.campaigns) &&
        campName.length === filterCampName.length &&
        call.publisher_name?.includes(setFilters?.publishers) &&
        buyerFilter &&
        call.vertical?.includes(setFilters?.verticals) &&
        targetFilter
      );
    });

    // console.log("newlyFilteredData:", newlyFilteredData);
    setFilteredData(newlyFilteredData);
    handlePageClick({ selected: 0 });
    // setFilteredData(newlyFilteredData?.sort(sortByTimestamp(true)));
  };

  const handleClick = async () => {
    if (
      moment(startDate).format("L") === moment().format("L") ||
      moment(endDate).format("L") === moment().format("L")
    ) {
      await insertAllCalls();
    } else {
      setData(null);
    }
    await getAllCallsFromDb();
    await getQaDispositions();
  };

  const handleShowFlaggedCalls = () => {
    const dataCopy = [...data];
    const flaggedCalls = [];

    dataCopy.forEach((call) => {
      if (call.tags.flags?.flagged) {
        flaggedCalls.push(call);
      }
    });

    if (flaggedCalls.length < 1) {
      alert("There are no flagged calls.");
      return;
    } else {
      setFilteredData(flaggedCalls);
    }
  };

  const handleShowMarkedCalls = () => {
    const dataCopy = [...data];
    const markedCalls = [];

    dataCopy.forEach((call) => {
      if (call.tags.marks?.marked) {
        markedCalls.push(call);
      }
    });

    if (markedCalls.length < 1) {
      alert("There are no flagged calls.");
      return;
    } else {
      setFilteredData(markedCalls);
    }
  };

  //! CURRENTLY NOT BEING USED AS THIS WILL AUTO-START THE SCRIPT
  const retrieveAllData = async () => {
    await insertAllCalls();
    await getAllCallsFromDb();
    await getQaDispositions();
  };
  //!-------------------------------------------------------------

  const updateCallCatDispoFrontEnd = (index, call) => {
    console.log("updateCallCatDispoFrontEnd callIndex:", index);
    console.log("updateCallCatDispoFrontEnd call:", call);

    const newData = [...data];
    newData && newData.length > 0 && newData.splice(index, 1, call);
    setData(newData);

    // if (filteredData) {
    //   handleFilteringDataClick();
    //   sortColumn(sortByColumnName);
    // }

    console.log("updatedCall in data array:", newData[index]);
  };

  const updateCallFromDeleteFrontEnd = (index) => {
    console.log("callIndex:", index);
    // console.log("call:", call);

    const newData = [...data];
    newData && newData.length > 0 && newData.splice(index, 1);
    setData(newData);

    // if (filteredData) {
    //   handleFilteringDataClick();
    //   sortColumn(sortByColumnName);
    // }
    console.log("updatedCall in array:", newData[index]);

    // console.log("updated Call front end!");
  };

  const updateCallFromFlaggedFrontEnd = (index, call) => {
    const newData = [...data];

    newData && newData.length > 0 && newData.splice(index, 1, call);

    setData(newData);

    // if (filteredData) {
    //   handleFilteringDataClick();
    //   sortColumn(sortByColumnName);
    // }

    console.log("updatedCall in array:", newData[index]);
  };

  //!Pagination--------------------------
  const handlePageClick = (event) => {
    console.log("event:", event);
    const newOffset = (event.selected * 200) % data.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  useEffect(() => {
    organizeExistingColumnNames();
  }, [data]);

  useEffect(() => {
    if (data || filteredData) {
      const endOffset = itemOffset + 200;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(
        filteredData
          ? filteredData?.slice(itemOffset, endOffset)
          : data?.slice(itemOffset, endOffset)
      );
      setPageCount(
        filteredData ? Math.ceil(filteredData?.length / 200) : Math.ceil(data?.length / 200)
      );
    }
  }, [itemOffset, data, filteredData]);

  // useEffect(() => {
  //   retrieveAllData();
  // }, []);

  const LOGME = () => {
    // console.log("existingQaKeys:", existingQaKeys);
    // console.log(existingQaKeys);
    // console.log("filters:", filters);
    // console.log("duplicateUniqueIds:", duplicateUniqueIds);
    console.log("data:", data);
    // console.log("filteredData:", filteredData);
    // console.log("Log!");
    // setFilteredData(null);
  };

  return (
    <Box
      w="100vw"
      h="95vh"
      m="auto"
      mt="2.5vh"
      mx="10px"
      px={3}
      alignSelf="center"
      justifySelf="center"
      overflowY="scroll"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
    >
      <TopHeading
        title="QA Calls"
        description="Monitor quality of all incoming calls."
        children={[
          <Flex zIndex={3}>
            {insertCallsLoading || loading || callsLoading ? (
              <Flex mx="10px" flexDir="column" w="300px">
                <Text fontStyle="italic" textAlign="center">
                  &nbsp;
                </Text>
                <Flex justifyContent="flex-start" alignItems="center">
                  <Spinner
                    color="green.400"
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    size="lg"
                  />
                  {insertCallsLoading && <Text ml={2}>Inserting calls into database...</Text>}
                  {callsLoading && <Text ml={2}>Retrieving all calls...</Text>}
                  {loading && <Text ml={2}>Retrieving Dispo keys...</Text>}
                </Flex>
              </Flex>
            ) : (
              <Flex w="300px" justifyContent="flex-end" alignItems="center">
                {lastGenerated && <Text ml={2}>Last generated: {lastGenerated}</Text>}
              </Flex>
            )}
          </Flex>,
          <Flex w="100%" justifyContent="space-between" alignItems="center" zIndex={3}>
            <Flex mx="10px" flexDir="column" zIndex={3}>
              <Text onClick={LOGME}>Start Date</Text>

              <DatePicker
                color="red"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                value={startDate ? moment(startDate).format("L") : null}
                isClearable
                customInput={<DatePickerInput />}
              />
            </Flex>
            <Flex mx="10px" flexDir="column">
              <Text onClick={() => console.log(filteredData)}>End Date</Text>

              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                value={endDate ? moment(endDate).format("L") : null}
                isClearable
                customInput={<DatePickerInput />}
                popperClassName="datepicker-class"
              />
            </Flex>
            <Flex flexDir="column" w="100%" ml="5px">
              <Text fontStyle="italic" textAlign="center">
                &nbsp;
              </Text>
              <Tooltip hasArrow placement="top" label="Generate">
                <IconButton
                  colorScheme="teal"
                  onClick={handleClick}
                  aria-label="Pull All Calls"
                  icon={<FiRefreshCcw />}
                  isLoading={insertCallsLoading || callsLoading || loading}
                />
              </Tooltip>
            </Flex>
            <Flex flexDir="column" w="100%" ml="5px">
              <Text fontStyle="italic" textAlign="center">
                &nbsp;
              </Text>
              <DispositionKey
                username={username}
                qaDispos={qaDispos}
                getQaDispositions={getQaDispositions}
                loading={insertCallsLoading || callsLoading || loading}
              />
            </Flex>
            {data || filteredData ? (
              <QaCallReportInternal
                data={data}
                filteredData={filteredData}
                insertCallsLoading={insertCallsLoading}
                callsLoading={callsLoading}
                loading={loading}
              />
            ) : null}
            {data || filteredData ? (
              <QaCallReportExternal
                data={data}
                filteredData={filteredData}
                insertCallsLoading={insertCallsLoading}
                callsLoading={callsLoading}
                loading={loading}
              />
            ) : null}
          </Flex>,
        ]}
      />

      <Flex flexDir="column" h="500px" mb="15px">
        {callsLoading && !data ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}

        {data || filteredData ? (
          <Flex flexDir="column" w="100%" justify="center" alignItems="flex-start" pb="45px">
            <Flex
              w="100%"
              borderWidth={1}
              padding={4}
              alignItems="center"
              position="sticky"
              top={109}
              backgroundColor={bgColor}
              zIndex={1}
            >
              {/* <Text onClick={LOGME}>HELLO</Text> */}
              <Box w="18.4%" mr="10px">
                <Select
                  placeholder="Vertical"
                  options={existingQaKeys.vertical}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "vertical")}
                />
              </Box>
              <Box w="18.4%" mr="10px">
                <Select
                  placeholder="Buyer"
                  options={existingQaKeys.buyer}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "buyer")}
                />
              </Box>
              <Box w="18.4%" mr="10px">
                <Select
                  placeholder="Publisher"
                  options={existingQaKeys.publisher}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "publisher")}
                />
              </Box>
              <Box w="18.4%" mr="10px">
                <Select
                  placeholder="Target"
                  options={existingQaKeys.target}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "target")}
                />
              </Box>
              <Box w="18.4%" mr="10px">
                <Select
                  placeholder="Campaign"
                  options={existingQaKeys.campaign}
                  styles={selectStatusStyles}
                  isMulti
                  onChange={(e) => handleFilterChange(e, "campaign")}
                />
              </Box>
              <Flex w="11%" justifyContent="space-evenly">
                <Tooltip label="Filter Data" placement="top" hasArrow>
                  <IconButton
                    colorScheme="teal"
                    icon={<AiFillFilter />}
                    onClick={handleFilteringDataClick}
                  />
                </Tooltip>
                <Tooltip label="Show Flagged Calls" placement="top" hasArrow>
                  <IconButton
                    ml={2}
                    colorScheme="red"
                    icon={<AiFillAlert />}
                    onClick={handleShowFlaggedCalls}
                  />
                </Tooltip>
                <Tooltip label="Show Marked Calls" placement="top" hasArrow>
                  <IconButton
                    ml={2}
                    colorScheme="yellow"
                    icon={<TiStarFullOutline />}
                    onClick={handleShowMarkedCalls}
                  />
                </Tooltip>

                <SearchCid
                  allCalls={data}
                  qaDispos={qaDispos}
                  getQaDispositions={getQaDispositions}
                  // loading={loading}
                  updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                  updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                  updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                  username={username}
                  // data={data}
                  // filteredData={filteredData}
                />
              </Flex>
            </Flex>
            <Table w="100%">
              <Thead position="sticky" top={183}>
                <Tr backgroundColor={bgColor}>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("timestamp")}
                  >
                    TIMESTAMP {sortByColumnName === "timestamp" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "timestamp" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("vertical")}
                  >
                    VERTICAL {sortByColumnName === "vertical" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "vertical" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("buyer")}
                  >
                    BUYER {sortByColumnName === "buyer" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "buyer" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("publisher")}
                  >
                    PUBLISHER {sortByColumnName === "publisher" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "publisher" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("callerid")}
                  >
                    CID {sortByColumnName === "callerid" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "callerid" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("campaign")}
                  >
                    TARGET {sortByColumnName === "campaign" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "campaign" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("campaign")}
                  >
                    CAMPAIGN {sortByColumnName === "campaign" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "campaign" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("payout")}
                  >
                    PAYOUT {sortByColumnName === "payout" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "payout" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("revenue")}
                  >
                    REVENUE {sortByColumnName === "revenue" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "revenue" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("endsource")}
                  >
                    END CALL SOURCE {sortByColumnName === "endsource" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "endsource" && !asc ? "▲" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    cursor="pointer"
                    _hover={{ color: "#4fd1c5" }}
                    onClick={() => sortColumn("duration")}
                  >
                    DURATION {sortByColumnName === "duration" && asc ? "▼" : null}{" "}
                    {sortByColumnName === "duration" && !asc ? "▲" : null}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {filteredData && currentItems
                  ? filteredData
                      // .sort(() => sortByTimestamp(asc))
                      .map((call, i) => (
                        <QaCallTableRow
                          key={call.call_unique_jd}
                          call={call}
                          qaDispos={qaDispos}
                          getQaDispositions={getQaDispositions}
                          loading={loading}
                          index={data.map((e) => e.call_unique_id).indexOf(call.call_unique_id)}
                          updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                          updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                          updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                          username={username}
                          isDuplicate={
                            duplicateUniqueIds.includes(call.call_unique_id) ? true : false
                          }
                          data={data}
                          filteredData={filteredData}
                        />
                      ))
                  : data
                      // .sort(() => sortByTimestamp(asc))
                      .map((call, i) => (
                        <QaCallTableRow
                          key={call.call_unique_jd}
                          call={call}
                          qaDispos={qaDispos}
                          getQaDispositions={getQaDispositions}
                          loading={loading}
                          index={i}
                          updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                          updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                          updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                          username={username}
                          isDuplicate={
                            duplicateUniqueIds.includes(call.call_unique_id) ? true : false
                          }
                          data={data}
                          filteredData={filteredData}
                        />
                      ))} */}

                {currentItems
                  ? currentItems
                      // .sort(() => sortByTimestamp(asc))
                      .map((call, i) => (
                        <QaCallTableRow
                          key={call.call_unique_jd}
                          call={call}
                          qaDispos={qaDispos}
                          getQaDispositions={getQaDispositions}
                          loading={loading}
                          index={data.map((e) => e.call_unique_id).indexOf(call.call_unique_id)}
                          updateCallCatDispoFrontEnd={updateCallCatDispoFrontEnd}
                          updateCallFromDeleteFrontEnd={updateCallFromDeleteFrontEnd}
                          updateCallFromFlaggedFrontEnd={updateCallFromFlaggedFrontEnd}
                          username={username}
                          isDuplicate={
                            duplicateUniqueIds.includes(call.call_unique_id) ? true : false
                          }
                          data={data}
                          filteredData={filteredData}
                        />
                      ))
                  : null}
              </Tbody>
            </Table>
          </Flex>
        ) : null}
        {currentItems ? (
          <Flex
            position="absolute"
            justifySelf="center"
            alignItems="center"
            bottom={0}
            bgColor="#1A202C"
            // w="86vw"
            w="90%"
            zIndex={1}
            margin="0 auto"
            h="35px"
          >
            <Flex w="100%" justifyContent="center" h="35px" alignItems="center">
              <ReactPaginate
                nextLabel=">>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                // marginPagesDisplayed={5}
                pageCount={pageCount}
                previousLabel="<<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
