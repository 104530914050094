import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Spinner,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { failedCallsReport } from "./DashboardAPIs";
import moment from "moment";

import { FiRefreshCcw } from "react-icons/fi";
import CallDetailsModal from "../reports/modals/CallDetailsModal";

export default function FailedCalls() {
  const [data, setData] = useState();
  const [totalsData, setTotalsData] = useState();
  const [loading, setLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState("time");
  const [lastUpdated, setLastUpdated] = useState();
  const hoverColor = useColorModeValue("gray.200", "gray.500");

  const sortByCampaignName = (a, b) => {
    if (a.campaignName < b.campaignName) {
      return -1;
    }
    if (a.campaignName > b.campaignName) {
      return 1;
    }
    return 0;
  };

  const sortByDateTime = (a, b) => {
    if (a.callDt > b.callDt) {
      return -1;
    }
    if (a.callDt < b.callDt) {
      return 1;
    }
    return 0;
  };

  const handleClick = async () => {
    setData(null);
    setLoading(true);
    try {
      const data = await failedCallsReport();
      setData(data);
      setLoading(false);
      setLastUpdated(moment().format("LT"));
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const calculateTotals = () => {
    let countData = [];
    if (data) {
      data.records.forEach((call) => {
        countData.push(call.campaignName);
      });
    }
    const countOccurrences = countData.reduce(function (obj, item) {
      obj[item] = (obj[item] || 0) + 1;
      return obj;
    }, {});

    const res = [];
    const separated = Object.keys(countOccurrences).forEach((el) => {
      const obj = {
        campaignName: el,
        count: countOccurrences[el],
      };
      res.push(obj);
    });

    setTotalsData(res);
  };

  useEffect(() => {
    handleClick();
  }, []);

  useEffect(() => {
    if (data) calculateTotals();
  }, [data]);

  return (
    <Box
      w="100%"
      h="85vh"
      m="auto"
      my="10px"
      mx="10px"
      p="20px"
      overflowY={data ? "scroll" : "hidden"}
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.15)"
      borderRadius="7px"
      alignSelf="center"
      justifySelf="center"
      // className="no-scrollbar"
    >
      <Flex justify="flex-end" mb="15px">
        <Flex w="100%" justify="flex-start" alignItems="center" fontSize="18px">
          <Text fontWeight="500" letterSpacing="5px" onClick={() => console.log(data)}>
            FAILED CALLS - {`${moment().format("L")}`}
          </Text>
        </Flex>
        <Flex w="100%" justifyContent="flex-end" alignItems="center">
          {lastUpdated ? (
            <Text fontStyle="italic" mr="5px">
              Last Updated: {lastUpdated}
            </Text>
          ) : null}
          <IconButton onClick={handleClick} aria-label="Pull Volume Info" icon={<FiRefreshCcw />} />
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDir="column" h="500px" mb="15px">
        {loading ? (
          <Flex mt="10%" justify="center">
            <Spinner
              color="green.400"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              size="xl"
            />
          </Flex>
        ) : null}
        {data ? (
          <Flex flexDir="column" w="100%">
            {data.totalCount ? (
              <Text fontSize="24px" fontWeight="500" textAlign="center" my="10px">
                Total Failed Calls: {data.totalCount}
              </Text>
            ) : null}
            {totalsData ? (
              <Table size="sm" w="100%" mb="10px">
                <Thead>
                  <Tr>
                    <Th>Campaign</Th>
                    <Th textAlign="right">Count</Th>
                    <Th textAlign="center">Percent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {totalsData.sort(sortByCampaignName).map((campaign, i) => (
                    <Tr _hover={{ backgroundColor: hoverColor }}>
                      <Td onClick={() => console.log(campaign)}>{campaign.campaignName}</Td>
                      <Td textAlign="right">{campaign.count}</Td>
                      <Td textAlign="center">
                        {((campaign.count / data.totalCount) * 100).toFixed(2)}%
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : null}
            <Table size="sm" w="100%">
              <Thead>
                <Tr>
                  <Th
                    textAlign="center"
                    _hover={{ color: "teal.300" }}
                    onClick={() => setSortFilter("time")}
                    cursor="pointer"
                    w="100px"
                  >
                    Time {sortFilter === "time" ? "▼" : null}
                  </Th>
                  <Th
                    textAlign="center"
                    _hover={{ color: "teal.300" }}
                    onClick={() => setSortFilter("name")}
                    cursor="pointer"
                  >
                    Campaign {sortFilter === "name" ? "▼" : null}
                  </Th>
                  <Th textAlign="center">Publisher</Th>
                  <Th textAlign="center">Caller ID</Th>
                  <Th textAlign="center">Incomplete Reason</Th>
                  <Th textAlign="center">Blocked Reason</Th>
                  <Th textAlign="center">Call Details</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.records
                  ? data.records
                      .sort(sortFilter === "time" ? sortByDateTime : sortByCampaignName)
                      .map((campaign, i) => (
                        <Tr key={`${campaign}-${i}`} _hover={{ backgroundColor: hoverColor }}>
                          <Td textAlign="center">
                            {moment(campaign.callDt).tz("America/Los_Angeles").format("LT")}
                          </Td>
                          <Td textAlign="center">{campaign.campaignName}</Td>
                          <Td textAlign="center">{campaign.publisherName}</Td>
                          <Td textAlign="center">{campaign.inboundPhoneNumber}</Td>
                          <Td textAlign="center">{campaign.incompleteCallReason}</Td>
                          <Td textAlign="center">{campaign.blockReason}</Td>
                          <Td textAlign="center">
                            <CallDetailsModal inboundCallId={campaign.inboundCallId} />
                          </Td>
                        </Tr>
                      ))
                  : null}
              </Tbody>
            </Table>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}
