import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Switch,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  IconButton,
  Divider,
  Spinner,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tag,
  Tooltip,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import moment from "moment";

import {
  updateCampaignCaps,
  updateCampaignPayoutBuffer,
  getCallRoutesWithId,
  updateTargetCaps,
  updateRoutingWeightPriority,
  getTargetGeos,
  getRingTreeSchedule,
  getTargetFilters,
  enableTarget,
  getTargetReferences,
  getCampaignCaps,
  getAllCampaigns,
  enableCampaign,
  getCallRoutesWithIdDirect,
} from "./JobsApi";

import Select from "react-select";

import { RiRouteLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import RingbaLink from "../RingaLink";
import { produceRequiredFields } from "./produceRequiredFields";

const SingleRoute = ({ campaignData, callplanId, targetName }) => {
  const [routingData, setRoutingData] = useState();
  const [routingDataLoading, setRoutingDataLoading] = useState(false);

  const [campaignDataLoading, setCampaignDataLoading] = useState(false);
  const [enableCampaignLoading, setEnableCampaignLoading] = useState(false);

  const [loadingCampaignUpdate, setLoadingCampaignUpdate] = useState(false);
  const [enabled, setEnabled] = useState(campaignData?.enabled);

  const [dailyCap, setDailyCap] = useState();
  const [hourlyCap, setHourlyCap] = useState();
  const [concurrencyCap, setConcurrencyCap] = useState();
  const [payout, setPayout] = useState();
  const [buffer, setBuffer] = useState();
  const [priority, setPriority] = useState();
  const [weight, setWeight] = useState();

  const [oldDailyCap, setOldDailyCap] = useState(
    campaignData?.affiliates[0]?.payoutConversionSettings[0]?.dailyCap === -1
      ? 0
      : campaignData?.affiliates[0]?.payoutConversionSettings[0]?.dailyCap
  );
  const [oldHourlyCap, setOldHourlyCap] = useState(
    campaignData?.affiliates[0]?.payoutConversionSettings[0]?.hourlyCap === -1
      ? 0
      : campaignData?.affiliates[0]?.payoutConversionSettings[0]?.hourlyCap
  );
  const [oldConcurrencyCap, setOldConcurrencyCap] = useState(
    campaignData?.affiliates[0]?.payoutConversionSettings[0]?.concurrencyCap === -1
      ? 0
      : campaignData?.affiliates[0]?.payoutConversionSettings[0]?.concurrencyCap
  );
  const [oldBuffer, setOldBuffer] = useState();
  const [oldPayout, setOldPayout] = useState();

  const handlePayoutChange = (value) => setPayout(value);
  const handleBufferChange = (value) => setBuffer(value);
  const handleDailyCapChange = (value) => setDailyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleHourlyCapChange = (value) => setHourlyCap(value);
  const handlePriorityChange = (value) => setPriority(value);
  const handleWeightChange = (value) => setWeight(value);

  const handleCampaignCapUpdate = async (
    campaignId,
    affiliateId,
    payoutId,
    payoutAmount,
    callLength
  ) => {
    setLoadingCampaignUpdate(true);
    const caps = {
      dailyCap,
      concurrencyCap,
      hourlyCap,
    };

    try {
      const response = await updateCampaignCaps(
        campaignId,
        affiliateId,
        payoutId,
        caps,
        payoutAmount,
        callLength
      );
      if (response) {
        // handleCampaignUpdateFrontEnd();
        alert("Campaign has been updated successfully.");
        setOldCapsPayoutBuffer();
        // getCampaignData();
        setLoadingCampaignUpdate(false);
      } else {
        alert("There seems to have been an error.");
        setLoadingCampaignUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingCampaignUpdate(false);
    }
  };

  const setCaps = () => {
    if (campaignData?.affiliates[0]?.payoutConversionSettings?.length > 0) {
      setDailyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].dailyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap
      );
      setConcurrencyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap
      );
      setHourlyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap
      );
    }
  };

  const setPayoutBuffer = () => {
    if (campaignData?.affiliates[0]?.payoutConversionSettings[0]?.payoutConversionArgs) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
      setOldBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
    }
    if (
      campaignData?.affiliates[0]?.payoutConversionSettings[0]?.payoutConversionType ===
      "connectedCall"
    ) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(0);
      setOldBuffer(0);
    }
  };

  const setOldCapsPayoutBuffer = () => {
    setOldDailyCap(dailyCap);
    setOldHourlyCap(hourlyCap);
    setOldConcurrencyCap(concurrencyCap);
    setOldPayout(payout);
    setOldBuffer(buffer);
  };

  const handleEnableCampaign = async (campaignId) => {
    setEnableCampaignLoading(true);

    console.log("campaignData.enabled:", campaignData.enabled);

    try {
      const response = await enableCampaign(!enabled, campaignId);
      if (response) {
        enabled ? alert("Campaign has been paused.") : alert("Campaign has been enabled.");
      }
      setEnabled(!enabled);
      setEnableCampaignLoading(false);
    } catch (error) {
      alert(error);
      setEnableCampaignLoading(false);
    }
  };

  const handleGetCallRoutesWithIdDirect = async () => {
    const getMatchingTargetName = (routesArray) => {
      let matchingRoute;
      console.log("routesArray:", routesArray);
      routesArray.forEach((route) => {
        if (route.callTarget.name === targetName) {
          console.log("MATCHING TARGET NAME!");
          // console.log(route);
          matchingRoute = route;
        }
      });
      return matchingRoute;
    };

    setRoutingDataLoading(true);
    try {
      const res = await getCallRoutesWithIdDirect(callplanId);
      const matchingRoute = getMatchingTargetName(res.routes);
      console.log("matchingRoute::", matchingRoute);
      setRoutingData(matchingRoute);
      console.log("handleGetCallRoutesWithIdDirect matchingRoute::", matchingRoute);
      setRoutingDataLoading(false);
    } catch (error) {
      console.log(error.message);
      setRoutingDataLoading(false);
    }
  };

  const handleRouteUpdate = async (callRouteId, priority, weight) => {
    try {
      const updateStatus = await updateRoutingWeightPriority(callRouteId, priority, weight);
      if (updateStatus) {
        console.log("Weight and Priority updated successfully!");
        // alert(
        //   `Priority and Weight has been updated successfully:
        //   Priority: ${updateStatus.priority}
        //   Weight: ${updateStatus.weight}`
        // );
      } else {
        alert("There was an error.");
      }
    } catch (error) {
      alert(error.message);
    }
    // alert("ID:" + callRouteId + "Priority:" + priority + "Weight:" + weight);
  };

  const handleSetEnabled = () => {
    if (campaignData) {
      setEnabled(campaignData.enabled);
    }
  };

  const LOGME = () => {
    // console.log(campaignData);
    // console.log("callplanId:", callplanId);
    // console.log("targetName:", targetName);
    console.log("routingData:", routingData);
  };

  useEffect(() => {
    setCaps();
    setPayoutBuffer();
    handleSetEnabled();
  }, [campaignData]);

  useEffect(() => {
    if (routingData) {
      setPriority(routingData.priority.priority);
      setWeight(routingData.priority.weight);
    }
  }, [routingData]);

  // useEffect(() => {
  //   handleGetCallRoutes();
  // }, []);

  return (
    <Flex flexDir="column" p={2} _hover={{ borderWidth: 2, borderColor: "teal.300" }}>
      {campaignData ? (
        <>
          <Flex alignItems="center" py={2}>
            <Table variant="striped" size="lg" mb="10px">
              <Thead mt="5px">
                <Tr>
                  <Th textAlign="center" w="50%" onClick={() => console.log(campaignData)}>
                    Name
                  </Th>

                  <Th textAlign="center" w="25%">
                    DID
                  </Th>
                  <Th textAlign="center" w="20%">
                    Status
                  </Th>
                  <Th textAlign="center" w="5%"></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td
                    textAlign="center"
                    fontWeight={700}
                    onClick={LOGME}
                    color={!enabled ? "gray.600" : null}
                  >
                    {campaignData.name}{" "}
                    <RingbaLink
                      type={campaignData?.pingTreeId ? "ringtree" : "target"}
                      id={campaignData.id}
                    />
                  </Td>
                  <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                    {campaignData.affiliateNumbers &&
                    campaignData.affiliateNumbers.length > 0 &&
                    campaignData.affiliateNumbers[0]
                      ? campaignData.affiliateNumbers[0].phoneNumber
                      : "N/A"}
                  </Td>

                  <Td textAlign="center">
                    <Switch
                      colorScheme="teal"
                      isChecked={enabled}
                      onChange={() => handleEnableCampaign(campaignData.id)}
                    />
                  </Td>
                  <Td textAlign="center">
                    {/* <Spinner color="teal.300" /> */}
                    {!enabled && !enableCampaignLoading ? (
                      <Text color="red.400">(INACTIVE)</Text>
                    ) : null}
                    {enableCampaignLoading ? <Spinner color="teal.300" size="sm" /> : null}
                  </Td>
                </Tr>
              </Tbody>

              {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
            </Table>

            {/* <Text
              fontSize={20}
              fontWeight={700}
              onClick={LOGME}
              color={!enabled ? "gray.600" : null}
            >
              {campaignData.name}{" "}
              {campaignData ? <RingbaLink type="campaign" id={campaignData.id} /> : null}
            </Text>
            {!enabled ? (
              <Text fontWeight={700} color="red.600" ml={3}>
                (INACTIVE)
              </Text>
            ) : null} */}
          </Flex>
          <Flex flexDir="column">
            <Tabs variant="enclosed" colorScheme="teal" isFitted isLazy>
              <TabList>
                <Tab fontWeight={600} onClick={LOGME}>
                  CAMPAIGN CAPS | PAYOUT/BUFFER
                </Tab>
                <Tab fontWeight={600} onClick={handleGetCallRoutesWithIdDirect}>
                  WEIGHT / PRIORITY
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Table variant="striped" size="md" mb="10px">
                    <Thead>
                      <Tr>
                        <Th textAlign="center" color={!enabled ? "gray.600" : null}>
                          Daily
                        </Th>
                        <Th textAlign="center" color={!enabled ? "gray.600" : null}>
                          Hourly
                        </Th>
                        <Th textAlign="center" color={!enabled ? "gray.600" : null}>
                          CC
                        </Th>
                        <Th textAlign="center" color={!enabled ? "gray.600" : null}>
                          Payout
                        </Th>
                        <Th textAlign="center" color={!enabled ? "gray.600" : null}>
                          Buffer
                        </Th>

                        <Th textAlign="center"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          {oldDailyCap}
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          {oldHourlyCap}
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          {oldConcurrencyCap}
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          ${oldPayout}
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          {oldBuffer} s
                        </Td>
                      </Tr>
                      <Tr>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          <NumberInput
                            value={dailyCap}
                            onChange={handleDailyCapChange}
                            isDisabled={!enabled}
                          >
                            <NumberInputField
                              focusBorderColor="teal"
                              size="sm"
                              borderWidth="2px"
                              textAlign="center"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          <NumberInput
                            value={hourlyCap}
                            onChange={handleHourlyCapChange}
                            isDisabled={!enabled}
                          >
                            <NumberInputField
                              focusBorderColor="teal"
                              size="sm"
                              borderWidth="2px"
                              textAlign="center"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          <NumberInput
                            value={concurrencyCap}
                            onChange={handleConcurrencyChange}
                            isDisabled={!enabled}
                          >
                            <NumberInputField
                              focusBorderColor="teal"
                              size="sm"
                              borderWidth="2px"
                              textAlign="center"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          <NumberInput
                            value={payout}
                            onChange={handlePayoutChange}
                            isDisabled={!enabled}
                          >
                            <NumberInputField
                              focusBorderColor="teal"
                              size="sm"
                              borderWidth="2px"
                              textAlign="center"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          {buffer === 0 ? (
                            "Per Connected Call"
                          ) : (
                            <NumberInput
                              value={buffer}
                              onChange={handleBufferChange}
                              isDisabled={!enabled}
                            >
                              <NumberInputField
                                focusBorderColor="teal"
                                size="sm"
                                borderWidth="2px"
                                textAlign="center"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          )}
                        </Td>
                        <Td textAlign="center" color={!enabled ? "gray.600" : null}>
                          <IconButton
                            isDisabled={!enabled}
                            isLoading={loadingCampaignUpdate}
                            colorScheme="teal"
                            aria-label="Open Call Details"
                            icon={<FiUploadCloud />}
                            size="sm"
                            onClick={() =>
                              handleCampaignCapUpdate(
                                campaignData.id,
                                campaignData.affiliates[0].id,
                                campaignData.affiliates[0].payoutConversionSetting.id,
                                payout,
                                buffer
                              )
                            }
                          />
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TabPanel>
                <TabPanel>
                  <Table variant="striped" size="sm" mb="10px">
                    <Thead mt="5px">
                      <Tr>
                        <Th textAlign="center" w="50%">
                          Priority
                        </Th>
                        <Th textAlign="center" w="50%">
                          Weight
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {routingData ? (
                        <>
                          <Tr>
                            {/* <Td textAlign="center">{dailyCap === -1 ? 0 : dailyCap}</Td> */}

                            <Td textAlign="center" maxW="20px">
                              <NumberInput value={priority} onChange={handlePriorityChange}>
                                <NumberInputField
                                  focusBorderColor="teal"
                                  size="sm"
                                  borderWidth="2px"
                                  textAlign="center"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </Td>
                            <Td textAlign="center" maxW="20px">
                              <NumberInput value={weight} onChange={handleWeightChange}>
                                <NumberInputField
                                  focusBorderColor="teal"
                                  size="sm"
                                  borderWidth="2px"
                                  textAlign="center"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </Td>
                          </Tr>
                        </>
                      ) : (
                        <Flex flexDir="column" my="5%" justifyContent="center" alignItems="center">
                          <Spinner color="teal.300" size="sm" />
                        </Flex>
                      )}
                    </Tbody>
                    {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
                  </Table>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {/* <Text
              fontWeight={500}
              letterSpacing="3px"
              textAlign="center"
              backgroundColor="gray.400"
            >
              CAMPAIGN CAPS | PAYOUT/BUFFER
            </Text> */}
          </Flex>
        </>
      ) : (
        <Flex flexDir="column" my="5%" justifyContent="center" alignItems="center">
          <Spinner color="teal.300" size="sm" />
          <Text>Loading Campaign Data...</Text>
        </Flex>
      )}
      <Divider borderWidth={2} />
    </Flex>
  );
};

const CampaignRoutesTable = ({ targetRoutingReferences, targetName }) => {
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState();
  const [filteredCampaignData, setFilteredCampaignData] = useState();
  const fontColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "#2D3748");

  const activeOrPausedOptions = [
    { name: "All", label: "All" },
    { name: "Live", label: "Live" },
    { name: "Paused", label: "Paused" },
  ];

  const selectStatusStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: fontColor,
      backgroundColor: bgColor,
      width: "100%",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: fontColor,
      backgroundColor: isFocused ? "#4FD1C5" : "#1A202C",
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      color: fontColor,
      backgroundColor: "#1A202C",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      color: fontColor,
    }),
  };

  const sortByName = (a, b) => {
    if (a.name > b.name) return 1;
    else if (a.name < b.name) return -1;
    else return 0;
  };

  const getAllCampaignDataFromCallPlanNames = async () => {
    setLoading(true);
    try {
      const res = await getAllCampaigns();
      console.log(res);
      const matchingCampaigns = getOnlyMatchingCampaigns(res);
      console.log("matchingCampaigns:", matchingCampaigns);
      setCampaignData(matchingCampaigns);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getOnlyMatchingCampaigns = (campaignsArray) => {
    const matchingCampaigns = [];

    campaignsArray.forEach((campaign) => {
      targetRoutingReferences.forEach((route) => {
        if (campaign.name === route.callplanName) {
          const campaignAndRouteId = {
            callplanId: route.callplanId,
            campaign,
          };
          matchingCampaigns.push(campaignAndRouteId);
        }
      });
    });

    return matchingCampaigns;
  };

  const handleActiveOrPausedTypeChange = (type) => {
    const filteredData = filterReport(type.name);
    setFilteredCampaignData(filteredData);
  };

  const filterReport = (activeType) => {
    let filteredData;
    console.log("campaignData::", campaignData);
    if (activeType === "All") {
      filteredData = [...campaignData];
    }
    if (activeType === "Live") {
      filteredData = campaignData.filter((campaign) => {
        return campaign.campaign.enabled;
      });
    }
    if (activeType === "Paused") {
      filteredData = campaignData.filter((campaign) => {
        return !campaign.campaign.enabled;
      });
    }
    console.log("filteredData::", filteredData);
    return filteredData;
  };

  useEffect(() => {
    if (targetRoutingReferences && !campaignData) {
      getAllCampaignDataFromCallPlanNames();
    }
  }, [targetRoutingReferences]);

  const logMe = () => {
    console.log("campaignData:", campaignData);
    // console.log("campaignData:", campaignData);
    // console.log("targetName:", targetName);
    console.log("filteredCampaignData:", filteredCampaignData);
    // console.log("targetRoutingReferences:", targetRoutingReferences);
  };

  return (
    <Box my="10px">
      {/* <Text onClick={getAllCampaignDataFromCallPlanNames}>Hello</Text> */}
      {/* <Text onClick={logMe}>LOGME</Text> */}
      {campaignData ? (
        <Box w="25%" mb={3}>
          <Select
            placeholder="LIVE OR PAUSED"
            options={activeOrPausedOptions}
            styles={selectStatusStyles}
            onChange={(e) => handleActiveOrPausedTypeChange(e)}
          />
        </Box>
      ) : null}
      {loading && !campaignData ? (
        <Flex w="100%" mt="20%" justify="center" alignItems="center" flexDir="column">
          <Spinner
            color="green.400"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            size="xl"
          />
          <Text mt={4}>(Loading all campaign references...)</Text>
        </Flex>
      ) : null}
      {campaignData && filteredCampaignData ? (
        <>
          {filteredCampaignData?.sort(sortByName).map((campaign, i) => (
            <SingleRoute
              campaignData={campaign.campaign}
              key={campaign.id}
              callplanId={campaign.callplanId}
              targetName={targetName}
            />
          ))}
        </>
      ) : (
        <>
          {campaignData?.sort(sortByName).map((campaign, i) => (
            <SingleRoute
              campaignData={campaign.campaign}
              key={campaign.campaign.id}
              callplanId={campaign.callplanId}
              targetName={targetName}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const TargetCapsTable = ({ targetDetails }) => {
  const [loadingCapsUpdate, setLoadingCapsUpdate] = useState(false);

  const checkCapExists = (cap) => {
    // console.log("routeDetails:", routeDetails);
    if (cap === -1) {
      return 0;
    } else {
      return cap;
    }
  };

  const getDailyCap = () => {
    if (targetDetails.schedule.dailyCapDayOfWeek) {
      return targetDetails.schedule.dailyCapDayOfWeek[0];
    } else {
      return checkCapExists(targetDetails.schedule.dailyCap);
    }
  };

  const getHourlyCap = () => {
    if (targetDetails.schedule.callCapMatrix) {
      return targetDetails.schedule.callCapMatrix[0][0];
    } else {
      return checkCapExists(targetDetails.schedule.hourlyCap);
    }
  };

  const [dailyCap, setDailyCap] = useState(getDailyCap());
  const [hourlyCap, setHourlyCap] = useState(getHourlyCap());
  const [concurrencyCap, setConcurrencyCap] = useState(
    checkCapExists(targetDetails.schedule.concurrencyCap)
  );
  const [currentDailyCap, setCurrentDailyCap] = useState(dailyCap);
  const [currentHourlyCap, setCurrentHourlyCap] = useState(hourlyCap);
  const [currentConconcurrencyCap, setCurrentConconcurrencyCap] = useState(concurrencyCap);

  const handleHourlyCapChange = (value) => setHourlyCap(value);
  const handleConcurrencyChange = (value) => setConcurrencyCap(value);
  const handleDailyCapChange = (value) => setDailyCap(value);

  const handleTargetCapsUpdate = async () => {
    setLoadingCapsUpdate(true);

    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    // console.log("hoo:", hoo);

    try {
      const response = await updateTargetCaps(
        targetDetails.pingTreeId ? true : false,
        targetDetails.id,
        dailyCap,
        hourlyCap,
        concurrencyCap,
        targetDetails.schedule.hoursOfOperation
      );
      if (response) {
        alert("Target Caps Updated Successfully!");
        setCurrentDailyCap(dailyCap);
        setCurrentHourlyCap(hourlyCap);
        setCurrentConconcurrencyCap(concurrencyCap);
        setLoadingCapsUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingCapsUpdate(false);
    }
  };

  const LOGME = () => {
    // console.log("targetDetails:", targetDetails);
    // console.log("dailyCap:", dailyCap);
    // console.log("hourlyCap:", hourlyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    // console.log("concurrencyCap:", concurrencyCap);
  };

  return (
    <Flex flexDir="column" mb={4}>
      <Text
        fontWeight={500}
        letterSpacing="3px"
        textAlign="center"
        backgroundColor="gray.400"
        onClick={LOGME}
      >
        TARGET CAPS
      </Text>
      <Table variant="striped" size="sm" mb="10px">
        <Thead mt="5px">
          <Tr>
            <Th textAlign="center" w="18%">
              Daily
            </Th>
            <Th textAlign="center" w="18%">
              Hourly
            </Th>
            <Th textAlign="center" w="18%">
              CC
            </Th>
            <Th textAlign="center" w="10%">
              Update
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td textAlign="center">{currentDailyCap}</Td>
            <Td textAlign="center">{currentHourlyCap}</Td>
            <Td textAlign="center">{currentConconcurrencyCap}</Td>
          </Tr>
          <Tr>
            {/* <Td textAlign="center">{dailyCap === -1 ? 0 : dailyCap}</Td> */}
            <Td textAlign="center" maxW="20px">
              <NumberInput
                value={dailyCap}
                onChange={handleDailyCapChange}
                // isDisabled={!active}
              >
                <NumberInputField
                  focusBorderColor="teal"
                  size="sm"
                  borderWidth="2px"
                  textAlign="center"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Td>

            {/* <Td textAlign="center">{hourlyCap}</Td> */}
            <Td textAlign="center" maxW="20px">
              <NumberInput
                value={hourlyCap}
                onChange={handleHourlyCapChange}
                // isDisabled={!active}
              >
                <NumberInputField
                  focusBorderColor="teal"
                  size="sm"
                  borderWidth="2px"
                  textAlign="center"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Td>

            {/* <Td textAlign="center">{concurrencyCap}</Td> */}

            {/* <Td textAlign="center" fontWeight={600} letterSpacing="1px">
                  UPDATE
                </Td> */}
            <Td textAlign="center" maxW="20px">
              <NumberInput
                value={concurrencyCap}
                onChange={handleConcurrencyChange}
                // isDisabled={!active}
              >
                <NumberInputField
                  focusBorderColor="teal"
                  size="sm"
                  borderWidth="2px"
                  textAlign="center"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Td>

            <Td textAlign="center" maxW="20px">
              <IconButton
                isLoading={loadingCapsUpdate}
                colorScheme="teal"
                aria-label="Open Call Details"
                icon={<FiUploadCloud />}
                size="sm"
                onClick={handleTargetCapsUpdate}
                // isDisabled={!active}
              />
            </Td>
          </Tr>
        </Tbody>
        {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
      </Table>
    </Flex>
  );
};

export default function JobsTargetsModal({ campaignData, index, updateCampaignDetailsFrontEnd }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const [targetFilters, setTargetFilters] = useState();
  const [targetHoursOfOperation, setTargetHoursOfOperation] = useState();
  const [targetReferenceData, setTargetReferenceData] = useState();
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enableCampaignLoading, setEnableCampaignLoading] = useState(false);
  const [enabled, setEnabled] = useState(campaignData && campaignData.enabled);

  const [loadingCampaignUpdate, setLoadingCampaignUpdate] = useState(false);
  const [geosLoading, setGeosLoading] = useState(false);
  const [loadingTargetUpdate, setLoadingTargetUpdate] = useState(false);
  const [loadingPayoutBuffer, setLoadingPayoutBuffer] = useState(false);

  const [payout, setPayout] = useState();
  const [buffer, setBuffer] = useState();
  const [dailyCap, setDailyCap] = useState();
  const [oldBuffer, setOldBuffer] = useState();
  const [oldPayout, setOldPayout] = useState();
  const [concurrencyCap, setConcurrencyCap] = useState();
  const [hourlyCap, setHourlyCap] = useState();

  const [requiredDataFields, setRequiredDataFields] = useState([]);

  const [copiedText, setCopiedText] = useState();
  const hoverColor = useColorModeValue("gray.200", "gray.500");
  const bgColor = useColorModeValue("teal.200", "gray.500");

  const handleCallDetails = async () => {
    onOpen();
    setNoData(false);
    await handleGetTargetFilters();
    await handleGetRTSchedule();
    await handleGetTargetReferences();
  };

  const handleOnClose = () => {
    setTargetReferenceData();
    onClose();
  };

  const handleEnableTarget = async (campaignId) => {
    setEnableCampaignLoading(true);

    console.log("campaignData.enabled:", campaignData.enabled);

    try {
      const response = await enableTarget(!enabled, campaignId);
      if (response) {
        enabled ? alert("Campaign has been paused.") : alert("Campaign has been enabled.");
      }
      setEnabled(!enabled);
      setEnableCampaignLoading(false);
    } catch (error) {
      alert(error);
      setEnableCampaignLoading(false);
    }
  };

  const handleCampaignCapUpdate = async (
    campaignId,
    affiliateId,
    payoutId,
    payoutAmount,
    callLength
  ) => {
    setLoadingCampaignUpdate(true);
    const caps = {
      dailyCap,
      concurrencyCap,
      hourlyCap,
    };

    try {
      const response = await updateCampaignCaps(
        campaignId,
        affiliateId,
        payoutId,
        caps,
        payoutAmount,
        callLength
      );
      if (response) {
        handleCampaignUpdateFrontEnd();
        alert("Campaign has been updated successfully.");
        // getCampaignData();
        setLoadingCampaignUpdate(false);
      } else {
        alert("There seems to have been an error.");
        setLoadingCampaignUpdate(false);
      }
    } catch (error) {
      alert(error.message);
      setLoadingCampaignUpdate(false);
    }
  };

  const [loadingReferences, setLoadingReferences] = useState(false);
  const handleGetTargetReferences = async () => {
    setLoadingReferences(true);

    try {
      const res = await getTargetReferences(
        campaignData.pingTreeId ? campaignData.pingTreeId : campaignData.id,
        campaignData.pingTreeId ? true : false
      );
      if (res) {
        setTargetReferenceData(res);
        setLoadingReferences(false);
      } else {
        setLoadingReferences(false);
      }
    } catch (error) {
      console.error(error);
      setNoData(true);
      setLoadingReferences(false);
    }
  };

  const getRoutes = async () => {
    if (data) setData(null);
    setLoading(true);
    try {
      const data = await getCallRoutesWithId(campaignData.name);
      console.log("getRoutes data:", data);
      if (!data) {
        setLoading(false);
        setNoData(true);
        return;
      }
      setData(data);
      // console.log("routes data::", data);
      setLoading(false);
    } catch (error) {
      alert("error:", error);
      setLoading(false);
    }
  };

  const handleGetTargetFilters = async () => {
    setGeosLoading(true);

    if (!campaignData?.criteria) {
      setGeosLoading(false);
      return;
    }

    let allFilters = [];

    for (const filter of campaignData.criteria) {
      if (!filter.bulkCriteria) {
        const filterData = {
          name: filter.tagCriteria[0].tagIds[0],
          values: [[filter.tagCriteria[0].comparisonType]],
          isNegativeMatch: filter.tagCriteria[0].isNegativeMatch,
        };
        allFilters.push(filterData);
        continue;
      }

      let filterData = {
        name: filter.bulkCriteria.tagIds[0],
        values: [],
        isNegativeMatch: filter?.bulkCriteria?.isNegativeMatch,
      };

      try {
        const res = await getTargetFilters(filter.bulkCriteria.id);
        console.log("getTargetFilters res::", res);
        filterData.values.push(res);
        allFilters.push(filterData);
      } catch (error) {
        console.error(error);
        setGeosLoading(false);
        return;
      }
    }

    setTargetFilters(allFilters);
    setGeosLoading(false);
  };

  const handleGetRTSchedule = async () => {
    if (!campaignData.pingTreeId) {
      return;
    }
    try {
      const res = await getRingTreeSchedule(campaignData.pingTreeId);
      setTargetHoursOfOperation(res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRouteUpdate = async (callRouteId, priority, weight) => {
    try {
      const updateStatus = await updateRoutingWeightPriority(callRouteId, priority, weight);
      if (updateStatus) {
        console.log("Weight and Priority updated successfully!");
        // alert(
        //   `Priority and Weight has been updated successfully:
        //   Priority: ${updateStatus.priority}
        //   Weight: ${updateStatus.weight}`
        // );
      } else {
        alert("There was an error.");
      }
    } catch (error) {
      alert(error.message);
    }
    // alert("ID:" + callRouteId + "Priority:" + priority + "Weight:" + weight);
  };

  const setCaps = () => {
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings.length > 0
    ) {
      setDailyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].dailyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].dailyCap
      );
      setConcurrencyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap
      );
      setHourlyCap(
        campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap === -1
          ? 0
          : campaignData.affiliates[0].payoutConversionSettings[0].hourlyCap
      );
    }
  };

  const setPayoutBuffer = () => {
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings &&
      campaignData.affiliates[0].payoutConversionSettings[0] &&
      campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
    ) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
      setOldBuffer(
        campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs
          .callLengthInSeconds
      );
    }
    if (
      campaignData &&
      campaignData.affiliates &&
      campaignData.affiliates[0] &&
      campaignData.affiliates[0].payoutConversionSettings &&
      campaignData.affiliates[0].payoutConversionSettings[0] &&
      campaignData.affiliates[0].payoutConversionSettings[0].payoutConversionType ===
        "connectedCall"
    ) {
      setPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setOldPayout(campaignData.affiliates[0].payoutConversionSettings[0].payoutAmount);
      setBuffer(0);
      setOldBuffer(0);
    }
  };

  const handleCampaignUpdateFrontEnd = () => {
    let newCampaignData = campaignData;

    newCampaignData.affiliates[0].payoutConversionSettings[0].dailyCap = parseInt(dailyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].hourlyCap = parseInt(hourlyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].concurrencyCap =
      parseInt(concurrencyCap);
    newCampaignData.affiliates[0].payoutConversionSettings[0].payoutAmount = parseInt(payout);
    newCampaignData.affiliates[0].payoutConversionSettings[0].payoutConversionArgs.callLengthInSeconds =
      parseInt(buffer);

    console.log("newCampaignData:", newCampaignData);
    setPayoutBuffer();
    updateCampaignDetailsFrontEnd(index, newCampaignData);
  };

  const calculateDayOfWeek = (day) => {
    if (day === 0) return "Sunday";
    if (day === 1) return "Monday";
    if (day === 2) return "Tuesday";
    if (day === 3) return "Wednesday";
    if (day === 4) return "Thursday";
    if (day === 5) return "Friday";
    if (day === 6) return "Saturday";
  };

  const logMe = () => {
    console.log("targetReferenceData:", targetReferenceData);
    // console.log("targetFilters:", targetFilters);
    // console.log("dailyCap:", dailyCap);
    // console.log("concurrencyCap:", concurrencyCap);
    console.log("campaignData:", campaignData);
    // console.log("data:", data);
    // console.log("requiredDataFields:", requiredDataFields);
    // createdExampleEndpoint();
  };

  // useEffect(() => {
  //   handleGetTargetGeos();
  // }, []);

  // useEffect(() => {
  //   handleGettingRequiredFields();
  // }, [data]);

  return (
    <>
      <Tr _hover={{ backgroundColor: hoverColor }} cursor="pointer" onClick={handleCallDetails}>
        <Td onClick={() => console.log(campaignData)}>{campaignData.name}</Td>
        <Td textAlign="center">
          {campaignData.instructions ? campaignData.instructions.number : "N/A"}
        </Td>
        <Td textAlign="center">
          <Switch colorScheme="teal" isChecked={enabled} isReadOnly />
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={handleOnClose} size="7xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h="85vh">
          {/* <Button onClick={logMe}>CLICK</Button> */}
          <ModalHeader>
            <Flex flexDir="column">
              <Text onClick={logMe}>
                {campaignData.name}{" "}
                <RingbaLink
                  type={campaignData?.pingTreeId ? "ringtree" : "target"}
                  id={campaignData.id}
                />
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {campaignData ? (
              <Flex h="70vh">
                <Box w="40%" borderWidth={1} mr={2} pl={2} overflowY="scroll">
                  <Table variant="striped" size="lg" mb="10px">
                    <Thead mt="5px">
                      <Tr>
                        <Th textAlign="center" w="50%">
                          Name
                        </Th>

                        <Th textAlign="center" w="25%">
                          DID
                        </Th>
                        <Th textAlign="center" w="20%">
                          Status
                        </Th>
                        <Th textAlign="center" w="5%"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr _hover={{ backgroundColor: hoverColor }}>
                        <Td textAlign="center">
                          {campaignData.name}{" "}
                          <RingbaLink
                            type={campaignData?.pingTreeId ? "ringtree" : "target"}
                            id={campaignData.id}
                          />
                        </Td>
                        <Td textAlign="center">
                          {campaignData.instructions ? campaignData.instructions.number : "N/A"}
                        </Td>

                        <Td textAlign="center">
                          <Switch
                            colorScheme="teal"
                            isChecked={enabled}
                            onChange={() => handleEnableTarget(campaignData.id)}
                          />
                        </Td>
                        <Td textAlign="center">
                          {/* <Spinner color="teal.300" /> */}
                          {enableCampaignLoading ? <Spinner color="teal.300" size="sm" /> : null}
                        </Td>
                      </Tr>
                    </Tbody>

                    {/* <Button onClick={() => console.log("routeDetails:", routeDetails)}>ROUTE DETAILS</Button> */}
                  </Table>
                  {campaignData ? (
                    <Flex flexDir="column">
                      <TargetCapsTable targetDetails={campaignData} />
                      <Text
                        fontWeight={500}
                        letterSpacing="3px"
                        textAlign="center"
                        backgroundColor="gray.400"
                      >
                        HOURS OF OPERATION
                      </Text>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th textAlign="center">Day</Th>
                            <Th textAlign="center">Hours</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {campaignData?.pingTreeId ? (
                            <>
                              {targetHoursOfOperation?.map((day, index) => (
                                <Tr
                                  _hover={{ backgroundColor: "teal.400" }}
                                  key={`b-${campaignData.id}-${day.openTime.hour}-${index}`}
                                >
                                  <Td textAlign="center">{calculateDayOfWeek(index)}</Td>
                                  <Td textAlign="center">
                                    {`${day.openTime.hour}AM - ${day.closeTime.hour - 12}PM`} (PT)
                                  </Td>
                                </Tr>
                              ))}
                            </>
                          ) : (
                            <>
                              {campaignData?.schedule?.hoursOfOperation ? (
                                campaignData?.schedule?.hoursOfOperation?.map((day, index) => (
                                  <Tr
                                    _hover={{ backgroundColor: "teal.400" }}
                                    key={`a-${campaignData.id}-${day.openTime.hour}-${index}`}
                                  >
                                    <Td textAlign="center">{calculateDayOfWeek(index)}</Td>
                                    <Td textAlign="center">
                                      {!day.isClosed
                                        ? `${day.openTime.hour}AM - ${
                                            day.closeTime.hour > 12
                                              ? day.closeTime.hour - 12
                                              : day.closeTime.hour
                                          }PM (PT)`
                                        : "CLOSED"}{" "}
                                    </Td>
                                  </Tr>
                                ))
                              ) : (
                                <Tr _hover={{ backgroundColor: "teal.400" }}>
                                  <Td textAlign="center">EVERYDAY</Td>
                                  <Td textAlign="center">OPEN</Td>
                                </Tr>
                              )}
                            </>
                          )}
                        </Tbody>
                      </Table>
                      <Text
                        my={3}
                        fontWeight={500}
                        letterSpacing="3px"
                        textAlign="center"
                        backgroundColor="gray.400"
                      >
                        FILTERS
                      </Text>

                      {campaignData?.criteria?.length > 0 ? (
                        <Table size="xs" mb={4}>
                          <Thead>
                            <Tr>
                              <Th textAlign="center" w="20%">
                                Name
                              </Th>
                              <Th textAlign="center" w="10%">
                                Operator
                              </Th>
                              <Th textAlign="center" w="70%">
                                Values
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {targetFilters ? (
                              <>
                                {targetFilters.map((filter, index) => (
                                  <Tr
                                    key={`${filter}-${index}`}
                                    _hover={{ backgroundColor: "teal.400" }}
                                  >
                                    {}
                                    <Td textAlign="center" onClick={() => console.log(filter)}>
                                      {filter?.name
                                        ?.replace("user:", "")
                                        .replace("InboundNumber:", "")}
                                    </Td>
                                    <Td textAlign="center">
                                      {filter.isNegativeMatch ? "NOT EQUAL" : "EQUALS"}
                                    </Td>
                                    <Td textAlign="center">
                                      {filter.values[0]?.length > 0 && filter.values[0]?.length < 60
                                        ? filter.values[0]
                                            ?.sort()
                                            ?.map((entry, index) => (
                                              <>
                                                {filter.values[0].lastIndexOf(entry) ===
                                                filter.values[0].length - 1 ? (
                                                  <span>{entry}</span>
                                                ) : (
                                                  <span>{entry}, </span>
                                                )}
                                              </>
                                            ))
                                        : null}

                                      {filter?.values[0]?.length > 59
                                        ? `${filter?.values[0]?.length} ENTRIES`
                                        : null}
                                    </Td>
                                  </Tr>
                                ))}
                              </>
                            ) : null}
                          </Tbody>
                        </Table>
                      ) : (
                        <Text textAlign="center" p="5%">
                          No Filters for this Target
                        </Text>
                      )}
                    </Flex>
                  ) : (
                    <Flex my="10%" justify="center">
                      <Spinner
                        color="green.400"
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        size="xl"
                      />
                    </Flex>
                  )}
                </Box>

                <Flex w="60%" h="70vh" overflowX="hidden" overflowY="scroll">
                  <Box w="100%" borderWidth={1} ml={2} pl={2}>
                    <Flex flexDir="column">
                      {loadingReferences ? (
                        <Flex mt="20%" justify="center" alignItems="center" flexDir="column">
                          <Spinner
                            color="green.400"
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            size="xl"
                          />
                          <Text mt={4}>(This can take several seconds...)</Text>
                        </Flex>
                      ) : null}
                      {noData ? (
                        <Flex mt="10%" justify="center">
                          <Text>No Routes Found.</Text>
                        </Flex>
                      ) : null}
                      {data ? (
                        <Text
                          fontWeight={500}
                          letterSpacing="3px"
                          textAlign="center"
                          backgroundColor="gray.400"
                          mt="10px"
                        >
                          TARGET ROUTING & CAPS
                        </Text>
                      ) : null}
                      {targetReferenceData ? (
                        <CampaignRoutesTable
                          targetRoutingReferences={targetReferenceData}
                          targetName={campaignData.name}
                        />
                      ) : null}
                      {!targetReferenceData && !loadingReferences ? (
                        <Text textAlign="center" mt="20%" letterSpacing={3}>
                          NO ROUTING REFERENCES
                        </Text>
                      ) : null}

                      {/* {data
                        ? data.routes.map((routeDetails, i) => (
                            <RoutingPlanTable
                              key={
                                routeDetails.callTarget
                                  ? routeDetails.callTarget
                                  : routeDetails.callPingTree
                              }
                              routeDetails={routeDetails}
                              handleRouteUpdate={handleRouteUpdate}
                              handleTargetCapsUpdate={handleTargetCapsUpdate}
                              loadingTargetUpdate={loadingTargetUpdate}
                              active={
                                (routeDetails.callTarget && routeDetails.callTarget.enabled) ||
                                (routeDetails.callPingTree && routeDetails.callPingTree.enabled)
                              }
                            />
                          ))
                        : null} */}
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
