import axios from "axios";
import moment from "moment";

const lookUpNumber = async (payload) => {
  const cid = payload.cid;
  const url = `https://api.blacklistalliance.net/lookup?key=XE2UbXJTxMrPRKDbgp7M&ver=v3&resp=json&phone=${cid}`;

  console.log("getAllDispositions payload:", payload);

  try {
    const result = await axios(url);
    if (result.data) {
      return result.data;
    } else {
      return "Error.";
    }
  } catch (error) {
    alert(error.message);
  }
};

export { lookUpNumber };
